<template>
  <div>
    <span
      class="title"
      v-text="MESSAGES.TITLE"
    />

    <CMLoader
      :loading="loading.isVisible"
      :message="loading.message"
    />

    <div v-if="!loading.isVisible">
      <div v-if="error.isVisible">
        <CMError
          :title="error.title"
          :text="error.text"
          :code="error.code"
          @hideError="hideError"
        />
      </div>

      <div v-else>
        <VContainer
          align="center"
          align-content="center"
          justify="center"
        >
          <VCol self-align="center">
            <VBtnToggle
              dense
              mandatory
              dark
            >
              <VBtn
                v-for="button in toggleButtons"
                :key="button.value"
                @click="setSearchMethod({
                  type: button.value,
                  resetCompleted: true
                })"
                v-text="button.title"
              />
            </VBtnToggle>
          </VCol>
        </VContainer>

        <CMSelectGroup
          v-if="searchMethod.type === MESSAGES.SEARCH_METHODS.ZONE"
          :source="source.AREAMAP"
          :depth="source.DEPTH"
          :dense="false"
          item-children="SUBAREAS"
          store-key="areaMap"
          @updateModel="setSearchMethod"
        />

        <ASElementForm
          v-else
          @updateModel="setSearchMethod"
        />

        <p
          v-if="isCompleted"
          class="noError"
          v-text="MESSAGES.DONE"
        />

        <VBtn
          v-if="searchMethod.value"
          class="form-submit"
          @click="setArea"
          v-text="MESSAGES.ACTION"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CMSelectGroup from '@/components/Common/CMSelectGroup.vue'
import CMLoader from '@/components/Common/CMLoader.vue'
import CMError from '@/components/Common/CMError.vue'
import ASElementForm from '@/components/AreaSelector/ASElementForm.vue'
import areaGridMixin from '@/mixins/areaGridMixin'
import errorsMixin from '@/mixins/errorsMixin'

export default {
  name: 'ASForm',

  components: {
    ASElementForm,
    CMSelectGroup,
    CMLoader,
    CMError
  },

  mixins: [areaGridMixin, errorsMixin],

  props: {
    source: {
      type: Object,
      default: () => ({})
    },
    isDrawMap: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      searchMethod: {
        type: 'ZONE',
        value: null
      },
      isCompleted: false,
      loading: {
        isVisible: false,
        message: this.$t('grid_set.getting_grid_msg')
      },
      MESSAGES: {
        TITLE: this.$t('grid_set.title'),
        DONE: this.$t('grid_set.completed_fields'),
        ACTION: this.$t('ok'),
        SEARCH_METHODS: {
          ZONE: 'ZONE',
          ELEMENT: 'ELEMENT'
        }
      }
    }
  },

  computed: {
    toggleButtons () {
      const { SEARCH_METHODS } = this.MESSAGES

      return [
        { title: this.$t('grid_set.by_area'), value: SEARCH_METHODS.ZONE },
        { title: this.$t('grid_set.by_element'), value: SEARCH_METHODS.ELEMENT }
      ]
    }
  },

  watch: {
    searchMethod: {
      deep: true,
      handler (searchMethod) {
        this.checkElementValues(searchMethod)
      }
    }
  },

  methods: {
    setSearchMethod ({ type, value, resetCompleted = false }) {
      if (resetCompleted) this.isCompleted = false

      this.searchMethod = {
        ...(type ? { type } : { type: this.searchMethod.type }),
        value
      }
    },

    checkElementValues (searchMethod) {
      const isElement = searchMethod.type === this.MESSAGES.SEARCH_METHODS.ELEMENT

      if (isElement) {
        const { type, name, id, radius } = searchMethod.value || {}
        const unIdentified = ![name, id].some(Boolean)

        if (type === null || unIdentified || !radius) {
          this.searchMethod.value = null
        }
      }
    },

    toggleLoading () {
      this.loading.isVisible = !this.loading.isVisible
    },

    async setArea () {
      this.isCompleted = true
      const isZone = this.searchMethod.type === this.MESSAGES.SEARCH_METHODS.ZONE
      const value = isZone ? this.searchMethod.value.ID : this.searchMethod.value

      this.$sessionStorage.zoneId = isZone && value
      this.$sessionStorage.gridElement = !isZone && JSON.stringify(value)

      if (this.isDrawMap) {
        return isZone
          ? await this.getAreaGrid({
            isZone,
            value,
            withEvent: true
          })
          : this.$emit('getElement')
      }

      this.toggleLoading()

      const response = await this.getAreaGrid({
        isZone,
        value,
        redirect: true
      })

      this.checkErrors(response)
      this.toggleLoading()
    },

    hideError () {
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  font-size: 24px;
  color: #f4c020;
}

.v-btn-toggle {
  border-radius: 3px;
}

.v-btn-toggle .v-btn:first-child {
  border-radius: 3px 0 0 3px;
}

.v-btn-toggle .v-btn:last-child {
  border-radius: 0 3px 3px 0;
}

.v-btn-toggle .v-btn:not(.v-item--active) {
  background-color: #1e1e1e;
  color: #f4c020;
}
.form-submit {
  background: #272727 !important;
  border: none;
  color: #f4c020 !important;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  text-align: center;
  transition: background 0.2s;
  border-radius: 3px;

  &:hover {
    background: #f4c020 !important;
    color: #000 !important;
  }
}
.noError {
  margin: 1rem 0 0;
  color: #096b22;
}
</style>
