<template>
  <div>
    <LCircle
      :key="station.NAME"
      :ref="station.NAME"
      :lat-lng="station.latLng"
      :name="'station_' + station.NAME"
      :color="setElementColor(station.NAME, colorStation)"
      :radius="3"
      :fill-color="setElementColor(station.NAME, colorStation)"
      :fill-opacity="setOpacity(station.NAME)"
      :opacity="setOpacity(station.NAME)"
      :class-name="'network' + station.NAME + ' station' + station.ID + ' circle'"
      @mouseover="mouseover($event.target, station.ID, 'station', level, station.NAME, true)"
      @mouseleave="mouseleave($event.target, station.ID, 'station', level, station.NAME, true)"
      @click="click(level, station.NAME, station.ID, 'station', false, hostingCapacityMap, multiselect)"
      @contextmenu="handleContextmenu($event, station)"
    >
      <LTooltip
        v-if="showTooltip"
        :options="tooltipOptions"
      >
        <span
          :style="tooltipStyle"
        >
          {{ station.NAME }}
        </span>
      </LTooltip>
      <LTooltip v-if="hostingCapacityMap && tooltipText">
        <strong>{{ tooltipText }}</strong>
      </LTooltip>
    </LCircle>
  </div>
</template>

<script>
import proj4 from 'proj4'
import { LCircle, LTooltip } from 'vue2-leaflet'
import click from '@/mixins/map/mapElementClickMixin'
import unclickElements from '@/mixins/map/mapElementUnselectMixin'
import clickElements from '@/mixins/map/mapElementSelectMixin'
import mouseover from '@/mixins/map/mapElementMouseoverMixin'
import mouseleave from '@/mixins/map/mapElementMouseleaveMixin'
import mapElementOpacityMixin from '@/mixins/map/mapElementOpacityMixin'
import mapElementColorMixin from '@/mixins/map/mapElementColorMixin'

export default {
  name: 'StationLayer',
  components: {
    LCircle,
    LTooltip
  },
  mixins: [
    click,
    unclickElements,
    clickElements,
    mouseover,
    mouseleave,
    mapElementOpacityMixin,
    mapElementColorMixin
  ],
  props: {
    stationData: {
      type: Object,
      required: true
    },
    colorStation: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    zoom: {
      type: Number,
      required: true
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    contextMenu: {
      type: Boolean,
      default: false
    },
    level: {
      type: String,
      default: ''
    },
    hostingCapacityMap: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      fontSizePerZoom: {
        18: 13,
        19: 14,
        20: 16,
        21: 18,
        22: 20
      },
      tooltipOptions: { permanent: true, direction: 'left', opacity: 1, className: 'tooltip-permanent' }
    }
  },
  computed: {
    lowVoltage() {
      const voltageLevels = this.stationData?.VOLTAGE_LEVELS?.filter (level => level < 1000 && level > 0)
      return voltageLevels[0]
    },
    station () {
      const StationUpdated = this.stationData
      const location = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [StationUpdated.X, StationUpdated.Y])
      StationUpdated.latLng = [location[1], location[0]]
      return StationUpdated
    },
    showTooltip () {
      let show = false
      if (this.zoom > 15) { show = true } else if (!this.stationData.VOLTAGE_LEVELS.includes(this.lowVoltage) && this.zoom > 11) { show = true }
      return show
    },
    tooltipStyle () {
      let size = 12
      if (this.fontSizePerZoom[this.zoom]) { size = this.fontSizePerZoom[this.zoom] }
      return 'font-size:' + size + 'px'
    }
  },

  methods: {
    handleContextmenu (e, station) {
      if (this.contextMenu) {
        const contextMenuPayload = {
          x: e.containerPoint.x + 75,
          y: e.containerPoint.y + 75,
          toggle: 'contextMenu'
        }

        const stationPayload = {
          path: 'contextMenuElement',
          value: { type: 'contextMenuStation', info: station }
        }

        this.$store.dispatch('setClick', { lat: e.latlng.lat, lng: e.latlng.lng, idElement: station.ID })
        this.$store.dispatch('setNewPosition', contextMenuPayload)
        this.$store.dispatch('setElement', stationPayload)
      }
    }
  }
}
</script>
<style>
.element-clicked {
  stroke: #f4c020;
}
.tooltip-permanent {
  background-color: rgba(0,0,0,0);
  border: 0px;
  box-shadow:0 0px;
  color:#fff;
}
.leaflet-tooltip-left:before{
  border: none;
}
</style>
