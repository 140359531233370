<template>
  <VTabs
    id="tabs"
    v-model="selectedTab"
    :background-color="isYellowTheme ? '#f4c020' : '#1e1e1e'"
    :height="isYellowTheme ? '50px' : ''"
  >
    <VTabsSlider :color="isYellowTheme ? '#000' : '#f4c01f'" />
    <VTab
      v-for="tab in tabs"
      :key="tab.title"
      :active="tab.active"
      :disabled="tab.disabled"
      light
      :style="customStyle"
    >
      {{ tab.translation ? tab.translation : tab.title }}
    </VTab>
    <slot />
  </VTabs>
</template>

<script>
export default {
  name: 'CMTabs',

  props: {
    tabs: {
      type: Array,
      default: () => []
    },

    handleChange: {
      type: Function,
      default: () => {}
    },
    customStyle: {
      type: String,
      default: ''
    },
    isYellowTheme: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    selectedTab: null
  }),

  watch: {
    tabs: {
      deep: true,
      handler (val) {
        this.selectedTab = val.find(tab => tab.active)
      }
    },

    selectedTab: {
      deep: true,
      handler (val) {
        const tableInfo = this.tabs.find((_, index) => index === val)
        tableInfo && this.handleChange(tableInfo)
      }
    }
  }
}
</script>

<style scoped>
.v-tab {
  max-width: unset !important;
  margin-inline: 0 !important;
}

.v-tabs {
  flex: unset;
}

::v-deep .theme--dark.v-tabs-items {
  background: unset !important;
}
</style>
