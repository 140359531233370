<template>
  <CMTable
    :items="violations.data"
    :page-size="10"
    show-select
    :multiple-select="false"
    identifier="uid"
    @onItemSelected="onItemSelected"
  />
</template>

<script>
import { mapState } from 'vuex'
import vuexMixin from '@/mixins/vuexMixin'

export default {
  name: 'DTPanelKpisPQViolations',

  components: {
    CMTable: () => import('@/components/Common/CMTable.vue')
  },

  mixins: [vuexMixin],

  computed: {
    ...mapState({
      violations: ({ violations }) => violations || {},
      DTProject: ({ DTProject }) => DTProject || {}
    })
  },

  methods: {
    getElementInfoByNetwork (elementTitle, elementId, networksInfo = []) {
      const dictionaryElementTypes = {
        CGP: 'connection_points',
        Line: 'lines',
        Transformer: 'transformers'
      }

      const elementType = dictionaryElementTypes[elementTitle]

      if (!elementType) return {}

      const networks = networksInfo
        .map(info => info.networks)
        .flat()

      return networks.map(network =>
        network[elementType]
          .filter(element => element.ID === elementId))
        .flat()
        .filter(Boolean)[0] || {}
    },

    updateHeatmapSelector (selected = {}) {
      const dictionaryViolation = {
        OverVoltage: phase => ({
          capital: 'V',
          measurement: 'voltage',
          submeasurement: `ph${phase}`
        }),
        OverLoading: () => ({
          capital: 'P',
          measurement: 'activePower',
          submeasurement: 'total'
        }),
        OverCurrent: phase => ({
          capital: 'I',
          measurement: 'current',
          submeasurement: `ph${phase}`
        }),
        VoltageUnbalance: () => ({
          capital: 'U',
          measurement: 'unbalance',
          submeasurement: ''
        })
      }

      dictionaryViolation.UnderVoltage = dictionaryViolation.OverVoltage

      const { capital, measurement, submeasurement } = dictionaryViolation[selected.violation]
        ? dictionaryViolation[selected.violation](selected.phase)
        : {}

      if (measurement) {
        this.forceUpdateMeasurement(capital, submeasurement)

        this.setVuexElement({
          path: 'measurement',
          value: {
            measurement,
            submeasurement
          }
        })

        return {
          capital,
          measurement,
          submeasurement
        }
      }
    },

    forceUpdateMeasurement (capital, submeasurement) {
      const measurementButton = document.getElementById('selected_measurement_content')
        
        if (measurementButton) {
          measurementButton.innerHTML = 
            `${capital}<span style="font-size:10px">${submeasurement || ''}</span>`
        }
    },

    forceMapZoomOut () {
      const zoomOutControl = document.querySelector('.leaflet-control-zoom-out')
      zoomOutControl && zoomOutControl.click()
    },

    simulatePathClick (pathElement) {
      const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })

      pathElement && pathElement.dispatchEvent(clickEvent)
    },

    getPathElement (networkId, elementId, elementType) {
      const query = elementType === 'fuse'
        ? `.${elementType}${elementId}`
        : `.network${networkId}.${elementType}${elementId}`

      return document.querySelector(query)
    },

    setZoomElementOnMap (networkId, elementId, elementType) {
      const pathElement = this.getPathElement(networkId, elementId, elementType)

      if (pathElement) {
        const svgElement = pathElement.parentElement.parentElement

        if (svgElement) {
          svgElement.addEventListener('click', () => {})
          this.simulatePathClick(pathElement)
        }
      }
    },

    onItemSelected ([selected = {}] = []) {
      const heatmapSelector = this.updateHeatmapSelector(selected)

      const dictionaryElementTypes = {
        CGP: 'connectionPoint',
        Line: 'line',
        Transformer: 'fuse'
      }

      this.setVuexElement({
        path: 'violations',
        value: {
          ...this.violations,
          selected
        }
      })

      if (heatmapSelector) {
        const elementData = this.getElementInfoByNetwork(
          selected.element,
          selected.ID,
          this.DTProject.networksInfo
        )

        return elementData
          ? this.setZoomElementOnMap(
              elementData.NETWORK,
              selected.ID,
              dictionaryElementTypes[selected.element]
            )
          : null
      }

      this.forceMapZoomOut()
    }
  }
}

</script>

<style scoped>
.kpi-table {
  font-family: 'Faktum Light', sans-serif;
}
</style>
