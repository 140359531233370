<template>
  <div
    class="hideKpiPanelButton"
  >
    <div class="selection-tool">
      <div
        id="selected_magnitude"
        class="hexagono-xs onmap selected"
        style="margin-right: -450px !important"
        @click="togglePanel()"
      >
        <span
          id="selected_measurement_content"
          class="hexagono-xs-content onmap selected"
        >
          {{ hideShowPanelText }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HideRightPanelBtn',
  props: {
    panelShow: {
      type: Boolean,
      default: false
    },
    togglePanelShow: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      hideShowPanelText: 'Hide'
    }
  },
  methods: {
    togglePanel () {
      this.togglePanelShow(!this.panelShow)
      this.hideShowPanelText = this.panelShow ? 'Show' : 'Hide'
    }
  }
}
</script>

<style lang="scss" scoped>
.hideKpiPanelButton {
  z-index: 400;
  position: relative;
  bottom: 55.3vh;
  float: right;
  height: 30px;
  line-height: 30px;
  left: -245px;
  background-color: red;
    .selection-tool{
      z-index:503;
      position:absolute;
      top:55px;
      right:33%;
    }
}
</style>
