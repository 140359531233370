<template>
  <v-card>
    <TIAMap v-if="tab==0" />
    <TIAMonitoring v-else-if="tab==1" />
  </v-card>
</template>

<script>

import 'splitpanes/dist/splitpanes.css'
import TIAMap from '@/components/TIA/TIAMap/TIAMap.vue'
import TIAMonitoring from '@/components/TIA/TIAMonitoring/TIAMonitoring.vue'
import { mapState } from 'vuex'

export default {
  name: 'TIA',
  metaInfo: {
    title: 'TIA'
  },
  components: {
    TIAMap,
    TIAMonitoring
  },

  data: () => ({
    coordinates: [40.40048958037617, -3.6783988212195466],
    allPlants: null,
    error: false,
    loadingSelf: {
      isVisible: null,
      message: 'Loading TIA Map'
    },
    tab: 0,
    tabTIAMonitoringEnabled: true
  }),
  computed: {
    ...mapState({
      tabTIA: (state) => state.TIA.tabTIA,
      tabTIAMonitoring: (state) => state.TIA.tabTIAMonitoring
    })
  },

  watch: {
    // whenever question changes, this function will run
    tabTIA () {
      this.tab = this.tabTIA
    },
    tabTIAMonitoring () {
      this.tabTIAMonitoringEnabled = this.tabTIAMonitoring
    }
  },
  mounted () {
    this.getHourZoneUTC()
  },

  methods: {
    getHourZoneUTC() {
      const now = new Date();
      const offsetMinutes = now.getTimezoneOffset();
      const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
      const offsetMin = Math.abs(offsetMinutes) % 60;

      const signe = offsetMinutes > 0 ? "%2D" : "%2B";

      const hours = offsetHours.toString().padStart(2, '0');
      const minutes = offsetMin.toString().padStart(2, '0');
      const hourZoneUTC = `${signe}${hours}:${minutes}`;
      window.sessionStorage.setItem('hourZone', hourZoneUTC)
      return hourZoneUTC
    },

    showLoading (show) {
      this.loadingSelf.isVisible = show
    },
    clickTabMap () {
      this.$store.dispatch('setElement', { path: 'TIA.tabTIA', value: 0 })
      // this.$store.dispatch('setElement', { path: 'TIA.tabTIAMonitoring', value: true })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.tabTIAMonitoring', value: 0 })
    },
    clickTabMonitoring () {
      this.$store.dispatch('setElement', { path: 'TIA.tabTIA', value: 1 })
      this.$store.dispatch('setElement', { path: 'TIA.tabTIAMonitoring', value: false })
    }
  }
}
</script>

<style scoped >

.v-tab {
  min-width: 59px !important;
  max-width: 1059px !important;
  margin-right: -14px !important;
  margin-left: -5px !important;
  min-height: 25px !important;
}

</style>
