<template>
  <LYFeedback
    code="404"
    message="Requested page was not found. Please contact support if the error persists"
  />
</template>

<script>
export default {
  name: 'PageNotFoundView',

  metaInfo: {
    title: 'Page not Found'
  },

  components: {
    LYFeedback: () => import('@/components/Layout/LYFeedback.vue')
  }
}
</script>
