class HostingCapacityMap {
  constructor (DB, Results, station, host, sqlPromise, warnFunc, errorFunc,
    infoFunc) {
    /*
    Class that stores the results of a digital twin simulation.
    It will be used for two purposes: access results and create incremental cases.
    */
    this.infoFunc = infoFunc
    this.errorFunc = errorFunc
    this.warnFunc = warnFunc
    this.stationID = station
    this.sqlPromise = sqlPromise
    this.DB = DB
    this.host = host
    this.Results = Results
    this.ws_protocol = 'wss'
  }

  getHCMFrame (frame1, frame2, phaseIndex, mode) {
    return this.Results.HC_GetFrame(frame1, frame2, phaseIndex, mode)
  }

  getHCMElement (connPointId, phaseIndex, mode) {
    return this.Results.HC_GetCGP(connPointId, phaseIndex, mode)
  }

  getGrid () {
    function res2JSON (table) {
      /*
      Args:
          * keys: array with the name of the keys
          * values: array of arrays with the values ordered
                    in the same way as the keys (they should
                    have the same length also)
      */
      const result = []

      if (table !== undefined) {
        let row
        let key
        const { columns, values } = table
        for (let i = 0; i < values.length; i += 1) {
          row = {}
          for (let j = 0; j < columns.length; j += 1) {
            key = columns[j]
            row[key] = values[i][j]
          }
          result.push(row)
        }
      }
      return result
    }

    function startGrid (db) {
      const grid = {}
      // Closed fuses
      const closedFuses = db.exec(`SELECT Switch.ID, Switch.STATION, Switch.X1, Switch.Y1, Switch.X2, Switch.Y2, Switch.L1, CASE WHEN Line.ID IS NULL THEN 0 ELSE Line.ID END AS L2 FROM
      (SELECT Switch.ID, Switch.STATION, Switch.X1, Switch.Y1, Switch.X2, Switch.Y2, CASE WHEN Line.ID IS NULL THEN 0 ELSE Line.ID END AS L1, Switch.Bus2 FROM
        (SELECT Switch.ID, Switch.STATION, Switch.X1, Switch.Y1, Bus.X AS X2, Bus.Y AS Y2, Switch.Bus1, Switch.Bus2 FROM
          (SELECT Switch.ID, Bus.Area1 AS STATION, Bus.X AS X1, Bus.Y AS Y1, Switch.Bus1, Switch.Bus2
            FROM Switch INNER JOIN Bus ON Bus.ID=Switch.Bus1
            WHERE Switch.State1=1) Switch
          INNER JOIN Bus
          ON Switch.Bus2=Bus.ID) Switch
        LEFT JOIN Line
        ON (Switch.Bus1=Line.Bus1 OR Switch.Bus1=Line.Bus2)) Switch
      LEFT JOIN Line
      ON (Switch.Bus2=Line.Bus1 OR Switch.Bus2=Line.Bus2)`)[0]
      grid.closedFuses = res2JSON(closedFuses)
      // Open fuses
      const openFuses = db.exec(`SELECT Switch.ID, Switch.X1, Switch.Y1, Switch.X2, Switch.Y2, Switch.L1, CASE WHEN Line.ID IS NULL THEN 0 ELSE Line.ID END AS L2 FROM
      (SELECT Switch.ID, Switch.X1, Switch.Y1, Switch.X2, Switch.Y2, CASE WHEN Line.ID IS NULL THEN 0 ELSE Line.ID END AS L1, Switch.Bus2 FROM
            (SELECT Switch.ID, Switch.X1, Switch.Y1, Bus.X AS X2, Bus.Y AS Y2, Switch.Bus1, Switch.Bus2 FROM
              (SELECT Switch.ID, Bus.X AS X1, Bus.Y AS Y1, Switch.Bus1, Switch.Bus2
                FROM Switch INNER JOIN Bus ON Bus.ID=Switch.Bus1
                WHERE Switch.State1=0) Switch
              INNER JOIN Bus
              ON Switch.Bus2=Bus.ID) Switch
          LEFT JOIN Line
          ON (Switch.Bus1=Line.Bus1 OR Switch.Bus1=Line.Bus2)) Switch
      LEFT JOIN Line
      ON (Switch.Bus2=Line.Bus1 OR Switch.Bus2=Line.Bus2)`)[0]
      grid.openFuses = res2JSON(openFuses)
      // Output Transformer lines
      const feeders = db.exec(`SELECT Switch.ID, Line.ID AS L, Switch.State1 AS STATE, Bus.X AS X, Bus.Y AS Y,
      CASE WHEN (Line.Bus1==Switch.Bus1 OR Line.Bus1==Switch.Bus2) THEN 1 ELSE 0 END AS FORWARD
      FROM Switch, Transformer, Bus, Line
      WHERE Switch.Bus1=Transformer.Bus2 AND Switch.Bus2=Bus.ID AND
      (Switch.Bus2=Line.Bus1 OR Switch.Bus1=Line.Bus1 OR Switch.Bus2=Line.Bus2 OR Switch.Bus1=Line.Bus2)`)[0]
      grid.fusesTF = res2JSON(feeders)
      // CGPs
      const cgps = db.exec('SELECT CGP.ID AS ID, Bus.Area1 as STATION, Bus.X as X, Bus.Y as Y FROM CGP, Bus WHERE CGP.Bus=Bus.ID')[0]
      grid.connPoints = res2JSON(cgps)
      // lines
      const lines = db.exec('SELECT Line.ID AS ID, group_concat(Seg.X) AS X, group_concat(Seg.Y) AS Y, Bus.Area1 AS STATION, Line.Str AS FORWARD FROM Line, Bus, Seg WHERE Line.ID=Seg.Line AND Line.Bus1=Bus.ID GROUP BY Seg.Line')[0]
      grid.Lines = res2JSON(lines).map((line) => {
        const outLine = {}
        outLine.ID = line.ID
        outLine.STATION = line.STATION
        outLine.FORWARD = line.FORWARD
        outLine.X = line.X.split(',').map((x) => parseFloat(x))
        outLine.Y = line.Y.split(',').map((x) => parseFloat(x))
        return outLine
      })
      //  Stations
      const stations = db.exec('SELECT s.ID AS ID, s.Name AS NAME, s.X AS X, s.Y AS Y FROM Station AS s')[0]
      grid.Stations = res2JSON(stations)

      const limits = db.exec('SELECT max(X) as Xmax, min(X) as Xmin, max(Y) as Ymax, min(Y) as Ymin FROM Seg')[0].values[0]
      grid.Xmax = limits[0]
      grid.Xmin = limits[1]
      grid.Ymax = limits[2]
      grid.Ymin = limits[3]

      return grid
    }

    return startGrid(this.DB)
  }

  getConnPointID (id) {
    return this.Results.GetCgpIndex(id)
  }
}

export default HostingCapacityMap
