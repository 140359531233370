<template>
  <div>
    <VDialog
      v-model="message"
      transition="dialog-top-transition"
      max-width="600"
    >
      <VCard>
        <VToolbar
          color="primary"
          dark
        >
          <VIcon
            class="redPlexi mright20"
            v-text="icon"
          />
          {{ title }}
        </VToolbar>
        <VCardText>
          <div
            class="text-h1 pa-12 yellowPlexi mtop20"
            v-text="message"
          />
        </VCardText>
        <VCardActions class="justify-end">
          <VBtn
            text
            @click="handleClick"
          >
            Close
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import { mdiAlertCircle } from '@mdi/js'

export default {
  name: 'CMError',

  props: {
    title: {
      type: String,
      default: 'Please contact support'
    },
    text: {
      type: String,
      default: ''
    },
    code: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      icon: mdiAlertCircle
    }
  },

  computed: {
    message () {
      if (this.code && this.text) {
        return `Error ${this.code} has ocurred. ${this.text}`
      }

      return this.text || 'An unexpected error has ocurred'
    }
  },

  methods: {
    handleClick () {
      this.$emit('hideError')
    }
  }
}
</script>

<style scoped>
.redPlexi{
  color:#b60c0c;
}
.mtop20{
  margin-top: 20px;
}
.mright20{
  margin-right: 20px;
}
</style>
