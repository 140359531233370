<template>
  <DtPanelTabsComponent
    :tabs="tabs"
  />
</template>

<script>
export default {
  name: 'DtPanelPlotPower',
  components: {
    // This way to avoid the recursive problem. Component needs a name
    DtPanelTabsComponent: () => import('@/components/DigitalTwin/DtPanelTabsComponent.vue')
  },
  props: {
    dataPlotPS: {
      type: Array,
      default: null
    },
    dataPlotPP: {
      type: Array,
      default: null
    },
    dataPlotPQ: {
      type: Array,
      default: null
    },
    dataPlotPL: {
      type: Array,
      default: null
    },
    dataPlotPUFC: {
      type: Array,
      default: null
    },
    dataPlotPUFV: {
      type: Array,
      default: null
    },
    typeValue: {
      type: Number,
      default: null
    },
    samplingPeriod: {
      type: Number,
      default: null
    },
    typeElement: {
      type: String,
      default: null
    },
  },
  computed: {
    // Must be computed because the props will change the value after the component is loaded.
    // Could it be in the DTPanel, but I prefer to make it here
    tabs () {
      const getPlot = (dataPlot, index) => {
        return (dataPlot) ? dataPlot[index][1] : 0
      }

      return [
        {
          icon: 'S',
          label: this.$t('apparent_power'),
          component: 'DtPanelPlotGeneric',
          show: !!this.dataPlotPS,
          props: {
            name: this.$t('apparent_power'),
            xXaxis: this.$t('plot.time_h'),
            yAxis: 'S(kVa)',
            dataPlot: this.dataPlotPS,
            typeValue: this.typeValue,
            samplingPeriod: this.samplingPeriod,
            pavY: getPlot(this.dataPlotPS, 0),
            pbvY: getPlot(this.dataPlotPS, 1),
            pcvY: getPlot(this.dataPlotPS, 2),
            pnvY: getPlot(this.dataPlotPS, 3)
          }
        },
        {
          icon: 'P',
          component: (this.typeElement.toLowerCase() === 'station') ? 'DtPanelPlotStationPower' : 'DtPanelPlotGeneric',
          label: this.$t('active_power'),
          show: !!this.dataPlotPP,
          props: {
            name: this.$t('active_power'),
            xXaxis: this.$t('plot.time_h'),
            yAxis: 'P(kW)',
            dataPlot: this.dataPlotPP,
            typeValue: this.typeValue,
            samplingPeriod: this.samplingPeriod,
            pavY: getPlot(this.dataPlotPP, 0),
            pbvY: getPlot(this.dataPlotPP, 1),
            pcvY: getPlot(this.dataPlotPP, 2),
            pnvY: getPlot(this.dataPlotPP, 3)
          }
        },
        {
          icon: 'Q',
          component: (this.typeElement.toLowerCase() === 'station') ? 'DtPanelPlotStationPower' : 'DtPanelPlotGeneric',
          label: this.$t('reactive_power'),
          show: !!this.dataPlotPQ,
          props: {
            name: this.$t('reactive_power'),
            xXaxis: this.$t('plot.time_h'),
            yAxis: 'Q(kVAr)',
            dataPlot: this.dataPlotPQ,
            typeValue: this.typeValue,
            samplingPeriod: this.samplingPeriod,
            pavY: getPlot(this.dataPlotPQ, 0),
            pbvY: getPlot(this.dataPlotPQ, 1),
            pcvY: getPlot(this.dataPlotPQ, 2),
            pnvY: getPlot(this.dataPlotPQ, 3)
          }
        },
        {
          icon: 'L',
          label: this.$t('losses'),
          component: 'DtPanelPlotGeneric',
          show: !!this.dataPlotPL,
          props: {
            name: this.$t('losses'),
            xXaxis: this.$t('plot.time_h'),
            yAxis: 'L(W)',
            dataPlot: this.dataPlotPL,
            typeValue: this.typeValue,
            samplingPeriod: this.samplingPeriod,
            pavY: getPlot(this.dataPlotPL, 0),
            pbvY: getPlot(this.dataPlotPL, 1),
            pcvY: getPlot(this.dataPlotPL, 2),
            pnvY: getPlot(this.dataPlotPL, 3)
          }
        },
        {
          icon: 'UF',
          label: this.$t('kpi.unbalanced_factor'),
          component: 'DtPanelPlotPowerUF', // Tabs
          show: !!this.dataPlotPUFC,
          props: {
            dataPlotPUFC: this.dataPlotPUFC,
            dataPlotPUFV: this.dataPlotPUFV,
            typeValue: this.typeValue,
            samplingPeriod: this.samplingPeriod
          }
        }
      ]
    }
  }
}
</script>
