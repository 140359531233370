import Vue from 'vue'
import VueRouter from 'vue-router'
import GridInspection from '@/views/GridInspection.vue'
import DigitalTwinSet from '@/views/DigitalTwinSet.vue'
import DigitalTwinNewConnections from '@/views/DigitalTwinNewConnections.vue'
import MontecarloTest from '@/views/MontecarloTest.vue'
import DashboardView from '@/views/Dashboard.vue'
import DigitalTwinTest from '@/views/DigitalTwinTest.vue'
import DigitalTwinPlanning from '@/views/DigitalTwinPlanning.vue'
import Login from '@/views/Login.vue'
import UserConfiguration from '@/views/UserConfiguration.vue'
import UserAdminPanel from '@/views/UserAdminPanel.vue'
import Measurements from '@/views/Measurements.vue'
import DataUpload from '@/views/DataUpload.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import TIA from '@/views/TIA.vue'
import LoadAnalysis from '@/views/LoadAnalysis.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'DashboardView',
    component: DashboardView,
    props: true
  },
  {
    path: '/file-importer',
    name: 'DataUpload',
    component: DataUpload,
    props: true
  },
  {
    path: '/grid-inspection',
    name: 'GridInspection',
    component: GridInspection,
    props: true
  },
  {
    path: '/planning',
    name: 'DigitalTwinSet',
    component: DigitalTwinSet,
    props: true
  },
  {
    path: '/planning/sensitivity-analysis',
    name: 'DigitalTwinNewConnections',
    component: DigitalTwinNewConnections,
    props: true
  },
  {
    path: '/planning/montecarlo',
    name: 'DigitalTwinPlanning',
    component: DigitalTwinPlanning,
    props: true
  },
  {
    path: '/digital-twin-test',
    name: 'DigitalTwinTest',
    component: DigitalTwinTest
  },
  {
    path: '/montecarlo',
    name: 'MontecarloTest',
    component: MontecarloTest
  },
  {
    path: '/user-configuration',
    name: 'UserConfiguration',
    component: UserConfiguration,
    props: true
  },
  {
    path: '/user-admin',
    name: 'UserAdminPanel',
    component: UserAdminPanel,
    props: true
  },
  {
    path: '/measurements-app',
    name: 'Measurements',
    component: Measurements,
    props: true
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/tia',
    name: 'TIA',
    component: TIA,
    props: true
  },
  {
    path: '/load-analysis-app',
    name: 'LoadAnalysis',
    component: LoadAnalysis,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && (window.sessionStorage.getItem('logged') === 'false' || !window.sessionStorage.getItem('logged'))) next('/')
  else next()
})

export default router
