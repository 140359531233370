var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('splitpanes',{staticClass:"default-theme",attrs:{"id":"panelscontainer","horizontal":""},on:{"resized":function($event){return _vm.onSplitpanesResized($event)}}},[_c('pane',[_c('v-container',{staticClass:"info-pane-container"},[_c('v-row',{staticClass:"info-pane-container-header"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"type-element"},[_vm._v(" "+_vm._s(_vm.typeElementMayus)+" ")])])],1),_c('v-row',{staticClass:"info-pane-container-content"},[_c('v-col',{attrs:{"cols":"12"}},[(
                _vm.typeElement == 'line' &&
                  _vm.elementDetails &&
                  _vm.elementDetailsUpdated
              )?_c('line-details',{attrs:{"info":_vm.elementDetails,"network":_vm.network}}):_vm._e(),(
                _vm.typeElement == 'fuse' &&
                  _vm.elementDetails &&
                  _vm.elementDetailsUpdated
              )?_c('fuse-details',{attrs:{"info":_vm.elementDetails,"network":_vm.network}}):_vm._e(),(
                _vm.typeElement == 'connectionPoint' &&
                  _vm.elementDetails &&
                  _vm.elementDetailsUpdated
              )?_c('connection-point-details',{attrs:{"info":_vm.elementDetails,"network":_vm.network}}):_vm._e(),(
                _vm.typeElement == 'station' &&
                  _vm.elementDetails &&
                  _vm.elementDetailsUpdated
              )?_c('station-details',{attrs:{"info":_vm.elementDetails,"network":_vm.network}}):_vm._e(),(
                _vm.typeElement == 'network' &&
                  _vm.idElement &&
                  _vm.elementDetails &&
                  _vm.elementDetailsUpdated
              )?_c('network-details',{attrs:{"info":_vm.elementDetails,"station-info":_vm.stationInfo,"network":_vm.network}}):_vm._e()],1)],1)],1)],1),(_vm.showPlots)?_c('pane',{ref:"card",staticClass:"plots-pane",attrs:{"min-size":7,"size":_vm.setPlotsPaneSize(),"max-size":_vm.setPlotsPaneSize()}},[_c('PlotsPane',{attrs:{"id":"plotspane","selected-type-view-map":_vm.selectedTypeViewMap,"hcm-time":_vm.hcmTime,"element-h-c-m":_vm.elementHCM,"data-plot-h-c":_vm.dataPlotHC,"reservation-network":_vm.reservationNetwork,"card-height":_vm.cardHeight - 190},on:{"updateMapAfterRemoveHCReservation":_vm.updateMapAfterRemoveHCReservation}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }