<template>
  <v-container
    fluid
    :class="setClass()"
    class="plots-container"
  >
    <v-row>
      <CMTabs
        :tabs="tabs"
        :custom-style="`font-size: 18px; font-family: 'Faktum Light'; color: #000; margin-top: 10px; height: inherit`"
        :is-yellow-theme="true"
        :handle-change="handleChange"
      >
        <VTabItem>
          <v-card v-if="showPlotsTab">
            <PlotsTab
              :hcm-time="hcmTime"
              :element-h-c-m="elementHCM"
              :data-plot-h-c="dataPlotHC"
              :selected-type-view-map="selectedTypeViewMap"
            />
          </v-card>
        </VTabItem>
        <VTabItem>
          <v-card v-if="showPlotsTab">
            <ReservesTab
              :is-consumption="selectedTypeViewMap === 'consumption'"
              :reservation-network-name="reservationNetwork"
              :card-height="cardHeight"
              @updateMapAfterRemoveHCReservation="updateMapAfterRemoveHCReservation"
            />
          </v-card>
        </VTabItem>
      </CMTabs>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import CMTabs from '@/components/Common/CMTabs.vue'
import PlotsTab from '@/components/GridInspection/GIPlotsSection/GIPlotsTab.vue'
import ReservesTab from '@/components/GridInspection/GIPlotsSection/GIReservesTab.vue'

export default {
  name: 'GridInspectionPlots',
  components: {
    PlotsTab,
    ReservesTab,
    CMTabs
  },
  props: {
    selectedTypeViewMap: {
      type: String,
      required: true
    },
    elementHCM: {
      type: Object,
      default: () => {}
    },
    dataPlotHC: {
      type: Array,
      default: () => []
    },
    hcmTime: {
      type: Object,
      default: () => {}
    },
    reservationNetwork: {
      type: String,
      default: null
    },
    cardHeight: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      activeTab: 'Plots',
      showPlotsTab: false
    }
  },
  computed: {
    ...mapState({
      HCReservations: ({ hostingCapacity }) => hostingCapacity.HCReservations
    }),
    tabs () {
      const tabs = [
        {
          title: this.$t('plots'),
          disabled: false
        },
        {
          title: this.$t('hcm.reserves'),
          disabled: this.HCReservations.length === 0 || this.reservationNetwork === null
        }
      ]
      return tabs
    }
  },
  watch: {
    dataPlotHC (value) {
      this.showPlotsTab = value.length !== 0
    }
  },
  methods: {
    handleChange (tab) {
      this.activeTab = tab?.title
    },
    updateMapAfterRemoveHCReservation (removedReservation) {
      this.$emit('updateMapAfterRemoveHCReservation', removedReservation)
      this.showPlotsTab = false
    },
    setClass () {
      return this.activeTab.includes('Reserves') ? 'tab-bg-color' : 'tab-bg-color-dark'
    }
  }
}
</script>
<style lang="scss" scoped>
.plots-container {
  padding:0;
  color:#fff;
  background-color:#1e1e1e;
  height:inherit;
  &.tab-bg-color {
    background-color: #1e1e1e;
  }
  &.tab-bg-color-dark {
    background-color: #000000;
  }
  .row {
    margin: 0;
    height:inherit;
  }
}
.v-tabs {
  flex: 0 1 auto;
}
</style>
