<template>
  <DtPanelTabsComponent
    :tabs="tabs"
  />
</template>

<script>
export default {
  name: 'DtPanelPlotPowerUF',
  components: {
    DtPanelTabsComponent: () => import('@/components/DigitalTwin/DtPanelTabsComponent.vue')
  },
  props: {
    dataPlotPUFC: {
      type: Array,
      default: null
    },
    dataPlotPUFV: {
      type: Array,
      default: null
    },
    typeValue: {
      type: Number,
      default: null
    },
    samplingPeriod: {
      type: Number,
      default: null
    }
  },
  computed: {
    // Must be computed because the props will change the value after the component is loaded.
    // Could it be in the DTPanel, but I prefer to make it here
    tabs () {
      return [
        {
          icon: 'I',
          label: this.$t('current'),
          component: 'DtPanelPlotGeneric',
          show: true,
          props: {
            name: this.$t('kpi.unbalance_factor_current'),
            dataPlot: this.dataPlotPUFC,
            typeValue: this.typeValue,
            samplingPeriod: this.samplingPeriod,
            xAxis: this.$t('plot.time_h'),
            yAxis: 'UF'
          }
        },
        {
          icon: 'V',
          label: this.$t('voltage'),
          component: 'DtPanelPlotGeneric',
          show: true,
          props: {
            name: this.$t('kpi.unbalance_factor_Voltage'),
            dataPlot: this.dataPlotPUFV,
            typeValue: this.typeValue,
            samplingPeriod: this.samplingPeriod,
            xAxis: this.$t('plot.time_h'),
            yAxis: 'UF'
          }
        }
      ]
    }
  }
}
</script>
