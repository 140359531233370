import { mapState } from 'vuex'

export default {
  data: () => ({
    currentChanges: {}
  }),

  computed: {
    ...mapState(['DTChanges', 'DTResults', 'connectionPoint', 'markers', 'creatingChange'])
  },

  watch: {
    DTChanges: {
      handler (val) {
        // 'CGP_New' doen't have an existant connection point, it comes from a line. The id is generated from the greatest connection point id's
        const cpId = (this.creatingChange.type === 'CGP_New') ? this.creatingChange.idElement : this.connectionPoint?.ID
        const changesEntries = Object.entries(val)
        const results = {}

        // Know if the element is inside a CGP_New
        const cgpNewValues = changesEntries.find(function (item) {
          return item[0] === 'CGP_New'
        })
        const isNewCGPposition = cgpNewValues[1].CGP.indexOf(cpId) // If it's old return -1, if not the index

        // Get the bus id through the connection point id
        let busId

        if (isNewCGPposition >= 0) {
          busId = cgpNewValues[1].Node[isNewCGPposition]
        } else if (this.DTResults.Results) {
          const cgpIndex = this.DTResults.Results.GetCgpIndex(cpId)
          const busIndex = this.DTResults.Results.CGP_Bus[cgpIndex]
          busId = this.DTResults.Results.Bus_ID[busIndex]
        }

        changesEntries.forEach(([type, data]) => {
          if (typeof data === 'object' && type !== 'Switch' && type !== 'Disable_Element' && type !== 'cable_change') { //  && type !== 'CGP_New'
            // Functions to use below
            const getIndexes = (cp, index) => (cp === cpId ? index : [])
            const getNodeIndexes = (node, index) => (node === busId ? index : []) // Same as getIndexes but for ESS
            const getChanges = (changes) => (_, index) => changes.includes(index)
            const setEntries = (indexes) => (key) => [key, data[key].filter(getChanges(indexes))]
            // ESS doesn't have CGP so we will use the Node to get a proper index
            const indexes = data?.CGP?.flatMap(getIndexes) ?? data?.Node?.flatMap(getNodeIndexes)
            const keys = Object.keys(data)
            const entries = keys.map(setEntries(indexes))

            results[type] = Object.fromEntries(entries)
          }
        })
        this.currentChanges = results
      },
      deep: true,
      immediate: true
    }
  }
}
