<template>
  <div
    class="cm-loader"
    :style="{ height: height || '100%' }"
  >
    <VOverlay
      v-if="loading && overlay"
      :value="loading"
      :z-index="501"
      :opacity="0.7"
    >
      <VRow
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <VCol
          cols="12"
          style="color:#f4c020"
        >
          {{ message }}
        </VCol>
        <VCol
          cols="12"
          class="text-center"
        >
          <CMLoadingHexagon :size="'60px'" />
        </VCol>
      </VRow>
    </VOverlay>

    <div
      v-if="loading && !overlay"
      class="text-center"
    >
      <span
        style="color: #f4c020"
        class="mx-auto"
        v-text="message || $t('fetching')"
      />
      <CMLoadingHexagon :size="'60px'" />
    </div>
  </div>
</template>

<script>
import CMLoadingHexagon from '@/components/Common/CMLoadingHexagon.vue'

export default {
  name: 'CMLoader',

  components: {
    CMLoadingHexagon
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    overlay: {
      type: Boolean,
      default: true
    },

    height: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.v-overlay__content {
  z-index: 502;
}

.cm-loader {
  display: grid;
  place-content: center;
}
</style>
