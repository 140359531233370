<template>
  <div>
    <v-app-bar
      dense
      dark
    >
      <v-toolbar-title>
        <div>
          Supply Point:
          <strong
          >{{ nameStationSelected }}</strong>          
          <strong
            v-if="supplyPoint !== null"
            style="color:#F4C020"
          >>{{ supplyPointSelected }} </strong>
        </div>
      </v-toolbar-title>

    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TIANavBar',

  components: {
  },
  props: {
    supplyPoint: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dateTimeText: null,
    dialog: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu2: false,
    time: null,
    menuTime: false
  }),

  computed: {
    ...mapState({
      areaMapName: ({ areaMap }) => areaMap.at(-1)?.NAME,
      nameStationSelected: (state) => state.TIA.TIAMap.nameStationSelected,
      supplyPointSelected: (state) => state.TIA.TIAMonitoring.supplyPointSelectedName,
    }),
    dateError () {
      return this.date ? [] : ['Date is required']
    },
    timeError () {
      return this.time ? [] : ['Time is required']
    },
    isFormValid () {
      return !this.dateError.length && !this.timeError.length
    }
  },

  watch: {

  },
  mounted () {
    const fechaHoraActual = new Date()
    const fecha = window.sessionStorage.getItem('dateMachine') !== null
      ? window.sessionStorage.getItem('dateMachine')
      : fechaHoraActual.toLocaleDateString()
    const hora = window.sessionStorage.getItem('timeMachine') !== null
      ? window.sessionStorage.getItem('timeMachine')
      : fechaHoraActual.toLocaleTimeString()
    this.dateTimeText = fecha + ' ' + hora
  },

  methods: {
    clickAreaNavBar () {
      this.$store.dispatch('setElement', { path: 'TIA.tabTIA', value: 0 })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.nameStationSelected', value: null })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.supplyPointSelectedId', value: null })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.supplyPointSelectedName', value: null })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.tabTIAMonitoring', value: 0 })
    },
    setTimeMachine () {
      window.sessionStorage.setItem('dateMachine', this.date)
      window.sessionStorage.setItem('timeMachine', this.time + ':00')
      this.$store.dispatch('setElement', { path: 'TIA.timeMachine', value: this.date })
      this.dialog = false
    }

  }
}
</script>

<style>

</style>
