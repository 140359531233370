<template>
  <v-alert
    color="#272727"
    class="m-0"
  >
    <h3>
      {{ text }}
    </h3>
    <v-container fluid>
      <div>
        <p
          v-for="item of items"
          :key="item"
        >
          {{ item }}
        </p>
      </div>
    </v-container>
    <v-col
      v-if="errorMsg"
      class="redPlexi text-center p-0"
    >
      {{ errorMsg }}
    </v-col>
    <v-row
      align="center"
      no-gutters
    >
      <v-col cols="6">
        <v-btn
          color="#f4c020"
          text
          data-cy="dt-delete-project-confirm"
          class="btn-delete"
          @click="deleteConfirmation()"
        >
          {{ $t('delete') }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          color="#f4c020"
          text
          class="btn-cancel"
          @click="deleteCancel()"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: 'ConfirmDelete',
  props: {
    items: {
      type: Array,
      default: undefined,
      required: true
    },
    text: {
      type: String,
      default: '',
      required: true
    },
    errorMsg: {
      type: String,
      default: undefined
    }
  },
  methods: {
    deleteConfirmation () {
      this.$emit('deleteConfirmation')
    },
    deleteCancel () {
      this.$emit('deleteCancel')
    }
  }
}
</script>
<style scoped lang="scss">
.v-dialog.v-dialog--active {
  max-width: 450px !important;
}
.redPlexi {
  color: #b60c0c;
}
.v-btn {
  border: none;
  margin-top: 1rem;
  padding: 1rem 0;
  cursor: pointer;
  text-align: center;
  transition: background 0.2s;
  border-radius: 3px;
  &.btn-cancel {
    background: #434343 !important;
    color: #f4c020 !important;
  }
  &.btn-delete {
    background: #f4c020 !important;
    color: #434343 !important;
  }
}
</style>
