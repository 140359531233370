import mapElementDisableMixin from '@/mixins/map/mapElementDisableMixin'

export default {
  mixins: [mapElementDisableMixin],
  methods: {
    mouseover (target, elementHovered, elementType, level, network, isStationElement = false) {
      const isElementDisabled = this.disabledElement(network)
      if (isElementDisabled) return null

      let isCPElementDisabled = false
      if (
        isStationElement &&
        this.$store.state.measurements.currentTab?.title === 'Measurements' &&
        this.$store.state.measurements.metersCodeMapTypes.length
      ) {
        const element = this.$store.state.measurements.inventory.find(
          (el) => el.CGP === elementHovered
        )
        if (element) {
          isCPElementDisabled = this.isItemDisabled(element.TYPE)
        }
      }
      if (isCPElementDisabled) return null
      if (
        (this.mode === 'element_mode' &&
        !this.digitalTwinMap &&
        !this.hostingCapacityMap) || (this.mode === 'network_mode' && elementType === 'fuse' && !network)
      ) {
        // this is the color set for the fuses
        target.setStyle({
          color: '#f4c020'
        })
      } else if (this.mode === 'element_mode' && (this.digitalTwinMap || this.hostingCapacityMap)) {
        target.setStyle({
          weight: isStationElement ? 10 : 6
        })
      } else {
        if (isStationElement && (this.digitalTwinMap || this.hostingCapacityMap)) {
          target.setStyle({
            weight: 10
          })
        }
        level && this.$emit('hover', level, network)
      }
      this.$store.dispatch('setElement', {
        path: 'elementHovered',
        value: elementHovered
      })
    }
  }
}
