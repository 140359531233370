<template>
<div>
  <div 
    class="align-right"
  >
     <v-tooltip 
     left
     open-delay=500>
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on" @click="openDownloadForm"><v-icon>{{iconDownload}}</v-icon></button>
          </template>
        <span>{{ $t('download_csv') }}</span>
        </v-tooltip>
  </div>
  <v-data-table
    ref="load-analysis-table"
    dense
    :items="formattedLoadAnalysisData"
    :headers="translatedHeaders"
    :items-per-page="15"
    fixed-header
  />
  <v-dialog
      v-model="downloadDialog"
      max-width="400"
      overlay-opacity="0.5"
    >
      <TDDownloadDataForm
        :headers="translatedHeaders"
        :data="formattedLoadAnalysisData"
        @close="closeDownloadForm"
      />
    </v-dialog>
</div>
</template>

<script>
import { mdiDownload } from '@mdi/js'
import { getConsumption } from '@/services/topDown'
import TDDownloadDataForm from '@/components/TopDown/TDDownloadDataForm.vue'

export default {
  name: 'TDLoadAnalysisTable',
  components: {
    TDDownloadDataForm
  },
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
  },
  data: () => ({
    iconDownload: mdiDownload,
    downloadDialog: false,
    loadAnalysisData: [],
    headers: [
      { value: 'station_name' },
      { value: 'kva_rated_power' },
      { value: 'kva_peak_power' },      
      { value: 'percent_missing_data' },
      { value: 'percent_peak_utilization' },
      { value: 'kva_mean_power' },
      { value: 'percent_utilization_factor' },
      { value: 'percent_time_above_100_percent' },
      { value: 'percent_time_above_120_percent' },
      { value: 'percent_time_above_75_percent' },
      { value: 'percent_time_above_50_percent' },
      { value: 'kwh_total_energy_consumed' },
      { value: 'kwh_energy_consumed_above_100_percent' },
      { value: 'kwh_energy_consumed_above_120_percent' },
      { value: 'count_overload_events' },
      { value: 'count_critical_load_events' },
      { value: 'hours_duration_above_rated_power' },
      { value: 'hours_duration_above_maximum_power' }
    ]
  }),
  computed: {
    translatedHeaders() {
      return this.headers.map(header => ({
        ...header,
        text: this.$t(`top_down.${header.value}`)
      }))
    },
    formattedLoadAnalysisData() {
      return this.loadAnalysisData.map(item => {
        let formattedItem = { ...item }
        Object.keys(formattedItem).forEach(key => {
          if (typeof formattedItem[key] === 'number' && !Number.isInteger(formattedItem[key])) {
            formattedItem[key] = formattedItem[key].toFixed(2)
          }
        })
        return formattedItem
      })
    }
  },
  watch: {
    startDate: 'fetchLoadAnalysisData',
    endDate: 'fetchLoadAnalysisData'
  },
  methods: {
    async fetchLoadAnalysisData() {
      this.loadAnalysisData = await getConsumption(this.startDate, this.endDate)
    },
    openDownloadForm() {
      this.downloadDialog = true
    },
    closeDownloadForm() {
      this.downloadDialog = false
    },
  },
  async mounted () {
    await this.fetchLoadAnalysisData()
  }
}

</script>
<style scoped>
.align-right{
  text-align:right;
  margin-bottom: 12px;
  margin-top: -45px;
  margin-right: 4px;
}
</style>
