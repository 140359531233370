<template>
  <div>
    <h4 v-if="loadScaleList">
      This case has a Load Scale Factor of: {{ loadScaleList }}
    </h4>
  </div>
</template>

<script>
export default {
  name: 'DtPanelKpisNewLF',
  props: {
    loadScaleList: {
      type: Number,
      default: null
    }
  }
}
</script>
