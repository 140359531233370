export default {
  methods: {
    disabledElement (name) {
      const { selectedNetworksNames } = this.$store.state.measurements
      let isInclude = false
      if (selectedNetworksNames !== undefined) {
        isInclude = selectedNetworksNames.includes(name)
      }
      return this.$route.name === 'Measurements' && !isInclude
    },

    isItemDisabled (item = {}) {
      const isIncludesType = this.isMetersCodeMapIncludesType(item.TYPE)
      return this.$store.state.measurements.metersCodeMapTypes.length !== 0 && !isIncludesType
    },

    isMetersCodeMapIncludesType (type) {
      let isInclude = true
      if (this.$store.state.measurements.metersCodeMapTypes.length && this.$store.state.measurements.currentTab.title !== 'Assets') {
        if (!this.$store.state.measurements.metersCodeMapTypes.includes(type)) {
          const measurementsMeters =
            this.$store.state.measurements.metersCodeMap.MEASUREMENTS[type]
          if (this.$store.state.measurements.selectedMeasurementId) {
            isInclude = measurementsMeters?.includes(this.$store.state.measurements.selectedMeasurementId)
          } else {
            isInclude = this.$store.state.measurements.metersCodeMapTypes?.some(
              (typeEl) => {
                return this.$store.state.measurements.metersCodeMap.MEASUREMENTS[
                  typeEl
                ]?.some((el) => measurementsMeters?.includes(el))
              }
            )
          }
        }
      }
      return isInclude
    }
  }
}
