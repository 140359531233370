<template>
  <div class="test">
    <h4>Test component for digital twin execution</h4>
  </div>
</template>

<script>
/* eslint-disable comma-dangle */
import DigitalTwinCreator from '@/assets/DigitalTwin/DigitalTwinCreator'
// import get from '@/assets/queries';

export default {
  name: 'DigitalTwinTest',
  components: {},
  props: {},
  async mounted () {
    const jsonChanges = {
      Switch: [],
      PV: {
        ndevs: [1],
        CGP: [2371973], // ConnectionPointID
        pp: [20], // Potencia de Pico
        ph: [4], // Fase (4 trifásico)
      },
      EV: {
        ndevs: [1, 2],
        CGP: [2371973, 2371973],
        ph: [4, 2],
        rcp: [7, 3],
        c: [50, 50],
        kmd: [30, 30],
        stdkmd: [5, 5],
        pf: [0.9, 0.8],
        start_min: [18, 1],
        start_max: [20, 3],
      },
      HP: {
        ndevs: [2],
        CGP: [2371973],
        nhP: [20],
        cop: [2],
        ph: [4],
        pf: [0.9],
      },
      Phase: {
        CGP: [2371973],
        Meter: [34393],
        ph: [-1], // Negativo para eliminar
      },
      LoadScale: 1.1,
    }
    // Initalizing Digital Twin Creator
    const dtc = new DigitalTwinCreator(
      this.$HEADER_HTTP,
      this.$API_HOST,
      this.$API_PORT,
      () => {},
      console.error,
      console.warn
    )

    // GET LIST OF PROJECTS ACCESSIBLE WITH CURRENT USER
    const availableProjects = await dtc.getProjectList()

    // GET FULL DESCRIPTION OF A GIVEN PROJECT
    // const projectInfo = await dtc.getProjectInfo('testingAdrian34');
    // console.log(projectInfo);

    // DELETE A PROJECT WITHOUT OPENING IT
    await dtc.deleteProject('testingAdrian34')

    // OPEN ALREADY CREATED PROJECT
    // const dt = await dtc.openProject('testingAdrian33');

    // // CREATE A NEW PROJECT
    const simParams = {
      StartTime: 1546300800000,
      StepCount: 300,
      BasePower: 2,
      InitDate: 'asdf',
      EndDate: 'asdf',
    }

    const qualityParams = {
      EV_PenetrationStart: 10.0,
      EV_PenetrationEnd: 100.0,
      EV_PenetrationStep: 10.0,
      PV_Penetration: 20.0,
      PV_PowerStart: 10.0,
      PV_PowerStep: 1.0,
      PV_PowerFactor: 1.0,
      Vmax: 1.07,
      Vmin: 0.93,
      NegSeqMax: 0.9,
      ZeroSeqMax: 0.9,
      OverCurrentRatio: 1.1,
      OverLoadingRatio: 1.2,
    }

    // Creating a new project
    const dt = await dtc.newProject('testingAdrian34', [65025], simParams, qualityParams)
    // Adding a new case
    await dt.addCase('newCase42', 'base', jsonChanges)
    await dt.simulateCase('newCase42')
    console.log(dt)
    // Get list of cgps where any change was added
    console.log('======= MODIFIED CGPS =======')
    const modifiedCGPs = dt.getModCGPs('newCase42')
    console.log(modifiedCGPs)
    // Get list of switches modified in a given case
    console.log('====== MODIFIED SWITCHES ======')
    const switchChanges = dt.getModSwitches('newCase42')
    console.log(switchChanges)
    // Get list of changes in a selected cgp
    console.log('====== CHANGES IN SINGLE CGP ======')
    const cgpChanges = dt.getChanges(2371973, 'newCase42')
    console.log(cgpChanges)
    // Get list of meters and phase for a given CGP in a given case
    console.log('====== METERS IN CGP ======')
    const meters = dt.getMeters(80029, 'newCase42')
    console.log(meters)
    // Case with CGP with no meters
    const noMeters = dt.getMeters(15126101, 'newCase42')
    console.log(noMeters)

    // // Adding a new case
    await dt.addCase('newCase43', 'newCase42', jsonChanges)
    await dt.simulateCase('newCase43')

    // DELETE CASE FROM PROJECT
    await dt.deleteCase('newCase42')

    // Adding a new case
    await dt.addCase('newCase42', 'newCase43', jsonChanges)
    await dt.simulateCase('newCase42')

    // DigitalTwin object
    console.log(dt)

    // Open Project
    // const dt2 = await dtc.openProject('testingAdrian34');
    // console.log(dt2);
  },
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
