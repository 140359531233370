<template>
  <div>
    <LMarker
      v-for="(marker, i) in markers"
      :key="`${marker}-${i}`"
      :lat-lng="marker.latLng"
      @contextmenu="withMarker($event, marker)"
    >
      <LIcon
        :icon-url="setIcon(marker)"
        :icon-anchor="anchor"
        :icon-size="size"
      />
    </LMarker>
  </div>
</template>

<script>
import { LMarker, LIcon } from 'vue2-leaflet'
import { get } from 'lodash'
import arraysMixin from '@/mixins/arraysMixin'
import { ICONS } from '@/store/constants'

export default {
  name: 'MarkersList',

  components: {
    LMarker,
    LIcon
  },

  mixins: [arraysMixin],

  props: {
    markers: {
      type: Array,
      default: () => []
    },

    size: {
      type: Array,
      default: () => [30, 30]
    },

    anchor: {
      type: Array,
      default: () => [16, 16]
    },

    handleContextmenu: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    cPWithMultipleChanges () {
      const elements = this.markers.map((m) => m.element?.ID)
      return this.findDuplicates(elements)
    },

    fontSize () {
      return `${this.size}px`
    }
  },

  methods: {
    withMarker ($event, marker) {
      this.$store.dispatch('setElement', {
        path: 'currentMarker',
        value: marker
      })

      this.handleContextmenu($event, marker)
    },

    setIcon (marker) {
      const cpId = get(marker, 'element.ID')
      return `/icons/${this.cPWithMultipleChanges.includes(cpId)
        ? ICONS.More
        : marker.icon
      }`
    }
  }
}
</script>
