export default {
  methods: {
    calculateStatus (maxPower, currentPower, reservation) {
      let status
      if (currentPower > maxPower) {
        status = 3 // out of limits
      } else {
        if (reservation) {
          status = 2 // activate flex
        } else {
          status = 1 // non activate flex
        }
      }
      return status
    }
  }
}
