<template>
  <div v-if="loadingSelf.isVisible">
    <CMLoader
      :loading="loadingSelf.isVisible"
      :message="loadingSelf.message"
    />
  </div>
  <v-card
    v-else-if="loadingSelf.isVisible == false"
    width="100%"
  >
    <div class="tia-nav-bar">
      <TIANavBar />
    </div>
    <splitpanes>
      <pane
        min-size="60"
        max-size="60"
        size="60"
      >
        <TIAMapBase
          v-if="gridBounds && stations && networks"
          :stations="stations"
          :networks="networks"
          :grid-bounds="gridBounds"
          @showLoading="showLoading"
        />
      </pane>
      <pane
        min-size="50"
        max-size="50"
        size="50"
      >
        <v-tabs
          v-model="tab"
          :height="30"
          dark
          fixed-tabs
          class="yellowPlexi"
        >
          <v-tab
            :height="25"
            :value="0"
          >
            KPIS
          </v-tab>
          <v-tab
            :height="25"
            :value="1"
          >
            EVENTS
          </v-tab>

          <v-tab-item :value="0">
            <TIAMapKpis
              :network="KPIsNetwork"
            />
          </v-tab-item>
          <v-tab-item :value="1">
            <TIAMapEvents
              :events="events"
              @getLogs="getLogs"
            />
          </v-tab-item>
        </v-tabs>
      </pane>
    </splitpanes>
  </v-card>
</template>

<script>

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import CMLoader from '@/components/Common/CMLoader.vue'
import TIAMapBase from '@/components/TIA/TIAMap/TIAMapBase.vue'
import TIANavBar from '@/components/TIA/TIANavBar.vue'
import TIAMapKpis from '@/components/TIA/TIAMap/TIAMapKpis.vue'
import TIAMapEvents from '@/components/TIA/TIAMap/TIAMapEvents.vue'
import { getFlexContainers } from '@/services/TIA'
import { getNetworksObject } from '@/mixins/map'
import getAreaGridMixin from '@/mixins/getAreaGridMixin'
import getLocalDateFormated from '@/mixins/TIA/getLocalDateFormated'
import { mapState } from 'vuex'
import calculateStatus from '@/mixins/TIA/calculateStatus'

export default {
  name: 'TIAMap',
  components: {
    Splitpanes,
    Pane,
    CMLoader,
    TIAMapBase,
    TIAMapKpis,
    TIAMapEvents,
    TIANavBar
  },
  mixins: [
    getNetworksObject,
    getAreaGridMixin,
    getLocalDateFormated,
    calculateStatus
  ],
  data: () => ({
    coordinates: [40.40048958037617, -3.6783988212195466],
    allPlants: null,
    stations: [],
    KPIsNetwork: [],
    networks: [],
    events: [],
    error: false,    
    gridBounds: undefined,
    loadingSelf: {
      isVisible: null,
      message: 'Loading TIA Map'
    },
    tab: 0,
    dateTimeText: null
  }),
  computed: {
    ...mapState({
      timeMachine: (state) => state.TIA.timeMachine
    })
  },

  watch: {

    timeMachine () {
      this.showLoading(true)
      this.getData()
      setTimeout(() => { this.showLoading(false) }, 500)
    }

  },
  mounted () {
    this.showLoading(true)
    this.setBounds()
    this.getData()
    setTimeout(() => { this.showLoading(false) }, 1000)
  },

  methods: {
    async getData () {
      try {
        const fechaHoraActual = new Date()

        if (window.sessionStorage.getItem('dateMachine') !== null){
          this.dateTimeText = window.sessionStorage.getItem('dateMachine') +
          'T'+window.sessionStorage.getItem('timeMachine')+window.sessionStorage.getItem('hourZone')
        }else{
          this.dateTimeText = fechaHoraActual.toISOString()
        }

        this.stations = await getFlexContainers(this.dateTimeText)
        // this.stations = await getFlexContainers()
        this.KPIsNetwork =  this.getKPISNetwork(this.stations)
        this.getLogs('future')

        const areaGrid = await this.getAreaGrid()
        const networkObject = this.getNetworksObject(areaGrid)
        this.networks = networkObject.network
        this.updateNetworkColor('#C3C3C3', this.networks)
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    setBounds () {
      this.gridBounds = {
        Xmax: parseFloat(sessionStorage.Xmax),
        Xmin: parseFloat(sessionStorage.Xmin),
        Ymax: parseFloat(sessionStorage.Ymax),
        Ymin: parseFloat(sessionStorage.Ymin)
      }
    },
    updateNetworkColor (color, tempNetwork = []) {
      tempNetwork.forEach((voltageLevel, indexLevel) => {
        voltageLevel.networks.forEach((network, index) => {
          tempNetwork[indexLevel].networks[index].color = color
        })
      })
    },
    async getLogs (period) {
      // this.events = await getMonitorLogs('', period)
    },

    showLoading (show) {
      this.loadingSelf.isVisible = show
    },
    getKPISNetwork(stations){      
      let forecastActiveFlexCount = 0
      let forecastNonActiveFlexCount = 0
      let forecastOutsideLimitsCount = 0

      let activeFlexCount = 0
      let nonActiveFlexCount = 0
      let outsideLimitsCount = 0

      let activatedSuccess = 0
      let timeWithinLimits = 0
      let activatedStations = 0

      stations.forEach((station) => {
        // forecast
        let statusForecast = station.forecasted_status
        if (statusForecast === 'not_required_flex'){
          forecastNonActiveFlexCount += 1          
        }else if (statusForecast === 'required_flex'){
          forecastActiveFlexCount += 1          
        }else if (statusForecast === 'limit_override'){
          forecastOutsideLimitsCount += 1          
        }
        // real Time
        let status = station.current_status
        if (status === 'not_required_flex'){
          nonActiveFlexCount += 1          
        }else if (status === 'required_flex'){
          activeFlexCount += 1          
        }else if (status === 'limit_override'){
          outsideLimitsCount += 1          
        }
        // Activated Stations
        if (station.flex_configuration === "active"){
          activatedSuccess +=1
        }

      })
      
      const data = [{
        forecast_non_active_flex_count: forecastNonActiveFlexCount,
        forecast_active_flex_count: forecastActiveFlexCount,
        forecast_outside_limits_count: forecastOutsideLimitsCount,
        non_active_flex_count: nonActiveFlexCount,
        active_flex_count: activeFlexCount,
        outside_limits_count: outsideLimitsCount,
        activated_success: '-',
        time_within_limits: '-',
        activated_stations: activatedSuccess
      }]
      return data
    }
  }
}
</script>

<style scoped>
.splitpanes--vertical > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  width: 2px;
  border-right: 2px solid rgba(238, 238, 238, 0.2);
}
.splitpanes--horizontal > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  height:2px;
  border-top:2px solid rgba(238, 238, 238, 0.2);
  margin-top:-1px;
  }
  .v-tab {
    min-width: 59px !important;
    max-width: 1059px !important;
    margin-right: -14px !important;
    margin-left: -5px !important;
}
.v-card-text{
    line-height: 1.25rem;
    padding-top: 16px;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 1px;
}
.v-tab.v-tab {
    height: 25px;
}
div.v-card__text {
    padding: 0px;
}
.tia-nav-bar{
  margin-top:0px
}
</style>
