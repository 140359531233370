<template>
  <div>
    <!-- Form new User -->
    <v-row
      justify="center"
      class="rowUser"
    >
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="yellowPlexi">
              {{ addUser }}
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <form @submit.prevent="userAdd">
            <v-card-title>
              <span class="text-h5">{{ $t('admin.add_user') }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="firstName"
                      :label="`${$t('admin.first_name')} *`"
                      :rules="[v => !!v || `${$t('admin.first_name_rule')}`]"
                      required
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      v-model="lastName"
                      :label="`${$t('admin.last_name')} *`"
                      :hint="$t('admin.last_name_hint')"
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="email"
                      :label="`${$t('email')} *`"
                      type="email"
                      :hint="$t('admin.email_hint')"
                      persistent-hint
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="username"
                      :label="`${$t('admin.username')} *`"
                      type="text"
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="department"
                      :label="`${$t('admin.department')} *`"
                      type="text"
                      required
                    />
                  </v-col>
                </v-row>
              </v-container>
              <small>*{{ $t('admin.indication') }}</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                {{ $t('close') }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                type="submit"
              >
                {{ $t('add') }}
              </v-btn>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Row User Selected -->
    <v-row
      v-if="userSelect"
      class="rowTop"
    >
      <p class="userSelected">
        {{ $t('admin.user_selected') }}
      </p>
      {{ userSelect }}
      <v-tooltip right>
        <template #activator="{ on }">
          <v-btn
            fab
            light
            x-small
            class="buttonDelete"
            v-on="on"
            @click="confirmSelect"
          >
            <v-icon
              style="color:black"
            >
              {{ deleteIcon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('admin.delete_user') }}</span>
      </v-tooltip>
      <v-tooltip right>
        <template #activator="{ on }">
          <v-btn
            fab
            light
            x-small
            class="buttonDelete"
            v-on="on"
            @click="resetPassword"
          >
            <v-icon
              style="color:black"
            >
              {{ resetIcon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('admin.reset_password') }}</span>
      </v-tooltip>
    </v-row>
    <!-- Datatable with Users -->
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="15"
      class="table"
      :dark="true"
      :footer-props="{ 'items-per-page-text':`${ $t('admin.rows_per_page') }`}"
      @click:row="rowClick"
    />
    <!-- Dialog for show the new password -->
    <v-dialog
      v-model="showPassword"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template>
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            {{ msgPass }}
          </v-toolbar>
          <v-card-text>
            <div class="text-h1 pa-12 yellowPlexi mtop20">
              {{ newPass }}
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="showPassword = false"
            >
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- Dialog for show the user delete correctly -->
    <v-dialog
      v-model="userDelete"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template>
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            {{ $t('admin.user_deleted') }}
          </v-toolbar>
          <v-card-text>
            <div class="text-h1 pa-12 yellowPlexi mtop20">
              {{ userDeleteResult }}
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="userDelete = false"
            >
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- Dialog for confirm your selection -->
    <v-dialog
      v-model="confirmSelection"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('admin.confirm_title') }}
        </v-card-title>
        <v-card-text class="yellowPlexi">
          <div class="text-h1 pa-12 yellowPlexi mtop20">
            {{ $t('admin.confirm_message') }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="confirmSelection = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="deleteUser"
          >
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  getInfoAdmin,
  changePassword,
  remove,
  resetPassword,
  add
} from '@/services/user'
import { mdiAccountPlus, mdiTrashCan, mdiLockReset } from '@mdi/js'

export default {
  data: () => ({
    addUser: mdiAccountPlus,
    deleteIcon: mdiTrashCan,
    resetIcon: mdiLockReset,
    firstName: null,
    lastName: null,
    email: null,
    username: null,
    department: null,
    dialog: false,
    showPassword: false,
    userDelete: false,
    confirmSelection: false,
    userSelect: null,
    emailSelect: null,
    newPass: null,
    msgPass: null,
    userDeleteResult: null,
    users: []
  }),
  computed: {
    headers () {
      return [
        {
          text: this.$t('admin.username'),
          align: 'start',
          sortable: true,
          value: 'username',
          class: 'tabletitle'
        },
        { text: this.$t('email'), value: 'email' },
        { text: this.$t('admin.company'), value: 'company' },
        { text: this.$t('admin.department'), value: 'department' },
        { text: this.$t('admin.last_login'), value: 'last_login' }
      ]
    }
  },
  async mounted () {
    try {
      const result = await getInfoAdmin()
      this.users = result
    } catch (error) {
      this.error = true
    }
  },
  methods: {
    confirmSelect () {
      this.confirmSelection = true
    },
    async changePassword () {
      try {
        await changePassword(
          this.oldPassword,
          this.newPassword,
          this.repeatPassword
        )
        this.$router.push('/')
      } catch (error) {}
    },
    rowClick (item) {
      this.userSelect = `${item.username} (${item.email})`
      this.emailSelect = item.email
    },
    async deleteUser () {
      let result = await remove(this.emailSelect)
      this.userDeleteResult = result
      this.confirmSelection = false
      this.userDelete = true
      result = await getInfoAdmin()
      this.users = result
    },

    async resetPassword () {
      const result = await resetPassword(this.emailSelect)
      this.msgPass = this.$t('admin.reset_password_message')
      this.newPass = result
      this.dialog = false
      this.showPassword = true
    },

    async userAdd () {
      let result = await add(
        this.firstName,
        this.lastName,
        this.email,
        this.username,
        this.department
      )
      this.newPass = result
      result = await getInfoAdmin(
        this.$HEADER_HTTP,
        this.$API_HOST,
        this.$API_PORT
      )
      this.msgPass = this.$t('admin.add_user_message')
      this.users = result
      this.dialog = false
      this.showPassword = true
    }
  }
}
</script>

<style  scoped>

.table{
  padding: 50px 30px 30px 30px ;
  background-color: transparent;
  color: #F4C020;
  cursor: pointer;
}

.error {
  margin: 1rem 0 0;
  color: #ff4a96;
}
.msg {
  margin-top: 3rem;
  text-align: center;
}
.rowTop{
  padding-top:10px;
  padding-left:50px;
  padding-bottom:10px;
  color:white;
}
.rowUser{
  padding:40px;
  color:white;
}
.userSelected{
  margin-right:5px;
  color:#F4C020;
}
.buttonDelete{
  margin-left:10px;
  margin-top:-5px
}
.yellowPlexi{
  color:#F4C020;
}
.mtop20{
  margin-top: 20px;
}
</style>
