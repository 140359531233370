<template>
  <div class="fuses">
    <!--FUSES-->
    <div
      id="fuses"
      class="dashboard-col"
    >
      <div
        id="fc"
        class="card dark-theme"
        style="background-color:#1F1F1F!important;padding:10px;"
      >
        <v-tooltip right>
          <template #activator="{ on }">
            <v-img
              src="@/assets/images/dbfuses.png"
              width="50"
              alt="Fuses"
              v-on="on"
            />
          </template>
          <span>{{ $t('grid_surveyor.fuses') }}</span>
        </v-tooltip>
        <!-- <img src="@/assets/images/dbfuses.png" alt="Connections Points" width="50" > -->
        <div class="row ">
          <div class=" dato-numerico col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div
              id="nfuses"
              class="numero dark-theme"
            >
              {{ nfuses }}
            </div>
            <div class="texto dark-theme">
              {{ $t('fuses.fuses_count') }}
            </div>
          </div>
          <div class="dato-stackedbar col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div
              id="NFusesData"
              class="stacked"
            />
          </div>
        </div>
        <div class="row">
          <div class="dato-numerico col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div
              id="fusesnotwired"
              class="numero dark-theme"
            >
              {{ nfeeders }}
            </div>
            <div class="texto dark-theme">
              {{ $t('fuses.feeders_count') }}
            </div>
          </div>
          <div class="dato-stackedbar col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div
              id="NFeedersData"
              class="stacked"
            />
          </div>
        </div>
        <div class="row">
          <div class="dato-numerico col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div
              id="controlfuses"
              class="numero dark-theme"
            >
              {{ nmaneuver }}
            </div>
            <div class="texto dark-theme">
              {{ $t('fuses.maneuvers_count') }}
            </div>
          </div>
          <div class="dato-stackedbar col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div
              id="NManeuversData"
              class="stacked"
              style="margin-bottom:10px;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getData from '@/mixins/dashboardGetData'
import sortData from '@/mixins/dashboardSortData'
import stackedBar from '@/mixins/dashboardStackedBar'

export default {
  name: 'Fuses',
  mixins: [stackedBar, sortData, getData],
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    nfuses: '0',
    nfeeders: '0',
    nmaneuver: '0'
  }),

  mounted () {
    this.nfuses = this.info.statistics_fuse.countAllFuses
    this.nfeeders = this.info.statistics_fuse.countFeederFuses
    this.nmaneuver = this.info.statistics_fuse.countManeuverFuses

    const NFusesData = [
      {
        label: 'Open',
        value: this.info.statistics_fuse.stackAllFusesStatus.open
      },
      {
        label: 'Closed',
        value: this.info.statistics_fuse.stackAllFusesStatus.close
      }
    ]
    const NFeedersData = [
      { label: 'Open', value: this.info.statistics_fuse.stackFeederFuses?.open },
      {
        label: 'Closed',
        value: this.info.statistics_fuse.stackFeederFuses?.close
      }
    ]
    const NManeuversData = [
      {
        label: 'Open',
        value: this.info.statistics_fuse.stackCTManeuverFuses.open
      },
      {
        label: 'Closed',
        value: this.info.statistics_fuse.stackCTManeuverFuses.close
      }
    ]

    this.stackedBar('#NFusesData', NFusesData)
    this.stackedBar('#NFeedersData', NFeedersData)
    this.stackedBar('#NManeuversData', NManeuversData)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fuses{
  background-color: rgb(53, 49, 49);
}
h1{
  color:#F4C020
}
.panelGris{
    background-color: rgb(53, 49, 49);
    color:white;
}
</style>
