export default {
  methods: {
    checkTimeMachine (dateMachine, dateReal) {
      let dateAPI
      if (dateMachine !== null) {
        dateAPI = dateMachine
      } else {
        dateAPI = dateReal
      }
      return dateAPI
    }
  }
}
