export default {
  data: () => ({
    error: {
      isVisible: false,
      title: null,
      text: null,
      code: null
    }
  }),

  methods: {
    checkErrors (response = {}) {
      this.error = {
        isVisible: !!response.code,
        ...response
      }
    }
  }
}
