<template>
  <div
    :class="classAssigned"
  >
    <v-footer
      dark
      padless
      class="px-5"
    >
      <v-row class="map-info-tia-row-container justify-space-between my-2">
        <v-col v-if="coordinatesText!==''">
          <v-row class="">
            <div class="">
              {{ $t('map_info.coordinates') }}:
              <strong>
                {{ coordinatesText }}
              </strong>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TIAMapInformation',
  props: {
    mode: {
      type: String,
      default: ''
    },
    coordinatesText: {
      type: String,
      default: ''
    },
    classAssigned: {
      type: String,
      default: 'map-info-tia'
    }

  },
  data () {
    return {
      elementSelected: JSON.parse(this.$sessionStorage.gridElement),
      dateTimeText: null

    }
  },

  computed: {
    ...mapState({
      areaMapName: ({ areaMap }) => areaMap.at(-1)?.NAME
    })
  },
  mounted () {
    const fechaHoraActual = new Date()
    const fecha = fechaHoraActual.toLocaleDateString()
    const hora = fechaHoraActual.toLocaleTimeString()
    this.dateTimeText = fecha + ' ' + hora
  }
}
</script>
<style scoped lang="scss">
.map-info-tia {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 500;
  margin-left: 20px;
  margin-bottom: 30px;
}
.monitoring-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 500;
  margin-left: 20px;
  margin-bottom: 5px;
}
.map-info-tia-row-container {
  width: 100%;
  &-item {
    padding: 12px;
  }
}
.mode-item {
  width: 105px;
}
.element-hovered-item {
  width: 256px;
}
.m-right-200 {
  margin-right: 200px;
}
.m-left-20 {
  margin-left: -168px;
}

</style>
