import { camelCase } from 'lodash';

export default {
  methods: {
    normalizeKeys(obj) {
      const createNewObject = (a, b) => {
        const setObject = ([key, value]) => ({ [camelCase(key)]: value });
        const isFirst = Array.isArray(a);

        return isFirst
          ? (setObject(a))
          : ({ ...a, ...setObject(b) });
      };

      const entries = Object.entries(obj);

      return entries.reduce(createNewObject);
    },
  },
};
