import { mapState } from 'vuex'
import { get } from 'lodash'
import { ICONS, VALIDATIONS } from '@/store/constants'
import proj4 from 'proj4'

export default {
  data () {
    return {
      phItems: [
        { text: 'A', value: 1 },
        { text: 'B', value: 2 },
        { text: 'C', value: 3 },
        { text: 'ABC', value: 4 }
      ],
      modeItems: [
        { text: 'OFF', value: 0 },
        { text: 'Constant Power Factor', value: 1 },
        { text: 'Voltage VAR', value: 2 }
      ],
      // ESS Phase
      phESS: [
        { text: 'A', value: 1 },
        { text: 'B', value: 2 },
        { text: 'C', value: 3 },
        { text: '3-phase', value: 4 }
      ],
      // ESS SigMode
      sigModeESS: [
        { text: 'Outgoing from node', value: 'out' },
        { text: 'Incoming at node', value: 'in' }
      ],
      // ESS Mode
      modeESS: [
        // { text: 'ESS off', value: 0 },
        // { text: 'Constant PQ', value: 1 },
        // { text: 'Volt-var', value: 2 },
        // { text: 'Volt-Watt', value: 3 },
        { text: 'Peak Shave', value: 4 }
        // { text: 'Peak shave & Volt-var', value: 5 }
      ],
      rules: [
        (v) => !!v || 'Item is required'
      ],
      valid: true
    }
  },

  props: {
    payload: {
      type: Object,
      default: () => ({
        edit: false
      })
    },

    showForm: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    /**
      * Insert a marker and the change into DTChange
      * @function
    */
    addDevice () {
      const validation = this.validate()
      const customIndex = this.setCustomIndex()

      const elementValue = { ...this.contextMenuElement.info }
      let elementIcon = this.ICON
      let markerMenu = this.typeElementMenu

      if (this.DEVICE === 'Disable_Element') {
        // In Disable Element should be different icons for each different type element
        elementIcon = ICONS[`${this.DEVICE}_${this.typeElement}`]
      } else if (this.DEVICE === 'CGP_New') {
        // This is because in CGP_New the element is the Line, not the new CGP and it will change the icon to + if there are two
        elementValue.ID = this.values.CGP
        elementValue.latLng = this.coordinates
        markerMenu = 'contextMenuConn'
      } else if (this.DEVICE === 'cable_change') {
        elementValue.ID = this.values.Line_id
        elementValue.latLng = this.coordinates
      }

      if (validation) {
        this.$store.dispatch('setElement', {
          path: 'creatingChange',
          value: {
            type: this.DEVICE,
            idElement: elementValue.ID,
            newElement: (this.DEVICE === 'CGP_New')
          }
        })

        this.$store.dispatch('setElement', {
          path: 'markers',
          value: [
            ...this.markers,
            {
              id: `${elementValue.ID}-${customIndex}`,
              icon: elementIcon,
              latLng: this.coordinates,
              element: elementValue,
              type: this.DEVICE,
              typeElement: markerMenu
            }
          ]
        })

        if (this.DEVICE !== 'Switch' && this.DEVICE !== 'Disable_Element' && this.DEVICE !== 'ESS') {
          this.updateChanges({ append: true })
          this.clearForm()
        }
      }
    },
    /**
      * Close de form modal
      * @function
    */
    closeForm () {
      this.$emit('closeForm')
    },
    /**
      * Send new values to DTChanges
      * @function
    */
    updateChanges ({ append, remove, customIndex }) {
      const values = {
        ...this.values,
        ...((this.DEVICE !== 'CGP_New' && this.DEVICE !== 'Disable_Element' && this.DEVICE !== 'ESS' && this.DEVICE !== 'cable_change') ? { CGP: this.connectionPoint.ID } : {})
      }

      this.$store.dispatch('setDTChange', {
        path: this.DEVICE,
        values,
        append,
        remove,
        customIndex,
        newBackend: this.newBackend
      })
    },
    /**
      * Set an index to the marker to distinguish when there are more than one in the same element
      * @function
    */
    setCustomIndex () {
      return this.markers
        ? this.markers.filter(
          (m) => m.id && m.id.toString().includes(this.connectionPoint.ID)
        ).length
        : 0
    },
    /**
      * Delete a marker and the change in DTChanges
      * @function
    */
    deleteDevice () {
      // From markers
      const indexMarker = this.markers.findIndex((m) => m.id === this.currentMarker.id)
      this.$store.dispatch('setElement', {
        path: 'markers',
        value: this.markers.filter((_, index) => index !== indexMarker)
      })
      // From DTChanges
      if (this.DEVICE !== 'Switch' && this.DEVICE !== 'CGP_New' && this.DEVICE !== 'Disable_Element') {
        this.updateChanges({
          remove: true,
          customIndex: this.payload.customIndex
        })
        this.clearForm()
      }
    },
    /**
      * Update a change from DTChanges. Only for connection points
      * @function
    */
    editDevice () {
      const validation = this.validate()

      if (validation) {
        this.updateChanges({
          append: false,
          customIndex: this.payload.customIndex
        })
        this.clearForm()
        this.$emit('closeForm')
      }
    },
    /**
      * Validate the form from adding a element to DTChanges
      * @function
    */
    validate () {
      if (this.DEVICE === 'Switch' || this.DEVICE === 'Disable_Element') {
        return 1
      }
      return this.$refs[`${this.DEVICE}Form`].validate()
    },
    /**
      * Transform a value in float
      * @function
    */
    setToNumber (val) {
      this.values[val] = parseFloat(this.values[val])
    },

    clearForm () {
      Object
        .keys(this.values)
        .forEach((key) => {
          this.values[key] = undefined
        })

      this.quantity = 1
      this.valid = true
      this.$emit('closeForm')
    },

    setPreviousValues () {
      Object
        .keys(this.values)
        .forEach((key) => {
          const previous = get(this, `payload.changes.${key}`)
          this.values[key] = previous
        })
    }
  },

  computed: {
    /**
      * Catch this values from vuex
    */
    ...mapState(['markers', 'currentMarker', 'connectionPoint', 'contextMenuElement']),
    /**
      * Return icons from constants.js
    */
    ICON () {
      return ICONS[this.DEVICE]
    },
    /**
      * Return validations from constants.js
    */
    VALIDATIONS () {
      return VALIDATIONS
    },
    /**
      * Return the type of element depending on the contextMenu selected
    */
    typeElement () {
      if (this.contextMenuElement.type === 'contextMenuFuse') {
        return 'Fuse'
      } else if (this.contextMenuElement.type === 'contextMenuLine') {
        return 'Line'
      } else if (this.contextMenuElement.type === 'contextMenuStation') {
        return 'Station'
      }
      return 'ConnectionPoint'
    },
    /**
      * Return the number assigned to each type of element. Needed in backend for Disable_Element
    */
    typeElementNumber () {
      const array = ['Line', 'ConnectionPoint', 'Fuse', 'Station']
      return array.indexOf(this.typeElement) + 1
    },
    /**
      * Return the contextMenu assigned to each type of element
    */
    typeElementMenu () {
      const menu = ['contextMenuLine', 'contextMenuConn', 'contextMenuFuse', 'contextMenuStation']
      return menu[this.typeElementNumber - 1]
    },
    /**
      * Give coordinates to put the marker
      * @function
    */
    coordinates () {
      if (this.typeElement === 'Fuse') {
        return this.contextMenuElement.info.latLngs[0]
      } else if (this.DEVICE === 'CGP_New') {
        const latLng = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [this.connectionPoint.X, this.connectionPoint.Y])
        // proj4 returns in order longitude/latitude
        return [latLng[1], latLng[0]]
      } else if (this.DEVICE === 'Disable_Element' || this.DEVICE === 'cable_change') {
        let markerCoordinates = []
        if (this.typeElement === 'Line') {
          let centeredIndex = 0
          if (this.contextMenuElement.info.latLng.length === 2) {
            markerCoordinates = [
              (this.contextMenuElement.info.latLng[0][0] + this.contextMenuElement.info.latLng[1][0]) / 2,
              (this.contextMenuElement.info.latLng[0][1] + this.contextMenuElement.info.latLng[1][1]) / 2
            ]
          } else {
            centeredIndex = Math.round(this.contextMenuElement.info.latLng.length / 2)
            markerCoordinates = this.contextMenuElement.info.latLng[centeredIndex]
          }
        } else if (this.typeElement === 'Station' || this.typeElement === 'ConnectionPoint') {
          markerCoordinates = this.contextMenuElement.info.latLng
        }

        return markerCoordinates
      }
      return this.contextMenuElement.info.latLng
    }
  },

  created () {
    this.payload.edit && this.setPreviousValues()
  },

  watch: {
    showForm (val) {
      val && this.payload.edit && this.setPreviousValues()
    }
  }
}
