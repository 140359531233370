// import DigitalTwinCreator from './DigitalTwinCreator';

class Montecarlo {
  constructor(DB, Results, projectName, host, sqlPromise, warnFunc, errorFunc, infoFunc) {
    /*
    Class that stores the results of a digital twin simulation.
    It will be used for two purposes: access results and create incremental cases.
    */
    this.infoFunc = infoFunc;
    this.errorFunc = errorFunc;
    this.warnFunc = warnFunc;
    this.projectName = projectName;
    this.host = host;
    this.sqlPromise = sqlPromise;
    this.DB = DB;
    this.Results = Results;
  }

  // getResults() {
  //   const [ip, port] = this.host.split(':', 2);
  //   const dtc = new DigitalTwinCreator(ip, port, this.infoFunc, this.errorFunc, this.warnFunc);
  //   return dtc.openMontecarlo(this.projectName);
  // }
}

export default Montecarlo;
