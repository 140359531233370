import router from '@/router'

let errorCount = 0

function refreshToken (host) {
  const refresh = window.sessionStorage.getItem('refresh')
  const body = new FormData()
  body.append('refresh', refresh)

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body
  }

  return fetch(`${host}/auth/api-token-refresh/`, requestOptions)
    .then((response) => response.json())
    .then((response) => {
      window.sessionStorage.setItem('access', response.access)
    })
}

export function parseJwt (token) {
  /*
  Function to decode JWT tokens.
  Taken from https://stackoverflow.com/questions/62676529/checking-the-validity-of-jwt-tokens-beforeenter
  */
  const base64Url = token?.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  // const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64')
  //   .toString('ascii')
  //   .split('')
  //   .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
  //   .join(''))
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

async function addAuthHeader (headers, host) {
  let parsedToken
  let accessToken = sessionStorage.getItem('access')

  errorCount++

  if (accessToken && errorCount < 5) {
    try {
      parsedToken = parseJwt(accessToken)
    } catch (error) {
      await refreshToken(host)
      return addAuthHeader(headers, host)
    }
    if (parsedToken.exp < Date.now() / 1000) {
      await refreshToken(host)
      accessToken = sessionStorage.getItem('access')
    }
    errorCount = 0
    headers.append('Authorization', `Bearer ${accessToken}`)
  } else {
    errorCount = 0
    router.push({ name: 'Login' })
    return Promise.reject(new Error('Limit of refreshToken attempts reached')) // Stop the flow
    // console.error('NO ACCESS TOKEN')
  }
  return headers
}

async function query (url, method, headers, body, notJson) {
  const host = url.split('/').slice(0, 3).join('/')
  let myheaders
  let requestOptions

  if (headers === undefined) {
    myheaders = await addAuthHeader(new Headers(), host)
  } else {
    myheaders = await addAuthHeader(headers, host)
  }

  if (body === undefined) {
    requestOptions = {
      method,
      headers: myheaders,
      redirect: 'follow'
    }
  } else {
    requestOptions = {
      method,
      headers: myheaders,
      redirect: 'follow',
      body
    }
  }

  try {
    const response = await fetch(url, requestOptions)

    const result = notJson ? response : await response.json()
    return response.ok
      ? result
      : { ...result, ok: response.ok, status: response.status }
  } catch (err) {
    console.log(err)
    return err
  }
}

export function get (url, headers, body, notJson) {
  return query(url, 'GET', headers, body, notJson)
}

export function post (url, headers, body) {
  return query(url, 'POST', headers, body)
}

export function del (url, headers, body) {
  return query(url, 'DELETE', headers, body)
}

export function patch (url, headers, body) {
  return query(url, 'PATCH', headers, body)
}

export default {}
