const formatDate = (date, format) => {
  const padL = (nr, chr = '0') => `${nr}`.padStart(2, chr)

  if (format === 'yyyy-mm-dd') {
    return (`${
      date.getUTCFullYear()}-${
      padL(date.getUTCMonth() + 1)}-${
      padL(date.getUTCDate())}`
    )
  }

  return (`${
    padL(date.getMonth() + 1)}/${
    padL(date.getDate())}/${
    date.getFullYear()} ${
    padL(date.getHours())}:${
    padL(date.getMinutes())}`
  )
}

export default formatDate
