
import { get } from '@/assets/queries';

const d3 = require('d3');

// Set the dimensions and margins of the diagram
const nodeDefinition = {
  bus: 1,
  closedfuse: 2,
  cgp: 3,
  openfuse: 4,
  conn2ct: 5,
  isolated: 6,
  measurement: 7,
};

const duration = 800;

function addIcon(element, iconClass) {
  const icon = element
    .append('svg:foreignObject')
    .attr('width', 30)
    .attr('height', 30)
    .append('xhtml:i')
    .attr('class', iconClass);

  icon.append('span')
    .attr('class', 'path1');

  icon.append('span')
    .attr('class', 'path2');

  icon.append('span')
    .attr('class', 'path3');

  return icon;
}
export class SLD {
  constructor(
    protocol,
    host,
    port,
    elementid,
    scrollable,
    legendNode,
    legendSegTopFunc,
    legendSegBotFunc,
    segmentColorFunc,
    busColorFunc,
    plotFlow,
    modObj,
  ) {
    this.scrollable = scrollable;
    this.legendFunc = legendNode;
    this.legendSegTopFunc = legendSegTopFunc;
    this.legendSegBotFunc = legendSegBotFunc;
    this.segmentColorFunc = segmentColorFunc;
    this.busColorFunc = busColorFunc;
    this.protocol = protocol;
    this.host = host;
    this.port = port;

    const iconsObj = JSON.parse(JSON.stringify(modObj));

    if (iconsObj === undefined || iconsObj === {}) {
      this.showIcons = false;
    } else {
      // Expected parameter in icons -> modObject in DigitalTwinTest
      this.showIcons = true;
      this.modObj = iconsObj;
    }

    // CLASS NAME OF USED CLASSES
    this.iconClasses = {
      pv: 'icon-pv-yellowcircle-solid', // ICON CLASS FOR PV
      ev: 'icon-car-yellowcircle-regular', // ICON CLASS FOR EV
      hp: 'icon-heatpump-yellowcircle-regular', // ICON CLASS FOR HEAT PUMP
      ph: 'icon-phase-detection-yellowcircle-regular', // ICON CLASS FOR PHASE CHANGE
      cl: 'icon-client-regular', // ICON CLASS FOR CLIENT MODIFICATION
      sw: 'icon-switch',
      un: '', // ICON CLASS FOR UNKNOWN DEVICE
    };

    this.leftClickFunc = undefined;
    // LINK TO THE API THAT RETRIEVES THE DENDROGRAM
    this.URL = `${this.protocol}://${this.host}:${this.port}/ari/dendrogram/`;
    // ID OF THE ELEMENT WHERE THE SLD WILL BE INJECTED TO
    this.element = elementid;
    this.el_obj = document.getElementById(elementid);
    // CONFIGURATION OF THE "CANVAS"
    this.margin = {
      top: 120,
      right: 120,
      bottom: 120,
      left: 120,
    };
    
    this.min_width = this.el_obj.clientWidth - this.margin.left - this.margin.right;
    this.min_height = this.el_obj.clientHeight - this.margin.top - this.margin.bottom;
    // this.min_height = this.el_obj.clientWidth/2.1 - this.margin.top - this.margin.bottom;
    // CONFIGURATION OF THE GRAPHICAL ELEMENTS
    this.drawingConfig = {
      busWidth: 20,
      busHeight: 5,
      fuseWidth: 10,
      fuseHeight: 25,
    };

    // CONFIGURATION OF FLOW ANIMATION
    if (plotFlow !== undefined) {
      this.flowDirection = plotFlow;
    } else {
      this.flowDirection = false;
    }

    this.i = 0;
    this.timeout = null;
  }

  draw(selectedCT) {
    return new Promise((myResolve, myReject) => {
      this.selectedCT = selectedCT;
      this.svg_canvas = d3.select(`#${this.element}`)
        // .style('width', `${this.min_width + this.margin.left + this.margin.right}px`)
        .style('height', '90vh')
        .append('svg')
        .attr('id', `canvas-${selectedCT}`)
        .attr('class', 'sld-canvas')
        .attr('width', this.min_width + this.margin.left + this.margin.right)
        .attr('height', this.min_height + this.margin.top + this.margin.bottom)

      if (this.scrollable) {
        this.treemap = d3
          .tree()
          .size([this.width, this.height])
          .nodeSize([70, 50])
          .separation(() => 1);

        this.svg = this.svg_canvas.append('g')
          .attr('id', 'sld-main-g')

      } else {
        this.width = this.min_width - this.margin.left - this.margin.right;
        this.height = this.min_height - this.margin.top - this.margin.bottom;
        this.treemap = d3.tree().size([this.width, this.height]);
        this.svg = this.svg_canvas.append('g')
          .attr('id', 'sld-main-g')
        
      }

      get(this.URL + selectedCT)
        .then((treeData) => {
          this.treeData = treeData;
  
          this.svg.each(() => {
            // Create hierarchy root from the received data
            // Params: 1st - hierarchical data (nested dictionary with data and children).
            //         2nd - function to access to children from each node.
            this.root = d3.hierarchy(this.treeData, (d) => d.children);
            // Add origin of animation
            this.root.y0 = 0;
            this.root.x0 = this.width / 2;

            this.update(this.root);

            this.root.children.forEach((d) => this.click(d));
          });
          myResolve();
        })
        .catch((err) => {
          myReject(err);
        })
    })
  }

  refresh() {
    if (this.legendFunc) {
      const buses = this.svg.selectAll('g.sld-bus');
      refreshLegends(buses, this.legendFunc, this.drawingConfig.busWidth);
    }

    if (this.legendSegBotFunc) {
      const segments = this.svg.selectAll('g.segment');
      refreshLegendSegs(segments, this.legendSegBotFunc, 'bottom-seg');
    }

    if (this.legendSegTopFunc) {
      const segments = this.svg.selectAll('g.segment');
      refreshLegendSegs(segments, this.legendSegTopFunc, 'top-seg');
    }

    if (this.segmentColorFunc) {
      const segments = this.svg.selectAll('g.segment');
      refreshSegmentColors(segments, this.segmentColorFunc, this.flowDirection, this.leftClickFunc);
    }

    if (this.busColorFunc) {
      const buses = this.svg.selectAll('rect.sld-bus');
      refreshBusColors(buses, this.busColorFunc);
    }
  }

  update(source) {
    this.centerX = this.el_obj.clientWidth / 2;
    this.centerY = this.el_obj.clientHeight / 2;

    const { busWidth } = this.drawingConfig;
    const { busHeight } = this.drawingConfig;
    const { fuseWidth } = this.drawingConfig;
    const { fuseHeight } = this.drawingConfig;

    // Assigns the x and y position for the nodes
    this.treeData = this.treemap(this.root);
    // Generate list of all the nodes in the treemap
    this.allNodes = this.treeData.descendants();
    this.links = this.treeData.descendants().slice(1);
    // Remove only the first node (Station node - ROOT)

    // Limit maximum separation between levels.
    if (this.scrollable) {
      this.allNodes.forEach((d) => {
        const offsetLevel = 2;
        if (d.depth > offsetLevel) {
          d.y = 100 + (d.depth - offsetLevel) * 100;
        }
      });
    } else {
      this.allNodes.forEach((d) => d.y = d.y > d.depth * 180 ? d.depth * 180 : d.y);
    }

    const nodesDict = {};
    this.allNodes.forEach((d) => {
      d.other_child = [];
      if (nodesDict[d.data.name] === undefined) {
        nodesDict[d.data.name] = d;
      } else {
        d.x = nodesDict[d.data.name].x;
        d.y = nodesDict[d.data.name].y;
      }
    });

    this.node = this.svg
      .selectAll('g.node')
      .data(this.allNodes, (d) => d.id || (d.id = this.i++));

    this.nodeEnter = this.node.enter()
      .append('g')
      .attr('class', (d) => {
        if (d.data.nodetype === nodeDefinition.bus) {
          return 'node sld-bus';
        } if (d.data.nodetype === nodeDefinition.openfuse) {
          return 'node sld-open-fuse';
        } if (d.data.nodetype === nodeDefinition.closedfuse) {
          return 'node sld-closed-fuse';
        } if (d.data.nodetype === nodeDefinition.cgp) {
          return 'node sld-cgp';
        } if (d.data.nodetype === nodeDefinition.conn2ct) {
          return 'node sld-extTF';
        } if (d.data.nodetype === nodeDefinition.isolated) {
          return 'node sld-isolated';
        }
        console.warn('Not identified type!');
      })
      .attr('id', (d) => d.data.name)
      .attr('transform', `translate(${source.x0 ? source.x0 : 0},${source.y0})`)
      .on('click', (e, d) => this.click(d, this.drawingConfig))
      .attr('cursor', 'pointer');

    const tf = this.nodeEnter.filter((d) => d.data.PARENT === -1);
    const bus = this.nodeEnter.filter((d) => d.data.nodetype === nodeDefinition.bus);
    const cgp = this.nodeEnter.filter((d) => d.data.nodetype === nodeDefinition.cgp);
    const openFuse = this.nodeEnter.filter((d) => d.data.nodetype === nodeDefinition.openfuse);
    const closedFuse = this.nodeEnter.filter((d) => d.data.nodetype === nodeDefinition.closedfuse);
    const extTF = this.nodeEnter.filter((d) => d.data.nodetype === nodeDefinition.conn2ct);
    const isolated = this.nodeEnter.filter((d) => d.data.nodetype === nodeDefinition.isolated);

    // Plot the main transformer of the network
    plotTransformer(tf, 1, 'sld-tf', this.leftClickFunc);
    // Plot the buses
    plotBus(bus, 'sld-bus', busWidth, busHeight, this.legendFunc, this.busColorFunc, this.leftClickFunc);
    // Plot the connection points
    plotLoad(cgp, 'sld-cgp', this.legendFunc, this.leftClickFunc, this.showIcons, this.modObj, this.iconClasses);
    // Plot open fuses
    plotFuse(openFuse, 'sld-open-fuse', this.legendFunc, fuseWidth, fuseHeight, this.leftClickFunc, this.showIcons, this.modObj, this.iconClasses);
    // Plot closed fuses
    plotFuse(closedFuse, 'sld-closed-fuse', this.legendFunc, fuseWidth, fuseHeight, this.leftClickFunc, this.showIcons, this.modObj, this.iconClasses);
    // Plot the external transformers
    plotTransformer(extTF, -1, 'sld-ext-tf', this.leftClickFunc);
    // Plot isolated buses
    plotBus(isolated, 'sld-iso-bus', busWidth, busHeight, this.legendFunc, this.leftClickFunc);

    tf.selectAll('.sld-tf').on('contextmenu', (e) => {
      e.preventDefault();
      this.leftClickFunc(this.selectedCT, 'transformer', e);
    });

    this.nodeUpdate = this.nodeEnter.merge(this.node);
    // Transition to the proper position for the node
    this.nodeUpdate.transition()
      .duration(duration)
      .attr('transform', (d) => `translate(${d.x},${d.y})`);

    this.nodeExit = this.node.exit().transition()
      .duration(duration)
      .attr('transform', () => `translate(${source.x},${source.y})`)
      .remove();

    this.nodeUpdate.each(function (d) {
      if (d._children) {
        d3.select(this).selectAll('.collapsible-indicator').classed('collapsed', true);
      } else {
        d3.select(this).selectAll('.collapsible-indicator').classed('collapsed', false);
      }
    });

    // //////////////////////////////////////////////////////////////////
    // //////////////        LINKS        ///////////////////////////////
    // //////////////////////////////////////////////////////////////////

    // //////////////// FUSES TERMINALS ////////////////////////////////
    this.link = this.svg.selectAll('g.link')
      .data(this.links, (d) => d.id);

    const linkEnter = this.link.enter().insert('g', 'g.node')
      .attr('class', (d) => {
        const checkParentBus = (
          d.parent.data.nodetype !== nodeDefinition.closedfuse
            && d.parent.data.nodetype !== nodeDefinition.openfuse
        );
        const checkChildrenBus = (
          d.data.nodetype !== nodeDefinition.closedfuse
            && d.data.nodetype !== nodeDefinition.openfuse
        );
        const checkChildrenCgp = (d.data.nodetype === nodeDefinition.cgp);
        if (checkChildrenCgp) {
          return 'link cgp';
        } if (checkParentBus && checkChildrenBus) {
          return 'link segment';
        }
        return 'link terminal';
      });

    const terminals = linkEnter.filter('.terminal');
    const segments = linkEnter.filter('.segment');
    const terminalsCgp = linkEnter.filter('.cgp');

    plotTerminal(terminals); // this.source second parameter removed
    plotSegment(
      segments,
      this.legendSegTopFunc,
      this.legendSegBotFunc,
      this.segmentColorFunc,
      this.leftClickFunc,
      this.flowDirection,
    );
    plotCGPTerminal(terminalsCgp); // this.source second parameter removed

    // UPDATE
    const linkUpdate = linkEnter.merge(this.link);
    updateLinks(linkUpdate);

    // Remove any exiting links
    const linkExit = this.link.exit()
      .remove();

    // Find minimum and maximum coordinates.
    // Take into account that the coordinates system is:
    //      * Root node is at x=0, y=0
    //      * Y axis going down
    //      * Center of 'canvas' placed at (x_max-x_min)/2, different from root position (0,0)
    let minX = 1000000;
    let maxX = 0;
    let maxY = 0;
    this.allNodes.forEach((d) => {
      // Find size of canvas
      maxY = d.y > maxY ? d.y : maxY;
      minX = d.x < minX ? d.x : minX;
      maxX = d.x > maxX ? d.x : maxX;
      // Store position of nodes to 'old' position indicators
      d.x0 = d.x;
      d.y0 = d.y;
    });
    if (this.scrollable) {
      // New needed size of canvas
      this.width = maxX - minX + this.margin.left + this.margin.right;
      this.height = maxY + this.margin.top + this.margin.bottom;
      // Update relative positioning of tree
      this.svg.transition()
        .duration(duration)
        .attr('transform', `translate(${-minX + this.margin.left},${this.margin.top})`);
      // Update size of canvas
      this.svg_canvas.transition().duration(duration)
        .attr('width', this.width)
        .attr('height', this.height);
    }

    function generateOrthogonalCoordinates(s, d) {
      /*
          Function to create an orthogonal path between a parent (d) and its children(s).
          */
      // s: child
      // d: parent
      // Calculate x increment between first and last child
      let childrenWidth = d.x_max - d.x_min;
      // if children are too close, limit minimum to busWidth
      childrenWidth = childrenWidth < busWidth ? busWidth : childrenWidth;
      const marginScale = 0.75;

      let yMiddle = d.y + (s.y - d.y) * 0.2; // Set deepest elbows to at 20%
      yMiddle = (yMiddle - d.y) < 25 ? (d.y + 25) : yMiddle;
      const Dy = Math.abs(d.x - s.x) / (childrenWidth / 2) * (yMiddle - d.y) * 0.5;
      let yIntermediate = yMiddle - Dy;
      yIntermediate = yIntermediate < (d.y + 5) ? (s.y - 30) : yIntermediate;

      let xInit = d.x + busWidth * marginScale / childrenWidth * (s.x - d.x);
      xInit = (yMiddle - Dy) < (d.y + 5) ? d.x : xInit;

      const Pp = [xInit, d.y];
      // Elbow next to parent
      const Ep = [xInit, yIntermediate];
      // Elbow next to child
      const Ec = [s.x, yIntermediate];
      // Connection with child node
      const Pc = [s.x, s.y];

      return [Pp, Ep, Ec, Pc];
    }

    function plotCGPTerminal(CGPGroup) {
      CGPGroup.append('path')
        .attr('d', (d) => {
          const o = {
            x: (source.x0 ? source.x0 : 0), y: source.y0, x_max: (source.x0 ? source.x0 : 0) + busWidth, x_min: (source.x0 ? source.x0 : 0) - busWidth,
          };
          for (let i = 0; i < source.children.length; i++) {
            o.x_max = o.x_max < source.children[i].x ? source.children[i].x : o.x_max;
            o.x_min = o.x_min > source.children[i].x ? source.children[i].x : o.x_min;
          }
          const pathCoords = generateOrthogonalCoordinates(o, o);
          return diagonal(pathCoords);
        })
        .attr('class', 'link cgp');
    }

    function updateLinks(updateGroup) {
      updateGroup.selectAll('path')
        .transition()
        .duration(duration)
        .attr('d', (d) => {
          d.counter = 0;
          d.parent.x_max = 0;
          d.parent.x_min = 1000000000;
          for (let i = 0; i < d.parent.children.length; i++) {
            d.parent.x_max = d.parent.x_max < d.parent.children[i].x
              ? d.parent.children[i].x
              : d.parent.x_max;
            d.parent.x_min = d.parent.x_min > d.parent.children[i].x
              ? d.parent.children[i].x
              : d.parent.x_min;
          }
          d.pathCoords = generateOrthogonalCoordinates(d, d.parent);
          return diagonal(d.pathCoords);
        });

      updateGroup.select('.top-seg')
        .transition()
        .duration(duration)
        .attr('transform', (d) => `translate(${d.pathCoords[2][0]},${d.pathCoords[2][1]})`);

      updateGroup.select('.bottom-seg')
        .transition()
        .duration(duration)
        .attr('transform', (d) => `translate(${d.pathCoords[3][0]},${d.pathCoords[3][1]})`);
    }

    function plotSegment(
      segmentsGroup,
      legendTop,
      legendBottom,
      colorFunc,
      leftClickFunc,
      plotFlow,
    ) {
      const paths = segmentsGroup.append('path')
        .attr('d', (d) => {
          const o = {
            x: (source.x0 ? source.x0 : 0), y: source.y0, x_max: (source.x0 ? source.x0 : 0) + busWidth, x_min: (source.x0 ? source.x0 : 0) - busWidth,
          };
          for (let i = 0; i < source.children.length; i++) {
            o.x_max = o.x_max < source.children[i].x ? source.children[i].x : o.x_max;
            o.x_min = o.x_min > source.children[i].x ? source.children[i].x : o.x_min;
          }
          d.pathCoords = generateOrthogonalCoordinates(o, o);
          return diagonal(d.pathCoords);
        })
        .attr('class', 'link segment main')
        .attr('id', (d) => d.data.LINE)
        .attr('stroke', (d) => {
          if (colorFunc) {
            return colorFunc(d.data.LINE, d.data.name)[0];
          }
        });
      if (plotFlow) {
        segmentsGroup.filter((d) => colorFunc(d.data.LINE, d.data.name)[1] !== undefined)
          .append('path')
          .attr('d', (d) => {
            const o = {
              x: source.x0, y: source.y0, x_max: source.x0 + busWidth, x_min: source.x0 - busWidth,
            };
            for (let i = 0; i < source.children.length; i++) {
              o.x_max = o.x_max < source.children[i].x ? source.children[i].x : o.x_max;
              o.x_min = o.x_min > source.children[i].x ? source.children[i].x : o.x_min;
            }
            d.pathCoords = generateOrthogonalCoordinates(o, o);
            return diagonal(d.pathCoords);
          })
          .attr('class', (d) => {
            if (colorFunc(d.data.LINE, d.data.name)[1]) {
              return 'link segment animation-positive';
            }
            return 'link segment animation-negative';
          });
      }
      segmentsGroup
        .attr('cursor', 'pointer')
        .on('mouseover', function (e, d) {
          d3.select(this).select('.main')
            .classed('segment', false)
            .classed('segment-highlight', true);
        })
        .on('mouseout', function (e, d) {
          d3.select(this).select('.main')
            .classed('segment-highlight', false)
            .classed('segment', true);
        });

      if (colorFunc === undefined) {
        paths.classed('fixed-color', true);
        paths.on('mouseout', function (e, d) {
          d3.select(this)
            .attr('class', 'link segment fixed-color main');
        });
      }

      if (legendTop) {
        plotLegendSegment(segmentsGroup, legendTop, 15, 'legend top-seg');
      }

      if (legendBottom) {
        plotLegendSegment(segmentsGroup, legendBottom, -20, 'legend bottom-seg');
      }

      if (leftClickFunc) {
        segmentsGroup.selectAll('path')
          .on('contextmenu', (e, d) => {
            e.preventDefault();
            leftClickFunc(d.data.LINE, 'line', e);
          });
      }
    }

    function plotLegendSegment(segmentsGroup, legendFunc, yShift, cls) {
      // let segsWithLegend = segmentsGroup.filter((d)=>{
      //     let legend_content = legendFunc(d.data.LINE, 'segment', []);
      //     return (legend_content[0]!=undefined);
      // })
      segmentsGroup.each((d) => {
        d.legendText = legendFunc(d.data.LINE, d.data.name, 'segment', []).map((val) => {
          if (val === undefined) {
            return '';
          }
          return String(val);
        });
      });

      const legend = segmentsGroup.append('g')
        .attr('class', cls)
        .attr('transform', (d) => `translate(${source.x0 ? source.x0 : 0},${source.y0})`);

      legend.append('rect')
        .attr('class', cls)
        .attr('width', 11 * 2)
        .attr('height', (d) => {
          if (d.legendText[1] === undefined) {
            return 12
          } else {
            return 23
          }
        })
        .attr('x', -10)
        .attr('y', yShift)
        .style('display', (d) => {
          if(d.legendText[0] && d.legendText[0].length > 0) {
            return 'block'
          }
          return 'none'
        });

      legend.append('text')
        .attr('class', (cls, 'pu'))
        .text((d) => d.legendText[0])
        .attr('fill', 'white')
        .style('font-size', '11px')
        .attr('text-anchor', 'middle')
        .attr('x', 0)
        .attr('y', yShift + 10);

      legend.append('text')
        .attr('class', cls)
        .attr('class', 'absolute')
        .text((d) => d.legendText[1])
        .attr('fill', 'white')
        .style('font-size', '8px')
        .attr('text-anchor', 'middle')
        .attr('x', 0)
        .attr('y', yShift + 20);
    }

    function plotTerminal(terminalGroup) {
      terminalGroup.append('path')
        .attr('d', (d) => {
          const o = {
            x: (source.x0 ? source.x0 : 0), y: source.y0, x_max: (source.x0 ? source.x0 : 0) + busWidth, x_min: (source.x0 ? source.x0 : 0) - busWidth,
          };
          for (let i = 0; i < source.children.length; i++) {
            o.x_max = o.x_max < source.children[i].x ? source.children[i].x : o.x_max;
            o.x_min = o.x_min > source.children[i].x ? source.children[i].x : o.x_min;
          }
          const pathCoords = generateOrthogonalCoordinates(o, o);
          return diagonal(pathCoords);
        })
        .attr('class', 'link terminal');
    }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const svgCanvas = document.getElementById(this.element);
      const mainG = document.getElementsByClassName('node sld-bus');
      const svgCanvasRect = svgCanvas.getBoundingClientRect();
      const mainGRect = mainG[0]?.getBoundingClientRect();

      svgCanvas.scrollBy({
        left: mainGRect.left/2 - svgCanvasRect.left,
        behavior: 'smooth'
      })
    }, 500);

    updateTextPositions(this.nodeEnter);
  }

  // Function to collapse/expand a node
  click(d) {
    if (d.children) {
      d._children = d.children; // Save children to a private attribute
      d.children = null; // Remove children attribute
    } else {
      d.children = d._children; // Recover the children attribute
      d._children = null;
    }
    this.update(d); // Refresh drawing
  }
}

function updateTextPositions(g, padding) {
  const textElements = g.selectAll("text");

  textElements.each(function (d, i) {
    const textValue = this.textContent;
    const currentText = d3.select(this);
    const bbox = this.getBBox();

    // Check for overlap with other text elements
    if (textValue.includes("CP")) {
      textElements.each(function () {
        if (this !== currentText.node()) {
          const otherBBox = this.getBBox();

          if (
            bbox.x < otherBBox.x + otherBBox.width &&
            bbox.x + bbox.width > otherBBox.x && 
            bbox.y < otherBBox.y + otherBBox.height &&
            bbox.y + bbox.height > otherBBox.y
          ) {
            // If overlap, adjust the y-position of the current label
            const newY = otherBBox.y + otherBBox.height + 18; // 5px spacing
            currentText.attr("y", newY);
          currentText.style('font-size', '9.5px')
          }
        }
      });
    }
  });
}

function refreshSegmentColors(segmentsGroup, colorFunc, plotFlow, leftClickFunc) {
  segmentsGroup.selectAll('path')
    .attr('stroke', (d) => colorFunc(d.data.LINE, d.data.name)[0]);

  if (plotFlow) {
    // In case animations are activated
    segmentsGroup.selectAll('path.animation-positive').remove();
    segmentsGroup.selectAll('path.animation-negative').remove();
    // Look for the segments with some direction defined
    const segs2update = segmentsGroup.filter((d) => {
      d.direction = colorFunc(d.data.LINE, d.data.name)[1];
      return d.direction !== undefined;
    });
      // Add an animated dashed line according to the defined direction
    segs2update
      .append('path')
      .attr('d', (d) => diagonal(d.pathCoords))
      .attr('class', (d) => {
        if (d.direction) {
          return 'link segment animation-positive';
        }
        return 'link segment animation-negative';
      });
    if (leftClickFunc) {
      segs2update
        .on('contextmenu', (e, d) => {
          e.preventDefault();
          leftClickFunc(d.data.LINE, 'segment', e);
        });
    }
    // Ensure that te legend group is always on the top of the segment drawing
    segs2update.selectAll('g.legend').raise();
  }
}

function diagonal(pathCoords) {
  const Pp = pathCoords[0];
  const Ep = pathCoords[1];
  const Ec = pathCoords[2];
  const Pc = pathCoords[3];

  const path = `M${Pp[0]},${Pp[1]
  }L${Ep[0]},${Ep[1]
  }L${Ec[0]},${Ec[1]
  }L${Pc[0]},${Pc[1]}`;

  return path;
}

function refreshBusColors(busGroup, colorFunc) {
  busGroup.style('fill', (d) => colorFunc(d.data.name));
}

function refreshLegendSegs(segmentGroup, legendFunc, cls) {
  const legends = segmentGroup.selectAll(`.${cls}`)
    .each((d) => {
      d.legendText = legendFunc(d.data.LINE, d.data.name, 'segment', []).map((val) => {
        if (val === undefined) {
          return '';
        }
        return String(val);
      });
    });

  legends.selectAll('.pu')
    .text((d) => d.legendText[0])

  legends.selectAll('.absolute')
    .text((d) => d.legendText[1]);

  legends.selectAll('rect')
    .attr('width', (d) => d.legendText[0].length * 5)
    .attr('height', (d) => {
      if (d.legendText[1] === undefined) {
        return 12
      } else {
        return 20
      }
    });
}

function plotBus(busGroup, cls, busWidth, busHeight, legendFunc, colorFunc, leftClickFunc) {
  busGroup.append('rect')
    .attr('width', busWidth)
    .attr('height', busHeight)
    .attr('transform', `translate(-${busWidth / 2},${-busHeight / 2})`)
    .attr('class', () => (`${cls} collapsible-indicator`))
    .attr('id', (d) => d.data.name)
    .style('fill', (d) => {
      if (colorFunc) {
        return colorFunc(d.data.name, {});
      }
    })
    .on('mouseover', function () {
      d3.select(this.parentNode).selectAll(`.${cls}`)
        .classed('sld-highlight-bus', true)
        .classed(cls, false);
    })
    .on('mouseout', function () {
      d3.select(this.parentNode).selectAll('.sld-highlight-bus')
        .classed(cls, true)
        .classed('sld-highlight-bus', false);
    });
  if (legendFunc) {
    refreshLegends(busGroup, legendFunc, busWidth);
  }

  if (leftClickFunc) {
    busGroup.selectAll('rect').on('contextmenu', (e, d) => {
      e.preventDefault();
    });
  }
}

function refreshLegends(nodeGroup, legendFunc, busWidth) {
  nodeGroup.selectAll('.updatable').remove();

  nodeGroup.each(function (d) {
    let nodetype;
    const typeId = d.data.nodetype;
    if (typeId === nodeDefinition.bus) {
      nodetype = 'bus';
    } else if (typeId === nodeDefinition.cgp) {
      nodetype = 'cgp';
    } else if (typeId === nodeDefinition.openfuse || typeId === nodeDefinition.closedfuse) {
      nodetype = 'fuse';
    } else if (typeId === nodeDefinition.extTF) {
      nodetype = 'ext_transformer';
    } else {
      nodetype = -1;
    }

    const lines = legendFunc(d.data.name, nodetype, {});
    for (let i = 0; i < lines.length; i++) {
      const t = lines[i];
      // const texts = lines[i].split(',');
      const fontSize = i === 0 ? '11px' : '6px'
      const verticalCenter = lines.length === 1 ? 5 : 0
      const el = d3.select(this);
      if (typeof t === 'string' && t.includes('CP')) {
        el.append('text')
          .attr('class', 'updatable')
          .attr('fill', 'white')
          .style('font-size', fontSize)
          .text(t)
          .attr('x', 0)
          .attr('y', 5)
          .attr('text-anchor', 'middle')
      } else {
        el.append('text')
          .attr('class', 'updatable')
          .attr('fill', 'white')
          .style('font-size', fontSize)
          .text(t)
          .attr('transform', `translate(${busWidth / 2 + 2},${(i * 10) + verticalCenter})`)// rotate(' + 90 + ")");
      }
    }
    
  });
}

function plotFuse(
  fuseGroup,
  cls,
  legendFunc,
  fuseWidth,
  fuseHeight,
  leftClickFunc,
  showIcons,
  modObj,
  iconClasses,
) {
  /*
  Function to create a fuse
  Params:
      * fuseGroup: group of objects to add
      * cls : name of the class to add to the fuse
  */
  fuseGroup.append('rect')
    .attr('height', fuseHeight)
    .attr('width', fuseWidth)
    .attr('rx', fuseWidth / 3)
    .attr('ry', fuseWidth / 3)
    .attr('class', cls)
    .attr('transform', `translate(-${fuseWidth / 2},-${fuseHeight / 2})`)
    .on('mouseover', function (e, d) {
      d3.select(this.parentNode).selectAll(`.${cls}`).attr('class', 'sld-highlight-fuse');
    })
    .on('mouseout', function (e, d) {
      d3.select(this.parentNode).selectAll('.sld-highlight-fuse').attr('class', cls);
    });

  fuseGroup.append('line')
    .attr('x1', 0)
    .attr('y1', -fuseHeight / 2)
    .attr('x2', 0)
    .attr('y2', fuseHeight / 2)
    .attr('class', cls)
    .on('mouseover', function () {
      d3.select(this.parentNode).selectAll(`.${cls}`).attr('class', 'sld-highlight-fuse');
    })
    .on('mouseout', function () {
      d3.select(this.parentNode).selectAll('.sld-highlight-fuse').attr('class', cls);
    });

  if (showIcons) {
    if ('Switch' in modObj) {
      const fuseWIcon = fuseGroup.filter((d) => modObj.Switch.includes(d.data.name));
      addIcon(fuseWIcon, iconClasses.sw);
    }
  }

  if (legendFunc) {
    refreshLegends(fuseGroup, legendFunc, fuseWidth);
  }

  if (leftClickFunc) {
    fuseGroup.on('contextmenu', (e, d) => {
      let elType;
      e.preventDefault();
      if (cls === 'sld-fuse-closed') {
        elType = 'fuse_closed';
      } else {
        elType = 'fuse_open';
      }
      leftClickFunc(d.data.name.split(" ")[1], elType, e);
    });
  }
}

function plotLoad(loadGroup, cls, legendFunc, leftClickFunc, showIcons, modObj, iconClasses) {
  const triangle = d3.symbol().type(d3.symbolTriangle).size(25);
  loadGroup.append('path')
    .attr('class', cls)
    .attr('d', triangle)
    .attr('transform', 'rotate(180)');

  if (showIcons) {
    if ('PV' in modObj) {
      const loadWPV = loadGroup.filter((d) => modObj.PV.CGP.includes(d.data.name));
      addIcon(loadWPV, iconClasses.pv);
    }
    if ('EV' in modObj) {
      const loadWEV = loadGroup.filter((d) => modObj.EV.CGP.includes(d.data.name));
      addIcon(loadWEV, iconClasses.ev);
    }
    if ('HP' in modObj) {
      const loadWHP = loadGroup.filter((d) => modObj.HP.CGP.includes(d.data.name));
      addIcon(loadWHP, iconClasses.hp);
    }
    if ('Phase' in modObj) {
      const loadWPH = loadGroup.filter((d) => modObj.Phase.CGP.includes(d.data.name));
      addIcon(loadWPH, iconClasses.ph);
    }
  }

  if (legendFunc) {
    refreshLegends(loadGroup, legendFunc, 15);
  }
  if (leftClickFunc) {
    loadGroup.on('contextmenu', (e, d) => {
      e.preventDefault();
      leftClickFunc(d.data.name.split(" ")[1], 'load', e); // changed from d.data.name
    });
  }
}

function plotTransformer(tfGroup, direction, cls, leftClickFunc) {
  /*
  Params:
      * tgGroup
      * direction:
          - 1: transformer on the top of bus
          - -1: transformer below the bus
      * cls
  */
  // Plot transformer
  tfGroup.append('rect')
    .attr('x', -10)
    .attr('y', (direction === 1) ? -40 : 0)
    .attr('width', 20)
    .attr('height', 40)
    .attr('class', cls)
    .on('mouseover', function () {
      d3.select(this.parentNode).selectAll(`.${cls}`).attr('class', 'sld-highlight-tf');
    })
    .on('mouseout', function () {
      d3.select(this.parentNode).selectAll('.sld-highlight-tf').attr('class', cls);
    });

  tfGroup.append('line')
    .attr('x1', 0)
    .attr('y1', -10 * direction)
    .attr('x2', 0)
    .attr('y2', 0)
    .attr('class', cls)
    .on('mouseover', function () {
      d3.select(this.parentNode).selectAll(`.${cls}`).attr('class', 'sld-highlight-tf');
    })
    .on('mouseout', function () {
      d3.select(this.parentNode).selectAll('.sld-highlight-tf').attr('class', cls);
    });

  tfGroup.append('circle')
    .attr('cx', 0)
    .attr('cy', -20 * direction)
    .attr('r', 10)
    .attr('class', cls)
    .on('mouseover', function () {
      d3.select(this.parentNode).selectAll(`.${cls}`).attr('class', 'sld-highlight-tf');
    })
    .on('mouseout', function () {
      d3.select(this.parentNode).selectAll('.sld-highlight-tf').attr('class', cls);
    });

  tfGroup.append('circle')
    .attr('cx', 0)
    .attr('cy', -30 * direction)
    .attr('r', 10)
    .attr('class', cls)
    .on('mouseover', function () {
      d3.select(this.parentNode).selectAll(`.${cls}`).attr('class', 'sld-highlight-tf');
    })
    .on('mouseout', function () {
      d3.select(this.parentNode).selectAll('.sld-highlight-tf').attr('class', cls);
    });

  if (leftClickFunc) {
    if (cls !== 'sld-tf') {
      tfGroup.selectAll('circle').on('contextmenu', (e, d) => {
        e.preventDefault();
        leftClickFunc(d.data.name, 'transformer', e);
      });
    }
  }
}
