import { mapState } from 'vuex'

export default {
  data () {
    return {
      radiusCP: 0.6,
      maxRadiusCPValue: 10
    }
  },
  computed: {
    ...mapState({
      instant: (state) => state.instant,
      elementsOnMap: ({ measurements }) => measurements.drawElementsOnMap[measurements.currentTab.title]
    })
  },
  watch: {
    instant () {
      this.setRadiusCP()
    }
  },
  methods: {
    setRadiusCP (cpId) {
      if (
        this.$route.name === 'Measurements' &&
        this.elementsOnMap !== null &&
        this.instant !== undefined
      ) {
        const elementId = this.elementsOnMap.elementIds.find((id) => String(id) === cpId)
        if (elementId !== undefined && this.elementsOnMap.data[elementId].value.length) {
          const value = this.elementsOnMap.data[elementId].value[this.instant]
          const heatmapRadiusValue = Math.round(value * this.maxRadiusCPValue) / this.elementsOnMap.maxRadiusValue
          return value ? heatmapRadiusValue : this.radiusCP
        }
      }
      return this.radiusCP
    },
    isShowTooltipWithCPValue (cpId) {
      if (
        this.$route.name === 'Measurements' &&
        this.elementsOnMap !== null &&
        this.instant !== undefined
      ) {
        const elementId = this.elementsOnMap.elementIds.find((id) => String(id) === cpId)
        return elementId !== undefined
      }
      return false
    }
  }
}
