<template>
  <VRow class="ma-0 inspector-padding">
    <VCol
      sm="1"
      lg="1"
      xl="1"
      class="inspector-padding"
    >
      <VCard height="100%">
        <MSSearchForm
          :zone="zone"
          :element="element"
        />
        <MSSelector />
      </VCard>
    </VCol>

    <VCol
      sm="12"
      lg="11"
      xl="11"
      class="inspector-padding"
    >
      <MSTable
        :height-percentage="heightPercentage"
      />
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'MSInspector',

  components: {
    MSSearchForm: () => import('@/components/Measurements/MSSearchForm.vue'),
    MSSelector: () => import('@/components/Measurements/MSSelector.vue'),
    MSTable: () => import('@/components/Measurements/MSTable.vue')
  },

  props: {
    heightPercentage: {
      type: Number,
      default: 0
    },
    zone: {
      type: Number,
      default: null
    },
    element: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.p-5 {
  padding: 5px;
}

.container,
.row,
.col {
  height: 100% !important;
}

.inspector-padding {
  padding: 5px 3px 5px 4px;
}
</style>
