<template>
  <v-card elevation="10">
    <v-form
      ref="ReservationForm"
      v-model="valid"
      lazy-validation
    >
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="info">
            <v-row style="margin-top: 0px">
              <v-col
                cols="12"
                sm="6"
              >
                ID:
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                {{ infoHCMId }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                {{ $t('grid.connection_point') }}:
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                {{ connectionPoint.ID }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                {{ $t('hcm.available_capacity') }}:
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                {{ availableCapacity }} kW
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="formData.ph"
                required
                :items="phItems"
                :label="$t('phase')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="formData.capacity"
                required
                :rules="VALIDATIONS.capacity"
                :label="$t('power')"
                type="number"
                suffix="kW"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model.number="formData.months"
                required
                :rules="VALIDATIONS.hcReservePeriod"
                :label="$t('hcm.reserve_period_month')"
                type="number"
                min="1"
                max="24"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="formData.description"
                :rules="descriptionRules"
                filled
                auto-grow
                rows="5"
                :label="$t('hcm.description_optional')"
                type="text"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text
        v-if="showError"
        class="redPlexi"
      >
        {{ errorDescription }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#f4c020"
          text
          @click="closeForm"
        >
          {{ $t('cancel') }}
        </v-btn>
        <VBtn
          :disabled="!valid"
          color="#f4c020"
          text
          @click="sendReservation"
        >
          {{ $t('add') }}
        </VBtn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import HCReservationMixin from '@/mixins/Heatmaps/HCReservationMixin'
import vuexMixin from '@/mixins/vuexMixin'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'HCMFullReservationForm',
  mixins: [HCReservationMixin, vuexMixin],

  data () {
    return {
      title: this.$t('hcm.full_reserve') + ` (${this.payload.reservationType ? this.$t('consumption') : this.$t('generation')})`,
      phItems: [
        { id: 0, text: 'A', value: 'A' },
        { id: 1, text: 'B', value: 'B' },
        { id: 2, text: 'C', value: 'C' },
        { id: 3, text: '3P', value: '3P' }
      ],
      formData: {
        ph: '',
        capacity: 0,
        months: 3,
        description: ''
      },
      descriptionRules: [(v) => v.length <= 100 || 'Max 100 characters'],
      showError: false,
      errorDescription: ''
    }
  },
  computed: {
    ...mapGetters(['HCManageResultsSolved']),
    ...mapState({
      HCManageResults: ({ hostingCapacity }) => hostingCapacity.HCManageResults
    })
  },
  mounted () {
    if (this.showForm) {
      this.formData.ph = this.phItems.find((ph) => ph.id === this.payload.phaseIndex).value
      this.formData.capacity = this.availableCapacity
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog__content {
  z-index: 501 !important;
}
.info {
  margin-bottom: 1rem;
  .col-12 {
    padding: 1px 15px !important;
    text-align: left;
  }
}
.redPlexi {
  color:#b60c0c;
}
</style>
