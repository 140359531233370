<template>
  <div>
    <v-container>
      <v-row
        align="center"
        align-content="center"
        justify="center"
      >
        <v-col style="color: #f4c01f">
          {{ title }}
        </v-col>
      </v-row>
    </v-container>
    <v-tabs
      v-model="currentTab"
      class="tabs"
      vertical
      slider-size="6"
      color="#f4c020"
      style="margin-top: 5px"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.value"
      >
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <h1
              left
              v-bind="attrs"
              v-on="on"
            >
              {{ tab.title }}
            </h1>
          </template>
          <span>{{ tab.tooltip }}</span>
        </v-tooltip>
      </v-tab>
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.value"
      >
        <v-card style="margin: 0px; padding: 0px">
          <v-card-text
            v-if="currentTab ? !reservesGeneration.length : !reservesConsumption.length"
          >
            {{ $t('no_data_available') }}
          </v-card-text>
          <v-card-text
            v-else
            style="overflow: auto"
          >
            <CMTable
              :items="currentTab ? reservesGeneration : reservesConsumption"
              :show-select="false"
              :page-size="12"
              :height="cardHeight"
              :extra-styles="{ marginTop: '20px' }"
              :header-config="{
                id: { notFilterable: true },
                capacity: { notFilterable: true },
                reservation_start_date: { notFilterable: true },
                reservation_expiration_date: { notFilterable: true },
                remove: { notFilterable: true }
              }"
              :slots="slots"
              :custom-title="titleTranslations"
            >
              <template #actions="{ itemId }">
                <ConfirmDeleteReserve
                  :item-id="itemId"
                  @updateMapAfterRemoveHCReservation="updateMapAfterRemoveHCReservation"
                />
              </template>
            </CMTable>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import HCReservationMixin from '@/mixins/Heatmaps/HCReservationMixin'
import vuexMixin from '@/mixins/vuexMixin'
import CMTable from '@/components/Common/CMTable'
import { mapState } from 'vuex'

export default {
  name: 'ReservesTab',
  components: {
    CMTable,
    ConfirmDeleteReserve: () => import('@/components/GridInspection/GIPlotsSection/GIDeleteReserveConfirmation.vue')
  },
  mixins: [HCReservationMixin, vuexMixin],
  props: {
    reservationNetworkName: {
      type: String,
      required: true
    },
    isConsumption: {
      type: Boolean,
      required: true
    },
    cardHeight: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      tabs: [
        { title: 'C', value: 'Consumption', tooltip: this.$t('consumption') },
        { title: 'G', value: 'Generation', tooltip: this.$t('generation') }
      ],
      currentTab: null,
      reservationNetwork: null,
      filters: {},
      activeFilters: { byInput: {} },
      reservesConsumption: [],
      reservesGeneration: [],
      slots: ['remove']
    }
  },
  computed: {
    ...mapState({
      HCManageResults: ({ hostingCapacity }) => hostingCapacity.HCManageResults
    }),
    title () {
      return this.currentTab ? this.$t('hcm.generation_reserves') : this.$t('hcm.consumption_reserves')
    },
    titleTranslations () {
      const titles = [
        { value: 'capacity', text: 'hcm.reserve_kw' },
        { value: 'phase', text: 'phase' },
        { value: 'cgp', text: 'grid.connection_point' },
        { value: 'feeder', text: 'grid.feeder' },
        { value: 'station', text: 'grid.station' },
        { value: 'network', text: 'grid.network' },
        { value: 'reservation_start_date', text: 'creation_date' },
        { value: 'reservation_expiration_date', text: 'expiration_date' },
        { value: 'remove', text: 'remove' }
      ]
      return titles
    }
  },
  watch: {
    reservationNetworkName: {
      immediate: true,
      deep: true,
      handler () {
        this.reservationNetwork = this.reservationNetworkName
        this.setReserves()
      }
    },
    HCReservations () {
      this.setReserves()
    }
  },
  mounted () {
    this.currentTab = this.isConsumption ? 0 : 1
  },

  methods: {
    setReserves () {
      const removeElement = (id) => ({
        props: {
          itemId: id
        }
      })
      this.reservesGeneration = this.HCReservations
        .filter((reservation) =>
          reservation.reservation_type === 0 &&
          reservation.network === this.reservationNetwork
        )
        .map((el) => {
          el.remove = removeElement(el.id)
          return (({
            id,
            capacity,
            phase,
            cgp,
            feeder,
            station,
            network,
            // eslint-disable-next-line camelcase
            reservation_start_date,
            // eslint-disable-next-line camelcase
            reservation_expiration_date,
            remove
          }) => ({
            id,
            capacity,
            phase,
            cgp,
            feeder,
            station,
            network,
            // eslint-disable-next-line camelcase
            reservation_start_date,
            // eslint-disable-next-line camelcase
            reservation_expiration_date,
            remove
          }))(el)
        })

      this.reservesConsumption = this.HCReservations
        .filter((reservation) =>
          reservation.reservation_type === 1 &&
          reservation.network === this.reservationNetwork
        )
        .map((el) => {
          el.remove = removeElement(el.id)
          return (({
            id,
            capacity,
            phase,
            cgp,
            feeder,
            station,
            network,
            // eslint-disable-next-line camelcase
            reservation_start_date,
            // eslint-disable-next-line camelcase
            reservation_expiration_date,
            remove
          }) => ({
            id,
            capacity,
            phase,
            cgp,
            feeder,
            station,
            network,
            // eslint-disable-next-line camelcase
            reservation_start_date,
            // eslint-disable-next-line camelcase
            reservation_expiration_date,
            remove
          }))(el)
        })
    },
    updateMapAfterRemoveHCReservation (removedReservation) {
      this.$emit('updateMapAfterRemoveHCReservation', removedReservation)
    }
  }
}
</script>
