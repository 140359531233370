const d3 = require('d3');

export default {
  methods: {
    pieChart(bind, data) {
      // set the dimensions and margins of the graph
      const width = 280;
      const height = 225;
      const margin = 25;
      const colors = ['#c29819', '#755c0f', '#d217e6', '#8b0099', '#00b88a', '#078868', '#24346B', '#556192', '#D94131'];

      // The radius of the pieplot is half the width or half the height (smallest one)
      // I subtract a bit of margin.
      const radius = Math.min(width, height) / 2 - margin;

      // append the svg object to the div called 'my_dataviz'
      const svg = d3.select(bind)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2})`);

      // Compute the position of each group on the pie:
      const pie = d3.pie()
        .sort(null) // Do not sort group by size
        .value((d) => d[1]);
      const dataReady = pie(Object.entries(data));
      // Compute percentages
      let totalCount = 0;
      dataReady.forEach((d) => { totalCount += d.value; });
      for (let i = 0; i < dataReady.length; i += 1) {
        dataReady[i].percentage = ((dataReady[i].value / totalCount) * 100).toFixed(1);
      }

      // The arc generator
      const arc = d3.arc()
        .innerRadius(radius * 0.4) // This is the size of the donut hole
        .outerRadius(radius * 0.85);

      // Another arc that won't be drawn. Just for labels positioning
      const outerArc = d3.arc()
        .innerRadius(radius * 0.9)
        .outerRadius(radius * 0.9);

      // const index = d3.local();
      // Build the pie chart:
      // Basically, each part of the pie is a path that we build using the arc function.
      const slices = svg
        .selectAll('allSlices')
        .data(dataReady)
        .enter()
        .append('path')
        .attr('id', (d) => `${bind.substring(1)}_${d.data[0]}`)
        .attr('d', arc)
        .attr('fill', (d, i) => colors[i]);

      // add hover event
      slices.on('mouseover', function sliceMouseover() {
        d3.select(this)
          .attr('stroke', '#fff')
          .attr('stroke-width', 2);
      });
      slices.on('mouseout', function sliceMouseout() {
        d3.select(this)
          .attr('stroke', 'none');
      });
      // slices.on('click', function sliceClick() {
      //   const params = this.id.split('_');
      //   window.location.replace(`/grid/${params[0]}/${params[1]}/${params[2]}`);
      // });

      // Add the number in each slice
      svg
        .selectAll('text')
        .data(dataReady)
      // .attr("transform", d => `translate(${arcLabel.centroid(d)})`)
        .call((text) => text.append('tspan')
          .attr('y', '-0.4em')
          .text((d) => d.data[0]))
        .attr('fill', '#fff');

      let previousY = 0;
      // Add the polylines between chart and labels:
      svg
        .selectAll('allPolylines')
        .data(dataReady)
        .enter()
        .append('polyline')
        .attr('stroke', 'white')
        .style('fill', 'none')
        .attr('stroke-width', 1)
        .attr('points', (d) => {
          // line insertion in the slice
          const posA = arc.centroid(d);
          // line break: we use the other arc generator that has been built only for that
          const posB = outerArc.centroid(d);
          // Label position = almost the same as posB
          const posC = outerArc.centroid(d);
          // we need the angle to see if the X position will be at the extreme right or extreme left
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          const side = midangle < Math.PI ? 1 : -1; // 1: right / -1: left
          previousY = Math.abs(posC[1] - previousY) < 10 ? (previousY + 10 * side) : posC[1];
          posC[1] = previousY;
          posB[1] = previousY;
          // let distance = radius * 0.9 - 5*d.data[0].length;
          posC[0] = (radius * 0.9 - 5 * d.data[0].length) * side;
          posC[0] = ((posC[0] - posB[0]) * side < 0)
            ? posB[0] : (radius * 0.9 - 5 * d.data[0].length) * side;
          // posC[0] = (radius * 0.9 - 5*d.data[0].length) * side;
          // multiply by 1 or -1 to put it on the right or on the left

          return [posA, posB, posC];
        });

      previousY = 0;
      let maxX = 0;
      // Add the polylines between chart and labels:
      svg
        .selectAll('allLabels')
        .data(dataReady)
        .enter()
        .append('text')
        .text((d) => {
          const content = `${d.data[0]}(${d.percentage}%)`;
          return content;// .match(/.{1,2}/g).join("\n");
        })

        .attr('transform', (d) => {
          const pos = outerArc.centroid(d);
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          const side = midangle < Math.PI ? 1 : -1;
          const posC = outerArc.centroid(d);
          previousY = Math.abs(posC[1] - previousY) < 15 ? (previousY + 15 * side) : posC[1];
          posC[1] = previousY;
          posC[0] = (radius * 0.91 - 5 * d.data[0].length) * side;
          posC[0] = ((posC[0] - pos[0]) * side < 0)
            ? pos[0] : ((radius * 0.91 - 5 * d.data[0].length) * side);
          maxX = Math.max(maxX, Math.abs(posC[0]) + 15 * d.data[0].length);
          return `translate(${posC})`;
        })
        .style('text-anchor', (d) => {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
          return (midangle < Math.PI ? 'start' : 'end');
        })
        .style('fill', '#fff')
        .style('font-family', 'Faktum Light')
        .style('font-size', 14);
    },
  },
};
