import { plexiRequest } from '.'

export const getInventory = async (asset, filter, type = 'inventory') => {
  if (asset && filter) {
    const data = await plexiRequest({ url: `ari/${type}?asset=${asset}&${filter}` })

    return data.map(({ DESCRIPTION, ...restData }) => restData)
  }
}

export const getAssetMapByZone = async zone =>
  zone && plexiRequest({ url: `ari/assetmap?area=${zone}` })

export const getAssetMapByElementId = async (element) =>
  element && plexiRequest({ url: `ari/assetmap?elementType=${element.type}&elementId=${element.id}&r=${element.radius}` })

export const getAssetMapByElementName = async (element) =>
  element && plexiRequest({ url: `ari/assetmap?elementType=${element.type}&elementName=${element.name}&r=${element.radius}` })

export const getElementDetails = async (type, id) => {
  return (type && id) &&
    plexiRequest({ url: `/ari/get_element_details?tipo=${type}&id_elemento=${id}` })
      .then(data => {
        const { transformers } = data
        if (!transformers) return data

        return {
          ...data,
          transformers: Object.keys(transformers).map(key => transformers[key])
        }
      })
}

export const getMeasurements = () =>
  plexiRequest({ url: '/measurements/signals/map/' })
    .then(data => data.map(measurement => ({
      text: measurement.name,
      value: measurement.code,
      units: measurement.units
    })))

export const getEvents = () =>
  plexiRequest({ url: '/measurements/events/map' })
    .then(data => data?.map(event => ({
      text: event.name,
      value: event.code
    })))

export const getPlotData = async ({
  measurement,
  meters,
  isSupervisor,
  aggregation,
  timeStart,
  timeEnd
}) => {
  const queryParams = `
    CODE=${measurement}& \
    aggregation=${aggregation}& \
    TIME_START=${timeStart}& \
    TIME_END=${timeEnd}& \
    is_supervisor=${isSupervisor} \
  `.replace(/  +/g, '')

  return measurement && meters && aggregation && timeStart && timeEnd &&
    plexiRequest({
      url: `/measurements/signals?${queryParams}`,
      method: 'post',
      data: {
        METERS: meters
      }
    }).then(data => {
      return aggregation === 'NONE'
        ? {
            [aggregation]: meters.map(meter => ({
              ...data[aggregation][meter],
              meter
            }))
          }
        : { ...data, meters }
    })
      .catch(err => ({ error: true, message: err }))
}

export const getEventsPlotData = async ({
  event,
  interval,
  meters,
  timeStart,
  timeEnd,
  aggregation
}) => {
  const queryParams = `
    CODE=${event}& \
    INTERVAL=${interval}& \
    TIME_START=${timeStart}& \
    TIME_END=${timeEnd} \
  `.replace(/  +/g, '')

  return event && meters && interval && aggregation && timeStart && timeEnd &&
    plexiRequest({
      url: `/measurements/events?${queryParams}`,
      method: 'post',
      data: {
        METERS: meters
      }
    }).then(data => {
      return {
        ...data,
        data,
        aggregation,
        meters
      }
    })
      .catch(err => ({ error: true, message: err }))
}

export const getMeasurementMapData = async ({
  measurement,
  meters,
  aggregation,
  timeStart,
  timeEnd
}) => {
  const queryParams = `
    CODE=${measurement}& \
    TIME_START=${timeStart}& \
    TIME_END=${timeEnd}& \
    aggregation=${aggregation} \
  `.replace(/  +/g, '')

  return measurement && meters && aggregation && timeStart && timeEnd &&
  plexiRequest({
    url: `/measurements/signals/by-cgp?${queryParams}`,
    method: 'post',
    data: {
      METERS: meters
    }
  })
}

export const getEventsMapData = async ({
  event,
  meters,
  interval,
  timeStart,
  timeEnd
}) => {
  const queryParams = `
    CODE=${event}& \
    TIME_START=${timeStart}& \
    TIME_END=${timeEnd}& \
    INTERVAL=${interval} \
  `.replace(/  +/g, '')

  return event && meters && interval && timeStart && timeEnd &&
  plexiRequest({
    url: `/measurements/events/by-cgp?${queryParams}`,
    method: 'post',
    data: {
      METERS: meters
    }
  })
}

export const getMetersCodeMap = async () =>
  plexiRequest({ url: 'ari/meters/codemap' })
