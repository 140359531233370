<template>
  <v-card elevation="10">
    <v-card-title class="text-h5">
      Select Series to export
    </v-card-title>
    <v-card-text>
      <v-col
        class="p-2 pl-6"
        dense
      >
        <v-checkbox
          v-model="selectedPhase"
          label="Power Limit"
          value="Power Limit"
          color="#f4c020"
          dense
        />
        <v-checkbox
          v-model="selectedPhase"
          label="Consumed Total Power"
          value="Consumed Total Power"
          color="#f4c020"
          dense
        />

        <v-checkbox
          v-model="selectedPhase"
          label="Consumed Non flex Power"
          value="Consumed Non flex Power"
          color="#f4c020"
          dense
        />

        <v-checkbox
          v-model="selectedPhase"
          label="Past Predicted Total Power"
          value="Past Predicted Total Power"
          color="#f4c020"
          dense
        />
        <v-checkbox
          v-model="selectedPhase"
          label="Past Predicted Non Flex Power"
          value="Past Predicted Non Flex Power"
          color="#f4c020"
          dense
        />
        <v-checkbox
          v-model="selectedPhase"
          label="Predicted Total Power"
          value="Predicted Total Power"
          color="#f4c020"
          dense
        />

        <v-checkbox
          v-model="selectedPhase"
          label="Predicted Non Flex Power"
          value="Predicted Non Flex Power"
          color="#f4c020"
          dense
        />

        <v-checkbox
          v-model="selectedPhase"
          label="Past Flex Capacity"
          value="Past Flex Capacity"
          color="#f4c020"
          dense
        />

        <v-checkbox
          v-model="selectedPhase"
          label="Future Flex Capacity"
          value="Future Flex Capacity"
          color="#f4c020"
          dense
        />
        <v-checkbox
          v-model="selectedPhase"
          label="Flex Margin"
          value="Flex Margin"
          color="#f4c020"
          dense
        />
        
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="#f4c020"
        text
        @click="closeDownload"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        text
        :disabled="selectedPhase == 0"
        @click="downloadData"
      >
        {{ $t('plot.download') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
  name: 'TIAMonitoringExportPlotDataForm',
  props: {
    data: { type: Array, default: null }
    
  },

  data () {
    return {
      selectedPhase: ['Power Limit', 'Consumed Total Power', 'Consumed Non flex Power',
       'Past Predicted Total Power','Past Predicted Non Flex Power','Predicted Total Power',
       'Predicted Non Flex Power','Past Flex Capacity','Future Flex Capacity','Flex Margin'],
      enhancedTime: [],
      time: []
    }
  },

  watch: {
    selectedPhase () {
      return this.selectedPhase.length
    }
  },

  methods: {
    closeDownload () {
      this.$emit('closeDownload')
    },

    downloadData () {
      console.log(this.selectedPhase)
      console.log(this.data)
      const wb = XLSX.utils.book_new();
      this.data.forEach((serie, index) => {
        console.log(serie.name)
        if (this.selectedPhase.includes(serie.name)){
          const ws = XLSX.utils.aoa_to_sheet([['Serie', 'Date', 'Value']])
          for (let i = 0; i < serie.x.length; i++) {
            const newRow = [serie.name, serie.x[i], serie.y[i]]
            XLSX.utils.sheet_add_aoa(ws, [newRow], {origin: -1})
          }
          XLSX.utils.book_append_sheet(wb, ws,  serie.name || `Sheet${index + 1}`)
        }
      })
      XLSX.writeFile(wb, 'data.xlsx')
      this.$emit('closeDownload')
    }
  }
}
</script>
