import '@/assets/main.css'
import '@/assets/icons/style.css'
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#F4C01F',
        secondary: '#272727',
        accent: '#15232E',
        black: '#000',
        error: '#c00',
        success: '#060'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})
