<template>
  <div>
    <VApp>
      <LCircle
        v-for="connectionPoint in connectionPoints"
        :key="connectionPoint.ID"
        :lat-lng="connectionPoint.latLng"
        :radius="setRadiusCP(connectionPoint.ID)"
        :color="connectionPointColor(connectionPoint)"
        :fill-color="setElementColor(connectionPoint.NETWORK, colorStation)"
        :fill-opacity="setOpacity(connectionPoint.ID)"
        :opacity="setOpacity(connectionPoint.ID)"
        :class-name="`network${connectionPoint.NETWORK} connectionPoint${connectionPoint.ID} circle`"
        @mouseover="mouseover($event.target, connectionPoint.ID, 'connectionPoint', level, connectionPoint.NETWORK)"
        @mouseleave="mouseleave($event.target, connectionPoint.ID, 'connectionPoint', level, connectionPoint.NETWORK)"
        @click="click(level, connectionPoint.NETWORK, connectionPoint.ID, 'connectionPoint', false, hostingCapacityMap, multiselect)"
        @contextmenu="handleContextmenu($event, connectionPoint)"
      >
        <LTooltip v-if="isShowTooltipWithCPValue(connectionPoint.ID)">
          {{ measurementTooltipValue(connectionPoint.ID) }}
        </LTooltip>
        <LTooltip v-else-if="hostingCapacityMap && (connectionPoint.tooltip || tooltipText)">
          <strong>{{ connectionPoint.tooltip || tooltipText }}</strong>
          <div
            v-if="connectionPoint.tooltipAbsolute"
            style="font-size:9px;"
          >
            {{ connectionPoint.tooltipAbsolute }}
          </div>
        </LTooltip>
      </LCircle>
      <div v-if="showReservationsIcons">
        <L-Marker-Cluster
          :options="clusterOptions"
        >
          <LMarker
            v-for="reservation in reservations"
            :key="reservation.id"
            :lat-lng="setReservationLatLng(reservation.cgp)"
            @click="onHCReservationClick(reservation)"
            @contextmenu="handleReservationContextmenu($event, reservation.cgp)"
          >
            <LIcon
              :icon-anchor="[12, 12]"
              :icon-size="[24, 24]"
              class-name="marker-icon"
            >
              <div class="circle-text">
                {{ reservation.reservation_type ? 'C' : 'G' }}
              </div>
            </LIcon>
          </LMarker>
        </L-Marker-Cluster>
      </div>
    </VApp>
  </div>
</template>

<script>
import proj4 from 'proj4'
import { LCircle, LTooltip, LMarker, LIcon } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { mapState, mapGetters } from 'vuex'

import click from '@/mixins/map/mapElementClickMixin'
import clickElements from '@/mixins/map/mapElementSelectMixin'
import unclickElements from '@/mixins/map/mapElementUnselectMixin'
import mouseover from '@/mixins/map/mapElementMouseoverMixin'
import mouseleave from '@/mixins/map/mapElementMouseleaveMixin'
import objectsMixin from '@/mixins/objectsMixin'
import mapContextMenuMixin from '@/mixins/map/mapContextMenuMixin'
import mapElementOpacityMixin from '@/mixins/map/mapElementOpacityMixin'
import mapElementColorMixin from '@/mixins/map/mapElementColorMixin'
import MSRadiusCPMixin from '@/mixins/Measurements/MSRadiusCPMixin'
import maths from '@/mixins/maths'

export default {
  name: 'ConnectionPointLayer',

  components: {
    LCircle,
    LTooltip,
    LMarker,
    LIcon,
    'L-Marker-Cluster': Vue2LeafletMarkerCluster
  },

  mixins: [
    unclickElements,
    click,
    clickElements,
    mouseover,
    mouseleave,
    objectsMixin,
    mapContextMenuMixin,
    mapElementOpacityMixin,
    mapElementColorMixin,
    MSRadiusCPMixin,
    maths
  ],

  props: {
    connectionPointsData: {
      type: Array,
      required: true
    },
    colorStation: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    contextMenu: {
      type: Boolean
    },
    hcmContextMenu: {
      type: Boolean
    },
    hcPhaseIndex: {
      type: Number,
      default: null
    },
    hostingCapacityMap: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: true
    },
    level: {
      type: String,
      default: ''
    },
    hcZoom: {
      type: Number,
      default: 0
    },
    selectedTypeViewMap: {
      type: [Array, Number, String],
      default: () => []
    },
    tooltipText: {
      type: String,
      default: null
    },
    notAvailableHC: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      stationsRead: 0,
      staticAnchor: [16, 37],
      clusterOptions: {},
      hcPhases: ['A', 'B', 'C', '3P']
    }
  },

  computed: {
    ...mapGetters(['HCManageResultsSolved']),
    ...mapState({
      markers: (state) => state.markers,
      HCReservations: ({ hostingCapacity }) => hostingCapacity.HCReservations
    }),
    connectionPoints () {
      const connectionPointsUpdated = this.connectionPointsData
      const data = this.connectionPointsData
      data.forEach((element, index) => {
        const location = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [element.X, element.Y])
        connectionPointsUpdated[index].latLng = [location[1], location[0]]
      })

      return connectionPointsUpdated
    },
    reservations () {
      let hcType = 1
      if (this.selectedTypeViewMap === 'generation') {
        hcType = 0
      }
      return this.HCReservations.filter((element) =>
        element.reservation_type === hcType &&
        element.phase === this.hcPhases[this.hcPhaseIndex] &&
        element.network === this.connectionPointsData[0].NETWORK
      )
    },
    showReservationsIcons () {
      let isReservation = false
      if (this.hostingCapacityMap && this.connectionPointsData.length) {
        const st = this.HCReservations.find(el => el.network === this.connectionPointsData[0].NETWORK)
        isReservation = st !== undefined
      }
      return isReservation && this.HCReservations.length
    }
  },
  mounted () {
    setTimeout(() => {
      this.$nextTick(() => {
        this.clusterOptions = { disableClusteringAtZoom: 11 }
      })
    }, 5000)
  },

  methods: {
    connectionPointColor (connectionPoint) {
      if (this.hostingCapacityMap && connectionPoint.colorHC) {
        return connectionPoint.colorHC
      }
      return this.setElementColor(connectionPoint.STATION, this.colorStation)
    },
    isHCContextMenuAvailable (cpNotAvailableHC) {
      return this.hcmContextMenu && (!cpNotAvailableHC || !this.notAvailableHC)
    },
    handleContextmenu (e, connectionPoint) {
      if (this.contextMenu || this.isHCContextMenuAvailable(connectionPoint.notAvailableHC)) {
        this.resetContextMenu()

        const contextMenuPayload = {
          x: e.containerPoint.x + 75,
          y: e.containerPoint.y + 75,
          toggle: 'contextMenu'
        }

        const connectionPointPayload = {
          path: 'connectionPoint',
          value: connectionPoint
        }

        const connectionPointPayLoad = {
          path: 'contextMenuElement',
          value: { type: 'contextMenuConn', info: connectionPoint }
        }

        this.$store.dispatch('setClick', { lat: e.latlng.lat, lng: e.latlng.lng, idElement: connectionPoint.ID })
        this.$store.dispatch('setNewPosition', contextMenuPayload)
        this.$store.dispatch('setElement', connectionPointPayload)
        this.$store.dispatch('setElement', connectionPointPayLoad)
      }
    },
    measurementTooltipValue (id) {
      let text = 'No data available'
      if (this.elementsOnMap.data[id].value.length) {
        text = this.elementsOnMap.data[id].value[this.instant]
          ? this.roundToThree(this.elementsOnMap.data[id].value[this.instant])
          : 0
        text += ` ${this.elementsOnMap.units}`
      }
      return text
    },
    hasHCReservation (cpId) {
      this.HCReservations?.forEach((reservation) => {
        if (reservation.id === cpId) {
          return true
        }
      })
      return false
    },
    setReservationLatLng (reservationId) {
      const cps = this.connectionPoints
      const connectionPoint = cps.find((el) => el.ID === reservationId.toString())
      return connectionPoint.latLng
    },
    onHCReservationClick (reservation) {
      if (this.hostingCapacityMap) {
        const cps = this.connectionPoints
        const connectionPoint = cps.find((el) => el.ID === reservation.cgp.toString())
        this.$emit('onHCReservationClick', reservation.network)
        this.click(this.level, connectionPoint.NETWORK, connectionPoint.ID, 'connectionPoint', false, this.hostingCapacityMap, this.multiselect)
      }
    },
    handleReservationContextmenu (e, cpId) {
      const cps = this.connectionPoints
      const connectionPoint = cps.find((el) => el.ID === cpId.toString())
      this.handleContextmenu(e, connectionPoint)
    }
  }
}
</script>

<style scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
.map-contexmenu {
  z-index: 500;
}
.marker-icon .circle-text {
  width: inherit;
  height: inherit;
  font-size: 16px;
  border-radius: 50%;
  padding: 10px;
  background: rgba(255, 255, 255, 0.892);
  border: 2px solid #000;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.marker-icon .circle-text:hover {
  background: #000;
  border: 2px solid rgba(255, 255, 255, 0.892);
  color: rgba(255, 255, 255, 0.892);
}
</style>
