<template>
  <div>
    <FullNetwork
      v-for="network in layer.networks"
      :key="network.network.NAME"
      :network-data="network"
      :mode="modeSelected"
      :digital-twin-map="digitalTwinMap"
      :hosting-capacity-map="hostingCapacityMap"
      :connection-point-context-menu="enableContextMenu"
      :connection-point-h-c-m-context-menu="enableHCMContextMenu"
      :hc-phase-index="hcPhaseIndex"
      :fuse-context-menu="enableContextMenu"
      :station-context-menu="enableContextMenu"
      :line-context-menu="enableContextMenu"
      :multiselect="multiselect"
      :level="layer.level"
      :zoom="zoom"
      :hc-zoom="zoom"
      :selected-type-view-map="selectedTypeViewMap"
      @hover="hover"
      @leave="leave"
      @click="click"
      @onHCReservationClick="onHCReservationClick"
    />
    <!-- <StationLayer
      v-for="station in layer.stations"
      :key="station.NAME"
      :station-data="station"
      :color-station="station.color"
      :mode="mode"
      :zoom="zoom"
      :multiselect="multiselect"
      :context-menu="enableContextMenu"
      :level="layer.level"
      @hover="hover"
      @leave="leave"
      @click="click"
    /> -->
    <div v-if="layer.switchBoxes">
      <SwitchBox
        :switch-boxes-data="layer.switchBoxes"
        :mode="modeSelected"
        color-station="#fff"
        @click="click"
        @hover="hover"
        @leave="leave"
      />
    </div>
    <div v-if="layer.openFuses">
      <FuseLayer
        :fuses-data="layer.openFuses"
        :mode="modeSelected"
        :color="colorOpenFuses"
        :context-menu="enableContextMenu"
        :hosting-capacity-map="hostingCapacityMap"
        @click="click"
      />
    </div>
    <div v-if="layer.isolatedLines">
      <LineLayer
        :isolated="true"
        :lines-data="layer.isolatedLines"
        :context-menu="enableContextMenu"
        mode="line_mode"
        color-station="#fff"
        @click="click"
      />
    </div>
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
import FullNetwork from '@/components/Map/NetworkGroupedLayer.vue'
import SwitchBox from '@/components/Map/SwitchBoxLayer.vue'
import FuseLayer from '@/components/Map/FuseLayer.vue'
// import StationLayer from '@/components/Map/StationLayer.vue'
import LineLayer from '@/components/Map/LineLayer.vue'
export default {
  name: 'TIAMapSearchBox',

  components: {
    FullNetwork,
    SwitchBox,
    FuseLayer,
    // StationLayer,
    LineLayer
  },
  props: {
    layer: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    searchIcon: mdiMagnify,
    value: null,
    zoom: 15,
    selectedTypeViewMap: 'rawView'
  }),
  mounted () {
    this.value = null
  },
  methods: {
    selectElement () {
      this.$emit('selectElement', this.value)
    }
  }
}
</script>

<style scoped>
.map-searchbox {
  z-index: 505;
  position: relative;

  float: right;

  height: 30px;
  line-height: 30px;
}
</style>
