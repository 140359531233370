<template>
  <div>
    <span class="title yellowPlexi"> <b>Expected for tomorrow</b></span>
    <v-divider />
    <splitpanes
      horizontal
      :push-other-panes="false"
    >
      <pane
        min-size="40"
        max-size="40"
        size="40"
      >
        <splitpanes :push-other-panes="false">
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMapKpisCard
              v-if="network.length>0"
              :title="networkDetails.forecast_non_active_flex_count[0]"
              :value="dash"
              :color="networkDetails.forecast_non_active_flex_count[1]"
              :icon-class="networkDetails.forecast_non_active_flex_count[2]"
              :assigned-class="networkDetails.forecast_non_active_flex_count[3]"
              :icon="networkDetails.forecast_non_active_flex_count[4]"
            />
          </pane>
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMapKpisCard
              v-if="network.length>0"
              :title="networkDetails.forecast_active_flex_count[0]"
              :value="dash"
              :color="networkDetails.forecast_active_flex_count[1]"
              :icon-class="networkDetails.forecast_active_flex_count[2]"
              :assigned-class="networkDetails.forecast_active_flex_count[3]"
              :icon="networkDetails.forecast_active_flex_count[4]"
            />
          </pane>
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMapKpisCard
              v-if="network.length>0"
              :title="networkDetails.forecast_outside_limits_count[0]"
              :value="dash"
              :color="networkDetails.forecast_outside_limits_count[1]"
              :icon-class="networkDetails.forecast_outside_limits_count[2]"
              :assigned-class="networkDetails.forecast_outside_limits_count[3]"
              :icon="networkDetails.forecast_outside_limits_count[4]"
            />
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>

    <span class="title yellowPlexi"> <b>Real Time</b></span>
    <v-divider />
    <splitpanes
      horizontal
      :push-other-panes="false"
    >
      <pane
        min-size="40"
        max-size="40"
        size="40"
      >
        <splitpanes :push-other-panes="false">
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMapKpisCard
              v-if="network.length>0"
              :title="networkDetails.non_active_flex_count[0]"
              :value="network[0].non_active_flex_count"
              :color="networkDetails.non_active_flex_count[1]"
              :icon-class="networkDetails.non_active_flex_count[2]"
              :assigned-class="networkDetails.non_active_flex_count[3]"
              :icon="networkDetails.non_active_flex_count[4]"
            />
          </pane>
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMapKpisCard
              v-if="network.length>0"
              :title="networkDetails.active_flex_count[0]"
              :value="network[0].active_flex_count"
              :color="networkDetails.active_flex_count[1]"
              :icon-class="networkDetails.active_flex_count[2]"
              :assigned-class="networkDetails.active_flex_count[3]"
              :icon="networkDetails.active_flex_count[4]"
            />
          </pane>
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMapKpisCard
              v-if="network.length>0"
              :title="networkDetails.outside_limits_count[0]"
              :value="network[0].outside_limits_count"
              :color="networkDetails.outside_limits_count[1]"
              :icon-class="networkDetails.outside_limits_count[2]"
              :assigned-class="networkDetails.outside_limits_count[3]"
              :icon="networkDetails.outside_limits_count[4]"
            />
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>

    <span class="title yellowPlexi"> <b>Performances KPIs</b></span>
    <v-divider />
    <span class="subtitle yellowPlexi"> <b>Today</b></span>
    <v-divider />
    <splitpanes
      horizontal
      :push-other-panes="false"
    >
      <pane
        min-size="40"
        max-size="40"
        size="40"
      >
        <splitpanes :push-other-panes="false">
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMapKpisCard
              v-if="network.length>0"
              :title="networkDetails.activated_success[0]"
              :value="network[0].activated_success "
              :color="networkDetails.activated_success[1]"
              :icon-class="networkDetails.activated_success[2]"
            />
          </pane>
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMapKpisCard
              v-if="network.length>0"
              :title="networkDetails.time_within_limits[0]"
              :value="network[0].time_within_limits "
              :color="networkDetails.time_within_limits[1]"
              :icon-class="networkDetails.time_within_limits[2]"
            />
          </pane>
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMapKpisCard
              v-if="network.length>0"
              :title="networkDetails.activated_stations[0]"
              :value="network[0].activated_stations"
              :color="networkDetails.activated_stations[1]"
              :icon-class="networkDetails.activated_stations[2]"
              :assigned-class="networkDetails.activated_stations[3]"
            />
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>
  </div>
</template>

<script>

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import TIAMapKpisCard from '@/components/TIA/TIAMap/TIAMapKpisCard.vue'

export default {
  name: 'TIAMapKpis',
  components: {
    Splitpanes,
    Pane,
    TIAMapKpisCard

  },
  props: {
    network: {
      type: Array,
      default: () => []
    }

  },
  data: () => ({
    dash:'-',
    networkDetails: {
      forecast_non_active_flex_count: ['Non-active flex', '#34AE60', 'x-large', 'circle-status-forecast-green', 'mdi-circle'],
      forecast_active_flex_count: ['Active flex', '#dde675', 'x-large', 'circle-status-forecast-yellow', 'mdi-circle'],
      forecast_outside_limits_count: ['Outside limits', '#EC7063', 'x-large', 'circle-status-forecast-red', 'mdi-circle'],
      non_active_flex_count: ['Non-active flex', '#34AE60', 'x-large', 'circle-status-green', 'mdi-circle-outline'],
      active_flex_count: ['Active flex', '#dde675', 'x-large', 'circle-status-yellow', 'mdi-circle-outline'],
      outside_limits_count: ['Outside limits', '#EC7063', 'x-large', 'circle-status-red', 'mdi-circle-outline'],
      activated_success: ['Activation Success', '#1E1E1E', '-'],
      time_within_limits: ['Time within the limits', '#1E1E1E', '-'],
      activated_stations: ['Activated Stations', '#1E1E1E', '-', 'circle-status-all']
    }

  }),

}
</script>

<style scoped>

.title {
  text-align: center;
  font-size: 27px;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 4px;
  color: rgb(195,195,195)
}
.subtitle {
  text-align: center;
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 4px;
}
.splitpanes__pane {
  display: flex;
  justify-content: center;

  color: rgba(255, 255, 255, 0.6);
  font-size: 1em;
}

</style>
