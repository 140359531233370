import { mapState } from 'vuex'
const d3 = require('d3')

export default {
  data: () => ({
    instant: 0,
    case: 0,
    colorScaleLower: 218.5,
    colorScaleMiddle: 230,
    colorScaleUpper: 241.5,
    colorScaleColorsList: {
      voltage: ['#4314FF', '#3982FF', '#8AFF63', '#D0383D', '#C60209'],
      default: ['#8AFF63', '#FFAE1A', '#C60209']
    },
    submeasurementOptions: {
      min: [0, 256],
      max: [1, 257],
      avg: [2, 258],
      phA: [0, 0],
      phB: [1, 1],
      phC: [2, 2],
      phN: [3, 3],
      puOReal: [0, 256],
      defaultAbsolute: [2, 258],
      default: [0, 0]

    },
    units: {
      voltage: ['V', 'p.u.'],
      current: ['A', '%'],
      activePower: ['kW', 'p.u.'],
      reactivePower: ['kVAR', 'p.u.'],
      losses: ['W', 'W'],
      unbalance: ['', '%'],
      default: ['', '']
    },
    areaStationsColors: [
      '#b4d8e5',
      '#d4bdc5',
      '#ef968f',
      '#e8b293',
      '#e791f0',
      '#2df4c2',
      '#da7fe4',
      '#8de8d1',
      '#889bd7',
      '#7f94f1',
      '#e4e6f9',
      '#62d5be',
      '#8fa3e0',
      '#e472f0',
      '#38f5ca',
      '#959dc1',
      '#ea9ef2',
      '#b4fae8',
      '#7685b8',
      '#84cce9',
      '#d4bdc5',
      '#ddcad0',
      '#f3aea8',
      '#f6c1be',
      '#ebbea4',
      '#efccb8',
      '#a4e6dc',
      '#b2eae1',
      '#a3b1e0',
      '#dadff3'
    ]
  }),
  computed: {
    ...mapState({
      measurement: (state) => state.measurement.measurement,
      submeasurement: (state) => state.measurement.submeasurement
    }),
    numberOfColors () {
      return this.colorScaleColors.length
    },
    colorScaleColors () {
      return (
        this.colorScaleColorsList[this.measurement] ||
        this.colorScaleColorsList.default
      )
    },
    hcmColors () {
      return this.colorScaleColorsList.default.reverse()
    }
  },
  methods: {
    getColorScale (numColors, colors, lower, middle, upper) {
      if (numColors === 3) {
        return d3.scaleLinear().domain([lower, middle, upper]).range(colors)
      }
      if (numColors === 5) {
        const midLow = (lower + middle) / 2
        const midUp = (middle + upper) / 2
        return d3
          .scaleLinear()
          .domain([lower, midLow, middle, midUp, upper])
          .range(colors)
      }
      return 0
    },
    async changeInstant (instant) {
      this.instant = instant
      this.$store.dispatch('setElement', { path: 'instant', value: instant })
      if (this.$route.name === 'GridInspection' && this.HCManageResultsSolved.length) {
        this.updateHCM()
      } else {
        this.refreshColors()
      }
    },
    async changeUpperLimit (upper) {
      this.colorScaleUpper = parseFloat(upper)
      this.colorScaleMiddle = (this.colorScaleLower + this.colorScaleUpper) / 2
      if (this.$route.name === 'GridInspection') {
        this.updateHCM(true)
      } else {
        this.refreshColors()
      }
    },
    async changeLowerLimit (lower) {
      this.colorScaleLower = parseFloat(lower)
      this.colorScaleMiddle = (this.colorScaleLower + this.colorScaleUpper) / 2
      if (this.$route.name === 'GridInspection') {
        this.updateHCM(true)
      } else {
        this.refreshColors()
      }
    },
    setColorScale (colorScale) {
      this.colorScale = colorScale
    },
    refreshColors () {
      if (this.SLDView) {
        this.$refs.sld.refreshSLD()
      } else {
        this.colorLines()
      }
    },

    colorLines () {
      // Set the color line corresponding to the instant, the id, and the measurement
      let sign = ''
      this.stationsDetailsWithHeatmapLines = this.stationsDetails
      const colorScale = this.getColorScale(
        this.numberOfColors,
        this.colorScaleColors,
        this.colorScaleLower,
        this.colorScaleMiddle,
        this.colorScaleUpper
      )
      this.stationsDetails.forEach((station, indexStation) => {
        station.lines.forEach((line, indexLine) => {
          const measurementValue = this.getMeasurementValue(line.ID, 1)
          const measurementAbsoluteValue = this.getMeasurementValue(line.ID, 0)
          const colorLine = colorScale(
            Math.abs(this.fixToLimits(measurementValue))
          )
          // Set sign of the results and direction of the animation if needed
          if (
            measurementValue > 0.0 ||
            (measurementValue === 0 && line.FORWARD === true)
          ) {
            this.stationsDetailsWithHeatmapLines[indexStation].lines[
              indexLine
            ].positive = true
          } else {
            this.stationsDetailsWithHeatmapLines[indexStation].lines[
              indexLine
            ].positive = false
          }
          if (
            ((this.measurement === 'activePower' ||
              this.measurement === 'reactivePower') &&
              measurementValue < 0.0 &&
              line.FORWARD === true) ||
            ((this.measurement === 'activePower' ||
              this.measurement === 'reactivePower') &&
              measurementValue > 0.0 &&
              line.FORWARD === false)
          ) {
            sign = '-'
          }
          this.stationsDetailsWithHeatmapLines[indexStation].lines[
            indexLine
          ].color = colorLine
          // Set the tooltip for the line
          this.stationsDetailsWithHeatmapLines[indexStation].lines[
            indexLine
          ].tooltip =
            sign +
            Math.abs(this.roundToThree(measurementValue)).toString() +
            this.getUnits(this.measurement, 1)
          this.stationsDetailsWithHeatmapLines[indexStation].lines[
            indexLine
          ].tooltipAbsolute =
            sign +
            Math.abs(this.roundToThree(measurementAbsoluteValue)).toString() +
            this.getUnits(this.measurement, 0)
        })
      })
    },
    fixToLimits (value) {
      if (value >= this.colorScaleUpper) {
        return this.colorScaleUpper
      }
      if (value <= this.colorScaleLower) {
        return this.colorScaleLower
      }
      return value
    },
    getUnits (measurement, relative) {
      return this.units[measurement][relative] || this.units.default[relative]
    },
    getMeasurementValue (lineID, index) {
      const value = this.measurementCalculation[this.measurement](lineID, index)
      return Math.abs(value) < parseFloat(0.001) ? 0 : value
    },
    getVoltageMeasurement (lineID, index) {
      if (
        this.submeasurement === 'min' ||
        this.submeasurement === 'max' ||
        this.submeasurement === 'avg'
      ) {
        try {
          return this.digitalTwinResults.Results.GetBusV(
            this.case,
            this.instant,
            this.instant,
            this.digitalTwinResults.Results.Con_Dst[
              this.digitalTwinResults.Results.GetLineIndex(lineID)
            ],
            this.submeasurementOptions[this.submeasurement][index]
          )[0]
        } catch (error) {
          console.warn('Error in line ', lineID, ' getting voltage')
        }
      } else if (
        this.submeasurement === 'phA' ||
        this.submeasurement === 'phB' ||
        this.submeasurement === 'phC' ||
        this.submeasurement === 'phN'
      ) {
        try {
          return this.digitalTwinResults.Results.GetBusPhaseV(
            this.case,
            this.instant,
            this.instant,
            this.digitalTwinResults.Results.Con_Dst[
              this.digitalTwinResults.Results.GetLineIndex(lineID)
            ],
            this.submeasurementOptions[this.submeasurement][index],
            this.submeasurementOptions.puOReal[index]
          )[0]
        } catch (error) {
          console.warn('Error in line ', lineID, ' getting voltage')
        }
      }
      return 'error'
    },
    getCurrentMeasurement (lineID, index) {
      const factor = index === 0 ? 1 : 100
      if (
        this.submeasurement === 'min' ||
        this.submeasurement === 'max' ||
        this.submeasurement === 'avg'
      ) {
        try {
          return (
            factor *
            this.digitalTwinResults.Results.GetLineI(
              this.case,
              this.instant,
              this.instant,
              this.digitalTwinResults.Results.GetLineIndex(lineID),
              this.submeasurementOptions[this.submeasurement][index]
            )[0]
          )
        } catch (error) {
          console.warn('Error in line ', lineID, ' getting current')
        }
      } else if (
        this.submeasurement === 'phA' ||
        this.submeasurement === 'phB' ||
        this.submeasurement === 'phC' ||
        this.submeasurement === 'phN'
      ) {
        try {
          return (
            factor *
            this.digitalTwinResults.Results.GetLinePhaseI(
              this.case,
              this.instant,
              this.instant,
              this.digitalTwinResults.Results.GetLineIndex(lineID),
              this.submeasurementOptions[this.submeasurement][index],
              this.submeasurementOptions.puOReal[index]
            )[0]
          )
        } catch (error) {
          console.warn('Error in line ', lineID, ' getting current')
        }
      }
      return 'error'
    },
    getActivePowerMeasurement (lineID, index) {
      if (
        this.submeasurement === 'phA' ||
        this.submeasurement === 'phB' ||
        this.submeasurement === 'phC' ||
        this.submeasurement === 'phN'
      ) {
        try {
          return this.digitalTwinResults.Results.GetLineP(
            this.case,
            this.instant,
            this.instant,
            this.digitalTwinResults.Results.GetLineIndex(lineID),
            this.submeasurementOptions[this.submeasurement][index],
            this.submeasurementOptions.puOReal[index]
          )[0]
        } catch (error) {
          console.warn('Error in line ', lineID, ' getting active power')
        }
      } else if (this.submeasurement === 'total') {
        try {
          let P = 0
          for (let i = 0; i <= 3; i += 1) {
            P += parseFloat(
              this.digitalTwinResults.Results.GetLineP(
                this.case,
                this.instant,
                this.instant,
                this.digitalTwinResults.Results.GetLineIndex(lineID),
                i,
                this.submeasurementOptions.puOReal[index]
              )[0]
            )
          }
          return P
        } catch (error) {
          console.warn('Error in line ', lineID, ' getting active power')
        }
      }
      return 'error'
    },
    getReactivePowerMeasurement (lineID, index) {
      if (
        this.submeasurement === 'phA' ||
        this.submeasurement === 'phB' ||
        this.submeasurement === 'phC' ||
        this.submeasurement === 'phN'
      ) {
        try {
          return this.digitalTwinResults.Results.GetLineQ(
            this.case,
            this.instant,
            this.instant,
            this.digitalTwinResults.Results.GetLineIndex(lineID),
            this.submeasurementOptions[this.submeasurement][index],
            this.submeasurementOptions.puOReal[index]
          )[0]
        } catch (error) {
          console.warn('Error in line ', lineID, ' getting reactive power')
        }
      } else if (this.submeasurement === 'total') {
        try {
          let Q = 0
          for (let i = 0; i <= 3; i += 1) {
            Q += parseFloat(
              this.digitalTwinResults.Results.GetLineQ(
                this.case,
                this.instant,
                this.instant,
                this.digitalTwinResults.Results.GetLineIndex(lineID),
                i,
                this.submeasurementOptions.puOReal[index]
              )[0]
            )
          }
          return Q
        } catch (error) {
          console.warn('Error in line ', lineID, ' getting reactive power')
        }
      }
      return 'error'
    },
    getUnbalanceMeasurement (lineID) {
      try {
        return (
          100 *
          this.digitalTwinResults.Results.GetBusVu(
            this.case,
            this.instant,
            this.instant,
            this.digitalTwinResults.Results.Con_Dst[
              this.digitalTwinResults.Results.GetLineIndex(lineID)
            ]
          )
        )
      } catch (error) {
        console.warn('Error in line ', lineID, ' getting unbalance')
      }
      return 'error'
    },
    getLossesMeasurement (lineID, index) {
      if (
        this.submeasurement === 'phA' ||
        this.submeasurement === 'phB' ||
        this.submeasurement === 'phC' ||
        this.submeasurement === 'phN'
      ) {
        try {
          return this.digitalTwinResults.Results.GetLinePhaseLos(
            this.case,
            this.instant,
            this.instant,
            this.digitalTwinResults.Results.GetLineIndex(lineID),
            this.submeasurementOptions[this.submeasurement][index]
          )
        } catch (error) {
          console.warn('Error in line ', lineID, ' getting losses')
        }
      } else if (this.submeasurement === 'total') {
        try {
          let L = 0
          for (let i = 0; i <= 3; i += 1) {
            L += parseFloat(
              this.digitalTwinResults.Results.GetLinePhaseLos(
                this.case,
                this.instant,
                this.instant,
                this.digitalTwinResults.Results.GetLineIndex(lineID),
                i
              )
            )
          }
          return L
        } catch (error) {
          console.warn('Error in line ', lineID, ' getting losses')
        }
      }
      return 'error'
    }
  }
}
