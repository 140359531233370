export default {
  data: {
    tooltipPane: null
  },
  methods: {
    removeTooltipPane () {
      const tooltipElement = document.querySelector('.leaflet-tooltip-pane')
      if (tooltipElement) {
        this.tooltipPane = tooltipElement
        tooltipElement.parentNode.removeChild(tooltipElement)
      }
    },
    addTooltipPane () {
      if (this.tooltipPane) {
        const mapPane = document.querySelector('.leaflet-map-pane')
        mapPane.appendChild(this.tooltipPane)
      }
    }
  }
}
