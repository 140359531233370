import createSqlWasm from 'sql-wasm/dist/esm/sql-wasm-browser'
import { post, get, del } from '@/assets/queries'
import { getVoltageArray, getDB, getMontecarlo } from '@/assets/DigitalTwin/resultsFunctions'
import PowerFlowResult from '@/assets/commons/PFR.js'
import DigitalTwin from './DigitalTwin'
import Montecarlo from './Montecarlo'
import store from '@/store'
import { getInventoryCables } from '@/services/cableChange'

class DigitalTwinCreator {
  constructor (protocol, ip, port, infoFunc, errorFunc, warnFunc, debug) {
    /*
        Class used for the set up and initialization of a digital twin.
        This class sends the initial data to create a new case and runs a simulation with
        the base configuration of the network (status in the database and historic data).
        Params:
            * ip: ip of the backend service allocating the digital twin service
            * port: port of the service allocating the digital twin service
            * infoFunc: callback function when info messages are coming from the backend
            * errorFunc: callback function when error messages are coming from the backend
            * warnFunc: callback function when warning messages are coming from the backend
        */
    this.infoFunc = infoFunc
    this.errorFunc = errorFunc
    this.warnFunc = warnFunc
    // Set-up of the sqlite interpreter. In wasmUrl it should be defined the endpoint of the
    // 'sqlite3.wasm'. IMPORTANT: this should be served by the front-end server in order to
    // avoid CORS conflicts.
    // Initialize sql engine
    this.sqlPromise = debug ? createSqlWasm({ wasmUrl: `${protocol}://${ip}:${port}/digital-twin/sqlite3-wasm/` }) : createSqlWasm({ wasmUrl: '/static/sqlite3.wasm' })
    this.host = `${ip}:${port}`
    this.protocol = protocol
    this.ws_protocol = (protocol === 'https') ? 'wss' : 'ws'
  }

  createNewMontecarlo (projectName, networks, simParams, qualityParams, config) {
    const myHeaders = new Headers()
    myHeaders.append('content-type', 'application/json')

    const data = JSON.stringify({
      name: projectName,
      networks,
      params: simParams,
      quality: qualityParams,
      config
    })

    return post(`${this.protocol}://${this.host}/digital-twin/montecarlo`, myHeaders, data)
  }

  openMontecarlo (projectName) {
    return new Promise((resolve) => {
      let interval
      const checkSolved = () => {
        get(`${this.protocol}://${this.host}/digital-twin/montecarlo?project=${projectName}`)
          .then((response) => {
            if (response.cases[0].solved === true) {
              // DOWNLOAD MONTECARLO SIMULATION RESULTS
              const mc = this.downloadMontecarlo(projectName)
              // STOP CHECK STATUS PROCESS
              clearInterval(interval)
              // RESOLVE PROMISE
              resolve(mc)
            }
          })
      }
      interval = setInterval(checkSolved, 20000) // Check status each 20 seconds
      checkSolved()
    })
  }

  async downloadMontecarlo (projectName) {
    // METHOD TO DOWNLOAD THE VIOLATIONS BUFFER FROM MONTECARLO PROJECTS RESOLVED
    const montecarloProm = getMontecarlo(this.protocol, this.host, projectName)
    return Promise.all([this.sqlPromise, montecarloProm])
      .then((responses) => {
        const montecarloResults = responses[1]
        // Initialize SQL object
        const SQL = responses[0]
        // Load received data to SQL object
        this.DB = new SQL.Database(montecarloResults[1])

        // Constants initialization for NET
        const counts = this.DB.exec('SELECT COUNT(ID) AS n, \'buses\' AS Description FROM Bus UNION ALL SELECT COUNT(ID) AS n, \'lines\' AS Description FROM Line;')[0].values
        const [tcount, simMode] = this.DB.exec('SELECT StepCount, SimMode FROM CasePar;')[0].values[0]
        // D object initialization
        this.Network = new PowerFlowResult()
        this.Network.MemNew(counts[0][0], counts[1][0], tcount, 7)

        // Setup Buses and Lines data
        this.Network.NewCase(this.DB, tcount, simMode)

        this.Network.LoadViolationBuffer(montecarloResults[0])

        return new Montecarlo(this.DB, this.Network, projectName, this.host,
          this.sqlPromise, this.warnFunc, this.errorFunc, this.warnFunc)
      })
      .catch((err) => this.errorFunc(err))
  }

  newProject (projectName, networks, simParams, qualityParams) {
    /*
    Function to start up a simulation of a base case.
    Params:
        * projectName (string): name of the case
        * networks: (array[uint]): list of networks name to simulate
        * t0 (int): id of the initial instant of simulation
        * t1 (int): id of end instant to simulate.
    It returns a Promise.
    The promise when resolved, returns a Digital Twin class object with the
    backend configuration of the current DigitalTwinCreator and the base case
    information and results pre-loaded.
    */

    // REMOVE StartDate AND EndDate from simParams
    const { EndDate, InitDate, ...inputParams } = simParams

    return this.createNewProject(projectName, networks, inputParams, qualityParams)
      .then((res) => (
        res.ok === false // TODO: Now after background run, this never happens
          ? res
          : this.initProject(projectName, inputParams.StartTime)
      ))
      .catch((e) => {
        console.error({ errorCreateProject: e })
        return e
      })
  }

  async createNewProject (projectName, networks, simParams, qualityParams) {
    const myHeaders = new Headers()
    myHeaders.append('content-type', 'application/json')

    const data = JSON.stringify({
      name: projectName,
      networks,
      params: simParams,
      quality: qualityParams
    })

    await post(`${this.protocol}://${this.host}/digital-twin/project`, myHeaders, data)
    // TODO: AVOID THIS PERIODICAL CHECK DUPLICATION CODE RESPECT TO MONTECARLO EXECUTION
    return new Promise((resolve) => {
      let interval
      const checkSolved = () => {
        get(`${this.protocol}://${this.host}/digital-twin/project?name=${projectName}`)
          .then((response) => {
            store.dispatch('setElement', {
              path: 'DTProgress',
              value: response.init_percentage,
              root: store.state
            })
            if (response.cases[0].created === true) {
              // STOP CHECK STATUS PROCESS
              clearInterval(interval)
              // RESOLVE PROMISE
              resolve({ ok: true })
            }
          })
      }
      interval = setInterval(checkSolved, 1000) // Check status each second
      checkSolved()
    })
  }

  async openProject (projectName) {
    // Get information about cases created for project
    const projectInfo = await get(`${this.protocol}://${this.host}/digital-twin/project?name=${projectName}`)
    // Initialize DigitalTwin and download base case results
    const dt = await this.initProject(projectName, projectInfo.init_date)
    const caseSims = []
    let casePrevious = 'base'
    for (let k = 1; k < projectInfo.cases.length; k += 1) {
      // Add case information
      dt.cases[projectInfo.cases[k].name] = {
        idx: k // Define index of the case in the buffer
      }
      // Download case information
      caseSims.push(await dt.getCaseInfo(projectInfo.cases[k].name))
      // Download case results
      caseSims.push(await dt.simulateCase(projectInfo.cases[k].name, casePrevious))

      casePrevious = projectInfo.cases[k].name
    }
    dt.prepareTopology('base', projectInfo.cases[projectInfo.cases.length - 1].name)
    return Promise.all(caseSims).then(() => dt)
  }

  initProject (projectName, startTime) {
    const result = new Promise((resolve) => {
      function resolveDecorator (wrapped) {
        store.dispatch('setElement', {
          path: 'DTProgress',
          value: 101,
          root: store.state
        })
        // Function to modify the callback when the results are received.
        // Apart from downloading and set-up the DB and Network attributes, the
        // resulting elements will be encapsulated using a DigitalTwin class and
        // this class will be returned in the promise resolve function.
        return async function decoratedFunction (...args) {
          const localResult = await wrapped.apply(this, args)
          const availableCables = await getInventoryCables()
          const dt = new DigitalTwin(this.DB, this.Network, projectName, `${this.protocol}://${this.host}`,
            startTime, this.sqlPromise, this.warnFunc, this.errorFunc, this.infoFunc, availableCables)
          resolve(dt)
          return localResult
        }
      }

      this.downloadBase = resolveDecorator(this.downloadBase)
      this.simulateBase(projectName)
    })

    return result
  }

  simulateBase (projectName) {
    // TODO: create a general WebSockets object in order to avoid the duplication of the
    //       code below in the DigitalTwinCreator and DigitalTwin classes.
    /*
        Function to create the WebSocket to stablish connection to the backend digital twin.
        Returns a WebSocket object.
        Params:
            * projectName (string): name of the case
            * networks (array[uint]): list of networks names to simulate
            * t0 (int): id of the initial instant of simulation
            * t1 (int): id of end instant to simulate.
        */
    // Instantiation of the WebSocket connection
    const DTSocket = new WebSocket(
      `${this.ws_protocol}://${
        this.host
      }/digital-twin-ws/run/`
    )
    // When the connection is stablished, send the parameters
    // for the DigitalTwin set-up.
    DTSocket.onopen = async () => {
      console.log('Stablished connection to Digital Twin')
      DTSocket.send(JSON.stringify({
        project: projectName,
        case: 'base',
        user_id: sessionStorage.user_id
      }))
    }

    // Function that interprets the messages comming from the backend
    DTSocket.onmessage = async (e) => {
      if ((typeof e.data) === 'string') { // Case when messages are text
        const message = JSON.parse(e.data)
        const { Type, data } = message
        if (Type === 'info') {
          this.infoFunc(data.message) // Information messages coming from the backend
        } else if (Type === 'warning') {
          this.warnFunc(data.message) // Warning messages coming from the backend
        } else if (Type === 'error') {
          this.errorFunc(data.message) // Error messages coming from the backend
        }
      } else { // Case when messages are binary (results)
        this.warnFunc('No binary messages expected!!')
      }
    }

    DTSocket.onclose = async (e) => {
      if (e.code === 1000) {
        this.infoFunc('Simulation finished')
        await this.downloadBase(projectName)
      } else {
        this.errorFunc(`Connection closed with code ${e.code}`)
      }
    }
    return DTSocket
  }

  async downloadBase (projectName) {
    /*
        Callback function when a base case was resolved.
        The database and frame buffer is downloaded. These values are used to initialize
        a Database type object (this.DB) and a PowerFlowResult object(this.Network).
        Params:
            * url_db: endpoint to download the sqlite database.
            * url_res: endpoint to download the frame buffer with the simulation results.
        */
    const dbprom = getDB(`${this.protocol}://${this.host}`, projectName, 'base')
    const vprom = getVoltageArray(`${this.protocol}://${this.host}`, projectName, 'base')
    return Promise.all([this.sqlPromise, dbprom, vprom])
      .then((responses) => {
        // Initialize SQL object
        const SQL = responses[0]
        // Load received data to SQL object
        this.DB = new SQL.Database(responses[1])

        // Constants initialization for NET
        const counts = this.DB.exec('SELECT COUNT(ID) AS n, \'buses\' AS Description FROM Bus UNION ALL SELECT COUNT(ID) AS n, \'lines\' AS Description FROM Line;')[0].values
        const [tcount] = this.DB.exec('SELECT StepCount FROM CasePar;')[0].values[0]
        // D object initialization
        this.Network = new PowerFlowResult()
        this.Network.MemNew(counts[0][0], counts[1][0], tcount, 7)

        // Setup Buses and Lines data
        this.Network.NewCase(this.DB, tcount, 0)
        // this.Network.NewCase(this.DB, tcount, 1)

        // Add Voltage results to Buffer
        this.Network.BufferCount += 1;

        [, , this.Network.BufferList[0]] = responses
      })
      .catch((err) => this.errorFunc(err))
  }

  getProjectList () {
    return get(`${this.protocol}://${this.host}/digital-twin/project`)
  }

  getProjectInfo (projectName) {
    return get(`${this.protocol}://${this.host}/digital-twin/project?name=${projectName}`)
  }

  deleteProject (projectName) {
    return del(`${this.protocol}://${this.host}/digital-twin/project?name=${projectName}`)
  }

  getMontecarloInfo (projectName) {
    return get(`${this.protocol}://${this.host}/digital-twin/montecarlo?project=${projectName}`)
  }
}

export default DigitalTwinCreator
