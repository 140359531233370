<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      scrollable
      width="500"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          dark
          v-bind="attrs"
          class="button-margin"
          v-on="on"
        >
          {{ $t('info') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2 center">
          {{ $t('dt.project_info') }}
        </v-card-title>

        <v-card-text>
          <v-list
            dense
            rounded
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('name') }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.state.DTProject.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('start_date') }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ projectDateInit }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('end_date') }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ projectEndDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('dt.step_time') }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.state.DTProject.sim.StepTime / 3600 }} hour
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('dt.created_date') }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ projectCreatedDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('dt.base_power') }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.state.DTProject.sim.BasePower }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('grid.network') }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.state.DTProject.networks.join(', ') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('dt.number_of_cases') }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.state.DTProjectInfo.numberOfCases }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list>
              <v-list-item-title>{{ $t('dt.power_constraints') }}</v-list-item-title>
              <v-list-item>
                <v-list-item-content cols="12">
                  <v-list-item-title>{{ $t('dt.max_voltage_limit') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $store.state.DTProject.quality.Vmax }} p.u.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content cols="12">
                  <v-list-item-title>{{ $t('dt.min_voltage_limit') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $store.state.DTProject.quality.Vmin }} p.u.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content cols="12">
                  <v-list-item-title>{{ $t('dt.max_voltage_unbalance') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $store.state.DTProject.quality.NegSeqMax }} p.u.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content cols="12">
                  <!-- TODO: Rename title for zero sequence voltage -->
                  <v-list-item-title>{{ $t('dt.max_voltage_unbalance') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $store.state.DTProject.quality.ZeroSeqMax }} p.u.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content cols="12">
                  <v-list-item-title>{{ $t('dt.max_transformer_loading') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $store.state.DTProject.quality.OverLoadingRatio }} p.u.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content cols="12">
                  <v-list-item-title>{{ $t('dt.max_current_on_lines') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $store.state.DTProject.quality.OverCurrentRatio }} p.u.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DTProjectInfoButton',
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    projectDateInit () {
      const dateData = new Date()
      dateData.setTime(this.$store.state.DTProject.sim.StartTime)
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      return dateData.toLocaleDateString('en-UK', options)
    },
    projectEndDate () {
      const dateData = new Date(this.$store.state.DTProject.sim.EndDate)
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      return dateData.toLocaleDateString('en-UK', options)
    },
    projectCreatedDate () {
      const dateData = new Date(this.$store.state.DTProjectInfo.date)
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      return dateData.toLocaleDateString('en-UK', options)
    }
  }
}
</script>

<style scoped>
.v-list {
    text-align: left;
}
.v-list-item {
    padding-left: 0;
}
.button-margin{
  margin-left: 130px !important;
}
</style>
