import { plexiRequest } from '.'
import router from '@/router'

let errorCount = 0

export const refreshToken = async (prevReqConfig) => {
  const body = new window.FormData()
  const refresh = window.sessionStorage.getItem('refresh')

  errorCount++
  if (errorCount >= 5) {
    errorCount = 0
    router.push({ name: 'Login' })
    return Promise.reject(new Error('Limit of refreshToken attempts reached')) // Stop the flow
  }

  body.append('refresh', refresh)

  return plexiRequest({
    url: 'auth/api-token-refresh/',
    method: 'post',
    data: body
  }).then(async (data) => {
    errorCount = 0
    window.sessionStorage.setItem('access', data.access)
    return await plexiRequest(prevReqConfig)
  })
}

export const login = async (email, password) => {
  const user = { email, password }
  return email && password &&
    plexiRequest({ method: 'post', data: user, url: '/auth/api-token/' })
}
