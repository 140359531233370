<template>
  <v-tabs
    vertical
    slider-size="6"
    color="#f4c020"
  >
    <template v-for="(tab, index) in tabs">
      <v-tab
        v-if="tab.show"
        :key="`tab-${index}`"
      >
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <span
              v-if="!tab.isIcon"
              left
              v-bind="attrs"
              v-on="on"
            >
              {{ tab.icon }}
            </span>
            <v-icon
              v-else
              left
              v-bind="attrs"
              v-on="on"
            >
              {{ tab.icon }}
            </v-icon>
          </template>
          <span>{{ tab.label }}</span>
        </v-tooltip>
      </v-tab>
      <v-tab-item :key="`tab-item-${index}`">
        <v-card>
          <v-card-text>
            <component
              :is="tab.component"
              v-bind="tab.props"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </template>
  </v-tabs>
</template>

<script>
// From DtPanelKpiTabs
import DtPanelKpisTable from '@/components/DigitalTwin/Kpis/DtPanelKpisTable.vue'
import DtPanelKpiNewConnections from '@/components/DigitalTwin/Kpis/DtPanelKpiNewConnections.vue' // Tabs
import DTPanelKpisPQViolations from '@/components/DigitalTwin/Kpis/DTPanelKpisPQViolations.vue'
// From DtPanelKpiNewConnections
import DtPanelKpisNewConnectionsData from '@/components/DigitalTwin/Kpis/DtPanelKpiNewConnections/DtPanelKpisNewConnectionsData.vue' // Component called from various places
import DtPanelKpisNewLF from '@/components/DigitalTwin/Kpis/DtPanelKpiNewConnections/DtPanelKpisNewLF.vue' // Single Component
// From DtPanelPlotTabs
import DtPanelPlotPower from '@/components/DigitalTwin/Plots/DtPanelPlotPower.vue' // Tabs
import DtPanelPlotLoadCurve from '@/components/DigitalTwin/Plots/DtPanelPlotLoadCurve.vue' // Tabs
import DtPanelPlotVoltageLength from '@/components/DigitalTwin/Plots/DtPanelPlotVoltageLength.vue' // Single Component
// From DtPanelPlotPower
import DtPanelPlotPowerUF from '@/components/DigitalTwin/Plots/DtPanelPlotPower/DtPanelPlotPowerUF.vue' // Tabs
import DtPanelPlotStationPower from '@/components/DigitalTwin/Plots/DtPanelPlotStationPower.vue' // For Stations
// From DtPanelPlotTabs, DtPanelPlotPower, DtPanelPlotPowerUF, DtPanelPlotLoadCurve
import DtPanelPlotGeneric from '@/components/DigitalTwin/Plots/DtPanelPlotGeneric.vue'

export default {
  name: 'DtPanelTabsComponent',
  components: {
    // From DtPanelKpiTabs
    DtPanelKpisTable,
    DtPanelKpiNewConnections,
    DTPanelKpisPQViolations,
    // From DtPanelKpiNewConnections
    DtPanelKpisNewConnectionsData,
    DtPanelKpisNewLF,
    // From DtPanelPlotTabs
    DtPanelPlotPower,
    DtPanelPlotLoadCurve,
    DtPanelPlotVoltageLength,
    // From DtPanelPlotPower
    DtPanelPlotPowerUF,
    DtPanelPlotStationPower,  
    // From DtPanelPlotTabs, DtPanelPlotPower, DtPanelPlotPowerUF, DtPanelPlotLoadCurve
    DtPanelPlotGeneric
  },
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  }
}
</script>
