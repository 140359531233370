import { get } from '@/assets/queries'
// import axios from 'axios'

export async function getVoltageArray (host, project, casename) {
  const voltFetch = await get(`${host}/digital-twin/v?project=${project}&case=${casename}`, undefined, undefined, true)
  const buf = await voltFetch.arrayBuffer()
  const volt = new Float32Array(buf)
  return volt
}

export async function getDB (host, project, casename) {
  const db = await get(`${host}/digital-twin/db?project=${project}&case=${casename}`, undefined, undefined, true)
  // const db = await fetch('/520.db')
  const buf = await db.arrayBuffer()
  return new Uint8Array(buf)
}

export async function getMontecarloVio (protocol, host, project) {
  const Vio = await get(`${protocol}://${host}/digital-twin/vio?project=${project}`)
  const buf = await Vio.arrayBuffer()
  return new Float32Array(buf)
}

export async function getMontecarlo (protocol, host, project) {
  // Initialize promises to get database and montecarlo results
  const vioProm = get(`${protocol}://${host}/digital-twin/vio?project=${project}`, undefined, undefined, true)
  const dbProm = get(`${protocol}://${host}/digital-twin/db?project=${project}&case=base`, undefined, undefined, true)
  // Wait for all the data to be downloaded
  const [bufVioProm, bufDBProm] = await Promise.all([vioProm, dbProm])
    .then((responses) => {
      const VioProm = responses[0].arrayBuffer()
      const DBProm = responses[1].arrayBuffer()
      return [VioProm, DBProm]
    })
  // Wait for the array buffers to be generated
  const [bufVio, bufDB] = await Promise.all([bufVioProm, bufDBProm])
    .then((responses) => {
      const Vio = new Float32Array(responses[0])
      const DB = new Uint8Array(responses[1])
      return [Vio, DB]
    })
  // Return results
  return [bufVio, bufDB]
}
