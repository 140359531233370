<template>
  <div class="lines">
    <!--LINES-->
    <div
      id="lines"
      class="dashboard-col"
    >
      <div
        id="lc"
        class="card dark-theme"
        style="background-color:#1F1F1F!important;padding:10px;"
      >
        <v-tooltip right>
          <template #activator="{ on }">
            <v-img
              src="@/assets/images/dblines.png"
              width="50"
              alt="Lines"
              v-on="on"
            />
          </template>
          <span>{{ $t('grid_surveyor.lines') }}</span>
        </v-tooltip>
        <!-- <img src="@/assets/images/dblines.png" alt="Lines" width="50" > -->
        <div
          class="row"
          style="margin-left:70px;"
        >
          <div class="dato-numerico col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div
              id="kmline"
              class="numero dark-theme"
            >
              {{ kmline }}
            </div>
            <div class="texto dark-theme">
              {{ $t('lines.km_of_line') }}
            </div>
          </div>
          <div class="dato-numerico col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div
              id="kmct"
              class="numero dark-theme"
            >
              {{ kmct }}
            </div>
            <div class="texto dark-theme">
              {{ $t('lines.km_per_station') }}
            </div>
          </div>
          <div class="dato-numerico col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div
              id="kmconn"
              class="numero dark-theme"
            >
              {{ kmconn }}
            </div>
            <div class="texto dark-theme">
              {{ $t('lines.m_per_connection') }}
            </div>
          </div>
          <div class="dato-numerico col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div
              id="kmclient"
              class="numero dark-theme"
            >
              {{ kmclient }}
            </div>
            <div class="texto dark-theme">
              {{ $t('lines.m_per_client') }}
            </div>
          </div>
        </div>
        <div
          class="row "
          style="margin-top:10px;margin-bottom:5px;"
        >
          <div class="dato-stackedbar col-xl-12 col-12">
            <div
              id="LineType"
              class="stacked"
            />
          </div>
        </div>
        <div
          class="row "
          style="margin-top:10px;margin-bottom:5px;"
        >
          <div class="dato-histograma col-xl-12 col-12">
            <div class="texto dark-theme">
              {{ $t('lines.peak_current') }}
            </div>
            <div
              id="LinePeakCurrent"
              style="margin-bottom:52px;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getData from '@/mixins/dashboardGetData'
import sortData from '@/mixins/dashboardSortData'
import stackedBar from '@/mixins/dashboardStackedBar'
import barChart from '@/mixins/dashboardBarChart'

export default {
  name: 'Lines',
  mixins: [barChart, stackedBar, sortData, getData],
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    kmline: '0',
    kmct: '0',
    kmconn: '0',
    kmclient: '0'
  }),

  computed: {
    overhead () {
      return this.$t('lines.overhead')
    },
    underground () {
      return this.$t('lines.underground')
    },
    LineTypeData () {
      return [
        {
          label: this.overhead,
          value: this.info.statistics_line.stackAerialUndeground.aerial
        },
        {
          label: this.underground,
          value: this.info.statistics_line.stackAerialUndeground.underground
        }
      ]
    }
  },

  mounted () {
    // count statistics - FUSES
    this.kmline = (this.info.statistics_line.totalLength / 1000).toFixed(2)
    this.kmct = (this.info.statistics_line.meanKmPerCT / 1000).toFixed(2)
    this.kmconn = this.info.statistics_line.meanKmPerCGP.toFixed(2)
    this.kmclient = this.info.statistics_line.meanKmPerMeter.toFixed(2)

    // const LineTypeData = ;

    this.stackedBar('#LineType', this.LineTypeData)
    this.barChart('#LinePeakCurrent', this.info.statistics_line.histogramMaxI)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lines{
  background-color: rgb(53, 49, 49);
}
h1{
  color:#F4C020
}
.panelGris{
    background-color: rgb(53, 49, 49);
    color:white;
}
</style>
