import HostingCapacityCreator from '@/assets/HostingCapacity/HostingCapacityCreator'
import { getHCForAllStations } from '../../services/hostingCapacity'

export default {
  data: () => ({
    elementHCM: {},
    notAvailableColor: 'rgba(255, 255, 255, 0.4)',
    notAvailableText: 'Not Available',
    recalculatingText: 'Recalculating hosting capacity map...'
  }),
  computed: {
    voltageLevels () {
      const levels = []
      for (const level in this.areaGrid.Grid) {
        levels.push(level)
      }
      return levels
    },
    lowVoltage () {
      return this.voltageLevels.find(level => level < 1000 && level > 0)
    }
  },

  methods: {
    async download (station) {
      const HC = new HostingCapacityCreator(this.$HEADER_HTTP, this.$API_HOST, this.$API_PORT,
        () => {}, console.error, console.warn, this.$DEBUG)
      const response = await HC.openHostingCapacityMap(station)
      return response
    },

    /* For each connection point of the grid we get a list of the lines that are connected to that conn Point */
    async prepareLines (network, HCMResponse) {
      const linesAll = this.areaGrid.Grid[this.lowVoltage].Lines
      const connPoints = []
      const lines = linesAll.filter((linesAll) => linesAll.NETWORK === network)
      const connPointsDetailed = await HCMResponse.getGrid().connPoints
      connPointsDetailed.forEach((connPoint) => {
        const connPointsLinesDetailed = lines.filter(line => line.CGP_HC.includes(connPoint.ID))
        const connPointsLines = []
        connPointsLinesDetailed.forEach((line) => {
          connPointsLines.push(line.ID)
        })
        connPoints.push({ connPoint: connPoint.ID, lines: connPointsLines })
      })
      return connPoints
    },

    async getWorstHCM (phaseIndex, mode, worstDay, HCMResponse, connPointsWithLines) {
      // Gets for each line and connection point the worst hosting capacity value in the worst day
      let responseWorstHCM
      let worstDates
      for (let instant = 0; instant < 24; instant += 1) {
        const responseInstantHCM = await HCMResponse.getHCMFrame(instant, instant, phaseIndex, mode)
        if (!responseWorstHCM) {
          responseWorstHCM = responseInstantHCM
          const worstDate = this.getTimeForTooltip(worstDay, instant)
          worstDates = Array(responseWorstHCM.length).fill(worstDate)
        } else {
          responseInstantHCM.forEach((hcm, index) => {
            if (hcm < responseWorstHCM[index]) {
              responseWorstHCM[index] = hcm
              worstDates[index] = this.getTimeForTooltip(worstDay, instant)
            }
          })
        }
      }
      const connPoints = this.addHCMValue(responseWorstHCM, worstDates, connPointsWithLines)
      const x = this.getWorstConnPointValuePerLine(this.areaGrid.Grid[this.lowVoltage].Lines, connPoints)
      return x
    },

    async getRawInstantHCM (HCMResponse, instant, phaseIndex, mode, tooltipDate, connPointsWithLines) {
      const HCMResults = await HCMResponse.getHCMFrame(instant, instant, phaseIndex, mode)
      const dates = Array(HCMResults.length).fill(tooltipDate)
      const connPoints = this.addHCMValue(HCMResults, dates, connPointsWithLines)
      return connPoints
    },

    /* Add the value of the hosting capacity for each conn Point and its tooltip with the date in which that worst value is obtain */
    addHCMValue (results, tooltipDates, connPointsWithLines) {
      const connPointsValue = JSON.parse(JSON.stringify(connPointsWithLines))
      results.forEach((hcm, index) => {
        connPointsValue[index].value = hcm
        connPointsValue[index].date = tooltipDates[index]
      })
      return connPointsValue
    },

    async getInstantHCM (HCMResponse, instant, phaseIndex, mode, tooltipDate, connPointsWithLines) {
      const instantHCM = await this.getRawInstantHCM(HCMResponse, instant, phaseIndex, mode, tooltipDate, connPointsWithLines)
      const connPointsWorstValue = this.getWorstConnPointValuePerLine(this.areaGrid.Grid[this.lowVoltage].Lines, instantHCM)
      return connPointsWorstValue
    },

    /* To assign a HC value to a line in the map, we get the worst value of all the conn Points connected to that line */
    getWorstConnPointValuePerLine (lines, connPoints) {
      lines.forEach((line) => {
        let worstCaseId, worstCaseValue
        line.CGP_HC.forEach((connPoint) => {
          if (!worstCaseId) { worstCaseId = connPoint }
          const foundConnPoint = connPoints.filter((cp) => cp.connPoint === connPoint)[0]
          if (!worstCaseId || worstCaseValue > foundConnPoint?.value) {
            worstCaseId = connPoint
            worstCaseValue = foundConnPoint.value
          }
        })
        line.CGP_HC.forEach((connPoint) => {
          if (connPoint !== worstCaseId) {
            // remove line from connPoint ConnPoints
            const foundConnPoint = connPoints.filter(obj => obj.connPoint === connPoint)[0]
            if (foundConnPoint) {
              const foundConnPointIndex = connPoints.findIndex(obj => obj.connPoint === connPoint)
              const lineIndex = foundConnPoint.lines.indexOf(line.ID)
              if (lineIndex !== -1) {
                connPoints[foundConnPointIndex].lines.splice(lineIndex, 1)
              }
            }
          }
        })
      })
      return connPoints
    },

    async getElementHCM (network, HCMResponse, elementType, elementID, mode) {
      this.elementHCM.ID = elementID
      this.elementHCM.type = elementType
      if (elementType === 'line') {
        let worstCaseId, worstCaseValue
        // check all conn points in the line
        const networkHCResults = this.HCManageResults.find(el => el.network === network)
        networkHCResults.connPoints.forEach(connPoint => {
          if (connPoint.lines.includes(elementID)) {
            if (!worstCaseId || worstCaseValue > connPoint.value) {
              worstCaseId = connPoint.connPoint
              worstCaseValue = connPoint.value
            }
          }
        })
        elementID = worstCaseId
      }
      for (let i = 0; i < 4; i += 1) {
        this.elementHCM[i] = await HCMResponse.getHCMElement(HCMResponse.getConnPointID(elementID), i, mode)
      }
      return this.elementHCM
    },

    setManageResults (networks, manageResults, lowNetworks, stations) {
      networks.forEach((networkInfo) => {
        const index = lowNetworks.networks.findIndex((network) => stations[network.network.NAME] === networkInfo.station)
        const networkName = lowNetworks.networks[index].network.NAME
        manageResults.push({
          network: networkName,
          infoHCM: networkInfo
        })
      })
      return manageResults
    },

    async setConnPoints (network, HCMResults, worstDay, connPointsWithLines = []) {
      if (!connPointsWithLines.length) {
        connPointsWithLines = await this.prepareLines(network, HCMResults)
      }
      let connPoints = []
      if (this.hcmWorstCaseView) {
        connPoints = await this.getWorstHCM(
          this.phaseIndex,
          this.hcmMode,
          worstDay,
          HCMResults,
          connPointsWithLines
        )
      } else {
        const tooltipDate = this.getTimeForTooltip(worstDay, this.instant)
        connPoints = await this.getInstantHCM(
          HCMResults,
          this.instant,
          this.phaseIndex,
          this.hcmMode,
          tooltipDate,
          connPointsWithLines
        )
      }
      return connPoints
    },

    async setMainHCResults (HCMResults, manageResult) {
      manageResult.connPoints = await this.setConnPoints(manageResult.network, HCMResults, manageResult.infoHCM[`worst_day_${this.selectedTypeViewMap}`])
      manageResult.HCMResults = HCMResults
    },

    async downloadAll (solvedNetworks) {
      let downloadNetworks
      let downloadResults = []
      let solvedNetworksChunk
      const maxNetworksRequest = 1000
      while (solvedNetworks.length > 0) {
        solvedNetworksChunk = solvedNetworks.splice(0, maxNetworksRequest)
        downloadNetworks = solvedNetworksChunk.map((solvedNetwork) => this.download(solvedNetwork.station))
        await Promise.all(downloadNetworks)
          .catch((err) => console.warn('error: ', err))
          .then(data => {
            downloadResults = downloadResults.concat(data)
          })
      }
      return downloadResults
    },

    async setAllHCInfo (allNetworksResults, manageResults) {
      const allInfo = allNetworksResults.filter((HCMResults) => HCMResults).map((HCMResults) => {
        const index = manageResults.findIndex((res) => res.infoHCM.station === HCMResults.stationID)
        return this.setMainHCResults(HCMResults, manageResults[index])
      })
      return await Promise.all(allInfo)
        .catch((err) => console.warn('error: ', err))
    },

    getStationsList (lowNetworksIndex) {
      const stations = {}
      const networkNames = this.networks[lowNetworksIndex].networks.map(
        (network) => {
          return network.network.NAME
        }
      )
      networkNames.forEach((networkName) => {
        const station = this.networks[lowNetworksIndex].stations.find(
          (station) => station.NAME === networkName
        )
        if (station) {
          stations[station.NAME.toString()] = station.ID
        }
      })
      return stations
    },

    async getNetworksInfoHCM (lowNetworksIndex) {
      const stations = this.getStationsList(lowNetworksIndex)
      const stationIds = Object.values(stations)
      const stationsWithHCMInfo = await getHCForAllStations(stationIds)
      if (!stationsWithHCMInfo.length) {
        return []
      }

      let manageResults = this.HCManageResults
      manageResults = this.setManageResults(stationsWithHCMInfo, manageResults, this.networks[lowNetworksIndex], stations)

      const solvedNetworks = stationsWithHCMInfo.filter((network) => network.is_solved)
      const allNetworksResults = await this.downloadAll(solvedNetworks)
      await this.setAllHCInfo(allNetworksResults, manageResults)

      this.setVuexElement({
        path: 'hostingCapacity.HCManageResults',
        value: manageResults
      })
      return stationsWithHCMInfo
    },

    async updateUnsolvedHCStations () {
      const hcManageResults = this.HCManageResults

      const unsolvedStationIds = hcManageResults
        .filter((result) => !result.infoHCM.is_solved)
        ?.map((result) => {
          return result.infoHCM.station
        })
      if (unsolvedStationIds.length) {
        let stationsWithHCMInfo = await getHCForAllStations(unsolvedStationIds)
        stationsWithHCMInfo = stationsWithHCMInfo.filter((el) => el.is_solved)
        if (stationsWithHCMInfo.length) {
          stationsWithHCMInfo?.forEach((stationWithInfo) => {
            const index = hcManageResults.findIndex(
              (result) => result.infoHCM.station === stationWithInfo.station
            )
            hcManageResults[index].infoHCM = stationWithInfo
          })
          const allNetworksResults = await this.downloadAll(stationsWithHCMInfo)
          for (const HCMResults of allNetworksResults) {
            const index = hcManageResults.findIndex((res) => res.infoHCM.station === HCMResults.stationID)
            hcManageResults[index].HCMResults = HCMResults
          }
        }
      }
      return hcManageResults
    },

    // Get information about Hosting Capacity for one station
    async getHCInfoForStation (
      lowNetworksIndex,
      networkIndex,
      stationId,
      stationInfo
    ) {
      const lowNetworks = this.networks[lowNetworksIndex]

      const manageResultIndex = this.HCManageResults.findIndex(
        (result) => result.infoHCM.station === stationId
      )
      const manageResult = this.HCManageResults[manageResultIndex]
      manageResult.infoHCM = stationInfo

      const downloadPerStation = (station) => {
        return new Promise((resolve) => {
          const downloadResult = this.download(station)
          return resolve(downloadResult)
        })
      }
      const HCResult = await downloadPerStation(stationId)
      if (HCResult) {
        await this.setMainHCResults(HCResult, manageResult)
      } else {
        lowNetworks.networks[networkIndex].color = this.notAvailableColor
        lowNetworks.networks[networkIndex].tooltipText = this.recalculatingText
        lowNetworks.networks[networkIndex].notAvailableHC = true
      }

      const updatedResults = this.HCManageResults
      updatedResults[manageResultIndex] = manageResult
      this.setVuexElement({
        path: 'hostingCapacity.HCManageResults',
        value: updatedResults
      })
      if (this.HCManageResultsSolved.length) {
        this.setColorScaleLimits()
      }
      this.colorHCMapMByNetwork(
        lowNetworks.networks[networkIndex],
        networkIndex,
        lowNetworksIndex
      )

      return lowNetworks.networks
    },

    setWhiteColorForStation (lowNetworks, networkIndex) {
      if (this.HCManageResultsSolved.length) {
        this.setColorScaleLimits()
        lowNetworks.networks[networkIndex].color = this.notAvailableColor
        lowNetworks.networks[networkIndex].tooltipText = this.recalculatingText
        lowNetworks.networks[networkIndex].notAvailableHC = true
        lowNetworks.networks[networkIndex].connection_points =
          lowNetworks.networks[networkIndex].connection_points.map((cp) => {
            if (cp.tooltip) {
              cp.tooltip = this.recalculatingText
              cp.notAvailableHC = true
              cp.tooltipAbsolute = ''
              cp.colorHC = this.notAvailableColor
            }
            return cp
          })
        lowNetworks.networks[networkIndex].lines = lowNetworks.networks[
          networkIndex
        ].lines.map((line) => {
          if (line.tooltip) {
            line.tooltip = this.recalculatingText
            line.notAvailableHC = true
            line.tooltipAbsolute = ''
            line.color = this.notAvailableColor
          }
          return line
        })
      }
      return lowNetworks
    },

    getHostingCapacityLineValue (lineID, lineNetwork) {
      const networkInfo = this.HCManageResultsSolved.find((results) =>
        results.network === lineNetwork
      )
      if (!networkInfo?.connPoints) {
        return undefined
      }
      return networkInfo.connPoints.find((connPoint) => connPoint.lines.includes(lineID))
    },

    getHostingCapacityCPValue (cgpID, cgpNetwork) {
      const networkInfo = this.HCManageResultsSolved.find((results) =>
        results.network === cgpNetwork
      )
      if (!networkInfo?.connPoints) {
        return undefined
      }
      return networkInfo.connPoints.find((connPoint) => connPoint.connPoint.toString() === cgpID)
    },

    colorHCMap () {
      const units = 'kW'
      const colorScale = this.getColorScale(
        this.colorScaleColorsList.default.length,
        this.hcmColors,
        this.colorScaleLower,
        this.colorScaleMiddle,
        this.colorScaleUpper
      )
      this.HCNetworksWithLines.forEach((voltageLevel, indexLevel) => {
        if (voltageLevel.level === this.lowVoltage) {
          voltageLevel.networks.forEach((network, indexNetwork) => {
            network.connection_points.forEach((cp, indexCP) => {
              const hostingCapacityValue = this.getHostingCapacityCPValue(cp.ID, cp.NETWORK)
              if (hostingCapacityValue) {
                const colorLine = colorScale(this.fixToLimits(hostingCapacityValue.value))
                this.networks[indexLevel].networks[indexNetwork].connection_points[indexCP].colorHC = colorLine
                this.networks[indexLevel].networks[indexNetwork].connection_points[indexCP].tooltip = hostingCapacityValue.value.toString() + units
                this.networks[indexLevel].networks[indexNetwork].connection_points[indexCP].tooltipAbsolute = hostingCapacityValue.date
                this.networks[indexLevel].networks[indexNetwork].connection_points[indexCP].notAvailableHC = false
              }
            })
            network.lines.forEach((line, indexLine) => {
              const hostingCapacityValue = this.getHostingCapacityLineValue(line.ID, line.NETWORK)
              if (hostingCapacityValue) {
                const colorLine = colorScale(this.fixToLimits(hostingCapacityValue.value))
                this.networks[indexLevel].networks[indexNetwork].lines[indexLine].color = colorLine
                this.networks[indexLevel].networks[indexNetwork].lines[indexLine].tooltip = hostingCapacityValue.value.toString() + units
                this.networks[indexLevel].networks[indexNetwork].lines[indexLine].tooltipAbsolute = hostingCapacityValue.date
                this.networks[indexLevel].networks[indexNetwork].lines[indexLine].notAvailableHC = false
              }
            })
          })
        }
      })
      this.setVuexElement({ path: 'hostingCapacity.HCNetworksWithLines', value: this.networks })
    },

    colorHCMapMByNetwork (network, indexNetwork, indexLowNetwork) {
      const units = 'kW'
      const colorScale = this.getColorScale(
        this.colorScaleColorsList.default.length,
        this.hcmColors,
        this.colorScaleLower,
        this.colorScaleMiddle,
        this.colorScaleUpper
      )
      this.HCNetworksWithLines[indexLowNetwork].networks[indexNetwork].connection_points
        .forEach((connectionPoint, indexCP) => {
          const hostingCapacityValue = this.getHostingCapacityCPValue(connectionPoint.ID, connectionPoint.NETWORK)
          if (hostingCapacityValue) {
            const colorLine = colorScale(this.fixToLimits(hostingCapacityValue.value))
            network.connection_points[indexCP].colorHC = colorLine
            network.connection_points[indexCP].tooltip = hostingCapacityValue.value.toString() + units
            network.connection_points[indexCP].tooltipAbsolute = hostingCapacityValue.date
            network.connection_points[indexCP].notAvailableHC = false
          } else {
            network.connection_points[indexCP].colorHC = this.notAvailableColor
            network.connection_points[indexCP].tooltip = this.notAvailableText
            network.connection_points[indexCP].tooltipAbsolute = ''
            network.connection_points[indexCP].notAvailableHC = true
          }
        })

      this.HCNetworksWithLines[indexLowNetwork].networks[indexNetwork].lines
        .forEach((line, indexLine) => {
          const hostingCapacityValue = this.getHostingCapacityLineValue(line.ID, line.NETWORK)
          if (hostingCapacityValue) {
            const colorLine = colorScale(this.fixToLimits(hostingCapacityValue.value))
            network.lines[indexLine].color = colorLine
            network.lines[indexLine].tooltip = hostingCapacityValue.value.toString() + units
            network.lines[indexLine].tooltipAbsolute = hostingCapacityValue.date
            network.lines[indexLine].notAvailableHC = false
          } else {
            network.lines[indexLine].color = this.notAvailableColor
            network.lines[indexLine].tooltip = this.notAvailableText
            network.lines[indexLine].tooltipAbsolute = ''
            network.lines[indexLine].notAvailableHC = true
          }
        })

      this.networks[indexLowNetwork].networks.splice(indexNetwork, 1, network)
    }
  }
}
