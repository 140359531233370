<template>
  <v-app>
    <h3>{{ $t('top_down.load_analysis') }} </h3>
    <div class="ma-4">
      <TDPeriodSelector
        @periodChanged="handlePeriodChange"
      />
      <TDLoadAnalysisTable
        :startDate="selectedStartDate"
        :endDate="selectedEndDate"
      />
    </div>
  </v-app>
</template>

<script>
import TDPeriodSelector from '@/components/TopDown/TDPeriodSelector.vue'
import TDLoadAnalysisTable from '@/components/TopDown/TDLoadAnalysisTable.vue'

export default {
  name: 'LoadAnalysis',
  components: {
    TDPeriodSelector,
    TDLoadAnalysisTable
  },
  data() {
    return {
      selectedStartDate: this.setInitStartDate(),
      selectedEndDate: this.setInitEndDate()
    }
  },
  
  metaInfo: {
    title: 'LoadAnalysis'
  },

  methods: {
    setInitEndDate() {
      const date = new Date()
      return date.toISOString().split('T')[0]
    },
    setInitStartDate(){
      const date = new Date()
      date.setDate(date.getDate() - 7)
      date.setMilliseconds(0)
      return date.toISOString().split('T')[0]
    },
    handlePeriodChange(startDate, endDate) {
      this.selectedStartDate = startDate
      this.selectedEndDate = endDate
    }
  }
}

</script>

<style scoped>
h3{
  color: #ddd;
  padding:15px;
}
</style>
