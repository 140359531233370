const d3 = require('d3');

export default {
  methods: {
    stackedBar(bind, data, config) {
      const extendedConfig = {
        f: d3.format('.1f'),
        margin: {
          top: 10, right: 35, bottom: 10, left: 5,
        },
        width: 200,
        height: 60,
        barHeight: 25,
        colors: ['#c29819', '#755c0f', '#d217e6', '#8b0099', '#00b88a', '#078868'],
        ...config,
      };
      const {
        margin, width, height, barHeight, colors,
      } = extendedConfig;
      const w = width - margin.left - margin.right - 20;
      const h = height - margin.top - margin.bottom;
      const halfBarHeight = barHeight / 2;

      const total = d3.sum(data, (d) => d.value);
      const endData = this.getData(this.sortData(data), total);

      // set up scales for horizontal placement
      const xScale = d3.scaleLinear()
        .domain([0, total])
        .range([0, w]);

      // create svg in passed in div
      const selection = d3.select(bind)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // stack rect for each data value
      const rects = selection.selectAll('rect')
        .data(endData)
        .enter().append('rect')
        .attr('class', 'rect-stacked')
        .attr('id', (d) => `${bind.substring(1)}_${d.label}`)
        .attr('x', (d) => xScale(d.cumulative))
        .attr('y', h / 2 - halfBarHeight)
        .attr('height', barHeight)
        .attr('width', (d) => {
          if (d.value > 0) { return xScale(d.value); }
          return xScale('5');
        })
        .style('fill', (d, i) => {
          if (d.value > 0) { return colors[i]; } return '#00000000';
        });

      // add values on bar
      selection.selectAll('.text-value')
        .data(endData)
        .enter().append('text')
        .attr('class', 'text-value')
        .attr('text-anchor', (d) => {
          if (d.value > 0) { return 'middle'; }
          return 'left';
        })
        .attr('x', (d) => {
          if (d.value > 0) { return xScale(d.cumulative) + (xScale(d.value) / 2); }
          return xScale(d.cumulative + 1);
        })
        .attr('y', (h / 2) + 5)
        .style('fill', '#fff')
        .style('font-size', (d) => {
          if (d.value > 0) { return 18; }
          return 15;
        })
        .text((d) => d.value);

      // add hover event
      rects.on('mouseover', function rectMouseover() {
        d3.select(this)
          .attr('stroke', '#fff')
          .attr('stroke-width', 2);
      });
      rects.on('mouseout', function rectMouseout() {
        d3.select(this)
          .attr('stroke', 'none');
      });
      // add click event
      // rects.on('click', function rectClick() {
      //   console.log(this.id);
      //   const params = this.id.split('_');
      //   console.log(params);
      //   window.location.replace(`/grid?filters=${params}`);
      // });
      // add some labels for percentages
      let lastX = -100;
      // add the labels
      selection.selectAll('.text-label')
        .data(endData)
        .enter().append('text')
        .attr('class', 'text-label')
        .attr('text-anchor', 'left')
        .attr('x', (d) => xScale(d.cumulative) - 5)
        .attr('y', (d) => {
          let y = (h / 2) + (halfBarHeight * 1.11) + 10;
          if ((xScale(d.cumulative) - lastX) < 5 * d.label.length) {
            y += 15;
            // let svgHeight = d3.select(bind).select('svg').attr('height');
            d3.select(bind).select('svg')
              .attr('height', height + 15);
          }
          // y = ((xScale(d.cumulative)-lastX)<5*d.label.length)?y+10:y;
          lastX = xScale(d.cumulative);
          return y;
        })
        .style('fill', '#fff')
        .style('font-family', 'Faktum Regular')
        .style('font-size', 13)
        .text((d) => d.label);
    },
  },
};
