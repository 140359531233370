<template>
  <VApp>
    <div v-if="showForm">
      <ASDialog
        :source="source"
        :loading="loadingArea"
        @getArea="getZone"
        @getElement="getElement"
      />
    </div>

    <Splitpanes v-else>
      <Pane
        min-size="65"
        :size="isDetailsOpen ? 65 : 100"
      >
        <MSInspector
          :zone="zone"
          :element="element"
        />
      </Pane>
      <Pane
        :size="isDetailsOpen ? 35 : 0"
        max-size="35"
      >
        <MSDetails />
      </Pane>
    </Splitpanes>
    <MSMap />
  </VApp>
</template>

<script>
import { mapState } from 'vuex'
import { Splitpanes, Pane } from 'splitpanes'
import MSInspector from '@/components/Measurements/MSInspector.vue'
import MSMap from '@/components/Measurements/MSMap.vue'
import MSDetails from '@/components/Measurements/MSDetails.vue'
import ASDialog from '@/components/AreaSelector/ASDialog.vue'
import { getMetersCodeMap } from '@/services/measurements'
import { getAreaMap } from '@/services/areaSelector'

export default {
  name: 'MeasurementsView',

  components: {
    ASDialog,
    Splitpanes,
    Pane,
    MSInspector,
    MSMap,
    MSDetails
  },

  metaInfo: {
    title: 'Measurements'
  },

  data () {
    return {
      source: null,
      loadingArea: false,
      zone: null,
      element: null
    }
  },

  computed: {
    ...mapState({
      showForm: state => state.showSetAreaForm,
      plotData: ({ measurements }) => measurements.plotData?.length,
      anyElementSelected: ({ measurements }) => measurements.elementDetails?.origin,
      currentTab: ({ measurements }) => measurements.currentTab?.title
    }),

    selectorSize () {
      const { innerHeight } = window
      return innerHeight < 700 ? innerHeight * 0.15 : 48
    },

    isDetailsOpen () {
      return this.plotData || this.anyElementSelected
    }
  },
  async mounted () {
    this.loadingArea = false
    this.source = await getAreaMap()
    if (this.$sessionStorage.zoneId && !this.$sessionStorage.gridElement) {
      this.getZone()
    } else if (this.$sessionStorage.gridElement && !this.$sessionStorage.zoneId) {
      this.getElement()
    } else {
      this.$store.dispatch('setElement', {
        path: 'showSetAreaForm',
        value: true
      })
    }
    this.loadingArea = false
    getMetersCodeMap().then((response) => {
      this.$store.dispatch('setElement', {
        path: 'measurements.metersCodeMap',
        value: response
      })
    })
  },
  methods: {
    getZone () {
      this.loadingArea = true
      this.zone = parseInt(this.$sessionStorage.zoneId, 10)
      if (this.element) {
        this.element = null
      }
      this.$store.dispatch('setElement', {
        path: 'showSetAreaForm',
        value: false
      })

      this.loadingArea = false
    },
    getElement () {
      this.loadingArea = true
      this.element = JSON.parse(this.$sessionStorage.gridElement)
      if (this.zone) {
        this.zone = null
      }
      this.$store.dispatch('setElement', {
        path: 'showSetAreaForm',
        value: false
      })
      this.loadingArea = false
    }
  }
}
</script>

<style scoped>
.splitpanes > .splitpanes__pane {
  background-color: #272727;
}

.leaflet-top {
  z-index: 500;
}
.v-overlay__content {
  z-index: 502;
}
</style>
