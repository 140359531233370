import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      elementSelected: ({ measurements }) => measurements.elementDetails.info.code
    })
  },

  methods: {
    setOpacity (elementID) {
      const [noOpacity, withOpacity] = [1, 0.7]
      return this.elementSelected === elementID ? noOpacity : withOpacity
    }
  }
}
