<template>
  <v-app
    id="inspire"
    :style="`height:${tableHeight}`"
  >
    <v-container
      id="newConnectionsDataContainer"
    >
      <v-data-table
        v-if="list"
        ref="table"
        dense
        :items="list"
        :headers="headings"
        :height="tableHeight"
        :search="search"
        class="faktum kpi-table"
        fixed-header
      >
        <template #top>
          <v-toolbar
            class="bg-dark"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-toolbar>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>

<script>

export default {
  name: 'DtPanelKpisNewConnectionsData',
  props: {
    list: {
      type: Array,
      default: null
    },
    tableHeight: {
      type: String,
      default: '274px'
    },
    name: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      search: ''
    }
  },

  computed: {
    headings () {
      const header = this.setHeaders()
      return header
    },

    HPHeaders () {
      return [
        {
          text: `${this.$t('name')}`,
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: `${this.$t('dt.n_hps')}`, value: 'number' },
        { text: `${this.$t('grid.connection_point')}`, value: 'cgp' },
        { text: `${this.$t('phase')}`, value: 'ph' },
        { text: `${this.$t('dt.power_factor')}`, value: 'pf' },
        { text: `${this.$t('dt.pnom_hp')}`, value: 'pnom' },
        { text: `${this.$t('dt.hp_cop')}`, value: 'cop' },
        { text: '', value: 'data-table-expand' }
      ]
    },
    PVHeaders () {
      return [
        {
          text: `${this.$t('name')}`,
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: `${this.$t('dt.n_pv')}`, value: 'number' },
        { text: `${this.$t('grid.connection_point')}`, value: 'cgp' },
        { text: `${this.$t('phase')}`, value: 'ph' },
        { text: `${this.$t('dt.peak_power')}`, value: 'pp' },
        { text: '', value: 'data-table-expand' }
      ]
    },
    EVHeaders () {
      return [
        {
          text: `${this.$t('name')}`,
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: `${this.$t('new_connections.n_evs')}`, value: 'number' },
        { text: `${this.$t('grid.connection_point')}`, value: 'cgp' },
        { text: `${this.$t('dt.ev_cp')}`, value: 'rcp' },
        { text: `${this.$t('dt.ev_ec')}`, value: 'c' },
        { text: `${this.$t('phase')}`, value: 'ph' },
        { text: `${this.$t('dt.ev_mean')}`, value: 'kmd' },
        { text: `${this.$t('dt.daily_km')}`, value: 'stdkmd' },
        { text: `${this.$t('dt.ev_cpf')}`, value: 'pf' },
        { text: `${this.$t('dt.ev_t0')}`, value: 'start_min' },
        { text: `${this.$t('dt.ev_t1')}`, value: 'start_max' },
        { text: '', value: 'data-table-expand' }
      ]
    }
  },

  methods: {
    setHeaders: function () {
      if (this.name === 'Electric Vehicles') { return this.EVHeaders }
      if (this.name === 'PV Systems') return this.PVHeaders
      return this.HPHeaders
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#newConnectionsDataContainer {
  display: flex;
  flex: 1;
  padding: 0;
}

#newConnectionsDataContainer > div {
  flex: 1;
}

.color-primary {
  color: #f4c020 !important;
}

.faktum {
  font-family:'Faktum Light', sans-serif;
}

.kpi-table, .bg-dark{
  background-color:#1e1e1e !important
}

</style>
