<template>
  <div
    id="searchBox"
    class="map-searchbox"
  >
    <v-autocomplete
      v-if="type=='flexContainer'"
      v-model="valueFlex"
      :items="stations"
      item-text="name"
      item-value="id"
      label="Search"
      :append-outer-icon="searchIcon"
      dark
      solo
      dense
      @change="selectElement"
    />
    <v-autocomplete
      v-if="type=='supplyPoint'"
      v-model="valueSupply"
      :items="stations"
      item-text="name"
      item-value="id"
      label="Search"
      :append-outer-icon="searchIcon"
      dark
      solo
      dense
      @change="selectSupplyPoint"
    />
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'TIAMapSearchBox',

  components: {
  },
  props: {
    stations: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    searchIcon: mdiMagnify,
    valueFlex: null,
    valueSupply: null
  }),
  mounted () {
    this.value = null
  },
  methods: {
    selectElement () {
      const name = this.stations.find((fc) => fc.id === this.valueFlex)  
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.nameStationSelected', value: name.name })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.stationSelected', value: this.valueFlex })
    },

    selectSupplyPoint () {
      const name = this.stations.find((sp) => sp.id === this.valueSupply)  
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.supplyPointSelectedId', value: this.valueSupply })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.supplyPointSelectedName', value: name.name })
    },

  }
}
</script>

<style scoped>
.map-searchbox {
  z-index: 505;
  position: relative;

  float: right;

  height: 30px;
  line-height: 30px;
}
</style>
