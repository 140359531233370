<template>
  <VCard
    class="pa-4 overflow-auto"
    height="100%"
  >
    <CMTabs :tabs="tabs">
      <VTabItem class="py-4 text-left">
        <MSInfoDetails :element="element" />
      </VTabItem>
      <VTabItem>
        <MSVariablesDetails />
      </VTabItem>
      <VTabItem>
        <MSPlotDetails />
      </VTabItem>
    </CMTabs>
  </VCard>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MSDetails',

  components: {
    CMTabs: () => import('@/components/Common/CMTabs.vue'),
    MSInfoDetails: () => import('@/components/Measurements/MSInfoDetails.vue'),
    MSVariablesDetails: () => import('@/components/Measurements/MSVariablesDetails.vue'),
    MSPlotDetails: () => import('@/components/Measurements/MSPlotDetails.vue')
  },

  computed: {
    ...mapState({
      element: ({ measurements }) => measurements.elementDetails,
      activeTabs: ({ measurements }) => measurements.currentSelector.tabs,
      hasInventory: ({ measurements }) => measurements.inventory.length > 0
    }),

    tabs () {
      const hasAssets = this.activeTabs.includes('Assets')
      const hasMeasurements = this.activeTabs.includes('Measurements')

      return [
        {
          title: this.$t('info'),
          active: hasAssets,
          disabled: !this.element?.title
        },
        {
          title: this.$t('grid_monitor.variables'),
          active: hasMeasurements && this.hasInventory,
          disabled: !hasMeasurements || !this.hasInventory
        },
        {
          title: this.$t('plots'),
          active: hasMeasurements && this.hasInventory,
          disabled: !hasMeasurements || !this.hasInventory
        }
      ]
    }
  }
}
</script>
