/* eslint-disable consistent-return */
export default {
  methods: {
    removeDuplicates (arr, key) {
      return Array.from(new Set(arr.map((a) => a[key]))).map((y) => arr.find((a) => a[key] === y))
    },

    findDuplicates (obj, map = {}, duplicates = []) {
      Object
        .keys(obj)
        .forEach((key) => {
          if (typeof obj[key] === 'object') {
            return this.findDuplicates(obj[key], map, duplicates)
          }
          map[obj[key]] = (map[obj[key]] || 0) + 1
          map[obj[key]] === 2 && duplicates.push(obj[key])
        })
      return duplicates
    }
  }
}
