import mapElementDisableMixin from '@/mixins/map/mapElementDisableMixin'

export default {
  mixins: [mapElementDisableMixin],
  methods: {
    setElementColor (id, color) {
      const isElementDisabled = this.disabledElement(id)
      if (isElementDisabled) {
        return '#c4c4c4'
      } else return color
    },
    setFusesColor (id, color) {
      const isElementDisabled = this.disabledElement(id)
      if (isElementDisabled && color === '#d40000') {
        return this.blackColor
      } else return color
    }
  }
}
