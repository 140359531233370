<template>
  <div
    class="map-measurement-selector"
    :class="{ mstophight: sldView || topHigh, mstoplow: !topHigh }"
  >
    <div
      id="select_magnitude"
      class="selection-tool"
    >
      <div
        class="hexagono-xs onmap selected selected-magnitude"
        @click="showMagnitudeVertical()"
      >
        <span class="hexagono-xs-content onmap selected selected-measurement-content">
          {{ selectedItem.title }}
        </span>
      </div>
    </div>
    <div
      class="selection-tool-vertical dark-theme"
      style="display: none"
    >
      <label
        v-for="phase in phases"
        :key="phase.value"
        class="hexagono-xs"
      >
        <input
          id="unbalance_magnitude"
          type="radio"
          name="magnitude"
          :value="phase.value"
        >
        <span
          id="unbalance_magnitude_content"
          class="hexagono-xs-content"
          @click="measurementSelected(phase)"
        >
          <i
            class="fas fa-caret-left"
            style="margin-left: -5px"
          />
          <span style="margin-left: 5px">{{ phase.title }}</span>
        </span>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HCMPhaseSelector',
  props: {
    sldView: {
      type: Boolean,
      default: false
    },
    topHigh: {
      type: Boolean,
      default: false
    },
    phaseIndex: {
      type: Number,
      default: 3
    }
  },
  data () {
    return {
      selectedItem: null,
      phases: [
        { title: 'phA', value: 0 },
        { title: 'phB', value: 1 },
        { title: 'phC', value: 2 },
        { title: '3ph', value: 3 }
      ]
    }
  },
  beforeMount () {
    this.selectedItem = this.phases.find((ph) => ph.value === this.phaseIndex)
    this.setMeasurement(this.selectedItem)
  },

  methods: {
    showMagnitudeVertical () {
      const verticalBar = document.getElementsByClassName('selection-tool-vertical')[0]
      if (verticalBar.style.display === '') {
        verticalBar.style.display = 'none'
      } else {
        verticalBar.style.display = ''
      }
    },
    setMeasurement (selected) {
      const measurement = {
        measurement: 'default',
        submeasurement: selected.value === 3 ? 'avg' : selected.title
      }
      this.$store.dispatch('setElement', {
        path: 'measurement',
        value: measurement
      })
    },

    measurementSelected (selected) {
      this.setMeasurement(selected)

      const verticalBar = document.getElementsByClassName('selection-tool-vertical')[0]
      verticalBar.style.display = 'none'
      this.selectedItem = selected
      this.$emit('changePhaseIndex', selected.value)
    }
  }
}
</script>

<style scoped>
.map-measurement-selector {
  color: #fff;
  z-index: 500;
  position: relative;
  height: 30px;
  line-height: 30px;
  right: -15px;
}
.mstophight {
  top: calc(30px - 100%);
}
.mstoplow {
  top: calc(80px - 100%);
}
.magnitude-submenu {
  left: -7rem;
  float: right;
  top: 20px;
  z-index: 460;
}
.selection-tool {
  z-index: 500;
  position: absolute;
  top: 5px;
  right: 3%;
}
.selection-tool-vertical {
  padding-top: 12px;
  width: 41px;
  z-index: 499;
  position: absolute;
  top: 44px;
  right: 3%;
}
.selection-tool-vertical.dark-theme {
  background: #f4c020;
}
.selection-tool-vertical.left {
  right: 46px;
}
</style>
