export default {
  methods: {
    roundToTwo (num) {
      const nume2 = parseFloat(`${num}e+2`)
      return +(`${Math.round(nume2)}e-2`)
    },

    roundToThree (num) {
      const nume3 = parseFloat(`${num}e+3`)
      return +(`${Math.round(nume3)}e-3`)
    }
  }
}
