<template>
  <v-radio-group
    v-model="periodSelected"
    @change="changed"
    row
  >
    <v-radio
      v-for="period in periods"
      :key="period.value"
      :label="period.label"
      :value="period.value"
    >
    </v-radio>
  </v-radio-group>
</template>

<script>
export default {
  name: 'TDPeriodSelector',
  data () {
    return {
      periodSelected: 'week',
      periods: []
    }
  },
  computed: {  
    endDate () {
      // Is the current date
      const date = new Date()
      return date.toISOString().split('T')[0]
    },
    setStartDate() {
      return {
        week: this.weekAgo,
        month: this.monthAgo,
        year: this.yearAgo,
        ytd: this.yearToDate
      }
    },
    startDate () {
      return this.setStartDate[this.periodSelected]()
    }
  },
  methods: {
    createDate(date, days, months, years) {
      date.setDate(date.getDate() - days)
      date.setMonth(date.getMonth() - months)
      date.setFullYear(date.getFullYear() - years)
      date.setMilliseconds(0)
      return date.toISOString()  
    },
    weekAgo (){
      const date = new Date
      return this.createDate(date, 7, 0, 0)
    },
    monthAgo () {
      const date = new Date
      return this.createDate(date, 0, 1, 0)
    },
    yearAgo () {
      const date = new Date
      return this.createDate(date, 0, 0, 1)
    },
    yearToDate(){
      const date = new Date
      const currentYear = date.getFullYear()
      date.setDate(1)
      date.setMonth(0)
      date.setFullYear(currentYear)
      date.setHours(0, 0, 0)
      date.setMilliseconds(0)
      return date.toISOString()
    },
    changed(){
      this.$emit('periodChanged', this.startDate.split('T')[0], this.endDate)
    }
  },

  async mounted () {
    this.periods = [
      {
        label: this.$t('top_down.last_week'),
        value: 'week'
      },
      {
        label: this.$t('top_down.last_month'),
        value: 'month'
      },
      {
        label: this.$t('top_down.last_year'),
        value: 'year'
      },
      {
        label: this.$t('top_down.ytd'),
        value: 'ytd'
      }
    ]
    this.$emit('periodChanged', this.startDate.split('T')[0], this.endDate)
  }
}

</script>
