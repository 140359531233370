export default {
  methods: {
    setHeightPercentage (event) {
      this.heightPercentage = event[0].size
    },

    setCardHeight () {
      const { $el: cardElement } = this.$refs.card

      if (cardElement) {
        this.cardHeight = cardElement.offsetHeight > 390
          ? cardElement.offsetHeight
          : 390

        return cardElement
      }

      this.cardHeight = 390
    }
  }
}
