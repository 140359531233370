export default {
  methods: {
    getLocalDateFormated (offsetInDays, dateMachine) {
      let dateObject
      if (dateMachine !== null) {
        dateObject = new Date(dateMachine)
      } else {
        dateObject = new Date()
      }
      dateObject.setDate(dateObject.getDate() - offsetInDays)
      const year = dateObject.getFullYear()
      const month = String(dateObject.getMonth() + 1).padStart(2, '0')
      const day = String(dateObject.getDate()).padStart(2, '0')
      const isoString = `${year}-${month}-${day}`
      return isoString
    }
  }
}
