import { plexiRequest } from '.'

export const getDDBB = () =>
  plexiRequest({ url: '/measurements/db' })
    .then(data => data.map(DB => ({
      text: DB.dbname,
      value: DB.id
    })))

export const getDBFields = async (DB) =>
  DB && plexiRequest({ url: `/importer/event/fields?db=${DB}` })

export const postMeasurementFile = (file, config) => {
  const body = new FormData()
  body.append('file', file.data, file.title)
  body.append('timestamp', config.timestamp)
  body.append('reference', config.reference)
  body.append('measurement', config.measurement)
  body.append('codes', config.codes)

  return file && config &&
      plexiRequest({
        url: 'importer/measurement',
        method: 'post',
        data: body
      })
}

export const postEventFile = async (file, config) => {
  const body = new FormData()
  body.append('file', file.data, file.title)
  body.append('timestamp', config.timestamp)
  body.append('reference', config.reference)
  body.append('dbname', config.dbname)
  body.append('field_col', config.field_col)
  body.append('event_table', config.event_table)

  return file && config &&
      plexiRequest({
        url: 'importer/event',
        method: 'post',
        data: body
      })
}

export const postTopologyFile = async (file) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/zip')

  return file &&
      plexiRequest({
        url: `importer/csv/${file.title}`,
        method: 'post',
        data: file.data
      })
}
