export default {
  data: () => ({
    VUEX_ACTIONS: {
      SET_ELEMENT: 'setElement'
    }
  }),

  methods: {
    setVuexElement ({ path, value, root }) {
      this.$store.dispatch(
        this.VUEX_ACTIONS.SET_ELEMENT,
        { path, value, root }
      )
    }
  }
}
