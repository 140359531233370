export default {
  data: () => ({
    isLargeScreen: false,
    isExtraLargeScreen: false
  }),

  mounted () {
    this.isLargeScreen = window.innerHeight > 700 && window.innerHeight < 900
    this.isExtraLargeScreen = window.innerHeight > 900
  }
}
