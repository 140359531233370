<template>
  <MarkersList
    :markers="markers"
    :handle-contextmenu="handleContextmenu"
  />
</template>

<script>

import MarkersList from '@/components/Map/MarkersList.vue'
import { mapState } from 'vuex'
import mapContextMenuMixin from '@/mixins/map/mapContextMenuMixin'

export default {
  name: 'MarkerLayer',

  components: {
    MarkersList
  },

  mixins: [mapContextMenuMixin],
  computed: {
    ...mapState({
      markers: ({ markers }) => markers,
      contextMenuType: ({ contextMenuElement }) => contextMenuElement.type,
      contextMenuElement: ({ contextMenuElement }) => contextMenuElement.info,
      creatingCase: ({ creatingCase }) => creatingCase
    })
  },
  methods: {
    handleContextmenu (e, marker) {
      if (this.creatingCase) {
        this.resetContextMenu()

        const connectionPointPayload = {
          path: 'connectionPoint',
          value: marker.element
        }

        this.$store.dispatch('setElement', connectionPointPayload)

        const contextMenuPayload = {
          x: e.containerPoint.x + 75,
          y: e.containerPoint.y + 75,
          toggle: 'contextMenu'
        }

        const contextMenuType = marker.typeElement

        const elementPayload = {
          path: 'contextMenuElement',
          value: { type: contextMenuType, info: marker.element }
        }

        this.$store.dispatch('setClick', { lat: e.latlng.lat, lng: e.latlng.lng, idElement: marker.id })
        this.$store.dispatch('setNewPosition', contextMenuPayload)
        this.$store.dispatch('setElement', elementPayload)
      }
    }
  }

}
</script>
