// ---------------------------------------------------------------------------
const PFR_VER_STR = 'PFR 3.2.0'
const PFR_VER_DATE		= 20240304
const PFR_VER_TIME		= 113800
// ---------------------------------------------------------------------------
const PFR_INF_STR =
	'PFR (Power Flow Result) Review BM\n' +
	'Author : Bassam Mohamed (Main functions, MCS, Transformer model 3)\n' +
	'Author : Mohammed			 (KPI, HCM)\n' +
	'Author : Tarikua Taye	 (Signal, test, MV and per unit system)\n' +
	'Date   : 28/09/2023 10:00:00\n' +
	'JS class to generate power flow results from the per unit voltage buffer\n' +
	'Transformer function needs "RefSide" to define the required terminal'
// ---------------------------------------------------------------------------
// "use strict";
// ---------------------------------------------------------------------------
const PFR_FUN_I_REAL		= (0 << 16)
const PFR_FUN_I_IMG			= (1 << 16)
const PFR_FUN_I_MAG			= (2 << 16)
const PFR_FUN_P					= (3 << 16)
const PFR_FUN_Q					= (4 << 16)
const PFR_FUN_S					= (5 << 16)
// ---------------------------------------------------------------------------
const PFR_PHASE_COUNT			= 4
const PFR_NUM_PER_BUS			= 8
const PFR_NUM_PER_PHASE		= 2
// ---------------------------------------------------------------------------
const PFR_VIO_HOUR_COUNT	= 24
const PFR_VIO_PER_BUS			= 8
const PFR_VIO_PER_LINE		= 4
const PFR_VIO_BUS_MASK		= 255
const PFR_VIO_LINE_MASK		= 15
// ---------------------------------------------------------------------------
const PFR_PU_BIT_INDEX		= 8
const PFR_PU_MASK					= 256
const PFR_BIG_NUM					= 1e30
const PFR_POWER_TOL				= 0.001
const PFR_CURRENT_TOL			= 1e-6
const PFR_3600						= 3600.0
// ---------------------------------------------------------------------------
const PFR_0			= 0
const PFR_1			= 1
const PFR_2			= 2
const PFR_3			= 3
const PFR_4			= 4
const PFR_5			= 5
const PFR_6			= 6
const PFR_7			= 7
const PFR_8			= 8
const PFR_9			= 9
const PFR_10		= 10
const PFR_11		= 11
const PFR_12		= 12
const PFR_13		= 13
const PFR_14		= 14
const PFR_15		= 15
const PFR_16		= 16
const PFR_100		= 100.0
const PFR_1000	= 1000.0
const PFR_TOL		= 1e-8
const PFR_POS_TOL		= 0.050 // A, or V
const PFR_ZERO_TOL	= 1e-20
// ---------------------------------------------------------------------------
const PFR_UNDER_VOLTAGE_A			= 1 /* Under Voltage A				*/
const PFR_UNDER_VOLTAGE_B			=		 2 /* Under Voltage B				*/
const PFR_UNDER_VOLTAGE_C			=		 4 /* Under Voltage C				*/
const PFR_OVER_VOLTAGE_A			=		 8 /* Over Voltage	A					*/
const PFR_OVER_VOLTAGE_B			=		16 /* Over Voltage	B					*/
const PFR_OVER_VOLTAGE_C			=		32 /* Over Voltage	C					*/
const PFR_NEGATIVE_SEQUENCE		=		64 /* Negative Sequence			*/
const PFR_ZERO_SEQUENCE				=	 128 /* Zero Sequence					*/
const PFR_OVER_CURRENT_A			=	 256 /* Over Current A					*/
const PFR_OVER_CURRENT_B			=	 512 /* Over Current B					*/
const PFR_OVER_CURRENT_C			= 1024 /* Over Current C					*/
const PFR_OVER_CURRENT_N			= 2048 /* Over Current N					*/
const PFR_OVER_POWER					= 4096 /* Over power							*/
// ---------------------------------------------------------------------------
/* BM : used for GetTotalPower() */
const PFR_CGP_ACTIVE_POS 			= 0
const PFR_CGP_ACTIVE_NEG			= 1
const PFR_CGP_REACTIVE_POS		= 2
const PFR_CGP_REACTIVE_NEG		= 3
const PFR_CT_ACTIVE_POS				= 4
const PFR_CT_ACTIVE_NEG				= 5
const PFR_CT_REACTIVE_POS			= 6
const PFR_CT_REACTIVE_NEG			= 7
// ---------------------------------------------------------------------------
/* ======================================================================== */
/*
BM : Transformer model based on the OpenDss model on the following paper
Thanks for Mohammed Mahairi :
	for testing the original implementation of the paper in matlab
*/
// ---------------------------------------------------------------------------
const PFR_1_DIV_SQRT_3				= 0.577350269189626
const PFR_WINDING_COUNT_MAX		= 3
// ---------------------------------------------------------------------------
const PFR_WINDING_Y_SIZE_MAX =
		(PFR_WINDING_COUNT_MAX - 1) * (PFR_WINDING_COUNT_MAX - 1)
// ---------------------------------------------------------------------------
const PFR_START_INDEX				= 0
// ---------------------------------------------------------------------------
const PFR_TRANS_OFF			= 0
const PFR_TRANS_D11Yn		= 1
const PFR_TRANS_DD			= 2
const PFR_TRANS_D1Yn		= 3
const PFR_TRANS_YY			= 4
// ---------------------------------------------------------------------------
const PFR_D11				= 0
const PFR_YN				= 1
const PFR_D1				= 2
// ---------------------------------------------------------------------------
function PF_Select (check, x1, x2) {
  let y
  if (check != 0) {
    y = x1
  } else {
    y = x2
  }
  return (y)
}
// ---------------------------------------------------------------------------
function PF_Limit (x, x_min, x_max) {
  let y
  if (x < x_min) {
    y = x_min
  } else if (x > x_max) {
    y = x_max
  } else {
    y = x
  }
  return (y)
}
// ---------------------------------------------------------------------------
function PFR_GetPar (db, TabName, ColName) {
  let sql, res, val
  sql = 'SELECT '
  sql	= sql.concat(ColName)
  sql	= sql.concat(' FROM ')
  sql	= sql.concat(TabName)
  sql	= sql.concat(' LIMIT 1')
  try {
    res = db.exec(sql)
    val = res[PFR_0].values[PFR_0][PFR_0]
  } catch (err) {
    val = 0
  }
  return (val)
}
// ---------------------------------------------------------------------------
function PFR_table_exist (db, table_name) {
  let is_exist
  let stmt, sql
  sql = 'SELECT name FROM sqlite_master WHERE '
  sql = sql.concat("type='table' AND name='")
  sql = sql.concat(table_name)
  sql = sql.concat("'")
  stmt = db.prepare(sql)

  if (stmt.step() != 0) {
    is_exist = 1
  } else {
    is_exist = 0
  }
  stmt.free()
  return (is_exist)
}
// ---------------------------------------------------------------------------
function PFR_column_name (db, table_name) {
  let row, row_count
  let res, stmt, sql, column_name
  if (PFR_table_exist(db, table_name) == 0) {
    return ([])
  }
  row_count = 32
  sql = "SELECT name FROM pragma_table_info('"
  sql = sql.concat(table_name)
  sql = sql.concat("')")
  stmt = db.prepare(sql)
  column_name = []

  for (row = 0; (row < row_count) && (stmt.step() != 0); row++) {
    res = stmt.get()
    column_name.push(res[PFR_0])
  }
  stmt.free()
  return (column_name)
}
// ---------------------------------------------------------------------------
function PFR_GetCount (db, TabName) {
  let sql, res, row_count
  if (PFR_table_exist(db, TabName) != 0) {
    sql = 'SELECT COUNT(*) FROM '
    sql	= sql.concat(TabName)
    res = db.exec(sql)
    row_count = res[PFR_0].values[PFR_0][PFR_0]
  } else {
    row_count = 0
  }
  return (row_count)
}
// ---------------------------------------------------------------------------
function PFR_Min (a, b, c) {
  let y
  if (a < b) {
    y = a
  } else {
    y = b
  }
  if (c < y) {
    y = c
  } else {
    // NO CODE
  }
  return (y)
}
// ---------------------------------------------------------------------------
function PFR_Max (a, b, c) {
  let y
  if (a > b) {
    y = a
  } else {
    y = b
  }
  if (c > y) {
    y = c
  } else {
    // NO CODE
  }
  return (y)
}
// ---------------------------------------------------------------------------
function PFR_Avg (a, b, c) {
  const PhaseCount = 3
  return ((a + b + c) / PhaseCount)
}
// ---------------------------------------------------------------------------
function PFR_sum (a, b, c) {
  return (a + b + c)
}
// ---------------------------------------------------------------------------
function PFR_Rand () {
  let OutVal, ang
  const par1 = 137
  const par2 = 2.0
  const par3 = par2 * Math.PI / par1
  const par4 = 0.5
  if (typeof (PFR_Rand.ListIndex) === 'undefined') {
    PFR_Rand.ListIndex = 0
  } else {
    PFR_Rand.ListIndex = (PFR_Rand.ListIndex + 1) % par1
  }
  ang = PFR_Rand.ListIndex * par3
  OutVal = par4 * (1 + Math.sin(ang))
  return (OutVal)
}
// ---------------------------------------------------------------------------
function PFR_GetOutFun (op) {
  let fun_op, OutFun
  const fun_mask = 255
  fun_op = op & fun_mask
  if (fun_op == 0) {
    OutFun = PFR_Min
  } else if (fun_op == 1) {
    OutFun = PFR_Max
  } else if (fun_op == 2) {
    OutFun = PFR_Avg
  } else { // BM221221
    OutFun = PFR_sum
  }
  return (OutFun)
}
// ---------------------------------------------------------------------------==
function PFR_GetPowerFlow (V, Src, Dst, Yr, Yi, P, Q) {
  let VSr, VSi, dVr, dVi, Ir, Ii, k
  let SrcOffset, DstOffset
  const PhaseCount = 3
  const OffsetStep = 2
  SrcOffset = Src
  DstOffset = Dst
  for (k = 0; k < PhaseCount; k++) {
    VSr = V[SrcOffset + PFR_0]
    VSi = V[SrcOffset + PFR_1]
    dVr = V[SrcOffset + PFR_0] - V[DstOffset + PFR_0]
    dVi = V[SrcOffset + PFR_1] - V[DstOffset + PFR_1]
    Ir = dVr * Yr - dVi * Yi
    Ii = dVi * Yr + dVr * Yi
    P[k] = (VSr * Ir + VSi * Ii)
    Q[k] = (VSi * Ir - VSr * Ii)
    SrcOffset = SrcOffset + OffsetStep
    DstOffset = DstOffset + OffsetStep
  }
}
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
// ---------------------------------------------------------------------------
class PowerFlowResult {
// ---------------------------------------------------------------------------
  get_ver () {
    return (PFR_VER_STR)
  }

  // ---------------------------------------------------------------------------
  get_inf () {
    return (PFR_INF_STR)
  }

  // ---------------------------------------------------------------------------
  constructor (BusCountMax, LineCountMax, FrameCountMax, BufferCountMax) {
    const Switch_Y = 500.0
    this.BusCountMax		= 0
    this.LineCountMax		= 0
    this.FrameCountMax	= 0
    this.BufferCountMax = 0
    this.CT_CountMax			= 0

    this.TestMode				= 0			/** < 1 : EV , 2 : PV */
    this.TestCount			= 0			/** < Test count of capacity */
    this.PercentStart		= 0			/** < Start percent of EV penetration */
    this.PercentEnd			= 0			/** < Final percent of EV penetration */
    this.PercentStep		=	0			/** < Step power in PV capacity test	 */
    this.PowerStart			= 0			/** < Start power in PV capacity test	*/
    this.PowerEnd				=	0			/** < Final power in PV capacity test	*/
    this.PowerStep			= 0			/** < Step of total power in PV capacity test */
    this.MemNew(BusCountMax, LineCountMax,
      FrameCountMax, BufferCountMax)
    this.Switch_Y = [Switch_Y, -Switch_Y, Switch_Y, -Switch_Y]
  }

  // ---------------------------------------------------------------------------
  // BM:230802 updated for three winding transformer
  MemNew (BusCountMax, LineCountMax, FrameCountMax, BufferCountMax) {
    let k, n
    const CfgCountMax = 128 // Line configuration
    const TestCountMax = 128
    const BusCountPerCt = 1
    const CgpCountMax = 1 * BusCountMax
    const SwitchCountMax = 1 * LineCountMax
    const ConCountMax = LineCountMax + SwitchCountMax /* Maximum connections (lines and switches) */
    this.BusCountMax = BusCountMax
    this.LineCountMax = LineCountMax
    this.FrameCountMax = FrameCountMax
    this.BufferCountMax = BufferCountMax
    this.CT_CountMax = BusCountMax / BusCountPerCt // CtCountMax;
    this.FrameSizeMax = PFR_NUM_PER_BUS * BusCountMax
    this.BufferSize = PFR_NUM_PER_BUS * BusCountMax * FrameCountMax
    this.BufferList = []
    for (k = 0; k < this.BufferCountMax; k++) {
      this.BufferList[k] = new Float64Array(this.BufferSize) /* BusIndex voltage ABCN */
    }
    // BM : 221208 : New method to represent lines and switches as unified connection
    this.Cfg_ID				= new Uint32Array(CfgCountMax)
    this.Cfg_Yr1			= new Float32Array(CfgCountMax)
    this.Cfg_Yi1			= new Float32Array(CfgCountMax)
    this.Cfg_Yr4			= new Float32Array(CfgCountMax)
    this.Cfg_Yi4			= new Float32Array(CfgCountMax)
    this.Cfg_YM1			= new Float32Array(CfgCountMax)
    this.Cfg_YM4			= new Float32Array(CfgCountMax)
    this.Cfg_B1				= new Float32Array(CfgCountMax)
    this.Cfg_B4				= new Float32Array(CfgCountMax)

    this.Con_ID				= new Uint32Array(ConCountMax)
    this.Con_State		= new Uint32Array(ConCountMax) /* 0 : off , 1: on  */
    this.Con_Src			= new Uint32Array(ConCountMax)
    this.Con_Dst			= new Uint32Array(ConCountMax)
    this.Con_Cfg			= new Uint32Array(ConCountMax)
    this.Con_NomI1		= new Float32Array(ConCountMax) /* Nominal current per phase A,B and C */
    this.Con_NomI4		= new Float32Array(ConCountMax) /* Nominal current per nutral */
    this.Con_Length		= new Float32Array(ConCountMax) /* length in meters */

    this.Con_LineCount		= 0
    this.Con_SwitchCount	= 0

    this.Bus_ID				= new Uint32Array(BusCountMax) /* Bus ID */
    this.Bus_X				= new Float32Array(BusCountMax) /* Bus X */
    this.Bus_Y				= new Float32Array(BusCountMax) /* Bus Y */
    this.Bus_Base			= new Float32Array(BusCountMax) /* Bus base V */

    this.CGP_ID				= new Uint32Array(CgpCountMax) /* CGP ID */
    this.CGP_Bus			= new Uint32Array(CgpCountMax) /* CGP ID */

    /* CT : Transformer  */
    /* Configuration 0 : OFF, 1:D1YN, 2:DD, 3 : D11YN, 4 : YY */
    this.CT_TransCfg						= new Uint32Array(this.CT_CountMax)

    n = this.CT_CountMax
    this.CT_Station				= new Uint32Array(n)		/* Station ID */
    this.CT_ID						= new Uint32Array(n)		/* CT_ID */
    this.CT_NomS					= new Float32Array(n)	/* CT_NomS */

    n = PFR_WINDING_Y_SIZE_MAX * this.CT_CountMax
    this.CT_Yr						= new Float32Array(n)	/* real part of admittance */
    this.CT_Yi						= new Float32Array(n)	/* real part of admittance */

    n = PFR_WINDING_COUNT_MAX * this.CT_CountMax
    this.CT_bus				= new Uint32Array(n)		/* CT_Bus1 */
    this.CT_cfg				= new Uint8Array(n)		/* configuration of each winding */
    this.CT_scale_i		= new Float32Array(n)	/* current scale from pu to A */

    n = this.CT_CountMax
    this.CT_winding_first			= new Uint32Array(n + 1)		/* index of the first winding */

    this.VS_ID						= new Uint32Array(this.CT_CountMax)
    this.VS_Bus 					= new Uint32Array(this.CT_CountMax)
    this.VS_PoutMax				= new Float32Array(this.CT_CountMax)
    this.VS_PinMax				= new Float32Array(this.CT_CountMax)
    this.VS_QoutMax				= new Float32Array(this.CT_CountMax)
    this.VS_QinMax				= new Float32Array(this.CT_CountMax)
    // ---------------------------------------------------------
    n = PFR_WINDING_COUNT_MAX * this.CT_CountMax
    this.WD_bus_list			= new Uint32Array(BusCountMax) /* buses connected to CT */
    this.WD_bus_first			= new Uint32Array(n + 1) /* lines connected to CT */
    // ---------------------------------------------------------
    this.WD_con_list			= new Uint32Array(ConCountMax) /* lines connected to CT */
    this.WD_con_first			= new Uint32Array(n + 1) /* lines and switches connected to CT */
    // ---------------------------------------------------------
    this.WD_CGP						= new Uint32Array(BusCountMax) /* cgp connected to CT */
    this.WD_CgpFirst			= new Uint32Array(n + 1) /* cgp count for every CT */

    n = PFR_VIO_HOUR_COUNT * CgpCountMax * 4 * 2
    this.Hosting_capacity			= new Float32Array(n)

    n = PFR_NUM_PER_BUS * PFR_VIO_HOUR_COUNT * BusCountMax
    this.Violation_Bus			= new Float32Array(n)

    n = PFR_PHASE_COUNT * PFR_VIO_HOUR_COUNT * LineCountMax
    this.Violation_Line			= new Float32Array(n)
    this.Violation_Trans		= new Float32Array(FrameCountMax)

    n = PFR_VIO_HOUR_COUNT * TestCountMax * PFR_NUM_PER_BUS
    this.Violation_RecBus		= new Float32Array(n)

    n = PFR_VIO_HOUR_COUNT * TestCountMax * PFR_VIO_PER_LINE
    this.Violation_RecLine	= new Float32Array(n)

    n = PFR_VIO_HOUR_COUNT * TestCountMax
    this.Violation_RecTrans = new Float32Array(n)

    n = PFR_VIO_HOUR_COUNT * TestCountMax * PFR_VIO_PER_BUS * BusCountMax
    this.Violation_BufBus		= new Float32Array(n)

    n = PFR_VIO_HOUR_COUNT * TestCountMax * PFR_VIO_PER_LINE * LineCountMax
    this.Violation_BufLine	= new Float32Array(n)

    n = PFR_VIO_HOUR_COUNT * TestCountMax
    this.Violation_BufTrans = new Float32Array(n)
  }

  // ---------------------------------------------------------------------------
  MemFree () {
    let k
    if (this.BusCountMax == 0) /* No allocated memory */
    {
      return (0)
    }
    for (k = 0; k < this.BufferCountMax; k++) {
      this.BufferList[k] = null
    }

    this.Cfg_B1							= null
    this.Cfg_B4							= null
    this.Cfg_Yr1						= null
    this.Cfg_Yi1						= null
    this.Cfg_Yr4						= null
    this.Cfg_Yi4						= null
    this.Cfg_YM1						= null
    this.Cfg_YM4						= null
    this.Con_ID							= null
    this.Con_State					= null /* 0 : off , 1: on  */
    this.Con_Src						= null
    this.Con_Dst						= null
    this.Con_Cfg						= null
    this.Con_NomI1					= null
    this.Con_NomI4					= null
    this.Con_Length					= null

    this.Bus_ID							= null
    this.Bus_X							= null
    this.Bus_Y							= null
    this.Bus_Base						= null

    this.CGP_ID							= null
    this.CGP_Bus						= null

    this.CT_ID						= null
    this.CT_bus						= null
    this.CT_Yr						= null
    this.CT_Yi						= null
    this.CT_scale_i				= null
    this.CT_cfg						= null
    this.CT_Station				= null
    this.CT_TransCfg			= null
    this.CT_NomS					= null

    this.WD_bus_list				= null
    this.WD_bus_first				= null

    this.WD_con_list				= null
    this.WD_con_first				= null

    this.WD_CGP							= null
    this.WD_CgpFirst				= null
    this.Hosting_capacity		= null
    this.Violation_Bus			= null
    this.Violation_Line			= null
    this.Violation_Trans		= null
    this.Violation_RecBus		= null
    this.Violation_RecLine	= null
    this.Violation_RecTrans = null
    this.Violation_BufBus		= null
    this.Violation_BufLine	= null
    this.Violation_BufTrans = null
    this.VS_ID							= null
    this.VS_Bus 						= null
    this.VS_PoutMax					= null
    this.VS_PinMax					= null
    this.VS_QoutMax					= null
    this.VS_QinMax					= null
    this.BusCountMax = 0
    return (1)
  }

  // ---------------------------------------------------------------------------
  GetBusOffset (Frame1, BusIndex, PhaseIndex) {
    let offset
    offset = this.FrameSize * Frame1
    offset +=	PFR_NUM_PER_BUS * BusIndex
    offset +=	PFR_NUM_PER_PHASE * PhaseIndex
    return (offset)
  }

  // ---------------------------------------------------------------------------
  GetSrcOffset (Frame1, LineIndex, PhaseIndex) {
    let offset, BusIndex
    BusIndex = this.Con_Src[LineIndex]
    offset = this.FrameSize * Frame1
    offset +=	PFR_NUM_PER_BUS * BusIndex
    offset +=	PFR_NUM_PER_PHASE * PhaseIndex
    return (offset)
  }

  // ---------------------------------------------------------------------------
  GetDstOffset (Frame1, LineIndex, PhaseIndex) {
    let offset, BusIndex
    BusIndex = this.Con_Dst[LineIndex]
    offset = this.FrameSize * Frame1
    offset +=	PFR_NUM_PER_BUS * BusIndex
    offset +=	PFR_NUM_PER_PHASE * PhaseIndex
    return (offset)
  }

  // ---------------------------------------------------------------------------
  GetBusIndex (ID) // BM : 221207
  {
    let k
    for (k = this.BusCount - 1; ((k >= 0) && (this.Bus_ID[k] != ID)); k--) {
      // NO CODE
    }
    return (k)
  }

  // ---------------------------------------------------------------------------
  GetLineIndex (ID) // BM : 221207
  {
    let k
    for (k = this.LineCount - 1; ((k >= 0) && (this.Con_ID[k] != ID)); k--) {
      // NO CODE
    }
    return (k)
  }

  // ---------------------------------------------------------------------------
  GetCfgIndex (ID) // BM : 221207
  {
    let k
    for (k = this.Cfg_Count - 1; ((k >= 0) && (this.Cfg_ID[k] != ID)); k--) {
      // NO CODE
    }
    return (k)
  }

  // ---------------------------------------------------------------------------
  GetSwitchIndex (ID) // BM : 221207
  {
    let k, k0
    k0 = this.LineCount
    for (k = k0 + this.SwitchCount - 1; ((k >= k0) && (this.Con_ID[k] != ID)); k--) {
      // NO CODE
    }
    if (k < k0) {
      k = -1
    } else {
      // NO CODE
    }
    return (k)
  }

  // ---------------------------------------------------------------------------
  GetScaleBus (op, BusIndex) {
    let is_pu, scale
    const pu_bit_index = 8
    is_pu = (op >> pu_bit_index) & 1
    if (is_pu != 0) {
      scale = 1.0
    } else {
      scale = this.Bus_Base[BusIndex]
    }
    return (scale)
  }

  // ---------------------------------------------------------------------------
  GetScaleLine (op, LineIndex) {
    let is_pu, scale, BusIndex, I_max
    const pu_bit_index = 8
    is_pu = (op >> pu_bit_index) & 1

    BusIndex = this.Con_Src[LineIndex]
    if (is_pu == 0) /* real values */
    {
      scale = this.Bus_Base[BusIndex]
    } else /* scale the current relative to the maximum current */
    {
      I_max = this.Con_NomI1[LineIndex]
      scale = this.Bus_Base[BusIndex] / I_max
    }
    return (scale)
  }

  // ---------------------------------------------------------------------------
  ReadDataBus (db) {
    let sql, stmt, k, res
    const num_three = 3.0
    const scale = PFR_1000 / Math.sqrt(num_three)
    // Read Bus data
    sql	= 'SELECT Bus.ID, Bus.X, Bus.Y, Base.kV FROM Bus, Base '
    sql	= sql.concat('WHERE Bus.Base=Base.ID ')
    sql	= sql.concat('ORDER BY Base.kV DESC, Bus.ID')

    stmt = db.prepare(sql)
    for (k = 0; (k < this.BusCount) && (stmt.step() != 0); k++) {
      res = stmt.get()
      this.Bus_ID[k]		= res[PFR_0]
      this.Bus_X[k]		= res[PFR_1]
      this.Bus_Y[k]		= res[PFR_2]
      this.Bus_Base[k]	= res[PFR_3] * scale
    }
    stmt.free()
    return (0)
  }

  // ---------------------------------------------------------------------------
  read_cfg (db) {
    let sql, stmt, k, res
    let R1, X1, B1, R4, X4, B4, ZM2, kV, ZM2_Tol, id_max

    sql	= 'SELECT DISTINCT Line.Cfg_R as ID, '
    sql	= sql.concat('Cfg_R.R11, Cfg_X.X11, Cfg_B.B11, ')
    sql	= sql.concat('Cfg_R.R44, Cfg_X.X44, Cfg_B.B44, Base.kV ')
    sql	= sql.concat('From Line, Cfg_R, Cfg_X, Cfg_B, Bus, Base ')
    sql	= sql.concat('WHERE Line.Bus1 = Bus.ID AND ')
    sql	= sql.concat('Bus.Base = Base.ID AND ')
    sql	= sql.concat('Line.Cfg_R = Cfg_R.ID AND ')
    sql	= sql.concat('Line.Cfg_X = Cfg_X.ID AND ')
    sql	= sql.concat('Line.Cfg_B = Cfg_B.ID ')
    sql	= sql.concat('ORDER BY Line.Cfg_R')

    ZM2_Tol = PFR_TOL * PFR_TOL
    id_max = -1
    stmt = db.prepare(sql)
    for (k = 0; (k < this.Cfg_Count) && (stmt.step() != 0); k++) {
      res = stmt.get()
      this.Cfg_ID[k] = res[PFR_0]
      if (id_max < this.Cfg_ID[k]) {
        id_max = this.Cfg_ID[k]
      } else {
        // NO CODE
      }
      R1 = res[PFR_1]
      X1 = res[PFR_2]
      B1 = res[PFR_3]
      R4 = res[PFR_4]
      X4 = res[PFR_5]
      B4 = res[PFR_6]
      kV = res[PFR_7]

      ZM2 = (R1 * R1) + (X1 * X1)
      if (ZM2 < ZM2_Tol) /* almost zero impedance */
      {
        this.Cfg_YM1[k] = 0.0 /* avoid division by zero */
        this.Cfg_Yr1[k] = 0.0
        this.Cfg_Yi1[k] = 0.0
      } else {
        this.Cfg_YM1[k] = 1.0 / Math.sqrt(ZM2)
        this.Cfg_Yr1[k] = R1 / ZM2
        this.Cfg_Yi1[k] = -X1 / ZM2
      }

      ZM2 = (R4 * R4) + (X4 * X4)
      if ((ZM2 < ZM2_Tol) || (kV > 1.0)) /* almost zero impedance */
      {
        this.Cfg_YM4[k] = 0.0 /* avoid division with zero */
        this.Cfg_Yr4[k] = 0.0
        this.Cfg_Yi4[k] = 0.0
      } else {
        this.Cfg_YM4[k] = 1.0 / Math.sqrt(ZM2)
        this.Cfg_Yr4[k] = R4 / ZM2
        this.Cfg_Yi4[k] = -X4 / ZM2
      }

      this.Cfg_B1[k] = B1
      this.Cfg_B4[k] = B4
    }
    stmt.free()

    this.Cfg_ID[k]	= id_max + 100 // MM use max. ID
    this.Cfg_YM1[k] = 500.0 * Math.sqrt(2.0)
    this.Cfg_Yr1[k] = 500.0
    this.Cfg_Yi1[k] = -500.0
    this.Cfg_YM4[k] = this.Cfg_YM1[k]
    this.Cfg_Yr4[k] = this.Cfg_Yr1[k]
    this.Cfg_Yi4[k] = this.Cfg_Yi1[k]
    this.Cfg_B1[k]	= 0.0
    this.Cfg_B4[k]	= 0.0
    this.Cfg_Count	= k + 1
    return (0)
  }

  // ---------------------------------------------------------------------------
  ReadDataLine (db) {
    let sql, stmt, k, res
    sql	= 'SELECT Line.ID, Line.Bus1, Line.Bus2, Line.Cfg_R, '
    sql	= sql.concat('Line.Length, Line.Imax1, Line.Imax4, ')
    sql	= sql.concat('Base.kV, Line.Mode From Line, Bus, Base ')
    sql	= sql.concat('WHERE Line.Bus1 = Bus.ID AND ')
    sql	= sql.concat('Bus.Base = Base.ID ')
    sql	= sql.concat('ORDER BY Base.kV DESC , Line.ID ASC')

    stmt = db.prepare(sql)
    for (k = 0; (k < this.LineCount) && (stmt.step() != 0); k++) {
      res = stmt.get()
      this.Con_ID[k]			= res[PFR_0]
      this.Con_Src[k]			= this.GetBusIndex(res[PFR_1])
      this.Con_Dst[k]			= this.GetBusIndex(res[PFR_2])
      this.Con_Cfg[k]			= this.GetCfgIndex(res[PFR_3])
      this.Con_Length[k]	= res[PFR_4] / PFR_1000 // km
      this.Con_NomI1[k]		= res[PFR_5]
      this.Con_NomI4[k]		= res[PFR_6]
      if ((this.Con_Length[k]) < 0.0000001) // BM231117 : Fix the minimum length of line segment
      {
        this.Con_Length[k] = 0.0000001 // BM231117 : Fix the minimum length of line segment
      }
      this.Con_State[k] = (res[PFR_8] != 0)
    }
    stmt.free()
    return (0)
  }

  // ---------------------------------------------------------------------------
  ReadDataSwitch (db) {
    let sql, stmt, m, k, res
    sql = 'SELECT ID, Bus1, Bus2, State1 From Switch ORDER BY ID'
    stmt = db.prepare(sql)
    m = this.LineCount
    for (k = 0; (k < this.SwitchCount) && (stmt.step() != 0); k++) {
      res = stmt.get()
      this.Con_ID[m]			= res[PFR_0]
      this.Con_Src[m]			= this.GetBusIndex(res[PFR_1])
      this.Con_Dst[m]			= this.GetBusIndex(res[PFR_2])
      this.Con_State[m] 	= (res[PFR_3] != 0)
      this.Con_Cfg[m]			= this.Cfg_Count - 1
      this.Con_Length[m]	= 1.0
      m++
    }
    stmt.free()
    return (0)
  }

  // ---------------------------------------------------------------------------
  ReadDataCGP (db) {
    let sql, stmt, k, res
    sql = 'SELECT ID, Bus From CGP ORDER BY ID'
    stmt = db.prepare(sql)
    for (k = 0; (k < this.CgpCount) && (stmt.step() != 0); k++) {
      res = stmt.get()
      this.CGP_ID[k]	= res[PFR_0]
      this.CGP_Bus[k] = this.GetBusIndex(res[PFR_1])
    }
    stmt.free()
    return (0)
  }

  // ---------------------------------------------------------------------------
  ReadDataVsource (db) {
    let column_name, sql, stmt, k, res, new_db
    column_name = PFR_column_name(db, 'Vs')
    if (column_name.length > 4) {
      sql = 'SELECT ID, Bus, PIN_MAX, POUT_MAX, QIN_MAX, QOUT_MAX From Vs ORDER BY ID'
      new_db = 1
    } else {
      sql = 'SELECT ID, Bus From Vs ORDER BY ID'
      new_db = 0
    }
    stmt = db.prepare(sql)
    for (k = 0; (k < this.VS_count) && (stmt.step() != 0); k++) {
      res = stmt.get()
      this.VS_ID[k]	 = res[PFR_0]
      this.VS_Bus[k] = this.GetBusIndex(res[PFR_1])
      if (new_db == 1) {
        this.VS_PinMax[k]		= res[PFR_2]
        this.VS_PoutMax[k] 	= res[PFR_3]
        this.VS_QinMax[k] 	= res[PFR_4]
        this.VS_QoutMax[k] 	= res[PFR_5]
      }
    }
    stmt.free()
    return (0)
  }

  // ---------------------------------------------------------------------------
  set_ct_scale_i (winding_index, trans_cfg, base_s) {
    let n, cfg
    let base_v, scale_i
    const PFR_TRANS_CFG	= [[0, 0, 0, 2, 1], [0, 1, 0, 1, 1]]

    for (n = 0; n < 2; n++) {
      cfg			= PFR_TRANS_CFG[n][trans_cfg]
      this.CT_cfg[winding_index] = cfg
      base_v	= this.Bus_Base[this.CT_bus[winding_index]]
      scale_i = base_s / base_v
      if (cfg == PFR_YN) // Y connection
      {
        this.CT_scale_i[winding_index] = scale_i
      } else // Delta connection
      {
        this.CT_scale_i[winding_index] = scale_i * PFR_1_DIV_SQRT_3
      }
      winding_index++
    }
    return (winding_index)
  }

  // ---------------------------------------------------------------------------
  // BM230804
  ReadDataTrans (db) {
    let m, k, res, bus1, bus2, trans_index
    let trans_cfg, row_count, isNotOrder
    let sql; let stmt; const Rp = [0, 0]; const Xp = [0, 0]
    let base_s

    this.ReadDataWinding(db)

    row_count = PFR_GetCount(db, 'Transformer')
    trans_index			= this.CT_count
    m = this.CT_winding_first[trans_index]
    sql = 'SELECT Station, Bus1, Bus2, MVA, Rp, Xp, Rn, Xn, ID, Cfg '
    sql	= sql.concat('From Transformer ORDER BY ID')
    stmt = db.prepare(sql)
    for (k = 0; (k < row_count) && (stmt.step() != 0); k++) {
      res = stmt.get()
      this.CT_Station[trans_index]		= res[PFR_0]
      this.CT_ID[trans_index]					= res[PFR_8]
      trans_cfg = res[PFR_9] // 0 : OFF , 1 : D11Yn , 2 : DD , 3 : D1Yn
      if ((trans_cfg < 0) || (trans_cfg > 4)) {
        trans_cfg = 1 // Assume the configuration as D11Yn
      } else {
        // NO CODE
      }
      bus1 = this.GetBusIndex(res[PFR_1])
      bus2 = this.GetBusIndex(res[PFR_2])
      isNotOrder = (this.Bus_Base[bus2] > this.Bus_Base[bus1])
      isNotOrder = isNotOrder && (this.Bus_Base[bus1] < PFR_1000)
      if (isNotOrder != 0) // For LV must be V1 > V2
      {
        this.CT_bus[m + PFR_0] = bus2
        this.CT_bus[m + PFR_1] = bus1
      } else {
        this.CT_bus[m + PFR_0] = bus1
        this.CT_bus[m + PFR_1] = bus2
      }
      this.CT_NomS[trans_index] = res[PFR_3] * PFR_1000
      base_s = res[PFR_3] * PFR_1000 * PFR_1000 / 3.0

      Rp[PFR_0] = res[PFR_4] / 200.0
      Xp[PFR_0] = res[PFR_5] / 200.0
      Rp[PFR_1] = Rp[PFR_0]
      Xp[PFR_1] = Xp[PFR_0]
      this.MakeTransModel(trans_index, 2, Rp, Xp)

      this.CT_winding_first[trans_index] = m
      m = this.set_ct_scale_i(m, trans_cfg, base_s)
      trans_index++
    }
    this.CT_count = trans_index
    this.CT_winding_first[trans_index] = m
    stmt.free()
    return (0)
  }

  // ---------------------------------------------------------------------------
  MakeTransModel (trans_index, winding_count, Rp, Xp, mode) {
    let k, index_y
    let R11, R12, R22, X11, X12, X22, mag2
    let d1r, d1i,	d2r, d2i, dr, di, dd

    index_y = PFR_WINDING_Y_SIZE_MAX * trans_index
    if (mode == 0) {
      for (k = 0; k < winding_count; k++) {
        this.CT_Yr[index_y] = 0.0
        this.CT_Yi[index_y] = 0.0
        index_y = index_y + 1
      }
    } else if (winding_count == 2) {
      R11 = Rp[0] + Rp[1]
      X11 = Xp[0] + Xp[1]
      mag2 = (R11 * R11) + (X11 * X11)
      this.CT_Yr[index_y] = R11 / mag2
      this.CT_Yi[index_y] = -X11 / mag2
    } else if (winding_count == 3) {
      R11 = Rp[0] + Rp[1]
      R22 = Rp[0] + Rp[2]
      R12 = Rp[0]

      X11 = Xp[0] + Xp[1]
      X22 = Xp[0] + Xp[2]
      X12 = Xp[0]

      d1r = R11 * R22 - X11 * X22
      d1i = R11 * X22 + X11 * R22
      d2r = R12 * R12 - X12 * X12
      d2i = R12 * X12 + X12 * R12
      dr = d1r - d2r
      di = d1i - d2i
      dd = (dr * dr) + (di * di)
      dr = dr / dd
      di = -di / dd

      this.CT_Yr[index_y] = dr * R22 - di * X22
      this.CT_Yi[index_y] = dr * X22 + di * R22
      index_y = index_y + 1
      this.CT_Yr[index_y] = -dr * R12 + di * X12
      this.CT_Yi[index_y] = -dr * X12 - di * R12
      index_y = index_y + 1
      this.CT_Yr[index_y] = this.CT_Yr[index_y - 1]
      this.CT_Yi[index_y] = this.CT_Yi[index_y - 1]
      index_y = index_y + 1
      this.CT_Yr[index_y] = dr * R11 - di * X11
      this.CT_Yi[index_y] = dr * X11 + di * R11
    } else {
      console.error('This code does not support more than 3 winding\n')
    }
  }

  // ---------------------------------------------------------------------------
  // BM:230802 updated for three winding transformer
  ReadDataWinding (db) {
    let winding_count, row_count, cfg, mode
    let trans_index, trans_id_1, trans_id
    let bus, m, isFirst, isLast, kv, scale_i
    const Rp = [0, 0, 0, 0]; const Xp = [0, 0, 0, 0]
    let sql, stmt, res
    const PFR_CFG_MAP =	[0, 0, 0, 0, 2, 1, 1]

    this.CT_count = 0
    this.CT_winding_first[0] = 0
    row_count = PFR_GetCount(db, 'Winding')
    if (row_count == 0) {
      return (0)
    }

 	sql	= 'SELECT Transformer2.ID, Transformer2.Station, '
    sql	= sql.concat('Winding.Cfg, Winding.Bus, Base.kV, Winding.kVA, ')
    sql	= sql.concat('Winding.Rp, Winding.Xp, Winding.Rn, Winding.Xn, ')
    sql	= sql.concat('Winding.TapCount, Winding.TapMin, ')
    sql	= sql.concat('Winding.TapMax, Winding.Tap, Transformer2.Mode ')
    sql	= sql.concat('FROM Winding, Transformer2, Bus, Base ')
    sql	= sql.concat('WHERE Winding.Transformer=Transformer2.ID AND ')
    sql	= sql.concat('Bus.Base=Base.ID AND Winding.Bus=Bus.ID ')
    sql	= sql.concat('ORDER BY Transformer2.ID, Winding.ID')

    stmt = db.prepare(sql)
    mode = 0
    winding_count = 0
    trans_index = 0
    trans_id_1 = -1
    for (m = 0; (m < row_count) && (stmt.step() != 0); m++) {
      res = stmt.get()
      trans_id = res[PFR_0]

      isFirst = (trans_id_1 != trans_id)
      isLast	= (isFirst && (m > 0))

      if (isLast != 0) // end of windings for the current transformer
      {
        // Base power in kVA phase to phase
        this.MakeTransModel(trans_index, winding_count, Rp, Xp, mode)
        trans_index = trans_index + 1
        winding_count = 0
      }

      mode = res[PFR_14]
      bus = res[PFR_3]
      bus = this.GetBusIndex(bus)
      this.CT_bus[m]			= bus
      kv			=	res[PFR_4]
      Rp[winding_count] =	res[PFR_6] / 100.0
      Xp[winding_count] =	res[PFR_7] / 100.0

      winding_count = winding_count + 1

      if (isFirst != 0) {
        this.CT_NomS[trans_index]		= res[PFR_5]	 // kVA
        this.CT_ID[trans_index]	= trans_id
        this.CT_winding_first[trans_index]	= m
        trans_id_1 = trans_id
      }

      cfg = PF_Limit(res[PFR_2] - 1, 0, 6)
      cfg = PFR_CFG_MAP[cfg]
      this.CT_cfg[m]		=	cfg
      //                                      Y       ,   Delta
      scale_i = PF_Select((cfg == 1), PFR_1_DIV_SQRT_3, (1.0 / 3.0))
      this.CT_scale_i[m] = scale_i * (this.CT_NomS[trans_index] / kv)
    }
    if (winding_count >= 2) {
      this.MakeTransModel(trans_index, winding_count, Rp, Xp, mode)
      this.CT_count = trans_index + 1
      this.CT_winding_first[trans_index + 1] = m
    } else {
      // NO CODE
    }
    stmt.free()
    return (this.CT_count)
  }

  // ---------------------------------------------------------------------------
  get_ct_i (trans_index, ref_bus, frame_index, index_v, V, Iw) {
    let W3, W1, W2, cfg_index, k, k1, k2, k3, k4, index_i
    let n1, n2, n3, bus1, bus2, bus3, start_y, index_y
    let src, dst, out, frame_size, winding_count
    let scale_i, scale_i1, scale1, scale2
    let dv_real, dv_imag, Yr, Yi, I_real, I_imag
    const PFR_TRANS_NEG		= [2, 4, 0, 6, 6, 6, 4, 0, 2]
    const PFR_TRANS_SCALE	= [PFR_1_DIV_SQRT_3, 1.0, PFR_1_DIV_SQRT_3]

    frame_size			= this.FrameSize

    start_y					= 4 * trans_index
    W1							= this.CT_winding_first[trans_index]
    W2							= this.CT_winding_first[trans_index + 1] - 1
    winding_count		= W2 - W1 + 1

    bus1						= this.CT_bus[W1]
    cfg_index				= this.CT_cfg[W1]
    scale1					= PFR_TRANS_SCALE[cfg_index]
    n1							= 3 * cfg_index

    scale_i1				= this.CT_scale_i[W1]

    W2							= W1 + index_v
    cfg_index				= this.CT_cfg[W2]
    scale2					= PFR_TRANS_SCALE[cfg_index]
    n2							= 3 * cfg_index
    bus2						= this.CT_bus[W2]

    out = frame_index * PFR_NUM_PER_BUS
    src = (frame_index * frame_size) + (PFR_NUM_PER_BUS * bus1)
    dst = (frame_index * frame_size) + (PFR_NUM_PER_BUS * bus2)
    for (k = 0; k < 3; k++) // phase counter
    {
      k1 = (2 * k) + src
      k3 = (2 * k) + dst
      k2 = PFR_TRANS_NEG[n1 + k] + src
      k4 = PFR_TRANS_NEG[n2 + k] + dst

      dv_real	=						((V[k1 + PFR_0] - V[k2 + PFR_0]) * scale1)
      dv_real	= dv_real - ((V[k3 + PFR_0] - V[k4 + PFR_0]) * scale2)
      dv_imag =						((V[k1 + PFR_1] - V[k2 + PFR_1]) * scale1)
      dv_imag	= dv_imag - ((V[k3 + PFR_1] - V[k4 + PFR_1]) * scale2)

      k1 = out + (2 * k)
      k2 = out + PFR_TRANS_NEG[n1 + k]
      for (index_i = 1; index_i < winding_count; index_i++) {
        index_y = start_y + 2 * (index_i - 1) + (index_v - 1)
        Yr = this.CT_Yr[index_y]
        Yi = this.CT_Yi[index_y]
        I_real = ((dv_real * Yr) - (dv_imag * Yi))
        I_imag = ((dv_real * Yi) + (dv_imag * Yr))
        if (ref_bus == bus1) {
          Iw[k1 + PFR_0] = Iw[k1 + PFR_0] + I_real * scale_i1
          Iw[k1 + PFR_1] = Iw[k1 + PFR_1] + I_imag * scale_i1
          Iw[k2 + PFR_0] = Iw[k2 + PFR_0] - I_real * scale_i1
          Iw[k2 + PFR_1] = Iw[k2 + PFR_1] - I_imag * scale_i1
        }

        W3					= W1 + index_i
        bus3				= this.CT_bus[W3]
        if (ref_bus == bus3) {
          cfg_index		= this.CT_cfg[W3]
          n3					= 3 * cfg_index
          k3 = out + (2 * k)
          k4 = out + PFR_TRANS_NEG[n3 + k]
          scale_i = this.CT_scale_i[W3]

          Iw[k3 + PFR_0] = Iw[k3 + PFR_0] - I_real * scale_i
          Iw[k3 + PFR_1] = Iw[k3 + PFR_1] - I_imag * scale_i
          Iw[k4 + PFR_0] = Iw[k4 + PFR_0] + I_real * scale_i
          Iw[k4 + PFR_1] = Iw[k4 + PFR_1] + I_imag * scale_i
        }
      }
    }
  }

  // ---------------------------------------------------------------------------
  GetWindingCount (CtIndex) {
    return (this.CT_winding_first[CtIndex + 1] - this.CT_winding_first[CtIndex])
  }

  // ---------------------------------------------------------------------------
  AddCtCurrent (BufferIndex, Frame1, Frame2, trans_index, ref_bus, Iw) {
    let W1, W2, m, index_v
    let frame_index, isConnected, winding_count
    let V

    V = this.BufferList[BufferIndex]

    W1							= this.CT_winding_first[trans_index]
    W2							= this.CT_winding_first[trans_index + 1] - 1
    winding_count		= W2 - W1 + 1

    isConnected						= 0
    for (m = W1; m <= W2; m++) {
      if (this.CT_bus[m] == ref_bus) {
        isConnected = 1
      } else {
        // NO CODE
      }
    }

    if (isConnected == 0) // ref_bus is not connected to this transformer
    {
      return
    }

    for (frame_index = Frame1; frame_index <= Frame2; frame_index++) {
      for (index_v = 1; index_v < winding_count; index_v++) {
        this.get_ct_i(trans_index, ref_bus, frame_index, index_v, V, Iw)
      }
    }
  }

  // ---------------------------------------------------------------------------
  NewCase (db, FrameCount, BufferCount) {
    let chk
    try {
      this.FrameCount = FrameCount
      this.BufferCount = BufferCount
      /* Read count of elements */
      this.BusCount			= PFR_GetCount(db, 'Bus')
      this.originalBusCount = PFR_GetCount(db, 'Bus')
      this.LineCount		= PFR_GetCount(db, 'Line')
      this.SwitchCount	= PFR_GetCount(db, 'Switch')
      this.CgpCount			= PFR_GetCount(db, 'CGP')
      this.VS_count			= PFR_GetCount(db, 'Vs')
      this.Cfg_Count		= PFR_GetCount(db, 'Cfg_R')
      /* Read parameter */
      this.Base_S				= PFR_GetPar(db, 'CasePar', 'BasePower')
      this.StepTime			= PFR_GetPar(db, 'CasePar', 'StepTime')
      this.V_min				= PFR_GetPar(db, 'Capacity', 'Vmin')
      this.V_max				= PFR_GetPar(db, 'Capacity', 'Vmax')
      this.NegSeqMax		= PFR_GetPar(db, 'Capacity', 'NegSeqMax') * PFR_100
      this.ZeroSeqMax		= PFR_GetPar(db, 'Capacity', 'ZeroSeqMax') * PFR_100
      this.ImaxRatio		= PFR_GetPar(db, 'Capacity', 'OverCurrentRatio')
      this.SmaxRatio		= PFR_GetPar(db, 'Capacity', 'OverLoadingRatio')

      this.FrameSize	= PFR_NUM_PER_BUS * this.BusCount
      this.Base_S			= this.Base_S * PFR_1000 * PFR_1000

      /* Check overflow */
      chk = 0
      chk = chk || (this.FrameSize > this.FrameSizeMax)
      chk = chk || (this.BusCount > this.BusCountMax)
      chk = chk || (this.LineCount > this.LineCountMax)
      chk = chk || (this.FrameCount > this.FrameCountMax)
      chk = chk || (this.BufferCount > this.BufferCountMax)
      if (chk != 0) {
        this.MemFree()
        this.MemNew(this.BusCount, this.LineCount,
          this.FrameCount, this.BufferCount)
      }

      // Read input database
      this.ReadDataBus(db)
      this.read_cfg(db)
      this.ReadDataLine(db)
      this.ReadDataSwitch(db)
      this.ReadDataCGP(db)
      this.ReadDataTrans(db)
      this.ReadDataVsource(db)
      this.Con_Count		= this.LineCount + this.SwitchCount
      this.AllocCGP()
    } catch (exception) {
      console.log('Error')
      throw (exception)
    }
    return (0)
  }

  /**
   * This function solves a problem with ESS when you change cases. The number of buses changes
   */
  AdjustBusCount (sum = 0) {
    this.BusCount = this.originalBusCount + sum
    this.FrameSize = PFR_NUM_PER_BUS * this.BusCount
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  LoadBuffer (BufferIndex, InputBuffer, op = 0) {
    let ElmIndex, ElmCount, Buf
    const Scale = 2045222520.0
    Buf = this.BufferList[BufferIndex]
    ElmCount = PFR_NUM_PER_BUS * this.BusCount * this.FrameCount
    if ((op == 0) || (op == 1)) // FP32 or FP64 frame buffer
    {
      for (ElmIndex = 0; ElmIndex < ElmCount; ElmIndex++) {
        Buf[ElmIndex] = InputBuffer[ElmIndex]
      }
    } else // (op == 2) SI32 frame buffer
    {
      for (ElmIndex = 0; ElmIndex < ElmCount; ElmIndex++) {
        Buf[ElmIndex] = InputBuffer[ElmIndex] / Scale
      }
    }
    return (0)
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  GetTimeVec (Frame1, Frame2) {
    let FrameCount, k, FrameIndex, OutDat
    FrameCount = Frame2 - Frame1 + 1
    FrameIndex = Frame1
    OutDat = new Float32Array(FrameCount)
    for (k = 0; k < FrameCount; k++) {
      OutDat[k] = FrameIndex++
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetVabc (InIndex, V) {
    let x, y, a, b, c
    x = V[InIndex]
    y = V[InIndex + PFR_1]
    a = Math.sqrt(x * x + y * y)
    x = V[InIndex + PFR_2]
    y = V[InIndex + PFR_3]
    b = Math.sqrt(x * x + y * y)
    x = V[InIndex + PFR_4]
    y = V[InIndex + PFR_5]
    c = Math.sqrt(x * x + y * y)
    return ([a, b, c])
  }

  // ---------------------------------------------------------------------------
  GetVoltageDif (V, Src, Dst, scale = 1.0) {
    let x, y, a, b, c
    x = (scale * V[Src + PFR_0]) - (scale * V[Dst + PFR_0])
    y = (scale * V[Src + PFR_1]) - (scale * V[Dst + PFR_1])
    a = Math.sqrt((x * x) + (y * y))
    x = (scale * V[Src + PFR_2]) - (scale * V[Dst + PFR_2])
    y = (scale * V[Src + PFR_3]) - (scale * V[Dst + PFR_3])
    b = Math.sqrt((x * x) + (y * y))
    x = (scale * V[Src + PFR_4]) - (scale * V[Dst + PFR_4])
    y = (scale * V[Src + PFR_5]) - (scale * V[Dst + PFR_5])
    c = Math.sqrt((x * x) + (y * y))
    return ([a, b, c])
  }

  // ---------------------------------------------------------------------------
  GetVoltageDif2 (V, Src, Dst, scale = 1.0) {
    let x, y, k
    const abcn = [0, 0, 0, 0]
    for (k = 0; k < 4; k++) {
      x = (scale * V[Src++]) - (scale * V[Dst++])
      y = (scale * V[Src++]) - (scale * V[Dst++])
      abcn[k] = ((x * x) + (y * y))
    }
    return (abcn)
  }

  // ---------------------------------------------------------------------------
  GetBusPhaseV (BufferIndex, Frame1, Frame2, BusIndex, PhaseIndex, op) {
    let V, OutDat, OutIndex, FrameCount
    let FrameSize, x, y, InIndex, scale
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    InIndex = this.GetBusOffset(Frame1, BusIndex, PhaseIndex)
    OutIndex = 0
    scale = this.GetScaleBus(op, BusIndex)
    for (; FrameCount > 0; FrameCount--) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      OutDat[OutIndex++] = scale * Math.sqrt(x * x + y * y)
      InIndex = InIndex + FrameSize
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetBusV (BufferIndex, Frame1, Frame2, BusIndex, op) {
    let V, OutDat, OutIndex, FrameCount, FrameSize, InIndex
    let tmp, OutFun, scale
    OutFun		= PFR_GetOutFun(op)
    scale	= this.GetScaleBus(op, BusIndex)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    OutIndex = 0
    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetVabc(InIndex, V)
      OutDat[OutIndex++] = scale * OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      InIndex = InIndex + FrameSize
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetFrameBusV (BufferIndex, Frame1, Frame2, op) {
    let V, OutDat, OutIndex, FrameCount, FrameSize, InIndex
    let tmp, ElmCount, OutFun, scale, BusIndex
    OutFun		= PFR_GetOutFun(op)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    ElmCount = FrameCount * this.BusCount
    OutDat = new Float32Array(ElmCount)
    InIndex = Frame1 * FrameSize
    OutIndex = 0
    for (; ElmCount > 0; ElmCount--) {
      BusIndex = (InIndex / PFR_NUM_PER_BUS) % this.BusCount
      scale	= this.GetScaleBus(op, BusIndex)
      tmp = this.GetVabc(InIndex, V)
      OutDat[OutIndex++] = scale * OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetLinePhaseI (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex, op = 0) {
    let V, OutDat, OutIndex, FrameCount, FrameSize, x, y
    let Src, Dst, scale, I_max, YM
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      if (PhaseIndex < PFR_3) {
        YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
        I_max = this.Con_NomI1[LineIndex]
      } else {
        YM = this.Cfg_YM4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
        I_max = this.Con_NomI4[LineIndex]
      }

      if (Math.abs(I_max) < PFR_CURRENT_TOL) {
        I_max = PFR_100
      }
      if (op == 0) {
        scale = this.Bus_Base[this.Con_Src[LineIndex]]
      } else {
        scale = this.Bus_Base[this.Con_Src[LineIndex]] / I_max
      }

      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)
      OutIndex = 0
      for (; FrameCount > 0; FrameCount--) {
        x = scale * V[Src + PFR_0] - scale * V[Dst + PFR_0]
        y = scale * V[Src + PFR_1] - scale * V[Dst + PFR_1]
        OutDat[OutIndex++] =	Math.sqrt(x * x + y * y) * YM
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetLineI (BufferIndex, Frame1, Frame2, LineIndex, op) {
    let V, OutDat, OutIndex, FrameCount, FrameSize, YM
    let Src, Dst, tmp, OutFun, scale, result
    OutFun = PFR_GetOutFun(op)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    if (this.Con_State[LineIndex] != 0) {
      scale = this.GetScaleLine(op, LineIndex)
      YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      OutIndex = 0
      for (; FrameCount > 0; FrameCount--) {
        tmp = this.GetVoltageDif(V, Src, Dst)
        result = scale * (OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2]) * YM)
        OutDat[OutIndex++] = result
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetFrameLineI (BufferIndex, Frame1, Frame2, op) {
    let V, OutDat, OutFun, tmp
    let OutIndex, FrameCount, FrameSize
    let Src, Dst, LineCount, LineIndex, ElmCount, FrameIndex
    let YM, scale, con_length
    OutFun = PFR_GetOutFun(op)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    LineCount = this.LineCount
    ElmCount = FrameCount * LineCount
    OutDat = new Float32Array(ElmCount)
    for (LineIndex = 0; LineIndex < LineCount; LineIndex++) {
      con_length = this.Con_Length[LineIndex]
      YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / con_length
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      scale = this.GetScaleLine(op, LineIndex)
      OutIndex = LineIndex
      for (FrameIndex = Frame1; FrameIndex <= Frame2; FrameIndex++) {
        tmp = this.GetVoltageDif(V, Src, Dst, scale)
        OutDat[OutIndex] = OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2]) * YM
        Src += FrameSize
        Dst += FrameSize
        OutIndex += LineCount
      }
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetLinePhaseLos (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex) {
    let V, OutDat, OutIndex, FrameCount, FrameSize, x, y
    let Src, Dst, scale
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    if (this.Con_State[LineIndex] != 0) {
      scale = this.Bus_Base[this.Con_Src[LineIndex]]
      scale = scale * scale
      if (PhaseIndex < PFR_3) {
        scale = scale * this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      } else {
        scale = scale * this.Cfg_Yr4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      }
      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)
      OutIndex = 0
      for (; FrameCount > 0; FrameCount--) {
        x = V[Src + PFR_0] - V[Dst + PFR_0]
        y = V[Src + PFR_1] - V[Dst + PFR_1]
        OutDat[OutIndex++] = scale * (x * x + y * y)
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetLineLos (BufferIndex, Frame1, Frame2, LineIndex) {
    let Src, Dst, OutIndex, FrameCount, FrameSize, cfg_index
    let V, OutDat
    let con_length, Base_V, scale1, scale4, d, sum, V2
    cfg_index = this.Con_Cfg[LineIndex]
    con_length = this.Con_Length[LineIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    if (this.Con_State[LineIndex] != 0) {
      Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
      scale1 = Base_V * Base_V * this.Cfg_Yr1[cfg_index] / con_length
      scale4 = Base_V * Base_V * this.Cfg_Yr4[cfg_index] / con_length
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      OutIndex = 0
      for (; FrameCount > 0; FrameCount--) {
        d = this.GetVoltageDif2(V, Src, Dst)
        V2 = d[PFR_0]
        V2 += d[PFR_1]
        V2 += d[PFR_2]
        sum = scale1 * V2 + scale4 * d[PFR_3] // Edited T
        OutDat[OutIndex++] = sum
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetFrameLos (BufferIndex, Frame1, Frame2) {
    let V, OutDat, OutIndex, FrameIndex, FrameCount, FrameSize, InIndex, sum
    let Src, Dst, LineCount, LineIndex, ElmCount, cfg_index
    let Base_V, scale1, scale4, d, V2, con_length
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    InIndex = Frame1 * FrameSize
    LineCount = this.LineCount
    ElmCount = FrameCount * LineCount
    OutDat = new Float32Array(ElmCount)
    OutIndex = 0
    for (FrameIndex = Frame1; FrameIndex <= Frame2; FrameIndex++) {
      for (LineIndex = 0; LineIndex < LineCount; LineIndex++) {
        cfg_index = this.Con_Cfg[LineIndex]
        con_length = this.Con_Length[LineIndex]
        Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
        scale1 = Base_V * Base_V * this.Cfg_Yr1[cfg_index] / con_length
        scale4 = Base_V * Base_V * this.Cfg_Yr4[cfg_index] / con_length
        Src = this.GetSrcOffset(FrameIndex, LineIndex, 0)
        Dst = this.GetDstOffset(FrameIndex, LineIndex, 0)
        d = this.GetVoltageDif2(V, Src, Dst)
        V2 = d[PFR_0]
        V2 += d[PFR_1]
        V2 += d[PFR_2]
        sum = scale1 * V2 + scale4 * d[PFR_3] // Edited T
        OutDat[OutIndex++] = sum
      }
      InIndex += FrameSize
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  // BM221225 : support single phase losses
  GetNetLos (BufferIndex, Frame1, Frame2, phase = 4) {
    let V, OutDat, OutIndex, FrameIndex, FrameCount, FrameSize
    let Base_V, d, con_length, sum
    const G = [0, 0, 0, 0]
    let Src, Dst, con_count, con_index, cfg_index
    FrameSize = this.FrameSize
    V = this.BufferList[BufferIndex]
    FrameCount = Frame2 - Frame1 + 1
    con_count = this.Con_Count
    OutDat = new Float32Array(FrameCount)
    for (con_index = 0; con_index < con_count; con_index++) {
      if (this.Con_State[con_index] != 0) {
        cfg_index = this.Con_Cfg[con_index]
        con_length = this.Con_Length[con_index]
        Base_V = this.Bus_Base[this.Con_Src[con_index]]
        G[0] = this.Cfg_Yr1[cfg_index] / con_length
        G[3] = this.Cfg_Yr4[cfg_index] / con_length
        G[1] = G[0]
        G[2] = G[0]
        OutIndex = 0
        Src = this.GetSrcOffset(Frame1, con_index, 0)
        Dst = this.GetDstOffset(Frame1, con_index, 0)
        for (FrameIndex = Frame1; FrameIndex <= Frame2; FrameIndex++) {
          d = this.GetVoltageDif2(V, Src, Dst, Base_V)
          if (phase < 4) {
            sum = G[phase] * d[phase]
          } else {
            sum		= G[PFR_0] * d[PFR_0]
            sum += G[PFR_1] * d[PFR_1]
            sum += G[PFR_2] * d[PFR_2]
            sum += G[PFR_3] * d[PFR_3]
          }
          OutDat[OutIndex] += (sum / 1000.0) // BM221225 : losses in kW
          Src = Src + FrameSize
          Dst = Dst + FrameSize
          OutIndex = OutIndex + 1
        }
      }
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetLineSig (V, Src, Dst, Y, scale, phase, mode) {
    let Yr, Yi, Vsr, Vsi, dVr, dVi, Ir, Ii, P, Q
    let Pt, Qt
    const out = [0, 0, 0, 0, 0, 0]
    let m, k, phase1, phase2
    if (phase < 4) {
      phase1 = phase
      phase2 = phase
    } else {
      phase1 = 0
      phase2 = 3
    }
    Pt = 0
    Qt = 0
    m = 2 * phase1
    for (k = phase1; k <= phase2; k++) {
      Yr = Y[m + PFR_0]
      Yi = Y[m + PFR_1]
      Vsr = V[Src + m + PFR_0] * scale
      Vsi = V[Src + m + PFR_1] * scale
      dVr = Vsr - (scale * V[Dst + m + PFR_0])
      dVi = Vsi - (scale * V[Dst + m + PFR_1])
      Ir = (dVr * Yr) - (dVi * Yi)
      Ii = (dVi * Yr) + (dVr * Yi)
      m = m + 2
      if (mode == PFR_FUN_I_REAL) // Real part current
      {
        out[k] = Ir
      } else if (mode == PFR_FUN_I_IMG) // imag part current
      {
        out[k] = Ii
      } else if (mode == PFR_FUN_I_MAG) // imag part current
      {
        out[k] = Math.sqrt((Ir * Ir) + (Ii * Ii))
      } else if (mode == PFR_FUN_P) // Active power
      {
        out[k] = ((Vsr * Ir) + (Vsi * Ii))
      } else if (mode == PFR_FUN_Q) // Reactive power
      {
        out[k] = ((Vsi * Ir) - (Vsr * Ii))
      } else // PFR_FUN_S apparent  power
      {
        P = ((Vsr * Ir) + (Vsi * Ii))
        Q = ((Vsi * Ir) - (Vsr * Ii))
        out[k] = Math.sqrt((P * P) + (Q * Q))
        Pt = Pt + P
        Qt = Qt + Q
      }
    }
    if (mode == PFR_FUN_S) {
      out[4] = Pt
      out[5] = Qt
    }
    return (out)
  }

  // ---------------------------------------------------------------------------
  GetLinePower (BufferIndex, Frame1, Frame2, LineIndex, phase, op, FunType) {
    let V, OutDat, Base_V, con_length
    let w, scale, sum, cfg_index
    const Y = [0, 0, 0, 0, 0, 0, 0, 0]
    let Src, Dst, OutIndex, FrameCount, FrameSize
    cfg_index = this.Con_Cfg[LineIndex]
    con_length = this.Con_Length[LineIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    if (this.Con_State[LineIndex] != 0) {
      Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
      if ((op & PFR_PU_MASK) != 0) {
        scale = 1.0 / this.Base_S
      } else {
        scale = 1.0 / PFR_1000
      }

      Y[PFR_0] = this.Cfg_Yr1[cfg_index] / con_length
      Y[PFR_1] = this.Cfg_Yi1[cfg_index] / con_length
      Y[PFR_2] = Y[PFR_0]
      Y[PFR_3] = Y[PFR_1]
      Y[PFR_4] = Y[PFR_0]
      Y[PFR_5] = Y[PFR_1]
      Y[PFR_6] = this.Cfg_Yr4[cfg_index] / con_length
      Y[PFR_7] = this.Cfg_Yi4[cfg_index] / con_length

      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      OutIndex = 0
      for (; FrameCount > 0; FrameCount--) {
        w = this.GetLineSig(V, Src, Dst, Y, Base_V, phase, FunType)
        if (phase < 4) {
          OutDat[OutIndex] = w[phase] * scale
        } else if (FunType == PFR_FUN_S) {
          sum = Math.sqrt((w[PFR_4] * w[PFR_4]) + (w[PFR_5] * w[PFR_5]))
          OutDat[OutIndex] = sum * scale
        } else {
          sum = w[PFR_0] + w[PFR_1] + w[PFR_2] + w[PFR_3]
          OutDat[OutIndex] = sum * scale
        }
        Src = Src + FrameSize
        Dst = Dst + FrameSize
        OutIndex = OutIndex + 1
      }
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetLineP (BufferIndex, Frame1, Frame2, LineIndex, phase, op) {
    let out
    out = this.GetLinePower(BufferIndex, Frame1,
      Frame2, LineIndex, phase, op, PFR_FUN_P)
    return (out)
  }

  // ---------------------------------------------------------------------------
  GetLineQ (BufferIndex, Frame1, Frame2, LineIndex, phase, op) {
    let out
    out = this.GetLinePower(BufferIndex, Frame1,
      Frame2, LineIndex, phase, op, PFR_FUN_Q)
    return (out)
  }

  // ---------------------------------------------------------------------------
  GetLineS (BufferIndex, Frame1, Frame2, LineIndex, phase, op) {
    let out
    out = this.GetLinePower(BufferIndex, Frame1,
      Frame2, LineIndex, phase, op, PFR_FUN_S)
    return (out)
  }

  // ---------------------------------------------------------------------------
  GetLineIr (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex) {
    let V, OutDat, OutIndex, FrameCount, FrameSize, cfg_index
    let Src, Dst, scale, Yr, Yi, VSr, VSi, dVr, dVi, con_length
    cfg_index = this.Con_Cfg[LineIndex]
    con_length = this.Con_Length[LineIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    if (this.Con_State[LineIndex] != 0) {
      PhaseIndex = PhaseIndex & 3
      if (PhaseIndex < PFR_3) {
        Yr = this.Cfg_Yr1[cfg_index] / con_length
        Yi = this.Cfg_Yi1[cfg_index] / con_length
      } else {
        Yr = this.Cfg_Yr4[cfg_index] / con_length
        Yi = this.Cfg_Yi4[cfg_index] / con_length
      }
      scale = this.Bus_Base[this.Con_Src[LineIndex]]
      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)
      OutIndex = 0
      for (; FrameCount > 0; FrameCount--) {
        VSr = V[Src]
        VSi = V[Src + PFR_1]
        dVr = VSr - V[Dst]
        dVi = VSi - V[Dst + PFR_1]
        OutDat[OutIndex++] = (dVr * Yr - dVi * Yi) * scale
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  GetLineIi (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex) {
    let V, OutDat, OutIndex, FrameCount, FrameSize, cfg_index
    let Src, Dst, scale, Yr, Yi, VSr, VSi, dVr, dVi, con_length
    cfg_index = this.Con_Cfg[LineIndex]
    con_length = this.Con_Length[LineIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    if (this.Con_State[LineIndex] != 0) {
      PhaseIndex = PhaseIndex & 3
      if (PhaseIndex < PFR_3) {
        Yr = this.Cfg_Yr1[cfg_index] / con_length
        Yi = this.Cfg_Yi1[cfg_index] / con_length
      } else {
        Yr = this.Cfg_Yr4[cfg_index] / con_length
        Yi = this.Cfg_Yi4[cfg_index] / con_length
      }
      scale = this.Bus_Base[this.Con_Src[LineIndex]]
      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)
      OutIndex = 0
      for (; FrameCount > 0; FrameCount--) {
        VSr = V[Src]
        VSi = V[Src + PFR_1]
        dVr = VSr - V[Dst]
        dVi = VSi - V[Dst + PFR_1]
        OutDat[OutIndex++] = (dVi * Yr + dVr * Yi) * scale
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  UnbalanceFactor (Ar, Ai, Br, Bi, Cr, Ci) {
    let PosRel, PosImg, NegRel, NegImg, PosAbs, NegAbs, result
    const x = -0.5
    const y = 0.866025403784439
    PosRel = Ar + (x * Br - y * Bi) + (x * Cr + y * Ci)
    PosImg = Ai + (x * Bi + y * Br) + (x * Ci - y * Cr)
    NegRel = Ar + (x * Br + y * Bi) + (x * Cr - y * Ci)
    NegImg = Ai + (x * Bi - y * Br) + (x * Ci + y * Cr)
    PosAbs = PosRel * PosRel + PosImg * PosImg
    NegAbs = NegRel * NegRel + NegImg * NegImg

    if (PosAbs < PFR_POS_TOL) {
      result = 0
    } else {
      result = PFR_100 * Math.sqrt(NegAbs / PosAbs)
    }
    return (result)
  }

  // ---------------------------------------------------------------------------
  UnbalanceFactorLine (V, Yr, Yi, Src, Dst, scale = 1.0) {
    let dVr, dVi, Ar, Ai, Br, Bi, Cr, Ci, result

    dVr = (scale * V[Src + PFR_0]) - (scale * V[Dst + PFR_0])
    dVi = (scale * V[Src + PFR_1]) - (scale * V[Dst + PFR_1])
    Ar = (dVr * Yr - dVi * Yi)
    Ai = (dVi * Yr + dVr * Yi)

    dVr = (scale * V[Src + PFR_2]) - (scale * V[Dst + PFR_2])
    dVi = (scale * V[Src + PFR_3]) - (scale * V[Dst + PFR_3])
    Br = (dVr * Yr - dVi * Yi)
    Bi = (dVi * Yr + dVr * Yi)

    dVr = (scale * V[Src + PFR_4]) - (scale * V[Dst + PFR_4])
    dVi = (scale * V[Src + PFR_5]) - (scale * V[Dst + PFR_5])
    Cr = (dVr * Yr - dVi * Yi)
    Ci = (dVi * Yr + dVr * Yi)
    result = this.UnbalanceFactor(Ar, Ai, Br, Bi, Cr, Ci)
    return (result)
  }

  // ---------------------------------------------------------------------------
  UnbalanceFactorBus (V, InIndex, base_v = 1.0) {
    let Ar, Ai, Br, Bi, Cr, Ci, result
    Ar = base_v * V[InIndex + PFR_0]
    Ai = base_v * V[InIndex + PFR_1]
    Br = base_v * V[InIndex + PFR_2]
    Bi = base_v * V[InIndex + PFR_3]
    Cr = base_v * V[InIndex + PFR_4]
    Ci = base_v * V[InIndex + PFR_5]
    result = this.UnbalanceFactor(Ar, Ai, Br, Bi, Cr, Ci)
    return (result)
  }

  //= ====================================================================
  // Unbalance factor of the bus voltage
  GetBusVu (BufferIndex, Frame1, Frame2, BusIndex) {
    let V, OutDat, OutIndex, FrameCount, FrameSize, InIndex
    let base_v
    base_v = this.Bus_Base[BusIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    OutIndex = 0
    for (; FrameCount > 0; FrameCount--) {
      OutDat[OutIndex++] = this.UnbalanceFactorBus(V, InIndex, base_v)
      InIndex = InIndex + FrameSize
    }
    return (OutDat)
  }

  //= ====================================================================
  // Unbalanced voltage factor at each CGP point
  GetCgpBusVu (BufferIndex, Frame1, Frame2, CgpIndex) {
    let V, OutDat, OutIndex, FrameCount, FrameSize, InIndex
    let BusIndex
    let base_v
    BusIndex = this.CGP_Bus[CgpIndex]
    base_v = this.Bus_Base[BusIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    OutIndex = 0
    for (; FrameCount > 0; FrameCount--) {
      OutDat[OutIndex++] = this.UnbalanceFactorBus(V, InIndex, base_v)
      InIndex = InIndex + FrameSize
    }
    return (OutDat)
  }

  //= ==================================
  // Unbalanced voltage factor at each CT secondary bus
  GetCtBusVu (BufferIndex, Frame1, Frame2, CtIndex, RefSide) // MM: include new input RefSide
  {
    let V, OutDat, OutIndex, FrameCount, FrameSize, InIndex
    let BusIndex, WdIndex
    let base_v
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[WdIndex]
    base_v = this.Bus_Base[BusIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    OutIndex = 0
    for (; FrameCount > 0; FrameCount--) {
      OutDat[OutIndex++] = this.UnbalanceFactorBus(V, InIndex, base_v)
      InIndex = InIndex + FrameSize
    }
    return (OutDat)
  }

  //= ==================================
  // Unbalanced Injection current at CGP point
  GetCgpInjIu (BufferIndex, Frame1, Frame2, CgpIndex) {
    let OutDat, OutIndex, FrameCount, InIndex, BusIndex, scale
    let Ar, Ai, Br, Bi, Cr, Ci, I
    FrameCount = Frame2 - Frame1 + 1
    BusIndex = this.CGP_Bus[CgpIndex]
    scale = 1.0 // BM221224 : update current buffer no need for "this.Bus_Base[BusIndex];"
    OutDat = new Float32Array(FrameCount)
    OutIndex = 0
    InIndex = 0
    I			= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    this.GetInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetCtInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    for (; FrameCount > 0; FrameCount--) {
      Ar = I[InIndex++] * scale // Iar
      Ai = I[InIndex++] * scale // Iai
      Br = I[InIndex++] * scale // Ibr
      Bi = I[InIndex++] * scale // Ibi
      Cr = I[InIndex++] * scale // Icr
      Ci = I[InIndex++] * scale // Ici
      OutDat[OutIndex++] = this.UnbalanceFactor(Ar, Ai, Br, Bi, Cr, Ci)
      InIndex = InIndex + PFR_2 // to jump neutral line current
    }
    return (OutDat)
  }

  //= ==================================
  // Unbalanced Injection current at secondary bus of CT
  GetCtInjIu (BufferIndex, Frame1, Frame2, CtIndex, RefSide) // MM: include new input RefSide
  {
    let OutDat, OutIndex, FrameCount, InIndex
    let Ar, Ai, Br, Bi, Cr, Ci, I, BusIndex, WdIndex
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    OutIndex = 0
    InIndex = 0
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[WdIndex]
    I	= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    this.AddCtCurrent(BufferIndex, Frame1, Frame2, CtIndex, BusIndex, I) // Direction 0 = Ip, 1 = Is
    for (; FrameCount > 0; FrameCount--) {
      Ar = I[InIndex++] // Iar
      Ai = I[InIndex++] // Iai
      Br = I[InIndex++] // Ibr
      Bi = I[InIndex++] // Ibi
      Cr = I[InIndex++] // Icr
      Ci = I[InIndex++] // Ici
      OutDat[OutIndex++] = this.UnbalanceFactor(Ar, Ai, Br, Bi, Cr, Ci)
      InIndex = InIndex + PFR_2 // to jump neutral line current
    }
    return (OutDat)
  }

  //= ==================================TT
  // ---------------------------------------------------------------------------
  // Unbalance factor of the line current
  GetLineIu (BufferIndex, Frame1, Frame2, LineIndex) {
    let Src, Dst, OutIndex, FrameCount, FrameSize, cfg_index
    let V, OutDat, Yr, Yi, Base_V, con_length, uf
    cfg_index = this.Con_Cfg[LineIndex]
    con_length = this.Con_Length[LineIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    if (this.Con_State[LineIndex] != 0) {
      Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
      Yr = this.Cfg_Yr1[cfg_index] / con_length
      Yi = this.Cfg_Yi1[cfg_index] / con_length
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      OutIndex = 0
      for (; FrameCount > 0; FrameCount--) {
        uf = this.UnbalanceFactorLine(V, Yr, Yi, Src, Dst, Base_V)
        OutDat[OutIndex++] = uf
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // GetSwitchIndex(ID)
  // 	{
  // 	let ListIndex, ListCount;
  // 	ListCount = this.SwitchCount;
  // 	for (ListIndex = 0; ListIndex < ListCount; ListIndex++)
  // 		{
  // 		if (this.Con_ID[ListIndex] == ID)
  // 			{
  // 			return(ListIndex);
  // 			}
  // 		}
  // 	return(-1);
  // 	}
  // ---------------------------------------------------------------------------
  GetCgpIndex (ID) {
    let ListIndex, ListCount
    ListCount = this.CgpCount
    for (ListIndex = 0; ListIndex < ListCount; ListIndex++) {
      if (this.CGP_ID[ListIndex] == ID) {
        return (ListIndex)
      }
    }
    return (-1)
  }

  // ---------------------------------------------------------------------------
  GetCGP2BusIndex (ID) {
    let CgpIndex, BusIndex
    CgpIndex = this.GetCgpIndex(ID)
    if (CgpIndex < 0) {
      return (-1)
    }
    BusIndex = this.CGP_Bus[CgpIndex]
    return (BusIndex)
  }

  // ---------------------------------------------------------------------------
  // BM : update the function to use Transformer ID
  GetCtIndex (Trans_ID) {
    let k, m, n
    m = this.CT_count
    for (k = 0; ((k < m) && (this.CT_ID[k] != Trans_ID)); k++) {
      // NO CODE
    }
    if (k < m) {
      n = k
    } else {
      n = -1
    }
    return (n)
  }

  // ---------------------------------------------------------------------------
  GetVsIndex (ID) {
    let ListIndex, ListCount
    ListCount = this.VS_count
    for (ListIndex = 0; ListIndex < ListCount; ListIndex++) {
      if (this.VS_ID[ListIndex] == ID) {
        return (ListIndex)
      }
    }
    return (-1)
  }// ---------------------------------------------------------------------------

  // ========================================================================
  AddElement (ElmCount, data_list, data_new) {
    let k
    for (k = 0; (k < ElmCount) && (data_list[k] != data_new); k++) {
      // NO CODE
    }
    if (k == ElmCount) {
      data_list[ElmCount++] = data_new
    } else {
      // NO CODE
    }
    return (ElmCount)
  }

  // ---------------------------------------------------------------------------
  Src2Dst (BusIndex) {
    let k
    for (k = 0; k < this.Con_Count; k++) {
      if ((this.Con_Src[k] == BusIndex) && (this.Con_State[k] != 0)) {
        this.WD_bus_list_size = this.AddElement(this.WD_bus_list_size,
          this.WD_bus_list, this.Con_Dst[k]) // Add element if not already added
        this.WD_con_list_size = this.AddElement(this.WD_con_list_size,
          this.WD_con_list, k) // Add element if not already added
      } else if ((this.Con_Dst[k] == BusIndex) && (this.Con_State[k] != 0)) {
        this.WD_bus_list_size = this.AddElement(this.WD_bus_list_size,
          this.WD_bus_list, this.Con_Src[k]) // Add element if not already added
        this.WD_con_list_size = this.AddElement(this.WD_con_list_size,
          this.WD_con_list, k) // Add element if not already added
      } else {
        /* NO CODE */
      }
    }
    return (0)
  }

  // ========================================================================
  MakeCgpList () {
    let WindingIndex, WindingCount, CgpCount, k, k1, k2, CgpIndex
    let BusIndex, WdCgpIndex
    WindingCount = this.CT_winding_first[this.CT_count]
    CgpCount	= this.CgpCount
    WdCgpIndex = 0
    for (WindingIndex = 0; WindingIndex < WindingCount; WindingIndex++) {
      this.WD_CgpFirst[WindingIndex] = WdCgpIndex
      k1 = this.WD_bus_first[WindingIndex]
      k2 = this.WD_bus_first[WindingIndex + PFR_1]
      for (k = k1; k < k2; k++) {
        BusIndex = this.WD_bus_list[k]
        for (CgpIndex = 0; CgpIndex < CgpCount; CgpIndex++) {
          if (this.CGP_Bus[CgpIndex] == BusIndex) {
            this.WD_CGP[WdCgpIndex++] = CgpIndex
          }
        }
      }
    }
    this.WD_CgpFirst[WindingIndex] = WdCgpIndex
  }

  // ========================================================================
  AllocCGP () {
    let WindingCount, m, BusIndex, WindingIndex
    WindingCount = this.CT_winding_first[this.CT_count]
    m = 0
    this.WD_bus_list_size			= 0
    this.WD_con_list_size			= 0
    this.WD_bus_first[PFR_0]		= 0
    for (WindingIndex = 0; WindingIndex < WindingCount; WindingIndex++) {
      BusIndex = this.CT_bus[WindingIndex]
      this.WD_bus_first[WindingIndex]			= this.WD_bus_list_size
      this.WD_con_first[WindingIndex]			= this.WD_con_list_size
      if (this.Bus_Base[BusIndex] < PFR_1000) {
        this.WD_bus_list[this.WD_bus_list_size++] = BusIndex
        for (; m < this.WD_bus_list_size; m++) {
          this.Src2Dst(this.WD_bus_list[m])
        }
      }
    }
    this.WD_bus_first[WindingIndex]			= this.WD_bus_list_size
    this.WD_con_first[WindingIndex]			= this.WD_con_list_size
    this.MakeCgpList()
  }

  // ---------------------------------------------------------------------------
  GetBusPQ (BufferIndex, Frame1, Frame2, BusIndex) {
    let I, PQ, FrameCount
    FrameCount = Frame2 - Frame1 + PFR_1
    I		= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    PQ	= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    this.GetInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetCtInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetInjPower(BufferIndex, Frame1, Frame2, BusIndex, I, PQ)
    return (PQ)
  }

  // ---------------------------------------------------------------------------
  GetCtPQ (BufferIndex, Frame1, Frame2, CtIndex, RefSide) {
    let I, PQ, FrameCount
    FrameCount = Frame2 - Frame1 + PFR_1
    I		= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    PQ	= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    this.GetCtPQinj(BufferIndex, Frame1, Frame2, CtIndex, RefSide, I, PQ)
    return (PQ)
  }

  // ---------------------------------------------------------------------------
  AddConCurrent (BufferIndex, Frame1, Frame2, con_index, RefBus, I) {
    let FrameIndex, Index, cfg_index, FrameSize, Src, Dst, scale
    let Ypr, Ypi, Ynr, Yni, dVr, dVi, V, con_length
    V = this.BufferList[BufferIndex]
    if (this.Con_State[con_index] != 0) // connection is on
    {
      Src = this.Con_Src[con_index]
      Dst = this.Con_Dst[con_index]
      if (RefBus == Src)			// Output from the node
      {
        scale = this.Bus_Base[Src]
      } else if (RefBus == Dst)	// Input to the node
      {
        scale = -this.Bus_Base[Src]
      } else // No flow
      {
        scale = 0
      }
    } else {
      scale = 0
    }
    if (scale != 0) {
      con_length = this.Con_Length[con_index]
      cfg_index = this.Con_Cfg[con_index]
      Ypr = scale * this.Cfg_Yr1[cfg_index] / con_length
      Ypi = scale * this.Cfg_Yi1[cfg_index] / con_length
      Ynr = scale * this.Cfg_Yr4[cfg_index] / con_length
      Yni = scale * this.Cfg_Yi4[cfg_index] / con_length
      FrameSize = this.FrameSize

      Index = 0
      Src = (Frame1 * this.FrameSize) + (PFR_NUM_PER_BUS * Src)
      Dst = (Frame1 * this.FrameSize) + (PFR_NUM_PER_BUS * Dst)
      for (FrameIndex = Frame1; FrameIndex <= Frame2; FrameIndex++) {
        dVr = V[Src + PFR_0] - V[Dst + PFR_0]
        dVi = V[Src + PFR_1] - V[Dst + PFR_1]
        I[Index++] += (dVr * Ypr - dVi * Ypi)
        I[Index++] += (dVi * Ypr + dVr * Ypi)
        dVr = V[Src + PFR_2] - V[Dst + PFR_2]
        dVi = V[Src + PFR_3] - V[Dst + PFR_3]
        I[Index++] += (dVr * Ypr - dVi * Ypi)
        I[Index++] += (dVi * Ypr + dVr * Ypi)
        dVr = V[Src + PFR_4] - V[Dst + PFR_4]
        dVi = V[Src + PFR_5] - V[Dst + PFR_5]
        I[Index++] += (dVr * Ypr - dVi * Ypi)
        I[Index++] += (dVi * Ypr + dVr * Ypi)
        dVr = V[Src + PFR_6] - V[Dst + PFR_6]
        dVi = V[Src + PFR_7] - V[Dst + PFR_7]
        I[Index++] += (dVr * Ynr - dVi * Yni)
        I[Index++] += (dVi * Ynr + dVr * Yni)
        Src += FrameSize
        Dst += FrameSize
      }
    }
  }

  // ---------------------------------------------------------------------------
  AddCurrent (FrameDef, Src, Dst, RefBus, Y, I) {
    let FrameIndex, Index, dVr, dVi, FrameSize
    let Ypr, Ypi, Ynr, Yni, flow_sign, V, Frame1, Frame2
    if (RefBus == Src)			// Output from the node
    {
      flow_sign = 1.0
    } else if (RefBus == Dst)	// Input to the node
    {
      flow_sign = -1.0
    } else // No flow
    {
      return
    }
    V = FrameDef.V
    Frame1 = FrameDef.Frame1
    Frame2 = FrameDef.Frame2
    Ypr = Y[PFR_0]
    Ypi = Y[PFR_1]
    Ynr = Y[PFR_2]
    Yni = Y[PFR_3]
    FrameSize = this.FrameSize
    Index = 0
    Src = (Frame1 * this.FrameSize) + (PFR_NUM_PER_BUS * Src)
    Dst = (Frame1 * this.FrameSize) + (PFR_NUM_PER_BUS * Dst)
    for (FrameIndex = Frame1; FrameIndex <= Frame2; FrameIndex++) {
      dVr = V[Src + PFR_0] - V[Dst + PFR_0]
      dVi = V[Src + PFR_1] - V[Dst + PFR_1]
      I[Index++] += (dVr * Ypr - dVi * Ypi) * flow_sign
      I[Index++] += (dVi * Ypr + dVr * Ypi) * flow_sign
      dVr = V[Src + PFR_2] - V[Dst + PFR_2]
      dVi = V[Src + PFR_3] - V[Dst + PFR_3]
      I[Index++] += (dVr * Ypr - dVi * Ypi) * flow_sign
      I[Index++] += (dVi * Ypr + dVr * Ypi) * flow_sign
      dVr = V[Src + PFR_4] - V[Dst + PFR_4]
      dVi = V[Src + PFR_5] - V[Dst + PFR_5]
      I[Index++] += (dVr * Ypr - dVi * Ypi) * flow_sign
      I[Index++] += (dVi * Ypr + dVr * Ypi) * flow_sign
      dVr = V[Src + PFR_6] - V[Dst + PFR_6]
      dVi = V[Src + PFR_7] - V[Dst + PFR_7]
      I[Index++] += (dVr * Ynr - dVi * Yni) * flow_sign
      I[Index++] += (dVi * Ynr + dVr * Yni) * flow_sign
      Src += FrameSize
      Dst += FrameSize
    }
  }

  // ---------------------------------------------------------------------------
  GetInjCurrent (BufferIndex, Frame1, Frame2, BusIndex, I) {
    let k
    I.fill(0.0) /* clear all elements to zero */
    for (k = 0; k < this.Con_Count; k++) {
      this.AddConCurrent(BufferIndex, Frame1, Frame2, k, BusIndex, I)
    }
  }

  // ---------------------------------------------------------------------------
  GetCtInjCurrent (BufferIndex, Frame1, Frame2, BusIndex, I) {
    let k
    for (k = 0; k < this.CT_count; k++) {
      this.AddCtCurrent(BufferIndex, Frame1, Frame2, k, BusIndex, I)
    }
  }

  // ---------------------------------------------------------------------------
  GetInjPower (BufferIndex, Frame1, Frame2, BusIndex, I, PQ) {
    let OutIndex, FrameCount, FrameSize, k, FrameIndex
    let scale, V, V_offset, I_offset, Vd, Vq, Id, Iq
    V = this.BufferList[BufferIndex]

    scale = this.Bus_Base[BusIndex] / PFR_1000
    FrameSize		= this.FrameSize
    FrameCount 	= Frame2 - Frame1 + 1
    // ---------------------------------------------------------------------------
    V_offset = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    I_offset = 0
    OutIndex = 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      for (k = 0; k < PFR_8; k += PFR_2) // BM221214 : include the neutral phase
      {
        Vd = V[V_offset + k + PFR_0]
        Vq = V[V_offset + k + PFR_1]
        Id = I[I_offset + k + PFR_0]
        Iq = I[I_offset + k + PFR_1]
        PQ[OutIndex++] = -1.0 * scale * (Vd * Id + Vq * Iq)
        PQ[OutIndex++] = -1.0 * scale * (Vq * Id - Vd * Iq)
      }
      V_offset = V_offset + FrameSize
      I_offset = I_offset + PFR_NUM_PER_BUS
    }
  }

  // ---------------------------------------------------------------------------
  GetCtPQinj (BufferIndex, Frame1, Frame2, CtIndex, RefSide, I, PQ) {
    let BusIndex, WdIndex
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[WdIndex]
    I.fill(0.0) /* clear all elements to zero */
    this.AddCtCurrent(BufferIndex, Frame1, Frame2, CtIndex, BusIndex, I)
    this.GetInjPower(BufferIndex, Frame1, Frame2, BusIndex, I, PQ)
  }

  // ---------------------------------------------------------------------------
  GetMeanLineIu (BufferIndex, Frame1, Frame2, LineIndex) {
    /**
		* Line Mean unbalance current factor.
		*
		* For every simulation instant the unbalance current factor is calculated and then a mean from all
		* these values is computed.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @return {double} 	 Return the mean unbalance current factor in %.
		*/
    let k, frame_count
    let avg
    let out
    out = this.GetLineIu(BufferIndex, Frame1, Frame2, LineIndex)
    frame_count = Frame2 - Frame1 + 1
    avg = 0.0
    for (k = 0; k < frame_count; k++) {
      avg = avg + out[k]
    }
    if (frame_count > 0) {
      avg = avg / frame_count
    } else {
      avg = 0.0
    }
    return (avg)
  }

  // ---------------------------------------------------------------------------
  GetRateLineIu (BufferIndex, Frame1, Frame2, LineIndex, array) {
    /**
		* Line Unbalance current rate.
		*
		* The percentage of time that the current is in unbalanced conditions.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that the current is in unbalanced conditions in %
		*						and the number of instants of violations. In addition it fill the array input
		*						with the indices of violation instants
		*/
    let k, frame_count, rate
    let NegSeqMax, percent
    let out

    NegSeqMax = this.NegSeqMax
    out = this.GetLineIu(BufferIndex, Frame1, Frame2, LineIndex)
    rate = 0
    frame_count = Frame2 - Frame1 + 1
    for (k = 0; k < frame_count; k++) {
      if (out[k] > NegSeqMax) {
        array[rate++] = (Frame1 + k)
      }
    }
    if (frame_count > 0) {
      percent = (PFR_100 * rate) / frame_count
    } else {
      percent = 0.0
    }
    return ([percent, rate])
  }

  // ---------------------------------------------------------------------------
  GetLinePhaseImax (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex) {
    /**
		* Phase Maximum Current.
		*
		* Maximum Current passed in the Phase in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral.
		* @return {double} 	 Return one output; Maximum Current in A.
		*/
    let V, FrameCount, FrameSize, x, y, YM
    let Src, Dst, scale, I_max, Line_I
    scale = this.Bus_Base[this.Con_Src[LineIndex]]
    I_max = 0
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      if (PhaseIndex < PFR_3) {
        YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      } else {
        YM = this.Cfg_YM4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      }
      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)
      for (; FrameCount > 0; FrameCount--) {
        x = V[Src + 0] - V[Dst + 0]
        y = V[Src + PFR_1] - V[Dst + PFR_1]
        Line_I = Math.sqrt(x * x + y * y) * YM
        if (Line_I > I_max) {
          I_max = Line_I
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (scale * I_max)
  }

  // ---------------------------------------------------------------------------
  GetLinePhaseImin (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex) {
    /**
		* Phase Minimum Current.
		*
		* Minimum Current passed in the Phase in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral.
		* @return {double} 	 Return one output; Minimum Current in A.
		*/
    let V, FrameCount, FrameSize, x, y
    let Src, Dst, scale, I_min, Line_I, YM
    I_min = PFR_BIG_NUM
    scale = this.Bus_Base[this.Con_Src[LineIndex]]
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      if (PhaseIndex < PFR_3) {
        YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      } else {
        YM = this.Cfg_YM4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      }
      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)
      for (; FrameCount > 0; FrameCount--) {
        x = V[Src + 0] - V[Dst + 0]
        y = V[Src + PFR_1] - V[Dst + PFR_1]
        Line_I = Math.sqrt(x * x + y * y) * YM
        if (Line_I < I_min) {
          I_min = Line_I
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (scale * I_min)
  }

  // ---------------------------------------------------------------------------
  GetLinePhaseIavg (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex) {
    /**
		* Phase Average Current.
		*
		* Average Current passed in the Phase in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral.
		* @return {double} 	 Return one output; Average Current in A.
		*/
    let V, FrameCount, FrameSize, x, y
    let Src, Dst, scale, Line_I, count, YM
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    scale = this.Bus_Base[this.Con_Src[LineIndex]]
    Line_I = 0
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      if (PhaseIndex < PFR_3) {
        YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      } else {
        YM = this.Cfg_YM4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      }
      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)

      for (; FrameCount > 0; FrameCount--) {
        x = V[Src + 0] - V[Dst + 0]
        y = V[Src + PFR_1] - V[Dst + PFR_1]
        Line_I += Math.sqrt(x * x + y * y) * YM
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (scale * Line_I / count)
  }

  // ---------------------------------------------------------------------------
  GetLineImax (BufferIndex, Frame1, Frame2, LineIndex, op) {
    /**
		* Line Maximum Current.
		*
		* Maximum Current passed in the line in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   op - it decides what result of phases to take and the form of the output; the required results is the summation of:
		*						 0 for minimum, 1 for maximum, or 2 for average + 0 for actual value and 256 for pu.
		* @return {double} 	 Return one output; Maximum Current in A.
		*/
    let V, FrameCount, FrameSize, YM
    let Src, Dst, tmp, OutFun, scale, Line_I, I_max
    scale = this.GetScaleLine(op, LineIndex)
    I_max = 0
    if (this.Con_State[LineIndex] != 0) {
      OutFun = PFR_GetOutFun(op)
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)

      for (; FrameCount > 0; FrameCount--) {
        tmp = this.GetVoltageDif(V, Src, Dst)
        Line_I = (OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2]) * YM)
        if (Line_I > I_max) {
          I_max = Line_I
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (scale * I_max)
  }

  // ---------------------------------------------------------------------------
  GetLineImin (BufferIndex, Frame1, Frame2, LineIndex, op) {
    /**
		* Line Minimum Current.
		*
		* Minimum Current passed in the line in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   op - it decides what result to take; e.g. maximum, minimum, or mean of phases. 0 for minimum,
		* 						1 for maximum, or 2 for average.
		* @return {double} 	 Return one output; Minimum Current in A.
		*/
    let V, FrameCount, FrameSize, YM
    let Src, Dst, tmp, OutFun, scale, Line_I, I_min
    scale = this.Bus_Base[this.Con_Src[LineIndex]]
    I_min = PFR_BIG_NUM
    if (this.Con_State[LineIndex] != 0) {
      OutFun = PFR_GetOutFun(op)
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)

      for (; FrameCount > 0; FrameCount--) {
        tmp = this.GetVoltageDif(V, Src, Dst)
        Line_I = (OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2]) * YM)
        if (Line_I < I_min) {
          I_min = Line_I
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (scale * I_min)
  }

  // ---------------------------------------------------------------------------
  GetLineIavg (BufferIndex, Frame1, Frame2, LineIndex, op) {
    /**
		* Line Average Current.
		*
		* Average Current passed in the line in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   op - it decides what result to take; e.g. maximum, minimum, or mean of phases. 0 for minimum,
		* 						1 for maximum, or 2 for average.
		* @return {double} 	 Return one output; Average Current in A.
		*/
    let V, FrameCount, FrameSize, YM
    let Src, Dst, tmp, OutFun, scale, Line_I, count
    Line_I = 0
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    scale = this.Bus_Base[this.Con_Src[LineIndex]]
    if (this.Con_State[LineIndex] != 0) {
      OutFun = PFR_GetOutFun(op)
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)

      for (; FrameCount > 0; FrameCount--) {
        tmp = this.GetVoltageDif(V, Src, Dst)
        Line_I += (OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2]) * YM)
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (scale * Line_I / count)
  }

  // ---------------------------------------------------------------------------
  GetLineEnergyLos (BufferIndex, Frame1, Frame2, LineIndex) {
    /**
		* Line Energy losses.
		*
		* The energy losses in the line in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @return {double} 	 Return The energy losses in the line in KWh.
		*/
    let V, FrameCount, FrameSize, d, sum
    let Src, Dst, Base_V, scale1, scale4, StepTime
    StepTime = this.StepTime / PFR_3600
    sum = 0
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
      scale1 = Base_V * Base_V * this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      scale4 = Base_V * Base_V * this.Cfg_Yr4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)

      for (; FrameCount > 0; FrameCount--) {
        d = this.GetVoltageDif2(V, Src, Dst)
        sum += scale1 * (d[PFR_0] + d[PFR_1] + d[PFR_2])
        sum += scale4 * d[PFR_3]
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (sum * StepTime / PFR_1000)
  }

  // ---------------------------------------------------------------------------
  GetLinePhaseEnergyLos (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex) {
    /**
		* Phase Energy losses.
		*
		* The energy losses in the Phase in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @return {double} 	 Return The energy losses in the Phase in KWh.
		*/
    let V, FrameCount, FrameSize, x, y
    let Src, Dst, Base_V, scale, sum, StepTime
    StepTime = this.StepTime / PFR_3600
    sum = 0
    scale = 0
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
      if (PhaseIndex < PFR_3) {
        scale = Base_V * Base_V * this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      } else {
        scale = Base_V * Base_V * this.Cfg_Yr4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      }
      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)
      for (; FrameCount > 0; FrameCount--) {
        x = V[Src + 0] - V[Dst + 0]
        y = V[Src + PFR_1] - V[Dst + PFR_1]
        sum += (x * x + y * y)
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return ((scale * sum * StepTime) / PFR_1000)
  }

  // ---------------------------------------------------------------------------
  GetLinePhaseOverLoad (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex, array) {
    /**
		* Line Phase Overload rate.
		*
		* The percentage of time that the Line is in overloading conditions (Inominal < I < Imax ).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that the Line is in overloading conditions in %
		* and the number of instants of violations. In addition it fill the input array with the indices of violation instants.
		*/
    let V, FrameCount, FrameSize, x, y, YM
    let Src, Dst, scale, rate, Line_I, count, FrameIndex
    let	I_nom, I_max
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    rate = 0
    if (this.Con_State[LineIndex] != 0) {
      if (PhaseIndex < PFR_3) {
        I_nom = this.Con_NomI1[LineIndex]
        YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      } else {
        I_nom = this.Con_NomI4[LineIndex]
        YM = this.Cfg_YM4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      }
      I_max = this.ImaxRatio * I_nom
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      scale = this.Bus_Base[this.Con_Src[LineIndex]]
      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)

      for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
        x = V[Src + 0] - V[Dst + 0]
        y = V[Src + PFR_1] - V[Dst + PFR_1]
        Line_I = scale * Math.sqrt(x * x + y * y) * YM
        if ((Line_I >= I_nom) && (Line_I <= I_max)) {
          array[rate++] = FrameIndex
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetLinePhaseOverUti (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex, array1, array2) {
    /**
		* Line Phase Over-utilization rate.
		*
		* The percentage of time that the Line is beyond its overload capacity ( I > Imax ).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param { array }   array1 - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @param { array }   array2 - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the Line is beyond its overload capacity in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants. And it fills
		* a second array with pu values of violation.
		*/
    let V, FrameCount, FrameSize, x, y, YM
    let Src, Dst, scale, rate, Line_I, count
    let	I_nom, I_max, FrameIndex
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    rate = 0
    if (this.Con_State[LineIndex] != 0) {
      if (PhaseIndex < PFR_3) {
        I_nom	= this.Con_NomI1[LineIndex]
        YM		= this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      } else {
        I_nom	= this.Con_NomI4[LineIndex]
        YM		= this.Cfg_YM4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      }
      I_max = this.ImaxRatio * I_nom
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      scale = this.Bus_Base[this.Con_Src[LineIndex]]
      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)

      for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
        x = V[Src + 0] - V[Dst + 0]
        y = V[Src + PFR_1] - V[Dst + PFR_1]
        Line_I = scale * Math.sqrt(x * x + y * y) * YM
        if (Line_I > I_max) {
          array1[rate] = FrameIndex
          array2[rate++] = Line_I / I_nom
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetLineOverLoad (BufferIndex, Frame1, Frame2, LineIndex, op, array) {
    /**
		* Line Overload rate.
		*
		* The percentage of time that the Line is in overloading conditions (Inominal < I < Imax ).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   op - it decides what result to take; e.g. maximum, minimum, or mean of phases. 0 for minimum,
		* 						1 for maximum, or 2 for average.
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the Line is in overloading conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants.
		*/
    let V, FrameCount, FrameSize, YM
    let Src, Dst, tmp, OutFun, scale, Line_I, rate, count
    let I_nom, I_max, FrameIndex
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    rate = 0
    if (this.Con_State[LineIndex] != 0) {
      I_nom = this.Con_NomI1[LineIndex]
      I_max = this.ImaxRatio * I_nom
      OutFun = PFR_GetOutFun(op)
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      scale = this.Bus_Base[this.Con_Src[LineIndex]]
      YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
        tmp = this.GetVoltageDif(V, Src, Dst)
        Line_I = scale * (OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2]) * YM)
        if ((Line_I > I_nom) && (Line_I < I_max)) {
          array[rate++] = FrameIndex
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetLineOverUti (BufferIndex, Frame1, Frame2, LineIndex, op, array) {
    /**
		* Line Over-utilization rate.
		*
		* The percentage of time that the Line is beyond its overload capacity ( I > Imax ).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   op - it decides what result to take; e.g. maximum, minimum, or mean of phases. 0 for minimum,
		* 						1 for maximum, or 2 for average.
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the Line is beyond its overload capacity in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants.
		*/
    let V, FrameCount, FrameSize, YM
    let Src, Dst, tmp, OutFun, scale, Line_I, rate, count
    let	I_nom, I_max, FrameIndex
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    rate = 0
    if (this.Con_State[LineIndex] != 0) {
      I_nom = this.Con_NomI1[LineIndex]
      I_max = this.ImaxRatio * I_nom
      OutFun = PFR_GetOutFun(op)
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      scale = this.Bus_Base[this.Con_Src[LineIndex]]
      YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
        tmp = this.GetVoltageDif(V, Src, Dst)
        Line_I = scale * (OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2]) * YM)
        if (Line_I > I_max) {
          array[rate++] = FrameIndex
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetLineCapFac (BufferIndex, Frame1, Frame2, LineIndex, op) {
    /**
		* Line Capacity factor.
		*
		* The mean conductor current over the nominal current which the conductor is designed for. This gives an idea about the degree of utilization of the wire..
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   op - it decides what result to take; e.g. maximum, minimum, or mean of phases. 0 for minimum,
		* 						1 for maximum, or 2 for average.
		* @return {double} 	 Return The mean capacity factor in %.
		*/
    let V, FrameCount, FrameSize, YM
    let Src, Dst, tmp, OutFun, scale, Line_I, count
    let I_nom
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    scale = this.Bus_Base[this.Con_Src[LineIndex]]
    I_nom = this.Con_NomI1[LineIndex]
    Line_I = 0
    if (this.Con_State[LineIndex] != 0) {
      OutFun = PFR_GetOutFun(op)
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)

      for (; FrameCount > 0; FrameCount--) {
        tmp = this.GetVoltageDif(V, Src, Dst)
        Line_I += (OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2]) * YM)
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (scale * Line_I / count / I_nom * PFR_100)
  }

  // ---------------------------------------------------------------------------
  GetLinePhaseCapFac (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex) {
    /**
		* Line Phase Capacity factor.
		*
		* The mean conductor current over the nominal current which the conductor is designed for. This gives an idea about the degree of utilization of the wire.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @return {double} 	 Return The mean capacity factor in %.
		*/
    let V, FrameCount, FrameSize, x, y
    let Src, Dst, scale, Line_I, count, YM
    let	I_nom
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    scale = this.Bus_Base[this.Con_Src[LineIndex]]
    if (PhaseIndex < PFR_3) {
      I_nom = this.Con_NomI1[LineIndex]
      YM = this.Cfg_YM1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
    } else {
      I_nom = this.Con_NomI4[LineIndex]
      YM = this.Cfg_YM4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
    }
    Line_I = 0
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)
      for (; FrameCount > 0; FrameCount--) {
        x = V[Src + 0] - V[Dst + 0]
        y = V[Src + PFR_1] - V[Dst + PFR_1]
        Line_I += Math.sqrt(x * x + y * y) * YM
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (scale * Line_I / count / I_nom * PFR_100)
  }

  // ---------------------------------------------------------------------------
  GetLinePhaseSavg (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex, op) {
    /**
		* Line Phase Average apparent power.
		*
		* Average apparent power passed in the phase in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double} 	 Return Average apparent power passed in the phase in KVA.
		*/
    let V, FrameCount, FrameSize, Line_S, count, Base_V
    let Src, Dst, scale, Yr, Yi, VSr, VSi, dVr, dVi, Ir, Ii, P, Q
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    Line_S = 0
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      if (PhaseIndex < PFR_3) {
        Yr = this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
        Yi = this.Cfg_Yi1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      } else {
        Yr = this.Cfg_Yr4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
        Yi = this.Cfg_Yi4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      }
      Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
      if ((op & PFR_PU_MASK) != 0) // pu value
      {
        scale = (Base_V * Base_V) / PFR_1000 / this.Base_S
      } else {
        scale = (Base_V * Base_V) / PFR_1000
      }

      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)

      for (; FrameCount > 0; FrameCount--) {
        VSr = V[Src]
        VSi = V[Src + PFR_1]
        dVr = VSr - V[Dst]
        dVi = VSi - V[Dst + PFR_1]
        Ir = dVr * Yr - dVi * Yi
        Ii = dVi * Yr + dVr * Yi
        P = (VSr * Ir + VSi * Ii) * scale
        Q = (VSi * Ir - VSr * Ii) * scale
        Line_S += Math.sqrt(P * P + Q * Q)
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (Line_S / count)
  }

  // ---------------------------------------------------------------------------
  GetLineSavg (BufferIndex, Frame1, Frame2, LineIndex, op) {
    /**
		* Line Average apparent power.
		*
		* Average apparent power passed in the line in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double} 	 Return Average apparent power passed in the line in KVA.
		*/
    let FrameCount, out, S, Index
    FrameCount = Frame2 - Frame1 + 1
    out = this.GetLineS(BufferIndex, Frame1, Frame2, LineIndex, PFR_4, op)
    S = 0
    for (Index = 0; Index < FrameCount; Index++) {
      S += out[Index]
    }
    return (S / FrameCount)
  }

  // ---------------------------------------------------------------------------
  GetLineMeanPhaseUnbal (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex, op) {
    /**
		* Line Mean Phase power unbalance.
		*
		* It show how the powers are unbalanced in the phases.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double} 	 Return two outputs; Line Mean Phase power unbalance and Line Mean Phase power share in %.
		*/
    let a, b, c, sum, result
    if (PhaseIndex > PFR_2) {
      return ([0, 0])
    }

    a = this.GetLinePhaseSavg(BufferIndex, Frame1, Frame2, LineIndex, PFR_0, op)
    b = this.GetLinePhaseSavg(BufferIndex, Frame1, Frame2, LineIndex, PFR_1, op)
    c = this.GetLinePhaseSavg(BufferIndex, Frame1, Frame2, LineIndex, PFR_2, op)
    sum = (a + b + c)
    if (Math.abs(sum) < 1e-2) {
      result = [0, 0]
    } else if (PhaseIndex == 0) {
      result = [a / sum * PFR_3, a / sum * PFR_100]
    } else if (PhaseIndex == 1) {
      result = [b / sum * PFR_3, b / sum * PFR_100]
    } else if (PhaseIndex == PFR_2) {
      result = [c / sum * PFR_3, c / sum * PFR_100]
    } else {
      result = [0, 0]
    }
    return (result)
  }

  // ---------------------------------------------------------------------------
  GetBusPhaseVmin (BufferIndex, Frame1, Frame2, BusIndex, PhaseIndex, op) {
    /**
		* Bus Phase Minimum voltage.
		*
		* Minimum voltage of the phase in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double} 	 Return Minimum voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, V_min, Bus_V, scale
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    V_min = PFR_BIG_NUM
    scale = this.GetScaleBus(op, BusIndex)
    for (; FrameCount > 0; FrameCount--) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V =	 Math.sqrt(x * x + y * y)
      if (Bus_V < V_min) {
        V_min = Bus_V
      }
      InIndex = InIndex + FrameSize
    }
    return (scale * V_min)
  }

  // ---------------------------------------------------------------------------
  GetBusVmin (BufferIndex, Frame1, Frame2, BusIndex, op) {
    /**
		* Bus Minimum voltage.
		*
		* Minimum voltage of the bus in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   op - it decides what result of phases to take and the form of the output; the required results is the summation of:
		*						 0 for minimum, 1 for maximum, or 2 for average + 0 for actual value and 256 for pu.
		* @return {double} 	 Return Minimum voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, InIndex, V_min, Bus_V
    let tmp, OutFun, scale
    OutFun = PFR_GetOutFun(op)
    scale = this.GetScaleBus(op, BusIndex)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    V_min = PFR_BIG_NUM
    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetVabc(InIndex, V)

      Bus_V = OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      if (Bus_V < V_min) {
        V_min = Bus_V
      }
      InIndex = InIndex + FrameSize
    }
    return (scale * V_min)
  }

  // ---------------------------------------------------------------------------
  GetBusPhaseVmax (BufferIndex, Frame1, Frame2, BusIndex, PhaseIndex, op) {
    /**
		* Bus Phase Maximum voltage.
		*
		* Maximum voltage of the phase in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double} 	 Return Maximum voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, V_max, Bus_V, scale
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    V_max = 0.0
    scale = this.GetScaleBus(op, BusIndex)
    for (; FrameCount > 0; FrameCount--) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V = Math.sqrt(x * x + y * y)
      if (Bus_V > V_max) {
        V_max = Bus_V
      }
      InIndex = InIndex + FrameSize
    }
    return (scale * V_max)
  }

  // ---------------------------------------------------------------------------
  GetBusVmax (BufferIndex, Frame1, Frame2, BusIndex, op) {
    /**
		* Bus Maximum voltage.
		*
		* Maximum voltage of the bus in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   op - it decides what result of phases to take and the form of the output; the required results is the summation of:
		*						 0 for minimum, 1 for maximum, or 2 for average + 0 for actual value and 256 for pu.
		* @return {double} 	 Return Maximum voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, InIndex, V_max, Bus_V
    let tmp, OutFun, scale
    OutFun = PFR_GetOutFun(op)
    scale = this.GetScaleBus(op, BusIndex)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    V_max = 0
    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetVabc(InIndex, V)

      Bus_V = OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      if (Bus_V > V_max) {
        V_max = Bus_V
      }
      InIndex = InIndex + FrameSize
    }
    return (scale * V_max)
  }

  // ---------------------------------------------------------------------------
  GetBusPhaseVavg (BufferIndex, Frame1, Frame2, BusIndex, PhaseIndex, op) {
    /**
		* Bus Phase Average voltage.
		*
		* Average voltage of the phase in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double} 	 Return Maximum voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, count, Bus_V, scale
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    Bus_V = 0.0
    scale = this.GetScaleBus(op, BusIndex)
    for (; FrameCount > 0; FrameCount--) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V += Math.sqrt(x * x + y * y)

      InIndex = InIndex + FrameSize
    }
    return (scale * Bus_V / count)
  }

  // ---------------------------------------------------------------------------
  GetBusVavg (BufferIndex, Frame1, Frame2, BusIndex, op) {
    /**
		* Bus Average voltage.
		*
		* Average voltage of the bus in the specified interval.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   op - it decides what result of phases to take and the form of the output; the required results is the summation of:
		*						 0 for minimum, 1 for maximum, or 2 for average + 0 for actual value and 256 for pu.
		* @return {double} 	 Return Average voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, InIndex, count, Bus_V
    let tmp, OutFun, scale
    OutFun = PFR_GetOutFun(op)
    scale = this.GetScaleBus(op, BusIndex)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    Bus_V = 0
    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetVabc(InIndex, V)

      Bus_V += OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])

      InIndex = InIndex + FrameSize
    }
    return (scale * Bus_V / count)
  }

  // ---------------------------------------------------------------------------
  GetMeanBusVu (BufferIndex, Frame1, Frame2, BusIndex) {
    /**
		* Mean unbalance voltage.
		*
		* For every simulation instant the unbalance voltage is calculated and then a mean from all these values is computed.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @return {double} 	 Return the mean unbalance voltage factor in %.
		*/
    let V, Bus_U, count, FrameCount, FrameSize, InIndex
    let base_v
    base_v = this.Bus_Base[BusIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    Bus_U = 0.0
    for (; FrameCount > 0; FrameCount--) {
      Bus_U += this.UnbalanceFactorBus(V, InIndex, base_v)
      InIndex = InIndex + FrameSize
    }
    return (Bus_U / count)
  }

  // ---------------------------------------------------------------------------
  GetRateBusVu (BufferIndex, Frame1, Frame2, BusIndex, array1, array2) {
    /**
		* Unbalance voltage rate.
		*
		* The percentage of time that the voltage is in unbalanced conditions.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param { array }   array1 - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @param { array }   array2 - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the voltage is in unbalanced conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants. . And it fills a second array
		* with pu values of violation.
		*/
    let V, Bus_U, rate, count, FrameCount, FrameSize, InIndex
    let NegSeqMax, FrameIndex
    let base_v
    base_v = this.Bus_Base[BusIndex]
    NegSeqMax = this.NegSeqMax
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    rate = 0.0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      Bus_U = this.UnbalanceFactorBus(V, InIndex, base_v)
      if (Bus_U > NegSeqMax) {
        array1[rate] = FrameIndex
        array2[rate++] = Bus_U
      }
      InIndex = InIndex + FrameSize
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetBusPhaseUnderV (BufferIndex, Frame1, Frame2, BusIndex, PhaseIndex, array1, array2) {
    /**
		* Phase Under-voltage rate.
		*
		* The percentage of time (samples) that the phase voltage is in Under-Voltage conditions (V < Vmin).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param { array }   array1 - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @param { array }   array2 - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the voltage is in under-voltage conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants. And it fills a second array with pu values of violation.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, rate, count, Bus_V, V_min
    let FrameIndex
    V_min = this.V_min
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    rate = 0.0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V = Math.sqrt(x * x + y * y)
      if (Bus_V < V_min) {
        array1[rate] = FrameIndex
        array2[rate++] = Bus_V
      }
      InIndex = InIndex + FrameSize
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetBusUnderV (BufferIndex, Frame1, Frame2, BusIndex, op, array) {
    /**
		* Bus Under-voltage rate.
		*
		* The percentage of time (samples) that the voltage is in Under-Voltage conditions (V < Vmin).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   op - it decides what result of phases to take; e.g. maximum, minimum, or mean of phases. 0 for minimum,
		* 						1 for maximum, or 2 for average.
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the voltage is in under-voltage conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants.
		*/
    let V, FrameCount, FrameSize, InIndex, rate, count, Bus_V, V_min
    let tmp, OutFun, FrameIndex
    V_min = this.V_min
    OutFun = PFR_GetOutFun(op)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    rate = 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      tmp = this.GetVabc(InIndex, V)

      Bus_V = OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      if (Bus_V < V_min) {
        array[rate++] = FrameIndex
      }
      InIndex = InIndex + FrameSize
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetBusPhaseOverV (BufferIndex, Frame1, Frame2, BusIndex, PhaseIndex, array1, array2) {
    /**
		* Phase Over-voltage rate.
		*
		* The percentage of time (samples) that the phase voltage is in Over-voltage conditions (V > Vmax).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param { array }   array1 - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @param { array }   array2 - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the voltage is in over-voltage conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants. And it fills a second array with pu values of violation.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, rate, count, Bus_V, V_max
    let FrameIndex
    V_max = this.V_max
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    rate = 0.0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V = Math.sqrt(x * x + y * y)
      if (Bus_V > V_max) {
        array1[rate] = FrameIndex
        array2[rate++] = Bus_V
      }
      InIndex = InIndex + FrameSize
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetBusOverV (BufferIndex, Frame1, Frame2, BusIndex, op, array) {
    /**
		* bus Over-voltage rate.
		*
		* The percentage of time (samples) that the bus voltage is in Over-voltage conditions (V > Vmax).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   op - it decides what result of phases to take; e.g. maximum, minimum, or mean of phases. 0 for minimum,
		* 						1 for maximum, or 2 for average.
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the voltage is in over-voltage conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants.
		*/
    let V, FrameCount, FrameSize, InIndex, rate, count, Bus_V
    let tmp, OutFun, V_max, FrameIndex
    V_max = this.V_max
    OutFun = PFR_GetOutFun(op)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    rate = 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      tmp = this.GetVabc(InIndex, V)

      Bus_V = OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      if (Bus_V > V_max) {
        array[rate++] = FrameIndex
      }
      InIndex = InIndex + FrameSize
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetBusPhaseQualV (BufferIndex, Frame1, Frame2, BusIndex, PhaseIndex) {
    /**
		* Phase Voltage qualified rate.
		*
		* The percentage of time (samples) that the voltage is in qualified conditions (Vmin <V< Vmax).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @return {double} 	 Return percentage of time that the voltage is in acceptable conditions in %.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, rate, count, Bus_V, V_min, V_max
    V_max = this.V_max
    V_min = this.V_min
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    rate = 0.0
    for (; FrameCount > 0; FrameCount--) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V = Math.sqrt(x * x + y * y)
      if ((Bus_V <= V_max) && (Bus_V >= V_min)) {
        rate++
      }
      InIndex = InIndex + FrameSize
    }
    return ((PFR_100 * rate) / count)
  }

  // ---------------------------------------------------------------------------
  GetBusQualV (BufferIndex, Frame1, Frame2, BusIndex, op) {
    /**
		* Bus Voltage qualified rate.
		*
		* The percentage of time (samples) that the voltage is in qualified conditions (Vmin <V< Vmax).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   op - it decides what result of phases to take; e.g. maximum, minimum, or mean of phases. 0 for minimum,
		* 						1 for maximum, or 2 for average.
		* @return {double} 	 Return percentage of time that the voltage is in acceptable conditions in %.
		*/
    let V, FrameCount, FrameSize, InIndex, rate, count, Bus_V, V_min, V_max
    let tmp, OutFun
    V_max = this.V_max
    V_min = this.V_min
    OutFun = PFR_GetOutFun(op)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    rate = 0
    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetVabc(InIndex, V)
      Bus_V = OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      if ((Bus_V <= V_max) && (Bus_V >= V_min)) {
        rate++
      }
      InIndex = InIndex + FrameSize
    }
    return ((PFR_100 * rate) / count)
  }

  // ---------------------------------------------------------------------------
  GetCgpPhaseSavg (BufferIndex, Frame1, Frame2, BusIndex, PhaseIndex) {
    /**
		* Phase Average apparent power.
		*
		* Average apparent power injected/consumed in this bus.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @return {double} 	 Return Average apparent power in KVA.
		*/
    let FrameCount, count, CgpCount, Bus_S, P, Q, CgpIndex, PQInj, InIndex
    CgpCount = this.CgpCount
    for (CgpIndex = 0; this.CGP_Bus[CgpIndex] != BusIndex; CgpIndex++) {
      if ((CgpIndex + 1) >= CgpCount) {
        return (0.0)
      }
    }
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    Bus_S = 0
    PQInj = this.GetBusPQ(BufferIndex, Frame1, Frame2, BusIndex)
    InIndex = PFR_2 * PhaseIndex
    for (; FrameCount > 0; FrameCount--) {
      P = PQInj[InIndex]
      Q = PQInj[InIndex + PFR_1]
      Bus_S += Math.sqrt(P * P + Q * Q)
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    return (Bus_S / count)
  }

  // ---------------------------------------------------------------------------
  GetCgpSavg (BufferIndex, Frame1, Frame2, BusIndex) {
    /**
		* Average apparent power.
		*
		* Average apparent power injected/consumed in this bus.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @return {double} 	 Return Average apparent power in KVA.
		*/
    let FrameCount, count, CgpCount, Bus_S, P, Q, CgpIndex, PQInj, InIndex
    CgpCount = this.CgpCount
    for (CgpIndex = 0; this.CGP_Bus[CgpIndex] != BusIndex; CgpIndex++) {
      if ((CgpIndex + 1) >= CgpCount) {
        return (0.0)
      }
    }
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    Bus_S = 0
    PQInj = this.GetBusPQ(BufferIndex, Frame1, Frame2, BusIndex)
    InIndex = 0
    for (; FrameCount > 0; FrameCount--) {
      P = PQInj[InIndex]
      Q = PQInj[InIndex + PFR_1]
      P += PQInj[InIndex + PFR_2]
      Q += PQInj[InIndex + PFR_3]
      P += PQInj[InIndex + PFR_4]
      Q += PQInj[InIndex + PFR_5]
      P += PQInj[InIndex + PFR_6]
      Q += PQInj[InIndex + PFR_7]
      Bus_S += Math.sqrt(P * P + Q * Q)
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    return (Bus_S / count)
  }

  // ---------------------------------------------------------------------------
  GetCgpMeanPhaseUnbal (BufferIndex, Frame1, Frame2, BusIndex, PhaseIndex) {
    /**
		* Mean Phase power unbalance.
		*
		* It show how the powers are unbalanced in the phases.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C.
		* @return {double} 	 Return two outputs; Mean Phase power unbalance and Mean Phase power share in %. If no power, the outputs will be zeros.
		*/
    let a, b, c, sum, result
    if (PhaseIndex > PFR_2) {
      return ([0, 0])
    }
    a = this.GetCgpPhaseSavg(BufferIndex, Frame1, Frame2, BusIndex, PFR_0)
    b = this.GetCgpPhaseSavg(BufferIndex, Frame1, Frame2, BusIndex, PFR_1)
    c = this.GetCgpPhaseSavg(BufferIndex, Frame1, Frame2, BusIndex, PFR_2)
    sum = (a + b + c)
    if (Math.abs(sum) < 1e-2) {
      result = [0, 0]
    } else if (PhaseIndex == PFR_0) {
      result = [a / (sum / PFR_3), a / (sum / PFR_100)]
    } else if (PhaseIndex == PFR_1) {
      result = [b / (sum / PFR_3), b / (sum / PFR_100)]
    } else if (PhaseIndex == PFR_2) {
      result = [c / (sum / PFR_3), c / (sum / PFR_100)]
    } else {
      result = [0, 0]
    }
    return (result)
  }

  // ---------------------------------------------------------------------------
  GetCgpMeanPF (BufferIndex, Frame1, Frame2, BusIndex) {
    /**
		* Mean power factor.
		*
		* For every simulation instant the power factor (pf=P/S) is calculated and then a mean from all these values is computed.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @return {double} 	 Return the mean power factor of the instants that included injection power. If there was no injection in the whole period, the output will be (-1).
		*/
    let FrameIndex, FrameCount, count, CgpCount, Bus_S
    let P, Q, PF, Bus_P, CgpIndex, PQInj, InIndex, result
    CgpCount = this.CgpCount
    FrameCount = Frame2 - Frame1 + 1
    PQInj = this.GetBusPQ(BufferIndex, Frame1, Frame2, BusIndex)
    PF = 0
    count = 0
    for (CgpIndex = 0; CgpIndex < CgpCount; CgpIndex++) {
      if (this.CGP_Bus[CgpIndex] == BusIndex) {
        InIndex = 0
        for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
          P = PQInj[InIndex]
          Q = PQInj[InIndex + PFR_1]
          P += PQInj[InIndex + PFR_2]
          Q += PQInj[InIndex + PFR_3]
          P += PQInj[InIndex + PFR_4]
          Q += PQInj[InIndex + PFR_5]
          P += PQInj[InIndex + PFR_6]
          Q += PQInj[InIndex + PFR_7]
          Bus_S = Math.sqrt(P * P + Q * Q)
          Bus_P =	 Math.abs(P)
          if (Bus_S > 1e-2) {
            PF += (Bus_P / Bus_S)
            count++
          } else {
            /* NO CODE */
          }
          InIndex = InIndex + PFR_NUM_PER_BUS
        }
      }
    }
    if (count == 0) {
      result = -1
    } else {
      result = (PF / count)
    }
    return (result)
  }

  // ---------------------------------------------------------------------------
  GetCtPhaseVmin (BufferIndex, Frame1, Frame2, CtIndex, PhaseIndex, RefSide, op) {
    /**
		* Transformer Phase Minimum voltage.
		*
		* Minimum voltage in the specified interval. This is calculated for the phase connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double} 	 Return Minimum voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, V_min, Bus_V, BusIndex, scale
    V = this.BufferList[BufferIndex]
    BusIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[BusIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    V_min = PFR_BIG_NUM
    if (((op >> PFR_PU_BIT_INDEX) & 1) != 0) {
      scale = 1
    } else {
      scale = this.Bus_Base[BusIndex]
    }
    for (; FrameCount > 0; FrameCount--) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V = Math.sqrt(x * x + y * y)
      if (Bus_V < V_min) {
        V_min = Bus_V
      }
      InIndex = InIndex + FrameSize
    }
    return (scale * V_min)
  }

  // ---------------------------------------------------------------------------
  GetCtVmin (BufferIndex, Frame1, Frame2, CtIndex, RefSide, op) {
    /**
		* Transformer Minimum voltage.
		*
		* Minimum voltage in the specified interval. This is calculated for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param {integer}   op - it decides what result of phases to take and the form of the output; the required results is the summation of:
		*						 0 for minimum, 1 for maximum, or 2 for average + 0 for actual value and 256 for pu.
		* @return {double} 	 Return Minimum voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, InIndex, V_min, Bus_V
    let tmp, OutFun, BusIndex, scale
    BusIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[BusIndex]
    OutFun = PFR_GetOutFun(op)
    scale = this.GetScaleBus(op, BusIndex)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    V_min = PFR_BIG_NUM
    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetVabc(InIndex, V)

      Bus_V = OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      if (Bus_V < V_min) {
        V_min = Bus_V
      }
      InIndex = InIndex + FrameSize
    }
    return (scale * V_min)
  }

  // ---------------------------------------------------------------------------
  GetCtPhaseVmax (BufferIndex, Frame1, Frame2, CtIndex, PhaseIndex, RefSide, op) {
    /**
		* Transformer Phase Maximum voltage.
		*
		* Maximum voltage in the specified interval. This is calculated for the phase connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double} 	 Return Maximum voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, V_max, Bus_V, BusIndex, scale
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    BusIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[BusIndex]
    FrameCount = Frame2 - Frame1 + 1
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    V_max = 0.0
    scale = this.GetScaleBus(op, BusIndex)
    for (; FrameCount > 0; FrameCount--) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V = Math.sqrt(x * x + y * y)
      if (Bus_V > V_max) {
        V_max = Bus_V
      }
      InIndex = InIndex + FrameSize
    }
    return (scale * V_max)
  }

  // ---------------------------------------------------------------------------
  GetCtVmax (BufferIndex, Frame1, Frame2, CtIndex, RefSide, op) {
    /**
		* Transformer Maximum voltage.
		*
		* Maximum voltage in the specified interval. This is calculated for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param {integer}   op - it decides what result of phases to take and the form of the output; the required results is the summation of:
		*						 0 for minimum, 1 for maximum, or 2 for average + 0 for actual value and 256 for pu.
		* @return {double} 	 Return Maximum voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, BusIndex, InIndex, V_max, Bus_V
    let tmp, OutFun, scale
    BusIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[BusIndex]
    OutFun = PFR_GetOutFun(op)
    scale = this.GetScaleBus(op, BusIndex)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    V_max = 0
    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetVabc(InIndex, V)

      Bus_V = OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      if (Bus_V > V_max) {
        V_max = Bus_V
      }
      InIndex = InIndex + FrameSize
    }
    return (scale * V_max)
  }

  // ---------------------------------------------------------------------------
  GetCtPhaseVavg (BufferIndex, Frame1, Frame2, CtIndex, PhaseIndex, RefSide, op) {
    /**
		* Transformer Phase Average voltage.
		*
		* Average voltage in the specified interval. This is calculated for the phase connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double} 	 Return Average voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, count, Bus_V, BusIndex, scale
    BusIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[BusIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    Bus_V = 0.0
    scale = this.GetScaleBus(op, BusIndex)
    for (; FrameCount > 0; FrameCount--) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V += Math.sqrt(x * x + y * y)

      InIndex = InIndex + FrameSize
    }
    return (scale * Bus_V / count)
  }

  // ---------------------------------------------------------------------------
  GetCtVavg (BufferIndex, Frame1, Frame2, CtIndex, RefSide, op) {
    /**
		* Transformer Average voltage.
		*
		* Average voltage in the specified interval. This is calculated for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param {integer}   op - it decides what result of phases to take and the form of the output; the required results is the summation of:
		*						 0 for minimum, 1 for maximum, or 2 for average + 0 for actual value and 256 for pu.
		* @return {double} 	 Return Average voltage in V or pu.
		*/
    let V, FrameCount, FrameSize, InIndex, count, Bus_V
    let tmp, OutFun, BusIndex, scale
    BusIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[BusIndex]
    OutFun = PFR_GetOutFun(op)
    scale = this.GetScaleBus(op, BusIndex)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    Bus_V = 0
    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetVabc(InIndex, V)

      Bus_V += OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])

      InIndex = InIndex + FrameSize
    }
    return (scale * Bus_V / count)
  }

  // ---------------------------------------------------------------------------
  GetMeanCtVu (BufferIndex, Frame1, Frame2, CtIndex, RefSide) {
    /**
		* Mean unbalance voltage.
		*
		* For every simulation instant the unbalance voltage is calculated and
		then a mean from all these values is computed. This is calculated for
		the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return the mean unbalance voltage factor in %.
		*/
    let V, Bus_U, count, FrameCount, FrameSize, InIndex, BusIndex
    let base_v
    BusIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[BusIndex]
    base_v = this.Bus_Base[BusIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    Bus_U = 0.0
    for (; FrameCount > 0; FrameCount--) {
      Bus_U += this.UnbalanceFactorBus(V, InIndex, base_v)
      InIndex = InIndex + FrameSize
    }
    return (Bus_U / count)
  }

  // ---------------------------------------------------------------------------
  GetRateCtVu (BufferIndex, Frame1, Frame2, CtIndex, RefSide, array) {
    /**
		* Unbalance voltage rate.
		*
		* The percentage of time that the voltage is in unbalanced conditions. This is calculated for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the voltage is in unbalanced conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants.
		*/
    let V, Bus_U, rate, count, FrameCount, FrameSize, InIndex
    let BusIndex, FrameIndex, NegSeqMax
    let base_v
    NegSeqMax = this.NegSeqMax
    BusIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[BusIndex]
    base_v = this.Bus_Base[BusIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    rate = 0.0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      Bus_U = this.UnbalanceFactorBus(V, InIndex, base_v)
      if (Bus_U > NegSeqMax) {
        array[rate++] = FrameIndex
      }
      InIndex = InIndex + FrameSize
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetCtPhaseSavg (BufferIndex, Frame1, Frame2, CtIndex, PhaseIndex, RefSide) {
    /**
		* Phase Average apparent power.
		*
		* Average apparent power injected/consumed. This is calculated for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return Average apparent power in KVA.
		*/
    let FrameCount, count, Bus_S, P, Q, PQInj, InIndex
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    Bus_S = 0
    PQInj = this.GetCtPQ(BufferIndex, Frame1, Frame2, CtIndex, RefSide)
    InIndex = PFR_2 * PhaseIndex
    for (; FrameCount > 0; FrameCount--) {
      P = PQInj[InIndex]
      Q = PQInj[InIndex + PFR_1]
      Bus_S += Math.sqrt(P * P + Q * Q)
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    return (Bus_S / count)
  }

  // ---------------------------------------------------------------------------
  GetCtSavg (BufferIndex, Frame1, Frame2, CtIndex, RefSide) {
    /**
		* Average apparent power.
		*
		* Average apparent power injected/consumed. This is calculated for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return Average apparent power in KVA.
		*/
    let FrameCount, count, Bus_S, P, Q, PQInj, InIndex
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    Bus_S = 0
    PQInj = this.GetCtPQ(BufferIndex, Frame1, Frame2, CtIndex, RefSide)
    InIndex = 0
    for (; FrameCount > 0; FrameCount--) {
      P = PQInj[InIndex]
      Q = PQInj[InIndex + PFR_1]
      P += PQInj[InIndex + PFR_2]
      Q += PQInj[InIndex + PFR_3]
      P += PQInj[InIndex + PFR_4]
      Q += PQInj[InIndex + PFR_5]
      P += PQInj[InIndex + PFR_6]
      Q += PQInj[InIndex + PFR_7]
      Bus_S += Math.sqrt(P * P + Q * Q)
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    return (Bus_S / count)
  }

  // ---------------------------------------------------------------------------
  GetCtMeanPhaseUnbal (BufferIndex, Frame1, Frame2, CtIndex, PhaseIndex, RefSide) {
    /**
		* Mean Phase power unbalance.
		*
		* It show how the powers are unbalanced in the phases. This is calculated for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @return {double} 	 Return two outputs; Mean Phase power unbalance and Mean Phase power share in %. If no power, the outputs will be zeros.
		*/
    let a, b, c, sum, result
    if (PhaseIndex > PFR_2) {
      return ([0, 0])
    }
    a = this.GetCtPhaseSavg(BufferIndex, Frame1, Frame2, CtIndex, PFR_0, RefSide)
    b = this.GetCtPhaseSavg(BufferIndex, Frame1, Frame2, CtIndex, PFR_1, RefSide)
    c = this.GetCtPhaseSavg(BufferIndex, Frame1, Frame2, CtIndex, PFR_2, RefSide)
    sum = (a + b + c)
    if (sum < 1e-2) {
      result = [0, 0]
    } else if (PhaseIndex == PFR_0) {
      result = [a / sum * PFR_3, a / sum * PFR_100]
    } else if (PhaseIndex == PFR_1) {
      result = [b / sum * PFR_3, b / sum * PFR_100]
    } else if (PhaseIndex == PFR_2) {
      result = [c / sum * PFR_3, c / sum * PFR_100]
    } else {
      result = [0, 0]
    }
    return (result)
  }

  // ---------------------------------------------------------------------------
  GetCtMeanPF (BufferIndex, Frame1, Frame2, CtIndex, RefSide) {
    /**
		* Mean power factor.
		*
		* For every simulation instant the power factor (pf=P/S) is calculated and
		then a mean from all these values is computed.
		This is calculated for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return the mean power factor of the instants that included injection power. If there was no injection in the whole period, the output will be (-1).
		*/
    let FrameCount, count, Bus_S, P, Q, PF, k, PQInj, InIndex
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    PF = 0
    PQInj = this.GetCtPQ(BufferIndex, Frame1, Frame2, CtIndex, RefSide)
    InIndex = 0
    for (; FrameCount > 0; FrameCount--) {
      P = PQInj[InIndex]
      Q = PQInj[InIndex + PFR_1]
      P += PQInj[InIndex + PFR_2]
      Q += PQInj[InIndex + PFR_3]
      P += PQInj[InIndex + PFR_4]
      Q += PQInj[InIndex + PFR_5]
      P += PQInj[InIndex + PFR_6]
      Q += PQInj[InIndex + PFR_7]
      Bus_S = Math.sqrt(P * P + Q * Q)
      k = Math.abs(P)
      if (Bus_S > PFR_POWER_TOL) {
        PF += k / Bus_S
      } else {
        count--
      }
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    if (count == 0) {
      return (-1)
    }
    return (PF / count)
  }

  // ---------------------------------------------------------------------------
  GetCtCapFac (BufferIndex, Frame1, Frame2, CtIndex, RefSide) {
    /**
		* Transformer Capacity factor.
		*
		* The mean transformer power (S) over its nominal power (Snominal). This gives an idea about the degree of utilization of the transformer.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return The mean capacity factor in %.
		*/
    let FrameCount, count, Bus_S, P, Q
    let	 Snom, PQInj, InIndex
    Snom = this.CT_NomS[CtIndex]
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    Bus_S = 0
    PQInj = this.GetCtPQ(BufferIndex, Frame1, Frame2, CtIndex, RefSide)
    InIndex = 0
    for (; FrameCount > 0; FrameCount--) {
      P = PQInj[InIndex]
      Q = PQInj[InIndex + PFR_1]
      P += PQInj[InIndex + PFR_2]
      Q += PQInj[InIndex + PFR_3]
      P += PQInj[InIndex + PFR_4]
      Q += PQInj[InIndex + PFR_5]
      P += PQInj[InIndex + PFR_6]
      Q += PQInj[InIndex + PFR_7]
      Bus_S += Math.sqrt(P * P + Q * Q)
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    return (Bus_S / count / Snom * PFR_100)
  }

  // ---------------------------------------------------------------------------
  GetCtOverLoad (BufferIndex, Frame1, Frame2, CtIndex, RefSide, array) {
    /**
		* Transformer Overload rate.
		*
		* The percentage of time that the transformer is in overloading conditions (Snominal < S < Smax ).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the transformer is in overloading conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants.
		*/
    let FrameCount, count, Bus_S, P, Q, rate
    let	 Snom, Smax, PQInj, InIndex, FrameIndex
    Snom = this.CT_NomS[CtIndex] // Transformer rating
    Smax = this.SmaxRatio * Snom // Scale to indicate when the transformer is overloaded
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    rate = 0
    Bus_S = 0
    PQInj = this.GetCtPQ(BufferIndex, Frame1, Frame2, CtIndex, RefSide)
    InIndex = 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      P = PQInj[InIndex]
      Q = PQInj[InIndex + PFR_1]
      P += PQInj[InIndex + PFR_2]
      Q += PQInj[InIndex + PFR_3]
      P += PQInj[InIndex + PFR_4]
      Q += PQInj[InIndex + PFR_5]
      P += PQInj[InIndex + PFR_6]
      Q += PQInj[InIndex + PFR_7]
      Bus_S = Math.sqrt(P * P + Q * Q)
      if (Bus_S <= Smax && Bus_S > Snom) {
        array[rate++] = FrameIndex
      }
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetCtOverUti (BufferIndex, Frame1, Frame2, CtIndex, RefSide, array1, array2) {
    /**
		* Transformer Over-utilization rate.
		*
		* The percentage of time that the transformer is beyond its overload capacity ( S > Smax ).
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param { array }   array1 - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @param { array }   array2 - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the transformer is beyond its overload capacity in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants. And it fills a second array with pu values of violation.
		*/
    let FrameCount, count, Trans_S, P, Q, rate
    let	 Snom, Smax, PQInj, InIndex, FrameIndex
    Snom = this.CT_NomS[CtIndex]
    Smax = this.SmaxRatio * Snom
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    rate = 0
    Trans_S = 0
    PQInj = this.GetCtPQ(BufferIndex, Frame1, Frame2, CtIndex, RefSide)
    InIndex = 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      P = PQInj[InIndex]
      Q = PQInj[InIndex + PFR_1]
      P += PQInj[InIndex + PFR_2]
      Q += PQInj[InIndex + PFR_3]
      P += PQInj[InIndex + PFR_4]
      Q += PQInj[InIndex + PFR_5]
      P += PQInj[InIndex + PFR_6]
      Q += PQInj[InIndex + PFR_7]
      Trans_S = Math.sqrt(P * P + Q * Q)
      if (Trans_S > Smax) {
        array1[rate] = FrameIndex
        array2[rate++] = Trans_S / Snom
      }
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetCtEnergySup (BufferIndex, Frame1, Frame2, CtIndex, RefSide) {
    /**
		* Transformer Supplied energy.
		*
		* Amount of energy delivered/absorbed to/from the system by the transformer in the indicated time-spam.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return Amount of energy supplied in KWh.
		*/
    let FrameCount, P, PQInj, InIndex, StepTime
    StepTime = this.StepTime / PFR_3600
    FrameCount = Frame2 - Frame1 + 1
    P = 0
    PQInj = this.GetCtPQ(BufferIndex, Frame1, Frame2, CtIndex, RefSide)
    InIndex = 0
    for (; FrameCount > 0; FrameCount--) {
      P += PQInj[InIndex]
      P += PQInj[InIndex + PFR_2]
      P += PQInj[InIndex + PFR_4]
      P += PQInj[InIndex + PFR_6]
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    return (P / PFR_1000 * StepTime)
  }

  // ---------------------------------------------------------------------------
  GetTotalPower (BufferIndex, Frame1, Frame2, Index, RefSide, SigType, PhaseList = null) {
    let InIndex, FrameIndex, PhaseIndex, PhaseOffset
    let PhaseCount, SigOffset, PowerBuffer
    let PhasePower, Power, power_sign, FrameCount
    FrameCount = Frame2 - Frame1 + 1
    if (PhaseList == null) {
      PhaseList = [PFR_0, PFR_1, PFR_2, PFR_3]
    }
    PhaseCount = PhaseList.length
    if ((SigType & PFR_4) == 0) // CGP
    {
      PowerBuffer = this.GetBusPQ(BufferIndex, Frame1, Frame2, Index)
    } else // Transformer
    {
      PowerBuffer = this.GetCtPQ(BufferIndex, Frame1, Frame2, Index, RefSide)
    }
    if ((SigType & PFR_2) == 0) // Active power
    {
      SigOffset = 0
    } else // Reactive power
    {
      SigOffset = 1
    }
    if ((SigType & PFR_1) == 0) // positive
    {
      power_sign = 1
    } else {
      power_sign = -1
    }
    Power = 0
    InIndex = 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      for (PhaseIndex = 0; PhaseIndex < PhaseCount; PhaseIndex++) {
        PhaseOffset = InIndex + SigOffset + (PhaseList[PhaseIndex] * PFR_2)
        PhasePower = power_sign * PowerBuffer[PhaseOffset]
        if (PhasePower > 0) {
          Power += PhasePower
        }
      }
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    return (power_sign * Power)
  }

  // ---------------------------------------------------------------------------
  GetDgEnergySup (BufferIndex, Frame1, Frame2, CtIndex, RefSide) {
    /**
		* DG Supplied energy.
		*
		* Amount of energy delivered to the system by DGs in the indicated time-spam.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return Amount of energy supplied in KWh..
		*/
    let CgpCount, BusIndex, PB_neg, Index, CgpIndex
    let StepTime, Energy, WdIndex
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    StepTime = this.StepTime / PFR_3600
    CgpCount = this.CgpCount
    PB_neg = 0
    for (Index = this.WD_bus_first[WdIndex]; Index < this.WD_bus_first[WdIndex + PFR_1]; Index++) {
      BusIndex = this.WD_bus_list[Index]
      for (CgpIndex = 0; CgpIndex < CgpCount; CgpIndex++) {
        if (this.CGP_Bus[CgpIndex] == BusIndex) {
          // Negative active power of CGP
          PB_neg += this.GetTotalPower(BufferIndex, Frame1, Frame2,
            BusIndex, RefSide, PFR_CGP_ACTIVE_NEG, [PFR_0, PFR_1, PFR_2])
        }
      }
    }
    Energy = (-PB_neg) * StepTime
    return (Energy)
  }

  // ---------------------------------------------------------------------------
  GetEnergySup (BufferIndex, Frame1, Frame2, CtIndex, RefSide) {
    /**
		* System Injected  energy.
		*
		* Amount of energy delivered to the system by Transformer and DGs in the indicated time-spam.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Returns 4 outputs; (1)  Amount of energy injected by
		* Transformer in KWh. (2) percentage of energy injected by transformer compared to the total injected energy.
		* (3) Amount of energy supplied by DGs in KWh.
		* (4) percentage of energy injected by DGs compared to the total injected energy.
		*/
    let CgpCount, BusIndex, PB_neg, PT_pos, WdIndex
    let Index1, Index2, Index, CgpIndex, StepTime, out, power
    StepTime = this.StepTime / PFR_3600
    CgpCount = this.CgpCount
    // Get positive active power of transformer
    PT_pos = this.GetTotalPower(BufferIndex, Frame1, Frame2,
      CtIndex, RefSide, PFR_CT_ACTIVE_POS, [PFR_0, PFR_1, PFR_2])
    PB_neg = 0
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    Index1 = this.WD_bus_first[WdIndex]
    Index2 = this.WD_bus_first[WdIndex + PFR_1]
    for (Index = Index1; Index < Index2; Index++) {
      BusIndex = this.WD_bus_list[Index]
      for (CgpIndex = 0; CgpIndex < CgpCount; CgpIndex++) {
        if (this.CGP_Bus[CgpIndex] == BusIndex) {
          PB_neg += this.GetTotalPower(BufferIndex, Frame1, Frame2,
            BusIndex, RefSide, PFR_CGP_ACTIVE_NEG, [PFR_0, PFR_1, PFR_2])
        }
      }
    }
    PB_neg = -PB_neg
    power = PT_pos + PB_neg
    if (Math.abs(power) < 1e-3) {
      return ([0, 0, 0, 0])
    } else {
      out =
			[
			  (StepTime * PT_pos), // kWh
			  PFR_100 * (PT_pos / power), // %
			  (StepTime * PB_neg), // kWh
			  PFR_100 * (PB_neg / power) // %
			]
      return (out) //  in KWh and %
    }
  }

  // ---------------------------------------------------------------------------
  GetCtPhaseEnergyLoss_old (BufferIndex, Frame1, Frame2, CtIndex, PhaseIndex, RefSide) {
    /**
		* Phase Energy losses.
		*
		* Total amount of energy losses of the entire network hanging down from the CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return two outputs; The energy losses in the line in KWh and the percentage of losses to the total injected power.
		*  	 If the CtIndex is a medium voltage transformer the return will be [0, 0].
		*/
    let EnergyNet, CgpCount, BusIndex, WdIndex
    let Index1, Index2, Index, CgpIndex, EnergyLos
    let PT_pos, PT_neg, PB_pos, PB_neg, StepTime
    StepTime = this.StepTime / PFR_3600
    CgpCount = this.CgpCount
    // Get positive active power of transformer
    PT_pos = this.GetTotalPower(BufferIndex, Frame1, Frame2,
      CtIndex, RefSide, PFR_CT_ACTIVE_POS, [PhaseIndex])
    // Get negative active power of transformer
    PT_neg = this.GetTotalPower(BufferIndex, Frame1, Frame2,
      CtIndex, RefSide, PFR_CT_ACTIVE_NEG, [PhaseIndex])

    PB_pos = 0
    PB_neg = 0
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    Index1 = this.WD_bus_first[WdIndex]
    Index2 = this.WD_bus_first[WdIndex + PFR_1]
    for (Index = Index1; Index < Index2; Index++) {
      BusIndex = this.WD_bus_list[Index]
      for (CgpIndex = 0; CgpIndex < CgpCount; CgpIndex++) {
        if (this.CGP_Bus[CgpIndex] == BusIndex) {
          // Get positive active power of CGP
          PB_pos += this.GetTotalPower(BufferIndex, Frame1, Frame2,
            BusIndex, RefSide, PFR_CGP_ACTIVE_POS, [PhaseIndex])
          // Get negative active power of CGP
          PB_neg += this.GetTotalPower(BufferIndex, Frame1, Frame2,
            BusIndex, RefSide, PFR_CGP_ACTIVE_NEG, [PhaseIndex])
        }
      }
    }
    EnergyLos = ((PT_pos + PT_neg) - (PB_pos + PB_neg)) * StepTime
    EnergyNet = (PT_pos - PB_neg) * StepTime
    if ((Math.abs(EnergyNet) < 1e-3) || (Index1 == Index2)) {
      return ([0, 0])
    } else {
      return ([EnergyLos, EnergyLos / EnergyNet * PFR_100]) //  in KWh and %
    }
  }

  // ---------------------------------------------------------------------------
  GetCtPhaseEnergyLoss (BufferIndex, Frame1, Frame2, CtIndex, PhaseIndex, RefSide) {
    /**
		* Phase Energy losses.
		*
		* Total amount of energy losses of the entire network hanging down from the CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return two outputs; The energy losses in the line in KWh and the percentage of losses to the total injected power.
		*  	 If the CtIndex is a medium voltage transformer the return will be [0, 0].
		*/
    let EnergyNet, CgpCount, BusIndex, WdIndex
    let Index1, Index2, Index, CgpIndex, EnergyLos
    let PT_pos, PB_neg, StepTime
    let con_index
    StepTime = this.StepTime / PFR_3600
    CgpCount = this.CgpCount
    // Get positive active power of transformer
    PT_pos = this.GetTotalPower(BufferIndex, Frame1, Frame2,
      CtIndex, RefSide, PFR_CT_ACTIVE_POS, [PhaseIndex])
    PB_neg = 0
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    Index1 = this.WD_bus_first[WdIndex]
    Index2 = this.WD_bus_first[WdIndex + PFR_1]
    for (Index = Index1; Index < Index2; Index++) {
      BusIndex = this.WD_bus_list[Index]
      for (CgpIndex = 0; CgpIndex < CgpCount; CgpIndex++) {
        if (this.CGP_Bus[CgpIndex] == BusIndex) {
          // Get negative active power of CGP
          PB_neg += this.GetTotalPower(BufferIndex, Frame1, Frame2,
            BusIndex, RefSide, PFR_CGP_ACTIVE_NEG, [PhaseIndex])
        }
      }
    }
    Index1 = this.WD_con_first[WdIndex]
    Index2 = this.WD_con_first[WdIndex + PFR_1]
    EnergyLos = 0
    for (Index = Index1; Index < Index2; Index++) {
      con_index = this.WD_con_list[Index]
      EnergyLos += this.GetLinePhaseEnergyLos(BufferIndex, Frame1, Frame2, con_index, PhaseIndex)
    }
    EnergyNet = (PT_pos - PB_neg) * StepTime
    if ((Math.abs(EnergyNet) < 1e-3) || (Index1 == Index2)) {
      return ([0, 0])
    } else {
      return ([EnergyLos, EnergyLos / EnergyNet * PFR_100]) //  in KWh and %
    }
  }

  // ---------------------------------------------------------------------------
  GetPTposNeg (PQInj, InIndex) {
    let PT_pos, PT_neg
    PT_pos = 0.0
    PT_neg = 0.0
    if (PQInj[InIndex] >= 0) {
      PT_pos += PQInj[InIndex]
    } else {
      PT_neg += PQInj[InIndex]
    }
    if (PQInj[InIndex + PFR_2] >= 0) {
      PT_pos += PQInj[InIndex + PFR_2]
    } else {
      PT_neg += PQInj[InIndex + PFR_2]
    }
    if (PQInj[InIndex + PFR_4] >= 0) {
      PT_pos += PQInj[InIndex + PFR_4]
    } else {
      PT_neg += PQInj[InIndex + PFR_4]
    }
    if (PQInj[InIndex + PFR_6] >= 0) {
      PT_pos += PQInj[InIndex + PFR_6]
    } else {
      PT_neg += PQInj[InIndex + PFR_6]
    }
    return ([PT_pos, PT_neg])
  }

  // -------------
  GetCtEnergyLoss_old (BufferIndex, Frame1, Frame2, CtIndex, RefSide) {
    /**
		* Energy losses.
		*
		* Total amount of energy losses of the entire network hanging down from the CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return two outputs; The energy losses in the lines in KWh and the percentage of losses to the total injected power.
		*  	 If the CtIndex is a medium voltage transformer the return will be [0, 0].
		*/
    let FrameCount, CgpCount, FrameIndex, BusIndex, CgpIndex, WdIndex
    let PB_pos, PB_neg, PQInj, InIndex, Index1, Index2, Index
    let Energy, EnergyLos, PT_pos, PT_neg, StepTime, tmp, result
    StepTime = this.StepTime / PFR_3600
    CgpCount = this.CgpCount
    FrameCount = Frame2 - Frame1 + 1
    PT_pos = 0
    PT_neg = 0
    PQInj = this.GetCtPQ(BufferIndex, Frame1, Frame2, CtIndex, RefSide)
    InIndex = 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      tmp = this.GetPTposNeg(PQInj, InIndex)
      PT_pos += tmp[PFR_0]
      PT_neg += tmp[PFR_1]
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    PB_pos = 0
    PB_neg = 0
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    Index1	= this.WD_bus_first[WdIndex]
    Index2	= this.WD_bus_first[WdIndex + PFR_1]
    for (Index = Index1; Index < Index2; Index++) {
      BusIndex = this.WD_bus_list[Index]
      for (CgpIndex = 0; CgpIndex < CgpCount; CgpIndex++) {
        if (this.CGP_Bus[CgpIndex] == BusIndex) {
          PQInj		= this.GetBusPQ(BufferIndex, Frame1, Frame2, BusIndex)
	      InIndex = 0
          for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
            tmp = this.GetPTposNeg(PQInj, InIndex)
            PB_pos += tmp[PFR_0]
            PB_neg += tmp[PFR_1]
            InIndex = InIndex + PFR_NUM_PER_BUS
          }
        }
      }
    }
    EnergyLos = ((PT_pos + PT_neg) - (PB_pos + PB_neg)) * StepTime
    Energy = (PT_pos - PB_neg) * StepTime
    if ((Math.abs(Energy) < 1e-3) || (Index1 == Index2)) {
      result = [0, 0]
    } else {
      result = [EnergyLos, PFR_100 * (EnergyLos / Energy)] //  in KWh and %
    }
    return (result)
  }

  // ---------------------------------------------------------------------------
  GetCtEnergyLoss (BufferIndex, Frame1, Frame2, CtIndex, RefSide) {
    /**
		* Energy losses.
		*
		* Total amount of energy losses of the entire network hanging down from the CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return two outputs; The energy losses in the lines in KWh and the percentage of losses to the total injected power.
		*  	 If the CtIndex is a medium voltage transformer the return will be [0, 0].
		*/
    let FrameCount, CgpCount, FrameIndex, BusIndex, CgpIndex, WdIndex
    let PB_pos, PB_neg, PQInj, InIndex, Index1, Index2, Index
    let Energy, EnergyLos, PT_pos, PT_neg, StepTime, tmp, result
    let con_index
    StepTime = this.StepTime / PFR_3600
    CgpCount = this.CgpCount
    FrameCount = Frame2 - Frame1 + 1
    PT_pos = 0
    PT_neg = 0
    PQInj = this.GetCtPQ(BufferIndex, Frame1, Frame2, CtIndex, RefSide)
    InIndex = 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      tmp = this.GetPTposNeg(PQInj, InIndex)
      PT_pos += tmp[PFR_0]
      PT_neg += tmp[PFR_1]
      InIndex = InIndex + PFR_NUM_PER_BUS
    }
    PB_pos = 0
    PB_neg = 0
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    Index1	= this.WD_bus_first[WdIndex]
    Index2	= this.WD_bus_first[WdIndex + PFR_1]
    for (Index = Index1; Index < Index2; Index++) {
      BusIndex = this.WD_bus_list[Index]
      for (CgpIndex = 0; CgpIndex < CgpCount; CgpIndex++) {
        if (this.CGP_Bus[CgpIndex] == BusIndex) {
          PQInj		= this.GetBusPQ(BufferIndex, Frame1, Frame2, BusIndex)
	      InIndex = 0
          for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
            tmp = this.GetPTposNeg(PQInj, InIndex)
            PB_pos += tmp[PFR_0]
            PB_neg += tmp[PFR_1]
            InIndex = InIndex + PFR_NUM_PER_BUS
          }
        }
      }
    }
    Index1 = this.WD_con_first[WdIndex]
    Index2 = this.WD_con_first[WdIndex + PFR_1]
    EnergyLos = 0
    for (Index = Index1; Index < Index2; Index++) {
      con_index = this.WD_con_list[Index]
      EnergyLos += this.GetLineEnergyLos(BufferIndex, Frame1, Frame2, con_index)
    }
    Energy = (PT_pos - PB_neg) * StepTime
    if ((Math.abs(Energy) < 1e-3) || (Index1 == Index2)) {
      result = [0, 0]
    } else {
      result = [EnergyLos, PFR_100 * (EnergyLos / Energy)] //  in KWh and %
    }
    return (result)
  }

  // ---------------------------------------------------------------------------
  // BM : 220729
  GetCtPowerLoss (BufferIndex, Frame1, Frame2, CtIndex, RefSide)// MM: include new input RefSide
  {
    let FrameCount, FrameIndex, OutDat, Cgp_Offset, WdIndex
    let WdCgpIndex, CT_PQ, CT_Power, CT_Offset, BusIndex
    let CgpIndex, CgpFirst, CgpEnd, CGP_power, CGP_PQ, CGP_PQ_N

    FrameCount = Frame2 - Frame1 + 1
    CT_PQ		= this.GetCtPQ(BufferIndex, Frame1, Frame2, CtIndex, RefSide)
    OutDat = new Float32Array(FrameCount)
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    CgpFirst		= this.WD_CgpFirst[WdIndex]
    CgpEnd			= this.WD_CgpFirst[WdIndex + PFR_1]
    CT_Offset		= 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      CT_Power = CT_PQ[CT_Offset] // CT active power for phase A
      CT_Power += CT_PQ[CT_Offset + PFR_2] // CT active power for phase B
      CT_Power += CT_PQ[CT_Offset + PFR_4] // CT active power for phase C
      CT_Offset += PFR_NUM_PER_BUS
      CGP_PQ_N = 0
      CGP_power = 0
      for (WdCgpIndex = CgpFirst; WdCgpIndex < CgpEnd; WdCgpIndex++) {
        CgpIndex = this.WD_CGP[WdCgpIndex]
        BusIndex = this.CGP_Bus[CgpIndex]
        CGP_PQ	= this.GetBusPQ(BufferIndex, Frame1, Frame2, BusIndex)
        Cgp_Offset = 0
        CGP_power += CGP_PQ[Cgp_Offset] // CGP active power for phase A
        CGP_power += CGP_PQ[Cgp_Offset + PFR_2] // CGP active power for phase B
        CGP_power += CGP_PQ[Cgp_Offset + PFR_4] // CGP active power for phase C
        CGP_PQ_N	+= CGP_PQ[Cgp_Offset + PFR_6] // CGP active power for phase N
      }
      OutDat[FrameIndex] = CT_Power - CGP_power - CGP_PQ_N // BM221221 : add neutral power
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  // BM : 221223 Add single phase function
  GetCtLos_old (BufferIndex, Frame1, Frame2, CtIndex, RefSide, phase = 4)// MM: include new input RefSide
  {
    let FrameCount, FrameIndex, OutDat, WdIndex
    let WdCgpIndex, CT_PQ, CT_Power, CT_Offset
    let CgpIndex, CgpFirst, CgpEnd, CGP_power, Cgp_Offset, CGP_PQ
    let phase1, phase2, phase_index, BusIndex

    if (phase < 4) {
      phase1 = phase
      phase2 = phase
    } else {
      phase1 = 0
      phase2 = 3
    }

    FrameCount = Frame2 - Frame1 + 1
    CT_PQ		= this.GetCtPQ(BufferIndex, Frame1, Frame2, CtIndex, RefSide)
    OutDat = new Float32Array(FrameCount)
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    CgpFirst		= this.WD_CgpFirst[WdIndex]
    CgpEnd			= this.WD_CgpFirst[WdIndex + PFR_1]
    CT_Offset		= 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      CT_Power = 0.0
      for (phase_index = phase1; phase_index <= phase2; phase_index++) {
        CT_Power += CT_PQ[CT_Offset + (2 * phase_index)] // CT active power for phase A
      }
      CT_Offset += PFR_NUM_PER_BUS
      CGP_power = 0
      for (WdCgpIndex = CgpFirst; WdCgpIndex < CgpEnd; WdCgpIndex++) {
        CgpIndex = this.WD_CGP[WdCgpIndex]
        BusIndex = this.CGP_Bus[CgpIndex]
      		CGP_PQ	= this.GetBusPQ(BufferIndex, Frame1, Frame2, BusIndex)
        Cgp_Offset = 0
        for (phase_index = phase1; phase_index <= phase2; phase_index++) {
          CGP_power += CGP_PQ[Cgp_Offset + (2 * phase_index)]
        }
      }
      OutDat[FrameIndex] = CT_Power - CGP_power
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  // BM : 221223 Add single phase function
  GetCtLos (BufferIndex, Frame1, Frame2, CtIndex, RefSide, phase = 4)// MM: include new input RefSide
  {
    let FrameCount, FrameIndex, OutDat, WdIndex
    let Index
    let Index1, Index2, con_index, EnergyLos

    FrameCount = Frame2 - Frame1 + 1
    OutDat = new Float32Array(FrameCount)
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    Index1 = this.WD_con_first[WdIndex]
    Index2 = this.WD_con_first[WdIndex + PFR_1]

    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      EnergyLos = 0
      for (Index = Index1; Index < Index2; Index++) {
        con_index = this.WD_con_list[Index]
        if (phase < 4) {
          EnergyLos += this.GetLinePhaseEnergyLos(BufferIndex, FrameIndex, FrameIndex, con_index, phase)
        } else {
          EnergyLos += this.GetLineEnergyLos(BufferIndex, FrameIndex, FrameIndex, con_index)
        }
      }
      OutDat[FrameIndex] = EnergyLos
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  GetCtPhaseUnderV (BufferIndex, Frame1, Frame2, CtIndex, PhaseIndex, RefSide, array) {
    /**
		* Phase Under-voltage rate.
		*
		* The percentage of time (samples) that the voltage is in Under-Voltage conditions (V < Vmin) for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the voltage is in under-voltage conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, rate, count, Bus_V, V_min
    let FrameIndex, BusIndex, WdIndex
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[WdIndex]
    V_min = this.V_min
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    rate = 0.0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V = Math.sqrt(x * x + y * y)
      if (Bus_V < V_min) {
        array[rate++] = FrameIndex
      }
      InIndex = InIndex + FrameSize
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetCtUnderV (BufferIndex, Frame1, Frame2, CtIndex, RefSide, op, array) {
    /**
		* Under-voltage rate.
		*
		* The percentage of time (samples) that the voltage is in Under-Voltage conditions (V < Vmin) for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param {integer}   op - it decides what result of phases to take; e.g. maximum, minimum, or mean of phases. 0 for minimum,
		* 						1 for maximum, or 2 for average.
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the voltage is in under-voltage conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants.
		*/
    let V, FrameCount, FrameSize, InIndex, rate, count, Bus_V, V_min
    let tmp, OutFun, FrameIndex, BusIndex, WdIndex
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[WdIndex]
    V_min = this.V_min
    OutFun = PFR_GetOutFun(op)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    rate = 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      tmp = this.GetVabc(InIndex, V)
      Bus_V = OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      if (Bus_V < V_min) {
        array[rate++] = FrameIndex
      }
      InIndex = InIndex + FrameSize
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetCtPhaseOverV (BufferIndex, Frame1, Frame2, CtIndex, PhaseIndex, RefSide, array) {
    /**
		* Phase Overvoltage rate.
		*
		* The percentage of time (samples) that the voltage is in Overvoltage conditions (V > Vmax) for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the voltage is in Overvoltage conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, rate, count, Bus_V, V_max
    let FrameIndex, BusIndex, WdIndex
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[WdIndex]
    V_max = this.V_max
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    rate = 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V = Math.sqrt(x * x + y * y)
      if (Bus_V > V_max) {
        array[rate++] = FrameIndex
      }
      InIndex = InIndex + FrameSize
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetCtOverV (BufferIndex, Frame1, Frame2, CtIndex, RefSide, op, array) {
    /**
		* Overvoltage rate.
		*
		* The percentage of time (samples) that the voltage is in Overvoltage conditions (V > Vmax) for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param {integer}   op - it decides what result of phases to take; e.g. maximum, minimum, or mean of phases. 0 for minimum,
		* 						1 for maximum, or 2 for average.
		* @param { array }   array - array of at least length = Frame2 - Frame1 + 1. The values inside will be updates.
		* @return {double} 	 Return two outputs; percentage of time that
		* the voltage is in Overvoltage conditions in % and the number of instants of violations.
		* In addition it fill the input array with the indices of violation instants.
		*/
    let V, FrameCount, FrameSize, InIndex, rate, count, Bus_V
    let tmp, OutFun, V_max, FrameIndex, BusIndex, WdIndex
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[WdIndex]
    V_max = this.V_max
    OutFun = PFR_GetOutFun(op)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    rate = 0
    for (FrameIndex = 0; FrameIndex < FrameCount; FrameIndex++) {
      tmp = this.GetVabc(InIndex, V)

      Bus_V = OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      if (Bus_V > V_max) {
        array[rate++] = FrameIndex
      }
      InIndex = InIndex + FrameSize
    }
    return ([(PFR_100 * rate) / count, rate])
  }

  // ---------------------------------------------------------------------------
  GetCtPhaseQualV (BufferIndex, Frame1, Frame2, CtIndex, PhaseIndex, RefSide) {
    /**
		* Phase Voltage qualified rate.
		*
		* The percentage of time (samples) that the voltage is in qualified conditions (Vmin <V< Vmax) for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @return {double} 	 Return percentage of time that the voltage is in acceptable conditions in %.
		*/
    let V, FrameCount, FrameSize, x, y, InIndex, rate, count
    let Bus_V, V_min, V_max, BusIndex, WdIndex
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[WdIndex]
    V_max = this.V_max
    V_min = this.V_min
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex + PFR_2 * PhaseIndex
    rate = 0.0
    for (; FrameCount > 0; FrameCount--) {
      x = V[InIndex]
      y = V[InIndex + PFR_1]
      Bus_V = Math.sqrt(x * x + y * y)
      if ((Bus_V <= V_max) && (Bus_V >= V_min)) {
        rate++
      }
      InIndex = InIndex + FrameSize
    }
    return ((PFR_100 * rate) / count)
  }

  // ---------------------------------------------------------------------------
  GetCtQualV (BufferIndex, Frame1, Frame2, CtIndex, RefSide, op) {
    /**
		* Voltage qualified rate.
		*
		* The percentage of time (samples) that the voltage is in qualified conditions (Vmin <V< Vmax) for the bus connected to the secondary of CT.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   CtIndex - The transformer index.
		* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
		* @param {integer}   op - it decides what result of phases to take; e.g. maximum, minimum, or mean of phases. 0 for minimum,
		* 						1 for maximum, or 2 for average.
		* @return {double} 	 Return percentage of time that the voltage is in acceptable conditions in %.
		*/
    let V, FrameCount, FrameSize, InIndex, rate, count, Bus_V, V_min, V_max
    let tmp, OutFun, BusIndex, WdIndex
    WdIndex = this.CT_winding_first[CtIndex] + RefSide
    BusIndex = this.CT_bus[WdIndex]
    V_max = this.V_max
    V_min = this.V_min
    OutFun = PFR_GetOutFun(op)
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    count = FrameCount
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    rate = 0
    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetVabc(InIndex, V)

      Bus_V = OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      if ((Bus_V <= V_max) && (Bus_V >= V_min)) {
        rate++
      }
      InIndex = InIndex + FrameSize
    }
    return ((PFR_100 * rate) / count)
  }
  // ---------------------------------------------------------------------------

  /* maximum values of a selected area */
  // ---------------------------------------------------------------------------
  GetLinePmax (BufferIndex, Frame1, Frame2, LineIndex, op) {
    let V, MaxPower, Line_P, FrameCount, FrameSize, Base_V
    let Src, Dst, scale, Yr, Yi, is_pu, P, Q
    is_pu = (op >> PFR_PU_BIT_INDEX) & 1
    Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
    MaxPower = 0
    if (is_pu != 0) // per unit
    {
      scale = Base_V * Base_V / this.Base_S
    } else {
      scale = Base_V * Base_V / PFR_1000
    }
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      Yr = this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Yi = this.Cfg_Yi1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)

      P = [0, 0, 0, 0]
      Q = [0, 0, 0, 0]
      for (; FrameCount > 0; FrameCount--) {
        PFR_GetPowerFlow(V, Src, Dst, Yr, Yi, P, Q)
        Line_P = P[PFR_0] + P[PFR_1] + P[PFR_2] + P[PFR_3]
        if (Line_P > MaxPower) {
          MaxPower = Line_P
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (MaxPower * scale)
  }

  // ---------------------------------------------------------------------------
  GetLinePmaxPhases (BufferIndex, Frame1, Frame2, LineIndex, op) {
    let V, MaxPower, Line_P, FrameCount, FrameSize, Base_V
    let Src, Dst, scale, Yr, Yi, OutFun, is_pu, P, Q
    is_pu = (op >> PFR_PU_BIT_INDEX) & 1
    Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
    MaxPower = 0
    if (is_pu != 0) // per unit
    {
      scale = Base_V * Base_V / this.Base_S
    } else {
      scale = Base_V * Base_V / PFR_1000
    }
    if (this.Con_State[LineIndex] != 0) {
      OutFun = PFR_GetOutFun(op)
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      Yr = this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Yi = this.Cfg_Yi1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      P = [0, 0, 0, 0]
      Q = [0, 0, 0, 0]
      for (; FrameCount > 0; FrameCount--) {
        PFR_GetPowerFlow(V, Src, Dst, Yr, Yi, P, Q)
        Line_P = OutFun(P[PFR_0], P[PFR_1], P[PFR_2])
        if (Line_P > MaxPower) {
          MaxPower = Line_P
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (MaxPower * scale)
  }

  // ---------------------------------------------------------------------------
  GetLineQmaxPhases (BufferIndex, Frame1, Frame2, LineIndex, op) {
    let V, MaxPower, Line_Q, FrameCount, FrameSize, Base_V
    let Src, Dst, scale, Yr, Yi, OutFun, is_pu, P, Q
    is_pu = (op >> PFR_PU_BIT_INDEX) & 1
    Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
    MaxPower = 0
    if (is_pu != 0) // per unit
    {
      scale = Base_V * Base_V / this.Base_S
    } else {
      scale = Base_V * Base_V / PFR_1000
    }
    if (this.Con_State[LineIndex] != 0) {
      OutFun = PFR_GetOutFun(op)
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      Yr = this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Yi = this.Cfg_Yi1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      P = [0, 0, 0, 0]
      Q = [0, 0, 0, 0]
      for (; FrameCount > 0; FrameCount--) {
        PFR_GetPowerFlow(V, Src, Dst, Yr, Yi, P, Q)
        Line_Q = OutFun(Q[PFR_0], Q[PFR_1], Q[PFR_2])
        if (Line_Q > MaxPower) {
          MaxPower = Line_Q
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (MaxPower * scale)
  }

  // ---------------------------------------------------------------------------
  GetLineQmax (BufferIndex, Frame1, Frame2, LineIndex, op) {
    let V, MaxPower, Line_Q, FrameCount, FrameSize, Base_V
    let Src, Dst, scale, Yr, Yi, is_pu, P, Q
    is_pu = (op >> PFR_PU_BIT_INDEX) & 1
    Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
    MaxPower = 0
    if (is_pu != 0) // per unit
    {
      scale = Base_V * Base_V / this.Base_S
    } else {
      scale = Base_V * Base_V / PFR_1000
    }
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      Yr = this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Yi = this.Cfg_Yi1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      P = [0, 0, 0, 0]
      Q = [0, 0, 0, 0]
      for (; FrameCount > 0; FrameCount--) {
        PFR_GetPowerFlow(V, Src, Dst, Yr, Yi, P, Q)
        Line_Q = Q[PFR_0] + Q[PFR_1] + Q[PFR_2] + Q[PFR_3]
        if (Line_Q > MaxPower) {
          MaxPower = Line_Q
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (MaxPower * scale)
  }

  // ---------------------------------------------------------------------------
  GetLineSmax (BufferIndex, Frame1, Frame2, LineIndex, op) {
    let V, FrameCount, FrameSize, Base_V, Line_S, Pt, Qt
    let Src, Dst, scale, Yr, Yi, P, Q, MaxPower, is_pu
    is_pu = (op >> PFR_PU_BIT_INDEX) & 1
    Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
    MaxPower = 0
    if (is_pu != 0) // per unit
    {
      scale = Base_V * Base_V / this.Base_S
    } else {
      scale = Base_V * Base_V / PFR_1000
    }
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      Yr = this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Yi = this.Cfg_Yi1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      P = [0, 0, 0, 0]
      Q = [0, 0, 0, 0]
      for (; FrameCount > 0; FrameCount--) {
        PFR_GetPowerFlow(V, Src, Dst, Yr, Yi, P, Q)
        Pt = P[PFR_0] + P[PFR_1] + P[PFR_2] + P[PFR_3]
        Qt = Q[PFR_0] + Q[PFR_1] + Q[PFR_2] + Q[PFR_3]
        Line_S = Math.sqrt(Pt * Pt + Qt * Qt)
        if (Line_S > MaxPower) {
          MaxPower = Line_S
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (MaxPower * scale)
  }

  // ---------------------------------------------------------------------------
  GetLineSmaxPhases (BufferIndex, Frame1, Frame2, LineIndex, op) {
    let V, FrameCount, FrameSize, Base_V, k, P, Q, S, Line_S
    let Src, Dst, scale, Yr, Yi, MaxPower, OutFun, is_pu
    is_pu = (op >> PFR_PU_BIT_INDEX) & 1
    Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
    MaxPower = 0
    if (is_pu != 0) // per unit
    {
      scale = Base_V * Base_V / this.Base_S
    } else {
      scale = Base_V * Base_V / PFR_1000
    }
    if (this.Con_State[LineIndex] != 0) {
      OutFun = PFR_GetOutFun(op)
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      Yr = this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Yi = this.Cfg_Yi1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      P = [0, 0, 0, 0]
      Q = [0, 0, 0, 0]
      S = [0, 0, 0, 0]
      for (; FrameCount > 0; FrameCount--) {
        PFR_GetPowerFlow(V, Src, Dst, Yr, Yi, P, Q)
        for (k = 0; k < PFR_3; k++) {
          S[k] = Math.sqrt(P[k] * P[k] + Q[k] * Q[k])
        }
        Line_S = OutFun(S[PFR_0], S[PFR_1], S[PFR_2])
        if (Line_S > MaxPower) {
          MaxPower = Line_S
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (MaxPower * scale)
  }

  // ---------------------------------------------------------------------------
  GetLineLossMax (BufferIndex, Frame1, Frame2, LineIndex) {
    /**
		* Line Energy losses.
		*
		* The maximum energy losses in the line in single instant.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @return {double} 	 Return The maximum energy losses in the line in KWh.
		*/
    let V, FrameCount, FrameSize, d, max, Loss
    let Src, Dst, Base_V, scale1, scale4, StepTime
    max = 0
    StepTime = this.StepTime / PFR_3600
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
      scale1 = Base_V * Base_V * this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      scale4 = Base_V * Base_V * this.Cfg_Yr4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
      Src = this.GetSrcOffset(Frame1, LineIndex, 0)
      Dst = this.GetDstOffset(Frame1, LineIndex, 0)
      for (; FrameCount > 0; FrameCount--) {
        d = this.GetVoltageDif2(V, Src, Dst)
        Loss = scale1 * (d[PFR_0] + d[PFR_1] + d[PFR_2]) + scale4 * d[PFR_3]
        if (Loss > max) {
          max = Loss
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return (max * StepTime / PFR_1000)
  }

  // ---------------------------------------------------------------------------
  GetLineLossPhasesMax (BufferIndex, Frame1, Frame2, LineIndex, PhaseIndex) {
    /**
		* Phase Energy losses.
		*
		* The maximum energy losses in the Phase in single instant.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   LineIndex - The line index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @return {double} 	 Return The energy losses in the Phase in KWh.
		*/
    let V, FrameCount, FrameSize, x, y, Loss
    let Src, Dst, Base_V, scale, max, StepTime
    max = 0
    Base_V = this.Bus_Base[this.Con_Src[LineIndex]]
    if (PhaseIndex < PFR_3) {
      scale = Base_V * Base_V * this.Cfg_Yr1[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
    } else {
      scale = Base_V * Base_V * this.Cfg_Yr4[this.Con_Cfg[LineIndex]] / this.Con_Length[LineIndex]
    }
    StepTime = this.StepTime / PFR_3600
    if (this.Con_State[LineIndex] != 0) {
      V = this.BufferList[BufferIndex]
      FrameSize = this.FrameSize
      FrameCount = Frame2 - Frame1 + 1
      Src = this.GetSrcOffset(Frame1, LineIndex, PhaseIndex)
      Dst = this.GetDstOffset(Frame1, LineIndex, PhaseIndex)
      for (; FrameCount > 0; FrameCount--) {
        x = V[Src + 0] - V[Dst + 0]
        y = V[Src + PFR_1] - V[Dst + PFR_1]
        Loss = (x * x + y * y)
        if (Loss > max) {
          max = Loss
        }
        Src += FrameSize
        Dst += FrameSize
      }
    }
    return ((scale * max * StepTime) / PFR_1000)
  }

  // ---------------------------------------------------------------------------
  GetBusVuMax (BufferIndex, Frame1, Frame2, BusIndex) {
    let V, FrameCount, FrameSize, InIndex, Vumax, Vu
    let base_v
    base_v = this.Bus_Base[BusIndex]
    V = this.BufferList[BufferIndex]
    FrameSize = this.FrameSize
    FrameCount = Frame2 - Frame1 + 1
    InIndex = Frame1 * FrameSize + PFR_NUM_PER_BUS * BusIndex
    Vumax = 0
    for (; FrameCount > 0; FrameCount--) {
      Vu = this.UnbalanceFactorBus(V, InIndex, base_v)
      if (Vu > Vumax) {
        Vumax = Vu
      }
      InIndex = InIndex + FrameSize
    }
    return (Vumax) // in percentage
  }

  // ---------------------------------------------------------------------------
  GetAreaLinesImax (BufferIndex, Frame1, Frame2, op) {
    /**
		* Area maximum Current.
		*
		* The maximum loading of a wire in a single phase at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double, integer} - Return two signals; The maximum Current loading in pu,
		* and the index of the line that has the maximum value.
		*/
    let LineIndex, LineCount, Line_I, I_max, IndexMax
    IndexMax = 0
    LineCount = this.LineCount
    I_max = 0
    op = op + 1
    for (LineIndex = 0; LineIndex < LineCount; LineIndex++) {
      Line_I = this.GetLineImax(BufferIndex, Frame1, Frame2, LineIndex, op)
      if ((Line_I - I_max) > 0.01) {
        I_max = Line_I
        IndexMax = LineIndex
      }
    }
    return ([I_max, IndexMax])
  }

  // ---------------------------------------------------------------------------
  GetAreaBusesVmax (BufferIndex, Frame1, Frame2, op) {
    /**
		* Area maximum Voltage.
		*
		* The maximum Voltage in a single phase at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double, integer} - Return two signals; The The maximum Voltage in V or pu,
		* and the index of the Bus that has the maximum value.
		*/
    let BusIndex, BusCount, Bus_V, V_max, IndexMax
    IndexMax = 0
    BusCount = this.BusCount
    V_max = 0
    op = op + 1
    for (BusIndex = 0; BusIndex < BusCount; BusIndex++) {
      if (this.is_VS_Bus(BusIndex) == 0) {
        Bus_V = this.GetBusVmax(BufferIndex, Frame1, Frame2, BusIndex, op)
        if ((Bus_V - V_max) > 0.01) {
          V_max = Bus_V
          IndexMax = BusIndex
        }
      } else {
        /* NO CODE */
      }
    }
    return ([V_max, IndexMax])
  }

  // ---------------------------------------------------------------------------
  GetAreaBusesVmin (BufferIndex, Frame1, Frame2, op) {
    /**
		* Area Minimum Voltage.
		*
		* The Minimum Voltage in a single phase at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double, integer} - Return two signals; The The Minimum Voltage in V or pu,
		* and the index of the Bus that has the Minimum value.
		*/
    let BusIndex, BusCount, Bus_V, V_min, IndexMax
    IndexMax = 0
    BusCount = this.BusCount
    V_min = PFR_BIG_NUM
    for (BusIndex = 0; BusIndex < BusCount; BusIndex++) {
      Bus_V = this.GetBusVmin(BufferIndex, Frame1, Frame2, BusIndex, op)
      if ((V_min - Bus_V) > 0.01) {
        V_min = Bus_V
        IndexMax = BusIndex
      }
    }
    return ([V_min, IndexMax])
  }

  // ---------------------------------------------------------------------------
  GetAreaUFmax (BufferIndex, Frame1, Frame2) {
    /**
		* Area maximum unbalance factor.
		*
		* The maximum unbalance factor in a bus at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @return {double, integer} - Return two signals; The maximum  unbalance factor in percentage %,
		* and the index of the bus that has the maximum value.
		*/
    let BusIndex, BusCount, Bus_Vu, Vumax, IndexMax
    IndexMax = 0
    BusCount = this.BusCount
    Vumax = 0
    for (BusIndex = 0; BusIndex < BusCount; BusIndex++) {
      Bus_Vu = this.GetBusVuMax(BufferIndex, Frame1, Frame2, BusIndex)
      if ((Bus_Vu - Vumax) > 0.001) {
        Vumax = Bus_Vu
        IndexMax = BusIndex
      }
    }
    return ([Vumax, IndexMax])
  }

  // ---------------------------------------------------------------------------
  GetAreaLinesPhasesPmax (BufferIndex, Frame1, Frame2, op) {
    /**
		* Area Phases maximum Active Power.
		*
		* The maximum Active Power passing in a single phase at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double, integer} - Return two signals; The maximum Active Power in KW or pu,
		* and the index of the line that has the phase of maximum value.
		*/
    let LineIndex, LineCount, Line_P, MaxPower, IndexMax
    IndexMax = 0
    LineCount = this.LineCount
    MaxPower = 0
    op = op + 1
    for (LineIndex = 0; LineIndex < LineCount; LineIndex++) {
      Line_P = this.GetLinePmaxPhases(BufferIndex, Frame1, Frame2, LineIndex, op)
      if ((Line_P - MaxPower) > 0.01) {
        MaxPower = Line_P
        IndexMax = LineIndex
      }
    }
    return ([MaxPower, IndexMax])
  }

  // ---------------------------------------------------------------------------
  GetAreaLinesPmax (BufferIndex, Frame1, Frame2, op) {
    /**
		* Area maximum Active Power.
		*
		*The maximum Active Power passing in a single Line at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double, integer} - Return two signals; The maximum Active Power in KW orpu,
		* and the index of the line that has the maximum value.
		*/
    let LineIndex, LineCount, Line_P, MaxPower, IndexMax
    IndexMax = 0
    LineCount = this.LineCount
    MaxPower = 0
    for (LineIndex = 0; LineIndex < LineCount; LineIndex++) {
      Line_P = this.GetLinePmax(BufferIndex, Frame1, Frame2, LineIndex, op)
      if ((Line_P - MaxPower) > 0.01) {
        MaxPower = Line_P
        IndexMax = LineIndex
      }
    }
    return ([MaxPower, IndexMax])
  }

  // ---------------------------------------------------------------------------
  GetAreaLinesQmax (BufferIndex, Frame1, Frame2, op) {
    /**
		* Area maximum Reactive Power.
		*
		* The maximum Reactive Power passing in a single Line at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double, integer} - Return two signals; The maximum Reactive Power in KVAR or pu,
		* and the index of the line that has the maximum value.
		*/
    let LineIndex, LineCount, Line_Q, Q_max, IndexMax
    IndexMax = 0
    LineCount = this.LineCount
    Q_max = 0
    for (LineIndex = 0; LineIndex < LineCount; LineIndex++) {
      Line_Q = this.GetLineQmax(BufferIndex, Frame1, Frame2, LineIndex, op)
      if ((Line_Q - Q_max) > 0.01) {
        Q_max = Line_Q
        IndexMax = LineIndex
      }
    }
    return ([Q_max, IndexMax])
  }

  // ---------------------------------------------------------------------------
  GetAreaLinesPhasesQmax (BufferIndex, Frame1, Frame2, op) {
    /**
		* Area maximum Reactive Power.
		*
		* The maximum Reactive Power passing in a single phase at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double, integer} - Return two signals; The maximum Reactive Power in KVAR or pu,
		* and the index of the line that has the phase of maximum value.
		*/
    let LineIndex, LineCount, Line_Q, Q_max, IndexMax
    IndexMax = 0
    LineCount = this.LineCount
    Q_max = 0
    op = op + 1
    for (LineIndex = 0; LineIndex < LineCount; LineIndex++) {
      Line_Q = this.GetLineQmaxPhases(BufferIndex, Frame1, Frame2, LineIndex, op)
      if ((Line_Q - Q_max) > 0.01) {
        Q_max = Line_Q
        IndexMax = LineIndex
      }
    }
    return ([Q_max, IndexMax])
  }

  // ---------------------------------------------------------------------------
  GetAreaLinesSmax (BufferIndex, Frame1, Frame2, op) {
    /**
		* Area maximum Apparent Power.
		*
		* The maximum Apparent Power passing in a single Line at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double, integer} - Return two signals; The maximum  Apparent Power in KVA or pu,
		* and the index of the line that has the maximum value.
		*/
    let LineIndex, LineCount, Line_S, Smax, IndexMax
    IndexMax = 0
    LineCount = this.LineCount
    Smax = 0
    for (LineIndex = 0; LineIndex < LineCount; LineIndex++) {
      Line_S = this.GetLineSmax(BufferIndex, Frame1, Frame2, LineIndex, op)
      if ((Line_S - Smax) > 0.01) {
        Smax = Line_S
        IndexMax = LineIndex
      }
    }
    return ([Smax, IndexMax])
  }

  // ---------------------------------------------------------------------------
  GetAreaLinesPhasesSmax (BufferIndex, Frame1, Frame2, op) {
    /**
		* Area maximum Apparent Power.
		*
		* The maximum Apparent Power passing in a single phase at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   op - it decides the form of the output; 0 for actual value and 256 for pu.
		* @return {double, integer} - Return two signals; The maximum  Apparent Power in KVA or pu,
		* and the index of the line that has the phase of maximum value.
		*/
    let LineIndex, LineCount, Line_S, Smax, IndexMax
    IndexMax = 0
    LineCount = this.LineCount
    Smax = 0
    op = op + 1
    for (LineIndex = 0; LineIndex < LineCount; LineIndex++) {
      Line_S = this.GetLineSmaxPhases(BufferIndex, Frame1, Frame2, LineIndex, op)
      if ((Line_S - Smax) > 0.01) {
        Smax = Line_S
        IndexMax = LineIndex
      }
    }
    return ([Smax, IndexMax])
  }

  // ---------------------------------------------------------------------------
  GetAreaLinePhaseLosMax (BufferIndex, Frame1, Frame2) {
    /**
		* Area maximum losses per phase
		*
		* The maximum Power Losses in a single phase at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @return {double, integer} - Return two signals; The maximum  phase Power loss in KWh and
		* the index of the line that has the maximum value.
		*/
    let LineIndex, LineCount, LineLoss, LossMax, IndexMax
    IndexMax = 0
    LineCount = this.LineCount
    LossMax = 0
    for (LineIndex = 0; LineIndex < LineCount; LineIndex++) {
      LineLoss = this.GetLineLossPhasesMax(BufferIndex, Frame1, Frame2, LineIndex, PFR_0)
      if ((LineLoss - LossMax) > 0.001) {
        LossMax = LineLoss
        IndexMax = LineIndex
      }
      LineLoss = this.GetLineLossPhasesMax(BufferIndex, Frame1, Frame2, LineIndex, PFR_1)
      if ((LineLoss - LossMax) > 0.001) {
        LossMax = LineLoss
        IndexMax = LineIndex
      }
      LineLoss = this.GetLineLossPhasesMax(BufferIndex, Frame1, Frame2, LineIndex, PFR_2)
      if ((LineLoss - LossMax) > 0.001) {
        LossMax = LineLoss
        IndexMax = LineIndex
      }
    }
    return ([LossMax, IndexMax])
  }

  // ---------------------------------------------------------------------------
  GetAreaLineLosMax (BufferIndex, Frame1, Frame2) {
    /**
		* Area maximum losses per line
		*
		* The maximum Power Losses in a single line at specified instants of time.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @return {double, integer} - Return two signals; The maximum  line Power loss in KWh and the index of the line that has the maximum value.
		*/
    let LineIndex, LineCount, LineLoss, LossMax, IndexMax
    IndexMax = 0
    LineCount = this.LineCount
    LossMax = 0
    for (LineIndex = 0; LineIndex < LineCount; LineIndex++) {
      LineLoss = this.GetLineLossMax(BufferIndex, Frame1, Frame2, LineIndex)
      if ((LineLoss - LossMax) > 0.001) {
        LossMax = LineLoss
        IndexMax = LineIndex
      }
    }
    return ([LossMax, IndexMax])
  }
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------

  /* Injection functions */
  // ---------------------------------------------------------------------------
  GetBusPhaseInj (BufferIndex, Frame1, Frame2, BusIndex, PhaseIndex) {
    /**
		* Bus Phase Injection.
		*
		* The current and powers injection from the bus phase where positive injection means the bus is injecting and negative injection means the bus is being supplied.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @return {double} 	 Return four signals; The injection current in A, injection active power in KW,
		* injected reactive power in KVAR, and injected apparent power in KVA, respectively.
		*/
    let I, PQ, Inj_I, Inj_P, Inj_Q, Inj_S, OutIndex, FrameCount, x, y, InIndex, scale
    FrameCount = Frame2 - Frame1 + 1
    I		= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    PQ		= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    Inj_I = new Float32Array(FrameCount)
    Inj_P = new Float32Array(FrameCount)
    Inj_Q = new Float32Array(FrameCount)
    Inj_S = new Float32Array(FrameCount)
    if (this.is_VS_Bus(BusIndex) == 1) {
      Inj_I.fill(0.0)
      Inj_P.fill(0.0)
      Inj_Q.fill(0.0)
      Inj_S.fill(0.0)
      return ([Inj_I, Inj_P, Inj_Q, Inj_S])
    }

    PhaseIndex = PhaseIndex & PFR_3 // BM221221 : limit the phase number to 0,1,2,3

    this.GetInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetCtInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetInjPower(BufferIndex, Frame1, Frame2, BusIndex, I, PQ)
    InIndex = PFR_2 * PhaseIndex
    OutIndex = 0
    scale = 1.0
    for (; FrameCount > 0; FrameCount--) {
      x = I[InIndex + PFR_0]
      y = I[InIndex + PFR_1]
      Inj_I[OutIndex] = scale * Math.sqrt(x * x + y * y)
      x = PQ[InIndex + PFR_0]
      y = PQ[InIndex + PFR_1]
      Inj_P[OutIndex] = x
      Inj_Q[OutIndex] = y
      Inj_S[OutIndex] = Math.sqrt(x * x + y * y)
      InIndex = InIndex + PFR_NUM_PER_BUS
      OutIndex++
    }
    return ([Inj_I, Inj_P, Inj_Q, Inj_S])
  }

  // ---------------------------------------------------------------------------
  GetIabc (InIndex, I) {
    let x, y, a, b, c
    x = I[InIndex + 0]
    y = I[InIndex + PFR_1]
    a = Math.sqrt(x * x + y * y)
    x = I[InIndex + PFR_2]
    y = I[InIndex + PFR_3]
    b = Math.sqrt(x * x + y * y)
    x = I[InIndex + PFR_4]
    y = I[InIndex + PFR_5]
    c = Math.sqrt(x * x + y * y)
    return ([a, b, c])
  }

  // ---------------------------------------------------------------------------
  // BM221221
  GetIabcn (InIndex, I) {
    let x, y, k, abcn
    for (k = 0; k < 4; k++) {
      x = I[InIndex++]
      y = I[InIndex++]
      abcn[k] = Math.sqrt(x * x + y * y)
    }
    return (abcn)
  }

  // ---------------------------------------------------------------------------
  // BM221221
  GetBusInjPhase (BufferIndex, Frame1, Frame2, BusIndex, Phase, op) {
    /**
		* Bus Injection.
		*
		* The current and powers injection from the bus where positive injection means the bus is injecting and negative injection means the bus is being supplied.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   Phase - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @param {integer}   op - 0: real value, 256 : pu values .
		* @return {double} 	 Return four signals; The injection current in A,
		* injection active power in KW, injected reactive power in KVAR, and injected apparent power in KVA, respectively.
		*/
    let PQ, I, Inj_I, Inj_P, Inj_Q, Inj_S, OutIndex
    let FrameCount, x, y, InIndex, scale_i, scale_s, base_v
    FrameCount = Frame2 - Frame1 + 1
    I			= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    PQ		= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    Inj_I = new Float32Array(FrameCount)
    Inj_P = new Float32Array(FrameCount)
    Inj_Q = new Float32Array(FrameCount)
    Inj_S = new Float32Array(FrameCount)
    if (this.is_VS_Bus(BusIndex) == 1) {
      Inj_I.fill(0.0)
      Inj_P.fill(0.0)
      Inj_Q.fill(0.0)
      Inj_S.fill(0.0)
      return ([Inj_I, Inj_P, Inj_Q, Inj_S])
    }

    Phase = Phase & 3 // BM221221 : limit the phase number to 0,1,2,3

    this.GetInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetCtInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetInjPower(BufferIndex, Frame1, Frame2, BusIndex, I, PQ)
    InIndex		= 2 * Phase
    OutIndex	= 0
    base_v = this.Bus_Base[BusIndex]
    if ((op & 256) != 0) // is pu  BM221221 : complete the following code
    {
      scale_i = base_v / this.Base_S
      scale_s = 1.0 / this.Base_S
    } else {
      scale_i = 1.0 // base_v;
      scale_s = 1.0
    }
    for (; FrameCount > 0; FrameCount--) {
      x = I[InIndex + PFR_0]
      y = I[InIndex + PFR_1]
      Inj_I[OutIndex] = Math.sqrt((x * x) + (y * y)) * scale_i
      x = PQ[InIndex + PFR_0]
      y = PQ[InIndex + PFR_1]
      Inj_P[OutIndex] = x * scale_s
      Inj_Q[OutIndex] = y * scale_s
      Inj_S[OutIndex] = Math.sqrt((x * x) + (y * y)) * scale_s
      InIndex = InIndex + PFR_NUM_PER_BUS
      OutIndex++
    }
    return ([Inj_I, Inj_P, Inj_Q, Inj_S])
  }

  // ---------------------------------------------------------------------------
  // BM221221 : Fix the total power
  GetBusInj (BufferIndex, Frame1, Frame2, BusIndex, op) {
    /**
		* Bus Injection.
		*
		* The current and powers injection from the bus where positive injection means the bus is injecting and negative injection means the bus is being supplied.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   BusIndex - The bus index.
		* @param {integer}   op - is used for the current only. (0) take minimum, (1) take maximum, else take average  of phases.
		* @return {double} 	 Return four signals; The injection current in A,
		* injection active power in KW, injected reactive power in KVAR, and injected apparent power in KVA, respectively.
		*/
    let p, q
    let PQ, I, Inj_I, Inj_P, Inj_Q, Inj_S, OutIndex
    let FrameCount, k, InIndex, scale_i, scale_s, base_v, OutFun, tmp
    OutFun = PFR_GetOutFun(op)
    FrameCount = Frame2 - Frame1 + 1
    I			= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    PQ		= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    Inj_I = new Float32Array(FrameCount)
    Inj_P = new Float32Array(FrameCount)
    Inj_Q = new Float32Array(FrameCount)
    Inj_S = new Float32Array(FrameCount)
    if (this.is_VS_Bus(BusIndex) == 1) {
      Inj_I.fill(0.0)
      Inj_P.fill(0.0)
      Inj_Q.fill(0.0)
      Inj_S.fill(0.0)
      return ([Inj_I, Inj_P, Inj_Q, Inj_S])
    }
    this.GetInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetCtInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetInjPower(BufferIndex, Frame1, Frame2, BusIndex, I, PQ)
    InIndex		= 0
    OutIndex	= 0
    base_v = this.Bus_Base[BusIndex]
    if ((op & 256) != 0) // is pu  BM221221 : complete the following code
    {
      scale_i = base_v / this.Base_S
      scale_s = 1.0 / this.Base_S
    } else {
      scale_i = 1.0
      scale_s = 1.0
    }
    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetIabc(InIndex, I)
      Inj_I[OutIndex] = scale_i * OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      p = 0.0
      q = 0.0
      for (k = 0; k < 4; k++) {
        p += PQ[InIndex++]
        q += PQ[InIndex++]
      }
      Inj_P[OutIndex] = (p * scale_s)
      Inj_Q[OutIndex] = (q * scale_s)
      Inj_S[OutIndex] = Math.sqrt((p * p) + (q * q)) * scale_s
      OutIndex++
    }
    return ([Inj_I, Inj_P, Inj_Q, Inj_S])
  }

  // ---------------------------------------------------------------------------
  GetCtPhaseInj (BufferIndex, Frame1, Frame2, CtIndex, PhaseIndex, RefSide) {
    /**
	* Station Phase Injection.
	*
	* The current and powers injection from the transformer phase where positive
	* injection means the transformer is injecting and negative injection means the transformer is being supplied.
	*
	* @since      2.0.1
	* @access     private
	* @param {integer}   BufferIndex - The index of the buffer (index of the case).
	* @param {integer}   Frame1 - The index of the starting frame or starting hour.
	* @param {integer}   Frame2 - The index of the ending frame or ending hour.
	* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   PhaseIndex - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
	* @return {double} 	 Return four signals; The injection current in A,
	* injection active power in KW, injected reactive power in KVAR, and injected apparent power in KVA, respectively.
	*/
    let I, PQ, Inj_I, Inj_P, Inj_Q, Inj_S
    let OutIndex, FrameCount, InIndex, op, m
    let x, y, scale_i, scale_s, base_v
    FrameCount = Frame2 - Frame1 + 1
    I			= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    PQ		= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    Inj_I = new Float32Array(FrameCount)
    Inj_P = new Float32Array(FrameCount)
    Inj_Q = new Float32Array(FrameCount)
    Inj_S = new Float32Array(FrameCount)
    this.GetCtPQinj(BufferIndex, Frame1, Frame2, CtIndex, RefSide, I, PQ)
    InIndex = PFR_2 * PhaseIndex
    OutIndex = 0
    op = 0
    m = this.CT_winding_first[CtIndex] + 1
    m = this.CT_bus[m]
    base_v = this.Bus_Base[m]
    if ((op & 256) != 0) // is pu  BM221221 : complete the following code
    {
      scale_i = base_v / this.Base_S
      scale_s = 1.0 / this.Base_S
    } else {
      scale_i = 1.0
      scale_s = 1.0
    }
    for (; FrameCount > 0; FrameCount--) {
      x = I[InIndex]
      y = I[InIndex + PFR_1]
      Inj_I[OutIndex] = Math.sqrt(x * x + y * y) * scale_i
      x = PQ[InIndex]
      y = PQ[InIndex + PFR_1]
      Inj_P[OutIndex] = x * scale_s
      Inj_Q[OutIndex] = y * scale_s
      Inj_S[OutIndex] = Math.sqrt((x * x) + (y * y)) * scale_s
      InIndex = InIndex + PFR_NUM_PER_BUS
      OutIndex++
    }
    return ([Inj_I, Inj_P, Inj_Q, Inj_S])
  }

  // ---------------------------------------------------------------------------
  GetCtInj (BufferIndex, Frame1, Frame2, CtIndex, RefSide, op) {
    /**
	* Station Injection.
	*
	* The current and powers injection from the transformer where positive injection means the transformer is injecting and negative injection means the transformer is being supplied.
	*
	* @since      2.0.1
	* @access     private
	* @param {integer}   BufferIndex - The index of the buffer (index of the case).
	* @param {integer}   Frame1 - The index of the starting frame or starting hour.
	* @param {integer}   Frame2 - The index of the ending frame or ending hour.
	* @param {integer}   CtIndex - The transformer index.
	* @param {integer}   RefSide - The side of Transformer; 0 for Primary side, 1 for Secondary, 2 for Tertiary winding..
	* @param {integer}   op - is used for the current only. (0) take minimum, (1) take maximum, else take average  of phases.
	* @return {double} 	 Return four signals; The injection current in A,
	* injection active power in KW, injected reactive power in KVAR, and injected apparent power in KVA, respectively.
	*/
    let PQ, I, Inj_I, Inj_P, Inj_Q, Inj_S, OutIndex, FrameCount
    let x, y, InIndex, scale, OutFun, tmp
    OutFun = PFR_GetOutFun(op)
    FrameCount = Frame2 - Frame1 + 1
    I			= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    PQ		= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    Inj_I = new Float32Array(FrameCount)
    Inj_P = new Float32Array(FrameCount)
    Inj_Q = new Float32Array(FrameCount)
    Inj_S = new Float32Array(FrameCount)

    this.GetCtPQinj(BufferIndex, Frame1, Frame2, CtIndex, RefSide, I, PQ)
    InIndex = 0
    OutIndex = 0
    scale = 1.0

    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetIabc(InIndex, I)
      Inj_I[OutIndex] = scale * OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      x = PQ[InIndex + 0]
      y = PQ[InIndex + PFR_1]
      Inj_P[OutIndex] += x
      Inj_Q[OutIndex] += y

      x = PQ[InIndex + PFR_2]
      y = PQ[InIndex + PFR_3]
      Inj_P[OutIndex] += x
      Inj_Q[OutIndex] += y

      x = PQ[InIndex + PFR_4]
      y = PQ[InIndex + PFR_5]
      Inj_P[OutIndex] += x
      Inj_Q[OutIndex] += y

      x = PQ[InIndex + PFR_6] // Neutral
      y = PQ[InIndex + PFR_7]
      Inj_P[OutIndex] += x
      Inj_Q[OutIndex] += y

      x = Inj_P[OutIndex]
      y = Inj_Q[OutIndex]
      Inj_S[OutIndex] = Math.sqrt(x * x + y * y)

      InIndex = InIndex + PFR_NUM_PER_BUS
      OutIndex++
    }
    return ([Inj_I, Inj_P, Inj_Q, Inj_S])
  }

  // ---------------------------------------------------------------------------
  GetVsInj (BufferIndex, Frame1, Frame2, VsIndex, op) {
    /**
		* Voltage Source Injection.
		*
		* The current and powers injection from the Voltage Source where positive injection means the Voltage Source is injecting
		* and negative injection means the Voltage Sourceus is being supplied.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   VsIndex - The voltage source index.
		* @param {integer}   op - is used for the current only. (0) take minimum, (1) take maximum, else take average  of phases.
		* @return {double} 	 Return four signals; The injection current in A,
		* injection active power in KW, injected reactive power in KVAR, and injected apparent power in KVA, respectively.
		*/
    let p, q, BusIndex
    let PQ, I, Inj_I, Inj_P, Inj_Q, Inj_S, OutIndex
    let FrameCount, k, InIndex, scale_i, scale_s, base_v, OutFun, tmp
    BusIndex = this.VS_Bus[VsIndex]
    OutFun = PFR_GetOutFun(op)
    FrameCount = Frame2 - Frame1 + 1
    I			= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    PQ		= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    Inj_I = new Float32Array(FrameCount)
    Inj_P = new Float32Array(FrameCount)
    Inj_Q = new Float32Array(FrameCount)
    Inj_S = new Float32Array(FrameCount)
    this.GetInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetCtInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetNegativeValueArray(I)
    this.GetInjPower(BufferIndex, Frame1, Frame2, BusIndex, I, PQ)
    InIndex		= 0
    OutIndex	= 0
    base_v = this.Bus_Base[BusIndex]
    if ((op & 256) != 0) // is pu  BM221221 : complete the following code
    {
      scale_i = base_v / this.Base_S
      scale_s = 1.0 / this.Base_S
    } else {
      scale_i = 1.0
      scale_s = 1.0
    }
    for (; FrameCount > 0; FrameCount--) {
      tmp = this.GetIabc(InIndex, I)
      Inj_I[OutIndex] = scale_i * OutFun(tmp[PFR_0], tmp[PFR_1], tmp[PFR_2])
      p = 0.0
      q = 0.0
      for (k = 0; k < 4; k++) {
        p += PQ[InIndex++]
        q += PQ[InIndex++]
      }
      Inj_P[OutIndex] = (p * scale_s)
      Inj_Q[OutIndex] = (q * scale_s)
      Inj_S[OutIndex] = Math.sqrt((p * p) + (q * q)) * scale_s
      OutIndex++
    }
    return ([Inj_I, Inj_P, Inj_Q, Inj_S])
  }

  // ---------------------------------------------------------------------------
  GetVsPhaseInj (BufferIndex, Frame1, Frame2, VsIndex, Phase) {
    /**
		* Voltage Source Phase Injection.
		*
		* The current and powers injection from the Voltage Source where positive injection means the Voltage Source is injecting
		* and negative injection means the Voltage Sourceus is being supplied.
		*
		* @since      2.0.1
		* @access     private
		* @param {integer}   BufferIndex - The index of the buffer (index of the case).
		* @param {integer}   Frame1 - The index of the starting frame or starting hour.
		* @param {integer}   Frame2 - The index of the ending frame or ending hour.
		* @param {integer}   VsIndex - The voltage source index.
		* @param {integer}   Phase - Phase or neutral index; 0 for phase A, 1 for phase B, 2 for phase C, 3 for the neutral..
		* @return {double} 	 Return four signals; The injection current in A,
		* injection active power in KW, injected reactive power in KVAR, and injected apparent power in KVA, respectively.
		*/
    let x, y, BusIndex
    let PQ, I, Inj_I, Inj_P, Inj_Q, Inj_S, OutIndex
    let FrameCount, InIndex
    BusIndex = this.VS_Bus[VsIndex]
    FrameCount = Frame2 - Frame1 + 1
    Phase = Phase & 3
    I			= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    PQ		= new Float32Array(FrameCount * PFR_NUM_PER_BUS)
    Inj_I = new Float32Array(FrameCount)
    Inj_P = new Float32Array(FrameCount)
    Inj_Q = new Float32Array(FrameCount)
    Inj_S = new Float32Array(FrameCount)
    this.GetInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetCtInjCurrent(BufferIndex, Frame1, Frame2, BusIndex, I)
    this.GetNegativeValueArray(I)
    this.GetInjPower(BufferIndex, Frame1, Frame2, BusIndex, I, PQ)
    InIndex		= 2 * Phase
    OutIndex	= 0
    for (; FrameCount > 0; FrameCount--) {
      x = I[InIndex + PFR_0]
      y = I[InIndex + PFR_1]
      Inj_I[OutIndex] = Math.sqrt((x * x) + (y * y))
      x = PQ[InIndex + PFR_0]
      y = PQ[InIndex + PFR_1]
      Inj_P[OutIndex] = x
      Inj_Q[OutIndex] = y
      Inj_S[OutIndex] = Math.sqrt((x * x) + (y * y))
      InIndex = InIndex + PFR_NUM_PER_BUS
      OutIndex++
    }
    return ([Inj_I, Inj_P, Inj_Q, Inj_S])
  }

  // ---------------------------------------------------------------------------
  GetNegativeValueArray (array) {
    let index
    for (index = 0; index < array.length; index++) {
      array[index] = -array[index]
    }
  }

  // ---------------------------------------------------------------------------
  GetSignSignal (BufferIndex, Frame1, Frame2, VsIndex, Type) {
    let k, Signal, Sign
    if (Type == 0) {
      Sign = 1
      k = 1
    } else if (Type == 1) {
      Sign = -1
      k = 1
    } else if (Type == 2) {
      Sign = 1
      k = 2
    } else {
      Sign = -1
      k = 2
    }
    Signal = this.GetVsInj(BufferIndex, Frame1, Frame2, VsIndex, 2)[k]
    return [Sign, Signal]
  }

  // ---------------------------------------------------------------------------
  SetVsLimits (VsIndex, Pin_max, Pout_max, Qin_max, Qout_max) {
    this.VS_PinMax[VsIndex] 	= Pin_max
    this.VS_PoutMax[VsIndex] 	= Pout_max
    this.VS_QinMax[VsIndex] 	= Qin_max
    this.VS_QoutMax[VsIndex] 	= Qout_max
  }

  // ---------------------------------------------------------------------------
  GetVsLimits (vsIndex) {
    return ([this.VS_PinMax[vsIndex], this.VS_PoutMax[vsIndex], this.VS_QinMax[vsIndex], this.VS_QoutMax[vsIndex]])
  }

  // ---------------------------------------------------------------------------
  GetSpVioDur (BufferIndex, Frame1, Frame2, VsIndex, Type, max_value) // Type: 0=Pout, 1=Pin, 2=Qout, 3=Qin
  {
    /**
	* Duration Subscription violations.
	*
	* The subscription violation values related to the duration of violation.
	*
	* @since      2.0.1
	* @access     private
	* @param {integer}   BufferIndex - The index of the buffer (index of the case).
	* @param {integer}   Frame1 - The index of the starting frame or starting hour.
	* @param {integer}   Frame2 - The index of the ending frame or ending hour.
	* @param {integer}   VsIndex - The voltage source index.
	* @param {integer}   Type - Type of violation; 0 = P_out, 1 = P_in, 2 = Q_out, 3 = Q_in.
	* @param {integer}   max_value - P_OUT_MAX, P_IN_MAX, Q_OUT_MAX, or Q_IN_MAX (KW, or KVAr) depend on Type.
	* @return {double} 	 Return four outputs:
	(1) The number of instants that the subscription limit was overpassed,
	(2) The total number of hours that the subscription limit was overpassed
	(3) The average duration (in hours) that the subscription limit was overpassed
	(4) The standard deviation of the duration (in hours) beyond the subscription limit.
	*/
    let tmp, Signal, Sign, FrameIndex, TotDur, StepTime, meanDur, Dur
    let count, state_final, state_init, number, H, FrameCount, std
    StepTime = this.StepTime / PFR_3600
    FrameCount = Frame2 - Frame1 + 1
    H			= new Float32Array(FrameCount)
    tmp = this.GetSignSignal(BufferIndex, Frame1, Frame2, VsIndex, Type)
    Sign = tmp[0]
    Signal = tmp[1]
    Dur = 0
    TotDur = 0
    state_final = 0
    state_init = state_final
    count = 0
    number = 0
    for (FrameIndex = Frame1; FrameIndex <= Frame2; FrameIndex++) {
      if (Sign * Signal[FrameIndex] > max_value) {
        state_final = 1
        number++
        TotDur += StepTime
        Dur += StepTime
      } else {
        state_final = 0
      }
      if (state_final > state_init) {
        count++
      } else if (state_final < state_init) {
        H[count - 1] = Dur
        Dur = 0
      } else {
        // NO CODE
      }
      state_init = state_final
    }
    if (state_final == 1) {
      H[count - 1] = Dur
    }
    if (count > 0) {
      meanDur = TotDur / count
      H = H.slice(0, count)
      std = this.GetSTD(H, meanDur)
    } else {
      meanDur = 0
      std = 0
    }
    return ([number, TotDur, meanDur, std])
  }

  // ---------------------------------------------------------------------------
  GetSpVioPower (BufferIndex, Frame1, Frame2, VsIndex, Type, max_value) {
    /**
	* Power Subscription violations.
	*
	* The subscription violation values related to the over-power of violation.
	*
	* @since      2.0.1
	* @access     private
	* @param {integer}   BufferIndex - The index of the buffer (index of the case).
	* @param {integer}   Frame1 - The index of the starting frame or starting hour.
	* @param {integer}   Frame2 - The index of the ending frame or ending hour.
	* @param {integer}   VsIndex - The voltage source index.
	* @param {integer}   Type - Type of violation; 0 = P_out, 1 = P_in, 2 = Q_out, 3 = Q_in.
	* @param {integer}   max_value - P_OUT_MAX, P_IN_MAX, Q_OUT_MAX, or Q_IN_MAX (KW, or KVAr) depend on Type.
	* @return {double} 	 Return four outputs:
	(1) The amount of maximum delta power in kW that the subscription limit was overpassed,
	(2) The average delta power in kW that the subscription limit was overpassed
	(3) The standard deviation in kW of the delta power that the subscription limit was overpassed
	(4) The amount of energy (KWh) that was demanded beyond the subscription limit.
	*/
    let tmp, Signal, Sign, FrameIndex, TotP, meanP, Pmax
    let deltaP, sumP, number, P, FrameCount, std, StepTime
    StepTime = this.StepTime / PFR_3600
    FrameCount = Frame2 - Frame1 + 1
    TotP			= new Float32Array(FrameCount)
    tmp = this.GetSignSignal(BufferIndex, Frame1, Frame2, VsIndex, Type)
    Sign = tmp[0]
    Signal = tmp[1]
    Pmax = 0
    deltaP = 0
    sumP = 0
    number = 0
    for (FrameIndex = Frame1; FrameIndex <= Frame2; FrameIndex++) {
      P = Sign * Signal[FrameIndex]
      if (P > max_value) {
        deltaP = P - max_value
        sumP += deltaP
        TotP[number++] = deltaP
        if (deltaP > Pmax) {
          Pmax = deltaP
        }
      }
    }
    if (number > 0) {
      meanP = sumP / number
      TotP = TotP.slice(0, number)
      std = this.GetSTD(TotP, meanP)
    } else {
      meanP = 0
      std = 0
    }
    return ([Pmax, meanP, std, StepTime * sumP])
  }

  // ---------------------------------------------------------------------------
  GetSTD (array, meanDur) {
    let k, sum, diff, count, std
    count = array.length
    sum = 0
    for (k = 0; k < count; k++) {
      diff = array[k] - meanDur
      sum += diff * diff
    }
    std = Math.sqrt(sum / count)
    return (std)
  }

  // ---------------------------------------------------------------------------
  is_VS_Bus (BusIndex) {
    let k, is
    is = 0
    for (k = 0; k < this.VS_count; k++) {
      if (this.VS_Bus[k] == BusIndex) {
        is = 1
      }
    }
    return (is)
  }

  // ---------------------------------------------------------------------------
  GetCaseVio (BufferIndex, Frame1, Frame2) {
    let index, BusIndex, phase, array1, array2, FrameCount, tmp, out, OutIndex, inputs
    FrameCount = Frame2 - Frame1 + 1
    array1 = new Uint16Array(FrameCount)
    array2 = new Float32Array(FrameCount)
    out = [] // BM fast fix for new Array
    OutIndex = 0
    for (index = 0; index < this.CgpCount; index++) {
      BusIndex = this.CGP_Bus[index]
      for (phase = 0; phase < PFR_3; phase++) {
        tmp = this.GetBusPhaseOverV(BufferIndex, Frame1, Frame2, BusIndex, phase, array1, array2)
        inputs = [tmp[1], 'OverVoltage', 'CGP', this.CGP_ID[index], phase]
        OutIndex = this.fill_out_array(inputs, array1, array2, out, OutIndex)
        tmp = this.GetBusPhaseUnderV(BufferIndex, Frame1, Frame2, BusIndex, phase, array1, array2)
        inputs = [tmp[1], 'UnderVoltage', 'CGP', this.CGP_ID[index], phase]
        OutIndex = this.fill_out_array(inputs, array1, array2, out, OutIndex)
      }
      tmp = this.GetRateBusVu(BufferIndex, Frame1, Frame2, BusIndex, array1, array2)
      inputs = [tmp[1], 'UnbalanceFactor', 'CGP', this.CGP_ID[index], PFR_4]
      OutIndex = this.fill_out_array(inputs, array1, array2, out, OutIndex)
    }
    for (index = 0; index < this.LineCount; index++) {
      for (phase = 0; phase < PFR_3; phase++) {
        tmp = this.GetLinePhaseOverUti(BufferIndex, Frame1, Frame2, index, phase, array1, array2)
        inputs = [tmp[1], 'OverCurrent', 'Line', this.Con_ID[index], phase]
        OutIndex = this.fill_out_array(inputs, array1, array2, out, OutIndex)
      }
    }
    for (index = 0; index < this.CT_count; index++) {
      tmp = this.GetCtOverUti(BufferIndex, Frame1, Frame2, index, array1, array2)
      inputs = [tmp[1], 'OverLoading', 'Transformer', this.CT_ID[index], PFR_4]
      OutIndex = this.fill_out_array(inputs, array1, array2, out, OutIndex)
    }
    return (out)
  }

  // ---------------------------------------------------------------------------
  fill_out_array (inputs, array1, array2, out, OutIndex) {
    let k, Letter, count, name, type, id, phase
    Letter = ['A', 'B', 'C', 'N', 'ABC']
    count = inputs[PFR_0]
    name = inputs[PFR_1]
    type = inputs[PFR_2]
    id = inputs[PFR_3]
    phase = Letter[inputs[PFR_4]]
    for (k = 0; k < count; k++) {
      out[OutIndex++] = [name, array2[k], type, id, phase, array1[k]]
    }
    return (OutIndex)
  }

  // ---------------------------------------------------------------------------
  GetConLines (BusIndex) {
    let lines, switches, OutLine, OutSwitch, k, cond
    lines = []
    switches = []
    OutLine = 0
    OutSwitch = 0
    for (k = 0; k < this.Con_Count; k++) {
      cond = (this.Con_Src[k] == BusIndex) || (this.Con_Dst[k] == BusIndex)
      if ((cond != 0) && (this.Con_State[k] != 0)) {
        if (k < this.LineCount) {
          lines[OutLine++] = this.Con_ID[k]
        } else {
          switches[OutSwitch++] = this.Con_ID[k]
        }
      } else {
        /* NO CODE */
      }
    }
    return ([lines, switches])
  }

  // ---------------------------------------------------------------------------
  vio_copy (s, s_index, count, is_ok, d, d_index) {
    let counter
    if (is_ok != 0) {
      for (counter = count; counter > 0; counter--) {
        d[d_index++] = s[s_index++]
      }
    } else {
      for (counter = count; counter > 0; counter--) {
        d[d_index++] = 0.0
      }
      count = 0
    }
    return (count)
  }

  // ---------------------------------------------------------------------------
  /* Capacity estimation based on Random Simulation */
  /*
*	This function should be called first before calling any function of capacity estimation.
*	It reads the binary buffer of violation which includes the results of capacity estimation.
*	@param {Array} Buffer binary buffer of violation
*	The structure of the binary buffer of violation is designed as following:
*	1) Header
*		1.1) BusCount			:	Bus count
*		1.2) LineCount		: Line count
*		1.3) TransCount		:	Trans count (should be fixed at 1)
*		1.4) FrameCount		: Frame count (should be fixed at 24)
*		1.5) StartTime	 	:	Starting time
*		1.6) StepTime			: Time step (should be 3600)
*		1.7) TestMode			: 1: EV capacity, 2: PV Capacity
*		1.8) TestCount		: Test count
*		1.9) PercentStart	: Start percent of EV penetration
*		1.10) PercentEnd	: Final percent of EV penetration
*		1.11) PercentStep	: Step percent in EV capacity test
*		1.12) PowerStart	: Start power in PV capacity test
*		1.13) PowerEnd		: Final power in PV capacity test
*		1.14) PowerStep		: Step of total power in PV capacity test
*		1.15) Percent			: Last percent without violation
*		1.16) Power				: Last power without violation
*	2) Test results
*		2.1) Bus records (24 records representing a single day hours from 0 to 23)
*			2.1.1) UVA : The percent of under voltage violation of phase A
*			2.1.2) UVB : The percent of under voltage violation of phase B
*			2.1.3) UVC : The percent of under voltage violation of phase C
*			2.1.4) OVA : The percent of over voltage violation of phase A
*			2.1.5) OVB : The percent of over voltage violation of phase B
*			2.1.6) OVC : The percent of over voltage violation of phase C
*			2.1.7) NS  : The percent of violation of negative sequence in the voltage
*			2.1.8) ZS  : The percent of violation of zero sequence in the voltage
*		2.2) Line records (24 records representing a single day hours from 0 to 23)
*			2.2.1) OCA : The percent of over current violation of phase A
*			2.2.2) OCB : The percent of over current violation of phase B
*			2.2.3) OCC : The percent of over current violation of phase C
*			2.2.4) OCN : The percent of over current violation of neutral
*		2.3) Transformer records (24 records representing a single day hours from 0 to 23)
*			The percent of violation of overloading of the transformer.
*/
  LoadViolationBuffer (buffer) {
    let m, n, k_in, k_rec_bus, k_rec_line, k_rec_trans
    let k_buf_bus, k_buf_line, k_buf_trans, is_ok, TestIndex
    let d

    this.TestMode = buffer[PFR_0] & 31
    this.TestCount			= buffer[PFR_7]			/** < Test count */
    this.PercentStart		= buffer[PFR_8]			/** < Start percent of EV penetration */
    this.PercentEnd			= buffer[PFR_9]			/** < Final percent of EV penetration */
    this.PercentStep		= buffer[PFR_10]			/** < Step percent in EV capacity test	 */
    this.PowerStart			= buffer[PFR_11]			/** < Start power in PV capacity test	*/
    this.PowerEnd				= buffer[PFR_12]			/** < Final power in PV capacity test	*/
    this.PowerStep			= buffer[PFR_13]			/** < Step of total power in PV capacity test */

    k_in					= PFR_16
    k_rec_bus			= 0
    k_rec_line		= 0
    k_rec_trans		=	0
    k_buf_bus			= 0
    k_buf_line		= 0
    k_buf_trans		=	0

    is_ok = (this.TestMode == 11) || (this.TestMode == 12) // extended mode
    for (TestIndex = 0; TestIndex < this.TestCount; TestIndex++) {
      /* Bus Violation */
      n	= PFR_VIO_HOUR_COUNT * PFR_VIO_PER_BUS
      d = this.Violation_RecBus
      m = this.vio_copy(buffer, k_in, n, 1, d, k_rec_bus)
      k_in = k_in + m
      k_rec_bus = k_rec_bus + n

      n	= PFR_VIO_HOUR_COUNT * PFR_VIO_PER_BUS * this.BusCount
      d = this.Violation_BufBus
      m = this.vio_copy(buffer, k_in, n, is_ok, d, k_buf_bus)
      k_in = k_in + m
      k_buf_bus = k_buf_bus + n

      /* Line Violation */
      n	= PFR_VIO_HOUR_COUNT * PFR_VIO_PER_LINE
      d = this.Violation_RecLine
      m = this.vio_copy(buffer, k_in, n, 1, d, k_rec_line)
      k_in = k_in + m
      k_rec_line = k_rec_line + n

      n	= PFR_VIO_HOUR_COUNT * PFR_VIO_PER_LINE * this.LineCount
      d = this.Violation_BufLine
      m = this.vio_copy(buffer, k_in, n, is_ok, d, k_buf_line)
      k_in = k_in + m
      k_buf_line = k_buf_line + n

      /* Trans Violation */
      n	= PFR_VIO_HOUR_COUNT
      d = this.Violation_RecTrans
      m = this.vio_copy(buffer, k_in, n, 1, d, k_rec_trans)
      k_in = k_in + m
      k_rec_trans = k_rec_trans + n

      n	= PFR_VIO_HOUR_COUNT * this.CT_count
      d = this.Violation_BufTrans
      m = this.vio_copy(buffer, k_in, n, is_ok, d, k_buf_trans)
      k_in = k_in + m
      k_buf_trans = k_buf_trans + n
    }
    return (0)
  }

  // ---------------------------------------------------------------------------
  /*
Generate a power vector which is used during the tests of capacity estimation for PV.
The values of this vector are calculated based on the parameters of the header.
PowerStart	: Start power in PV capacity test
PowerEnd		: Final power in PV capacity test
PowerStep		: Step of total power in PV capacity test
@returns {Array} power vector of capacity estimation for PV
*/
  TestPowerVec () {
    let Index, Power, OutDat
    OutDat = new Float32Array(this.TestCount)
    Power = this.PowerStart
    for (Index = 0; Index < this.TestCount; Index++) {
      OutDat[Index] = Power
      Power = Power + this.PowerStep
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  /*
*	Generate a vector of the percent of penetration of EV which is used during
*	the tests of capacity estimation for EV.
*	The values of this vector are calculated based on the parameters of the header.
*	PercentStart	: Start percent of EV penetration
*	PercentEnd	  : Final percent of EV penetration
*	PercentStep	  : Step percent in EV capacity test
*
*	@returns {Array} vector of the percent of penetration of EV
*/
  TestPercentVec () {
    let Index, Percent, OutDat
    OutDat = new Float32Array(this.TestCount)
    Percent = this.PercentStart
    for (Index = 0; Index < this.TestCount; Index++) {
      OutDat[Index] = PFR_100 * Percent
      Percent = Percent + this.PercentStep
    }
    return (OutDat)
  }
  // ---------------------------------------------------------------------------
  /*
*	This is an internal private function and should not be called outside this class.
*	Generate an average aggregated violation vector for a single test.
*	@param {Array} buf       : The buffer of violation records
*	@param {number} TestIndex : The index of the selected test
*	@param {number} Frame1    : The first frame index
*	@param {number} Frame2    : The last frame index
*	@param {number} RecMask   : binary record mask to select any group of violation types as following :
*     	 Bus RecMask
*					1 : Under voltage violation of phase A
*					2 : Under voltage violation of phase B
*					4 : Under voltage violation of phase C
*					8 : Over voltage violation of phase A
*				 16 : Over voltage violation of phase B
*				 32 : Over voltage violation of phase C
*				 64 : Violation of negative sequence in the voltage
*				128 : Violation of zero sequence in the voltage
*     	 Line RecMask
*				    1 : Over current violation of phase A
*     			2 : Over current violation of phase B
*     			4 : Over current violation of phase C
*     			8 : Over current violation of phase N
*			Transformer RecMask
*     			1 : Over loading of transformer
*     SigCount  : The count of violation signals (8 : bus , 4 : line and 1 : transformer)
*     MaxVal    : The initial value of maximum violation
*
*	@returns {Array} average aggregated violation vector
*
*/

  GetSigVio (buf, TestIndex, Frame1, Frame2, RecMask, SigCount, MaxVal) {
    let Val, FrameIndex, SigIndex, StartOffset, Offset, chk

    StartOffset = (SigCount * PFR_VIO_HOUR_COUNT * TestIndex)
    StartOffset += (SigCount * Frame1)
    Val = MaxVal
    for (SigIndex = 0; SigIndex < SigCount; SigIndex++) {
      chk	 = RecMask & 1
      RecMask = RecMask >> 1
      if (chk != 0) {
        Offset = StartOffset + SigIndex
        for (FrameIndex = Frame1; FrameIndex <= Frame2; FrameIndex++) {
          if (Val < buf[Offset]) {
            Val = buf[Offset]
          }
          Offset = Offset + SigCount
        }
      }
    }
    return (Val)
  }
  // ---------------------------------------------------------------------------
  /*
*	Generate an average aggregated violation vector for a selected frame range.
*	@param {number} Frame1 : The first frame index
*	@param {number} Frame2 : The last frame index
*	@param {number} Mask   : Binary mask to select any group of violation types as following :
*			1 : Under voltage violation of phase A
*			2 : Under voltage violation of phase B
*			4 : Under voltage violation of phase C
*			8 : Over voltage violation of phase A
*		 16 : Over voltage violation of phase B
*		 32 : Over voltage violation of phase C
*		 64 : Violation of negative sequence in the voltage
*		128 : Violation of zero sequence in the voltage
*		256 : Over current violation of phase A
*		512 : Over current violation of phase B
*	 1024 : Over current violation of phase C
*	 2048 : Over current violation of phase N
*	 4096 : Over loading of transformer
*	@returns {Array} average aggregated violation vector
*
*/

  GetViolation (Frame1, Frame2, Mask) {
    let OutDat, MaxVal, TestIndex, RecMask
    OutDat = new Float32Array(this.TestCount)

    for (TestIndex = 0; TestIndex < this.TestCount; TestIndex++) {
      MaxVal = 0

      /* Bus Violation */
      RecMask = (Mask & PFR_VIO_BUS_MASK)
      MaxVal = this.GetSigVio(this.Violation_RecBus, TestIndex,
        Frame1, Frame2, RecMask, PFR_VIO_PER_BUS, MaxVal)

      /* Line Violation */
      RecMask = ((Mask >> PFR_8) & PFR_VIO_LINE_MASK)
      MaxVal = this.GetSigVio(this.Violation_RecLine, TestIndex,
        Frame1, Frame2, RecMask, PFR_VIO_PER_LINE, MaxVal)

      /* Transformer Violation */
      RecMask = ((Mask >> PFR_12) & 0x0001)
      MaxVal = this.GetSigVio(this.Violation_RecTrans, TestIndex,
        Frame1, Frame2, RecMask, 1, MaxVal)

      OutDat[TestIndex] = MaxVal
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  MCS_GetHeatMap (test_index, frame, mode, vio_mask) {
    let elm_count, elm_index, vio_count, vio_index, k_in, k_out, vio_val, chk
    let b, out
    if (mode == 0) // bus violation heat map
    {
      b = this.Violation_BufBus
      vio_count = 8
      elm_count = this.BusCount
    } else if (mode == 1) // line violation heat map
    {
      vio_mask = vio_mask >> 8
      b = this.Violation_BufLine
      vio_count = 4
      elm_count = this.LineCount
    } else // transformer violation heat map
    {
      vio_mask = vio_mask >> 12
      b = this.Violation_BufTrans
      vio_count = 1
      elm_count = this.CT_count
    }
    k_out = 0
    k_in = test_index * vio_count * elm_count * PFR_VIO_HOUR_COUNT
    k_in += frame * vio_count * elm_count
    out = new Float32Array(elm_count)
    for (elm_index = 0; elm_index < elm_count; elm_index++) {
      vio_val = 0 // assume no violation
      for (vio_index = 0; vio_index < vio_count; vio_index++) {
        chk	 = (vio_mask & (1 << vio_index)) && (vio_val < b[k_in])
        if (chk != 0) {
          vio_val = b[k_in]
        }
        k_in++
      }
      out[k_out++] = vio_val
    }
    return (out)
  }

  // ---------------------------------------------------------------------------
  /*
*	Find the frame index with the maximum violation of a given test
*	@param {number} Frame1 : The first frame index
*	@param {number} Frame2 : The last frame index
*	@param {number} Mask   : Binary mask to select any group of violation types as following :
*			1 : Under voltage violation of phase A
*			2 : Under voltage violation of phase B
*			4 : Under voltage violation of phase C
*			8 : Over voltage violation of phase A
*		 16 : Over voltage violation of phase B
*		 32 : Over voltage violation of phase C
*		 64 : Violation of negative sequence in the voltage
*		128 : Violation of zero sequence in the voltage
*		256 : Over current violation of phase A
*		512 : Over current violation of phase B
*	 1024 : Over current violation of phase C
*	 2048 : Over current violation of phase N
*	 4096 : Over loading of transformer
*	@returns {number} the selected frame index with maximum violation
*
*/
  MCS_GetMaxVioFrame (Frame1, Frame2, Mask) {
    let MaxVal, TestIndex, frame, max_frame, max_vio, RecMask

    max_frame = Frame1
    max_vio = 0
    for (TestIndex = 0; TestIndex < this.TestCount; TestIndex++) {
      for (frame = Frame1; frame < Frame2; frame++) {
        MaxVal = 0
        /* Bus Violation */
        RecMask = (Mask & PFR_VIO_BUS_MASK)
        MaxVal = this.GetSigVio(this.Violation_RecBus, TestIndex,
          frame, frame, RecMask, PFR_VIO_PER_BUS, MaxVal)

        /* Line Violation */
        RecMask = ((Mask >> PFR_8) & PFR_VIO_LINE_MASK)
        MaxVal = this.GetSigVio(this.Violation_RecLine, TestIndex,
          frame, frame, RecMask, PFR_VIO_PER_LINE, MaxVal)

        /* Transformer Violation */
        RecMask = ((Mask >> PFR_12) & 0x0001)
        MaxVal = this.GetSigVio(this.Violation_RecTrans, TestIndex,
          frame, frame, RecMask, 1, MaxVal)

        if (max_vio < MaxVal) {
          max_vio		= MaxVal
          max_frame = frame
        }
      }
    }
    return (max_frame)
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  /* Hosting Capacity Map */
  /*
*	This function should be called first before calling any function of hosting capacity.
*	It reads the binary buffer of hosting capacity which includes the results of hosting capacity map.
*	@param {Array} Buffer binary buffer of hosting capacity
*	The structure of the binary buffer of hosting capacity is designed as following:
*	1) CGP records (24x3 records representing a single day hours from 0 to 23 for every phase)
*			1.1) PhaseA : The hosting capacity of phase A for a single day hours from 0 to 23
*			1.2) PhaseB : The hosting capacity of phase B for a single day hours from 0 to 23
*			1.3) PhaseC : The hosting capacity of phase C for a single day hours from 0 to 23
*
*/
  HC_LoadBuffer (Buffer) {
    let ElmCount, Index
    ElmCount = Buffer.length
    for (Index = 0; Index < ElmCount; Index++) {
      this.Hosting_capacity[Index] = Buffer[Index]
    }
  }

  // ---------------------------------------------------------------------------
  HC_GetFrame (Frame1, Frame2, PhaseIndex, Mode) {
    let HC, OutDat, OutIndex, FrameCount, InIndex, TypeShift
    let ElmCount, FrameIndex, CgpIndex, HoursCount, PhasesCount
    HoursCount = 24
    PhasesCount = 4
    if (PhaseIndex > 3) {
      PhaseIndex = 3
    }
    HC = this.Hosting_capacity
    FrameCount = Frame2 - Frame1 + 1
    ElmCount = FrameCount * this.CgpCount
    OutDat = new Float32Array(ElmCount)
    if (Mode == 0) {
      TypeShift = 0
    } else {
      TypeShift = HoursCount * this.CgpCount * PhasesCount
    }
    OutIndex = 0
    for (FrameIndex = Frame1; FrameIndex <= Frame2; FrameIndex++) {
      InIndex = FrameIndex * this.CgpCount * PhasesCount + PhaseIndex + TypeShift
      for (CgpIndex = 0; CgpIndex < this.CgpCount; CgpIndex++) {
        OutDat[OutIndex++] = HC[InIndex]
        InIndex += 4
      }
    }
    return (OutDat)
  }

  // ---------------------------------------------------------------------------
  HC_GetCGP (CgpIndex, PhaseIndex, Mode) {
    let HC, OutDat, OutIndex, InIndex, InOffset
    let FrameIndex, HoursCount, PhasesCount
    HoursCount = 24
    PhasesCount = 4
    if (PhaseIndex > 3) {
      PhaseIndex = 3
    }
    HC = this.Hosting_capacity
    OutDat = new Float32Array(HoursCount)
    InOffset = this.CgpCount * PhasesCount
    if (Mode == 0) {
      InIndex = 0
    } else {
      InIndex = HoursCount * this.CgpCount * PhasesCount
    }
    OutIndex = 0
    InIndex += (CgpIndex * PhasesCount) + PhaseIndex
    for (FrameIndex = 0; FrameIndex <= HoursCount; FrameIndex++) {
      OutDat[OutIndex++] = HC[InIndex]
      InIndex += InOffset
    }
    return (OutDat)
  }
// ---------------------------------------------------------------------------
} // End of class
// ===========================================================================
if ((typeof exports === 'object') && (typeof module === 'object')) {
  module.exports = PowerFlowResult
  // This will allow the module to be used in ES6 or CommonJS
  module.exports.default = PowerFlowResult
} else if ((typeof define === 'function') && (define.amd)) {
  define([],
    function () {
      return PowerFlowResult
    }
  )
} else if (typeof exports === 'object') {
  exports.Module = PowerFlowResult
} else {
  console.error('undefined object')
}
// ===========================================================================
