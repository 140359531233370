<template>
  <v-card elevation="10">
    <v-form
      ref="DisableElementForm"
      v-model="valid"
      lazy-validation
    >
      <v-card-title class="text-h5">
        {{ $t('new_connections.toggle_state') }}
      </v-card-title>
      <v-card-text>
        {{ $t('new_connections.confirm_toggle_state__msg') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#f4c020"
          text
          @click="closeForm"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          id="confirm"
          :disabled="!valid"
          data-cy="dt-add-ev"
          color="#f4c020"
          text
          @click="toggleDisableElement"
        >
          {{ $t('ok') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import digitalTwinForm from '@/mixins/digitalTwinForm'
import { mapState } from 'vuex'

export default {
  name: 'DTDisableElementForm',

  mixins: [digitalTwinForm],

  data () {
    return {
      DEVICE: 'Disable_Element'
    }
  },
  computed: {
    ...mapState({
      disables: (state) => state.DTChanges.Disable_Element
    }),
    indexDisableList () {
      const indices = []
      let idx = this.disables.id.indexOf(this.contextMenuElement.info.ID)
      // Could be coincident id's between different type of elements
      while (idx !== -1) {
        indices.push(idx)
        idx = this.disables.id.indexOf(this.contextMenuElement.info.ID, idx + 1)
      }

      if (indices.length > 0) {
        for (const index of indices) {
          if (this.disables.element_type[index] === this.typeElementNumber) {
            return index
          }
        }
      }
      return null
    }
  },
  methods: {
    toggleDisableElement () {
      const index = this.indexDisableList

      if (index !== null && index >= 0) {
        // If element is on list, delete it
        this.enableElement(index)
      } else {
        this.disableElement()
      }

      this.closeForm()
    },
    disableElement () {
      this.disables.id.push(this.$store.state.contextMenuElement.info.ID)
      this.disables.element_type.push(this.typeElementNumber)
      this.disables.mode.push(0) // From frontend always 0
      this.$store.dispatch('setElement', { path: 'DTChanges.Disable_Element', value: this.disables })
      this.addDevice() // add disabled icon on the map
    },
    enableElement (index) {
      this.disables.id.splice(index, 1)
      this.disables.element_type.splice(index, 1)
      this.disables.mode.splice(index, 1)

      this.$store.dispatch('setElement', { path: 'DTChanges.Disable_Element', value: this.disables })
      this.deleteDevice() // remove disabled icon on the map
    }
  }

}
</script>
