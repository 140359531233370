<template>
  <v-card elevation="10">
    <v-card-title class="text-h5">
      {{ $t('select_data_download') }}
    </v-card-title>
    <v-card-text>
      <v-col
        class="p-2 pl-6"
        dense
      >
      <v-text-field
        v-model='fileTitle'
        :label="$t('file_title')"
        :hint="$t('title_csv_to_download')"
        required
      />
      <v-checkbox v-for="header in headers"
        :key='header'
        v-model="headersSelected"
        :label="header.text"
        :value="header.value"
        color="#f4c020"
        input-value=true
        dense
        />
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="#f4c020"
        text
        @click="closeDownload"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        text
        :disabled="headersSelected == 0"
        @click="downloadData"
      >
        {{ $t('download') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import Papa from 'papaparse'

export default {
  name: 'TDDownloadDataForm',
  props: {
    headers: { type: Object, default: null },
    data: {type: Object, default:null}
  },

  data () {
    return {
      headersSelected:this.headers.map(header => header.value),
      fileTitle: 'LoadAnalysis'
    }
  },
  computed: {
    jsonData() {
      const updatedData = []
      this.data.map((dataObject)=> {
        const filteredDataObject = {}
        this.headersSelected.forEach(header => {
          if (dataObject.hasOwnProperty(header)){
            filteredDataObject[header]=dataObject[header]
          }
        })
      updatedData.push(filteredDataObject)})
      return updatedData
    }
  },

  methods: {
    closeDownload () {
      this.$emit('close')
    },

    convertJsonToCsv(data, props) {
      return Papa.unparse(data, props)
    },

    downloadData () {
      const csvData = this.convertJsonToCsv(this.jsonData,{quotes:false, header:true})
      
      // Download CSV
      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)

      // Create a link element and click it to trigger the download
      const a = document.createElement('a')
      a.href = url
      a.download = this.fileTitle + '.csv'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      this.$emit('close')
    }
  }
}
</script>