<template>
  <div>
    <v-card dark>
      <form @submit.prevent="showConfirmPassword = true">
        <v-card-title>
          <span class="text-h5">User Information</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              dark
            >
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="firstName"
                  label="First name*"
                  :rules="[v => !!v || 'FirstName is required']"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="lastName"
                  label="Last name*"
                  hint="Your Last Name "
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  label="Email*"
                  type="email"
                  hint="Will be your email for access"
                  persistent-hint
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="username"
                  label="Username*"
                  type="text"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="department"
                  label="Department*"
                  type="text"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            type="submit"
          >
            Save Changes
          </v-btn>
        </v-card-actions>
      </form>
      <!-- Dialog for confirm your selection -->
      <v-dialog
        v-model="showConfirmPassword"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Confirm
          </v-card-title>
          <v-card-text class="yellowPlexi">
            <v-text-field
              v-model="confirmPassword"
              label="Confirm your password"
              :rules="[v => !!v || 'Password is required']"
              hint="Password"
              type="password"
              required
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="showConfirmPassword = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="userUpdate"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-card dark>
      <form @submit.prevent="changePassword">
        <v-card-title>
          <span class="text-h5">Change password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              dark
            >
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <v-text-field
                  v-model="oldPassword"
                  label="Old Password*"
                  :rules="[v => !!v || 'Old Password is required']"
                  type="password"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <v-text-field
                  v-model="newPassword"
                  label="New Password*"
                  :rules="[v => !!v || 'New Password is required']"
                  hint="New Password"
                  type="password"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <v-text-field
                  v-model="repeatPassword"
                  label="Repeat New Password*"
                  :rules="[v => !!v || 'Repeat New Password is required']"
                  hint="Repeat New Password "
                  type="password"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
          <p
            v-if="error"
            class="error"
          >
            Failed to change password successfully.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            type="submit"
          >
            Change Password
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </div>
</template>

<script>
import { update, changePassword, getInfo } from '@/services/user'

export default {
  data: () => ({
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
    error: false,
    token: '',
    firstName: null,
    lastName: null,
    email: null,
    username: null,
    department: null,
    showConfirmPassword: false,
    confirmPassword: null
  }),

  metaInfo: {
    title: 'User Configuration'
  },
  async mounted () {
    try {
      const result = await getInfo()
      this.username = result.username
      this.email = result.email
      this.firstName = result.first_name
      this.lastName = result.last_name
      this.department = result.department
    } catch (error) {
      this.error = true
    }
  },
  methods: {
    async changePassword () {
      try {
        await changePassword(this.oldPassword, this.newPassword, this.repeatPassword)
        // sessionStorage.access = result.data.access;
        // sessionStorage.refresh = result.data.refresh;
        this.$router.push('/')
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async userUpdate () {
      this.showConfirmPassword = false
      try {
        // UPDATE USER INFO IN THE BACKEND
        let result = await update(
          this.firstName, this.lastName, this.email,
          this.username, this.department, this.confirmPassword)
        // UPDATE USER INFO IN THE SCREEN
        result = await getInfo()
        this.username = result.username
        this.email = result.email
        this.firstName = result.first_name
        this.lastName = result.last_name
        this.department = result.department
        // to have it refreshed in other components (Probably in the future we should do it only in this way)
        this.$sessionStorage.userInfo = JSON.stringify(result)

      } catch (error) {
        console.log(error)
        this.error = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  padding: 2rem;
}
.title {
  text-align: center;
  font-size: 24px;
  color: #f4c020;
}
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 350px;
  max-width: 100%;
  background: #1e1e1e;
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}
.form-label {
  align-self: flex-start;
  margin-top: 2rem;
  color: white;
  margin-bottom: 0.5rem;
  &:first-of-type {
    margin-top: 0rem;
  }
}
.form-input {
  padding: 10px 15px;
  background: none;
  background-image: none;
  border: 1px solid white;
  color: white;
  &:focus {
    outline: 0;
    border-color: #f4c020;
  }
}
.white{
  color: white!important;
}
.form-submit {
  background: #272727 !important;
  border: none;
  color: #f4c020 !important;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  text-align: center;
  transition: background 0.2s;
  border-radius: 3px;
  &:hover {
    background: #f4c020 !important;
    color: #000 !important;
  }
}
.error {
  margin: 1rem 0 0;
  color: #ff4a96;
}
.msg {
  margin-top: 3rem;
  text-align: center;
}
.table{
  padding: 50px 30px 30px 30px ;
  background-color: transparent;
  color: #F4C020;
}
.error {
  margin: 1rem 0 0;
  color: #ff4a96;
}
.rowTop{
  padding-top:10px;
  padding-left:50px;
  padding-bottom:10px;
  color:white;
}
.rowUser{
  padding:40px;
  color:white;
}
.userSelected{
  margin-right:5px;
  color:#F4C020;
}
.buttonDelete{
  margin-left:10px;
  margin-top:-5px
}
.yellowPlexi{
  color:#F4C020;
}
.mtop20{
  margin-top: 20px;
}

</style>
