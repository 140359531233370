<template>
  <div class="clients">
    <!--CLIENTS-->
    <div
      id="clients"
      class="dashboard-col"
    >
      <div
        id="c_c"
        class="card dark-theme"
        style="background-color:#1F1F1F!important;padding:10px;"
      >
        <v-tooltip right>
          <template #activator="{ on }">
            <v-img
              src="@/assets/images/dbclients.png"
              width="50"
              alt="Clients"
              v-on="on"
            />
          </template>
          <span>{{ $t('grid_surveyor.clients') }}</span>
        </v-tooltip>
        <!-- <img src="@/assets/images/dbclients.png" alt="Connections Points" width="50" > -->
        <div
          class="row"
          style="margin-left:50px;"
        >
          <div class="dato-numerico col-12">
            <div
              id="nclients"
              class="numero dark-theme"
            >
              {{ nclients }}
            </div>
            <div class="texto dark-theme">
              {{ $t('clients.clients_count') }}
            </div>
          </div>
        </div>
        <div
          class="row"
          style="margin-left:50px;"
        >
          <div class="dato-stackedbar col-12">
            <div
              id="ClientNPhase"
              class="stacked"
              style="margin-bottom:130px;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getData from '@/mixins/dashboardGetData'
import sortData from '@/mixins/dashboardSortData'
import stackedBar from '@/mixins/dashboardStackedBarWidth'

export default {
  mixins: [stackedBar, sortData, getData],
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    nclients: '0'
  }),

  mounted () {
    // count statistics - FUSES

    this.nclients = this.info.statistics_clients.countMeters
    const ClientNPhaseData = [
      {
        label: '3-phase',
        value: this.info.statistics_clients.stackPhaseType['3-phase']
      },
      {
        label: '1-phase',
        value: this.info.statistics_clients.stackPhaseType['1-phase']
      },
      {
        label: 'unknown',
        value: this.info.statistics_clients.stackPhaseType['unknown']
      }
    ]

    this.stackedBar('#ClientNPhase', ClientNPhaseData)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clients{
  background-color: rgb(53, 49, 49);
  height: 33vh;
}
h1{
  color:#F4C020
}
.panelGris{
    background-color: rgb(53, 49, 49);
    color:white;
}
</style>
