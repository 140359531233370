<template>
  <div
    :style="styles"
    class="spinner spinner-origami"
  >
    <div
      :style="innerStyles"
      class="spinner-inner loading"
    >
      <span class="slice" />
      <span class="slice" />
      <span class="slice" />
      <span class="slice" />
      <span class="slice" />
      <span class="slice" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CMLoadingHexagon',

  props: {
    size: {
      default: '40px',
      type: String
    },
    colors: {
      default: () => ({
        primary: '#f4c020',
        secondary: '#f4c020'
      }),
      type: Object
    }
  },

  computed: {
    innerStyles () {
      const size = parseInt(this.size, 10) / 60
      return {
        transform: `scale(${size})`,
        '--bg-color': this.colors.primary,
        '--secondary-color': this.colors.secondary
      }
    },
    styles () {
      return {
        width: this.size,
        height: this.size
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @for $i from 1 through 6 {
    @keyframes origami-show-#{$i} {
      from{
        transform: rotateZ(60* $i + deg) rotateY(-90deg) rotateX(0deg);
        border-left-color: var(--secondary-color);
      }
    }
    @keyframes origami-hide-#{$i} {
      to{
        transform: rotateZ(60* $i + deg) rotateY(-90deg) rotateX(0deg);
        border-left-color: var(--secondary-color);
      }
    }
    @keyframes origami-cycle-#{$i} {
      $startIndex: $i*5;
      $reverseIndex: (80 - $i*5);
      #{$startIndex * 1%}{
        transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0deg);
        border-left-color: var(--secondary-color);
      }
      #{$startIndex + 5%},
      #{$reverseIndex * 1%}{
        transform: rotateZ(60* $i + deg) rotateY(0) rotateX(0deg);
        border-left-color: var(--bg-color);
      }
      #{$reverseIndex + 5%},
      100%{
        transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0deg);
        border-left-color: var(--secondary-color);
      }
    }
  }

  .spinner {
    z-index: 502;
    display: block;
    margin-left:auto;
    margin-right:auto;
    justify-content: center;
    align-items: center;
    * {
      line-height: 0;
      box-sizing: border-box;
    }
  }
  .spinner-inner {
    display: block;
    width: 60px;
    height: 68px;

    .slice {
      border-top: 18px solid transparent;
      border-right: none;
      border-bottom: 16px solid transparent;
      border-left: 30px solid #000;
      position: absolute;
      top: 0px;
      left: 50%;
      transform-origin: left bottom;
      border-radius: 3px 3px 0 0;
    }

    @for $i from 1 through 6 {
      .slice:nth-child(#{$i}) {
        transform: rotateZ(60* $i + deg) rotateY(0deg) rotateX(0);
        animation: .15s linear .9 - $i*.08s origami-hide-#{$i} both 1;
      }
    }

    &.loading {
      @for $i from 1 through 6 {
        .slice:nth-child(#{$i}) {
          transform: rotateZ(60* $i + deg) rotateY(90deg) rotateX(0);
          animation: 2s origami-cycle-#{$i} linear infinite both;
        }
      }
    }
  }
</style>
