<template>
  <div>
    <div v-if="showError">
      <CMError
        :title="titleError"
        :text="textError"
        :code="numberError"
        @hideError="hideError"
      />
    </div>
    <div v-else>
      <div v-if="showForm">
        <ASDialog
          :source="source"
          :loading="loadingArea"
          @getArea="getArea"
          @getElement="getElement"
        />
      </div>
      <div v-else>
        <v-container>
          <v-row v-if="loading">
            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="9"
            >
              <SubStations
                v-if="info"
                :msg="$t('grid.stations')"
                :info="info"
              />
            </v-col>
            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="6"
              xl="3"
            >
              <Lines
                v-if="info"
                :msg="$t('grid_surveyor.lines')"
                :info="info"
              />
            </v-col>
            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="6"
              xl="3"
            >
              <Connections
                v-if="info"
                :msg="$t('grid_surveyor.connection_points')"
                :info="info"
              />
            </v-col>
            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="4"
              xl="3"
            >
              <Fuses
                v-if="info"
                :msg="$t('grid_surveyor.fuses')"
                :info="info"
              />
            </v-col>
            <v-col
              xs="12"
              sm="12"
              md="12"
              lg="8"
              xl="6"
            >
              <Clients
                v-if="info"
                :msg="$t('grid_surveyor.clients')"
                :info="info"
              />
            </v-col>
          </v-row>
          <div v-else>
            <CMLoader
              :loading="true"
              :message="$t('grid_surveyor.loading_grid_surveyor_msg')"
            />
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SubStations from '@/components/Dashboard/SubStationsOre.vue'
import Connections from '@/components/Dashboard/ConnectionsOre.vue'
import Lines from '@/components/Dashboard/LinesOre.vue'
import Fuses from '@/components/Dashboard/FusesOre.vue'
import Clients from '@/components/Dashboard/ClientsOre.vue'
import ASDialog from '@/components/AreaSelector/ASDialog.vue'
import CMLoader from '@/components/Common/CMLoader.vue'
import { getInfo } from '@/services/dashboard'
import { mapState } from 'vuex'
import { getAreaMap } from '@/services/areaSelector'
import getAreaGridMixin from '@/mixins/getAreaGridMixin'
import CMError from '@/components/Common/CMError.vue'

export default {
  name: 'DashboardView',
  components: {
    SubStations,
    Connections,
    Lines,
    Fuses,
    Clients,
    CMLoader,
    CMError,
    ASDialog
  },
  mixins: [getAreaGridMixin],
  metaInfo: {
    title: 'Dashboard'
  },

  data: () => ({
    info: null,
    loading: false,
    loadingSelf: {
      isVisible: true,
      message: 'Loading Dashboard'
    },
    loadingArea: false,
    areaResponse: null,
    showError: false,
    numberError: null,
    titleError: null,
    textError: null,
    openform: false
  }),
  computed: {
    ...mapState({
      showForm: (state) => state.showSetAreaForm
    })
  },
  async beforeCreate () {
    try {
      this.info = await getInfo()
      this.loading = true
    } catch (error) {
      console.warn(error)
      this.error = true
    }
  },
  async mounted () {
    this.loadingArea = false
    this.$store.dispatch('setElement', {
      path: 'showSetAreaForm',
      value: false
    })
    this.source = await getAreaMap()
  },
  methods: {
    async getArea () {
      this.loadingArea = true
      this.areaResponse = await this.getAreaGrid()
      this.$store.dispatch('setElement', {
        path: 'showSetAreaForm',
        value: false
      })
      this.info = await getInfo()
      this.loadingArea = false
    },
    async getElement () {
      this.loadingArea = true
      this.areaResponse = await this.getElementGrid()
      this.isError()
      this.$store.dispatch('setElement', {
        path: 'showSetAreaForm',
        value: false
      })
      this.info = await getInfo()
      this.loadingArea = false
    },
    isError () {
      if (this.areaResponse >= 400) {
        this.showError = true
        this.numberError = this.areaResponse
        this.titleError = 'Element not found'
        this.textError =
          'Server.\n The element selected was not found. Choose other element'
      }
    },
    hideError () {
      location.reload()
    },

    openoverload () {
      this.openform = true
    }
  }
}
</script>
<style>
.panel_dashboard {
  padding: 10px;
}

.margen_izquierda_arriba {
  margin-left: 12px;
}

.margen_izquierda {
  margin-left: -12px;
}

.margen_derecha_arriba {
  margin-left: -24px;
}

.margen_derecha {
  margin-left: -12px;
}

.margen_arriba {
  margin-top: -15px;
}

/*DASHBOARD*************************************************************/
.row.dashboard {
  margin: 4px;
}

.row-card {
  margin-left: -1px;
  margin-right: 0;
  margin-top: -0.5px;
}

#CTs_card,
#connections_card {
  min-height: 51vh;
}

#clients_card,
#lines_card,
#fuses_card {
  min-height: 42vh;
}

.dashboard-col {
  padding: 0 2px;
}

.dato-numerico {
  padding-left: 18px;
  padding-right: 18px;
  display: inline-block;
  padding-top: 10px;
  min-width: 180px;
  margin-left: -30px;
}

.numero {
  font-style: normal;
  font-weight: 450;
  font-size: 38px;
  line-height: 38px;
  text-align: center;
}

.texto {
  font-family: 'Faktum Regular', sans-serif;
  font-size: 18px;
  line-height: 12px;
  text-align: center;
}

.dato-stackedbar {
  margin: 5px 5px;
  display: inline-block;
}

.stacked {
  display: block;
}

.stacked.stacked-inferior {
  margin-top: -10px;
}

.dato-piechart {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.dato-histograma {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.blanco line {
  stroke: #fff;
}

.blanco path {
  stroke: #fff;
}

.blanco text {
  font-family: 'Faktum Light', sans-serif;
  font-size: 11px;
  fill: #fff;
}

a.ari-link {
  color: #c29819;
}

a.ari-link:hover {
  color: #c29819;
}

a.ari-link:visited {
  color: #755c07;
}

/*DARK THEME**********************************************************************/
body.dark-theme {
  background-color: #333;
}

nav.navbar-right.dark-theme {
  background-color: #000;
  padding: 4px 3px;
  flex-flow: row nowrap;
  display: block;
  user-select: none;
}

h1.menu.dark-theme {
  color: #f4c020;
}

ul.menu.dark-theme {
  color: #f4c020;
}

a.menu {
  color: #f4c020;
}

a.menu:hover {
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.dropdown-menu.dark-theme {
  background-color: #f4c020;
  padding: 0.5rem 0;
  min-width: 7rem;
}

.dropdown-item.menu {
  font-size: 15px;
  color: #000;
  padding: 0.1rem 0.4rem;
}

.dropdown-item.menu:hover,
.dropdown-item.menu.selected {
  background-color: #000;
  color: #f4c020;
}

.dropdown-divider.dark-theme {
  margin: 0.1rem, 0;
  border-top: 1px solid #000;
}

.dropdown-divider.discontinuos {
  border-top: 1px dashed #000;
}

/*  Hexagon buttons  */
.hexagono,
.hexagono-card {
  position: relative;
  height: 60px;
  width: 50px;
  display: inline-block;
  margin-bottom: -4px;
}

.hexagono-xs {
  position: relative;
  height: 52px;
  width: 41px;
  display: inline-block;
  margin: 0;
}

.hexagono-card {
  background: #fff;
}

.hexagono,
.hexagono-xs {
  background: #f4c020;
}

.hexagono.onmap,
.hexagono-xs.onmap {
  background: #000;
}

.hexagono.onmap:before,
.hexagono-xs.onmap:before {
  background: #000;
}

.hexagono-content.onmap,
.hexagono-xs-content.onmap {
  color: #f4c020;
}

.hexagono-content.onmap.selected,
.hexagono-xs-content.onmap.selected {
  color: #000;
}

.hexagono.selected.onmap,
.hexagono-xs.onmap.selected {
  background: #f4c020;
}

.hexagono.selected.onmap:before,
.hexagono-xs.onmap.selected:before {
  background: #f4c020;
}

.hexagono.onmap:hover,
.hexagono-xs.onmap:hover {
  background: #f4c020;
  cursor: pointer;
}

.hexagono.selected,
.hexagono-xs.selected {
  background: #f4c020;
}

.hexagono:before,
.doble:after,
.hexagono-card:before,
.hexagono-xs:before {
  position: absolute;
  content: "";
}

.hexagono:before,
.hexagono-xs:before {
  background: #f4c020;
  top: 2px;
  /* border width */
  left: 2px;
  /* border width */
  height: calc(100% - 4px);
  /* 100% - (2 * border width) */
  width: calc(100% - 4px);
  /* 100% - (2 * border width) */
}

.hexagono-card:before {
  background: #000;
  top: 1px;
  /* border width */
  left: 1px;
  /* border width */
  height: calc(100% - 2px);
  /* 100% - (2 * border width) */
  width: calc(100% - 2px);
  /* 100% - (2 * border width) */
}

.hexagono.selected:before,
.hexagono-xs.selected:before {
  background: #000;
}

.hexagono,
.hexagono:before,
.doble:after,
.hexagono-card,
.hexagono-card:before,
.hexagono-xs,
.hexagono-xs:before {
  -webkit-clip-path: polygon(50% 0%,
      100% 25%,
      100% 75%,
      50% 100%,
      0% 75%,
      0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.doble:after {
  top: 8px;
  left: 8px;
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  background: black;
}

.hexagono:hover,
.hexagono-xs:hover,
.hexagono.showing-menu,
.hexagono-xs.showing-menu {
  background: #000;
  cursor: pointer;
}

.hexagono-content,
.hexagono-card-content,
.hexagono-xs-content {
  position: absolute;
  z-index: 1000;
  width: 90%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  font-weight: bold;
  line-height: 25px;
  font-size: 1.1rem;
}

.hexagono-xs-content {
  font-size: 0.95rem;
}

.hexagono-xs-content.double {
  line-height: 5px;
  margin-top: -2px;
}

.hexagono-content,
.hexagono-xs-conten {
  color: #000;
}

.hexagono-content.selected,
.hexagono-xs-content.selected {
  color: #f4c020;
}

.hexagono-card-content {
  color: #fff;
}

.card {
  border-radius: 10px;
  height: 99.5%;
}

.card.dark-theme {
  background-color: #000;
  background: #000000;
  box-shadow: 1px 1px 2px #ffffff;
}

.numero.dark-theme {
  color: #f4c020;
}

.texto.dark-theme {
  color: #fff;
}

.text-value.dark-theme {
  color: #fff;
}
</style>
