export default {
  methods: {
    unclickElements (identifier) {
      const selection = document.getElementsByClassName(identifier)

      selection.forEach((element) => {
        element.classList.toggle(identifier)
      })

      const result = document.getElementsByClassName(identifier)
      return result.length && this.unclickElements(identifier)
    }
  }
}
