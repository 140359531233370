<template>
  <DtPanelTabsComponent
    id="plot-tabs"
    :tabs="tabs"
  />
</template>

<script>
import DtPanelTabsComponent from '@/components/DigitalTwin/DtPanelTabsComponent.vue'
import { mdiLightningBoltCircle } from '@mdi/js'

export default {
  name: 'DtPanelPlotTabs',
  components: {
    DtPanelTabsComponent
  },
  props: {
    idStation: {
      type: Number,
      default: null
    },
    levelVoltage: {
      type: Number,
      default: null
    },
    dataPlot: {
      type: Array,
      default: null
    },
    dataPlotLCP: {
      type: Array,
      default: null
    },
    dataPlotV: {
      type: Array,
      default: null
    },
    dataPlotI: {
      type: Array,
      default: null
    },
    dataPlotPS: {
      type: Array,
      default: null
    },
    dataPlotPP: {
      type: Array,
      default: null
    },
    dataPlotPQ: {
      type: Array,
      default: null
    },
    dataPlotPL: {
      type: Array,
      default: null
    },
    dataPlotPUFC: {
      type: Float32Array,
      default: null
    },
    dataPlotPUFV: {
      type: Float32Array,
      default: null
    },
    typeValue: {
      type: Number,
      default: null
    },
    samplingPeriod: {
      type: Number,
      default: null
    },
    digitalTwinResults: {
      type: Object,
      default: null
    },
    typeElement: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      powerIcon: mdiLightningBoltCircle
    }
  },
  computed: {
    // Must be computed because the props will change the value after the component is loaded.
    // Could it be in the DTPanel, but I prefer to make it here
    tabs () {
      return [
        {
          icon: 'V',
          label: this.$t('voltage'),
          component: 'DtPanelPlotGeneric',
          show: true,
          props: {
            name: this.$t('voltage'), // Before was pv
            dataPlot: this.dataPlotV,
            typeValue: this.typeValue,
            samplingPeriod: this.samplingPeriod,
            // More for the generic
            xAxis: this.$t('plot.time_h'),
            yAxis: 'V(V)',
            pavY: this.dataPlotV[0][1],
            pbvY: this.dataPlotV[1][1],
            pcvY: this.dataPlotV[2][1],
            pnvY: this.dataPlotV[3][1]
          }
        },
        {
          icon: 'I',
          label: this.$t('current'),
          component: 'DtPanelPlotGeneric',
          show: true,
          props: {
            name: this.$t('current'), // Before was pc
            dataPlot: this.dataPlotI,
            typeValue: this.typeValue,
            samplingPeriod: this.samplingPeriod,
            // More for the generic
            xAxis: this.$t('plot.time_h'),
            yAxis: (this.typeValue === 1) ? 'I(A)' : 'P(kW)',
            pavY: this.dataPlotI[0][1],
            pbvY: this.dataPlotI[1][1],
            pcvY: this.dataPlotI[2][1],
            pnvY: this.dataPlotI[3][1]
          }
        },
        {
          icon: mdiLightningBoltCircle,
          isIcon: true,
          label: this.$t('power'),
          component: 'DtPanelPlotPower', // Tabs
          show: true,
          props: {
            dataPlotPS: this.dataPlotPS,
            dataPlotPP: this.dataPlotPP,
            dataPlotPQ: this.dataPlotPQ,
            dataPlotPL: this.dataPlotPL,
            dataPlotPUFC: this.dataPlotPUFC,
            dataPlotPUFV: this.dataPlotPUFV,
            samplingPeriod: this.samplingPeriod,
            typeElement: this.typeElement,
          }
        },
        {
          icon: 'LDC',
          label: this.$t('kpi.ldc'),
          component: 'DtPanelPlotLoadCurve', // Tabs
          show: true,
          props: {
            dataPlot: this.dataPlot,
            dataPlotLCP: this.dataPlotLCP,
            samplingPeriod: this.samplingPeriod
          }
        },
        {
          icon: 'VL',
          label: this.$t('kpi.vl'),
          component: 'DtPanelPlotVoltageLength',
          show: this.levelVoltage < 1, // Only for low voltage
          props: {
            name: 'vlp',
            digitalTwinResults: this.digitalTwinResults,
            idStation: this.idStation
          }
        }
      ]
    }
  },
  created () {
    this.$store.dispatch('setElement', { path: 'Plots.phaseA', value: 'true', root: 'Plots' })
    this.$store.dispatch('setElement', { path: 'Plots.phaseB', value: 'true', root: 'Plots' })
    this.$store.dispatch('setElement', { path: 'Plots.phaseC', value: 'true', root: 'Plots' })
    this.$store.dispatch('setElement', { path: 'Plots.phaseN', value: 'legendonly', root: 'Plots' })
  }

}

</script>
