<template>
  <div
    id="searchBox"
    class="map-searchbox"
  >
    <v-autocomplete
      v-model="value"
      :items="stations"
      :label="$t('select_by_id')"
      :append-outer-icon="searchIcon"
      dark
      solo
      dense
      @change="selectElement"
    />
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'TIAMapSearchBox',

  components: {
  },
  props: {
    stations: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    searchIcon: mdiMagnify,
    value: null
  }),
  mounted () {
    this.value = null
  },
  methods: {
    selectElement () {
      this.$emit('selectElement', this.value)
    }
  }
}
</script>

<style scoped>
.map-searchbox {
  z-index: 505;
  position: relative;

  float: right;

  height: 30px;
  line-height: 30px;
}
</style>
