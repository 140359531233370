import { plexiRequest } from '.'

export const getAreaMap = async () =>
  plexiRequest({ url: 'ari/areamap' })

export const getAreaGridByZone = async (zone) =>
  zone && plexiRequest({ url: `/ari/grid?area=${zone}` })

export const getAreaGridByElement = async (element = {}) => {
  const setDynamicVariable = ({ id, name }) => {
    if (id) return `elementId=${element.id}`
    if (name) return `elementName=${element.name}`

    return false
  }

  const dynamicVariable = setDynamicVariable(element)
  return dynamicVariable && plexiRequest({
    url: `/ari/grid?elementType=${element.type}&${dynamicVariable}&r=${element.radius}`
  })
}

export const getGridByElementId = async (element) =>
  element &&
  plexiRequest({ url: `/ari/grid?elementType=${element.type}&elementId=${element.id}&r=${element.radius}` })

export const getGridByElementName = async (element) =>
  element &&
  plexiRequest({ url: `/ari/grid?elementType=${element.type}&elementName=${element.name}&r=${element.radius}` })
