<template>
  <div class="map-button">
    <v-tooltip bottom>
      <template #activator="{ on }">
        <label
          id="network_mode_label"
          for="network_mode"
          class="hexagono-xs"
          :class="disableNetworkMode ? 'disabled' : 'on-map selected'"
          style="margin-right: 1px; margin-top: 0px"
          v-on="on"
        >
          <input
            id="network_mode"
            ref="station-mode-radio"
            v-model="modeSelected"
            type="radio"
            value="network_mode"
            :disabled="disableNetworkMode"
            @change="changeMode()"
          >
          <span
            id="network_mode_content"
            class="hexagono-xs-content"
            :class="disableNetworkMode ? 'disabled' : 'on-map selected'"
          >
            <v-icon
              id="network_mode_icon"
              class="hexagono-xs-icon"
              :class="disableNetworkMode ? 'disabled' : 'selected'"
              medium
            >
              icon-station-regular
            </v-icon>
          </span>
        </label>
      </template>
      <span v-if="disableNetworkMode">{{ $t('not_available') }}</span>
      <span v-else>{{ $t('map_nav_bar.change_to_network_mode') }}
      </span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <label
          id="element_mode_label"
          for="element_mode"
          class="hexagono-xs"
          :class="disableElementMode ? 'disabled' : 'on-map selected'"
          v-on="on"
        >
          <input
            id="element_mode"
            ref="line-mode-radio"
            v-model="modeSelected"
            type="radio"
            value="element_mode"
            :disabled="disableElementMode"
            @change="changeMode()"
          >
          <span
            id="element_mode_content"
            class="hexagono-xs-content"
            :class="disableElementMode ? 'disabled' : 'on-map selected'"
          >
            <v-icon
              id="element_mode_icon"
              class="hexagono-xs-icon"
              :class="disableElementMode ? 'disabled' : 'selected'"
            >
              icon-line-regular
            </v-icon>
          </span>
        </label>
      </template>
      <span v-if="disableElementMode">{{ $t('not_available') }}</span>
      <span v-else>{{ $t('map_nav_bar.change_to_element_mode') }}
      </span>
    </v-tooltip>
    <div class="mode-selected-title">
      <span
        v-if="networkMode"
        key="network_mode"
      >
        {{ $t('map_nav_bar.network_mode') }}</span>
      <span
        v-else-if="elementMode"
        key="element_mode"
      >
        {{ $t('map_nav_bar.element_mode') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MapModeSelector',
  props: {
    mode: {
      type: String,
      default: 'network_mode'
    },
    disableElementMode: {
      type: Boolean,
      default: false
    },
    disableNetworkMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    modeSelected: null
  }),
  computed: {
    networkMode () {
      return this.modeSelected === 'network_mode'
    },
    elementMode () {
      return this.modeSelected === 'element_mode'
    }
  },

  watch: {
    mode () {
      this.modeSelected = this.mode
      this.deselect()
      this.select()
    }
  },
  mounted () {
    this.modeSelected = this.mode
    this.deselect()
    this.select()
    // this.select();
  },
  methods: {
    select () {
      const modeToSelect = document.getElementById(`${this.modeSelected}_label`)
      const modeToSelectIcon = document.getElementById(`${this.modeSelected}_icon`)

      modeToSelect && modeToSelect.classList.add('selected')
      modeToSelectIcon && modeToSelectIcon.classList.add('selected')
    },
    deselect () {
      const selectedRadio = document.getElementsByClassName('selected')
      while (selectedRadio.length) {
        selectedRadio[0].classList.remove('selected')
      }
    },
    changeMode () {
      this.$emit('change', this.modeSelected)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.map-button {
  z-index: 500;
  position: absolute;
  top: 15px;
  left: 50px;
  height: 30px;
  line-height: 30px;
}

.mode-selected-title {
  display: inline-block;
  padding-left: 15px;
  color: #f4c020;
  font-size: 1rem;
  vertical-align: bottom;
  padding-bottom: 12px;
}

#element_mode {
  margin-top: 3px;
}

.hexagono-xs {
  position: relative;
  height: 52px;
  width: 41px;
  display: inline-block;
  margin: 0;
  background: #000;
}

.hexagono-xs:before {
  background: #000;
}
.hexagono-xs.disabled:before{
  background:#292929;
}

.hexagono-xs-content.on-map {
  color: #f4c020;
}

.hexagono-xs-content.selected {
  color: #000;
}

.hexagono-xs-content.disabled {
  color: #555;
}

.hexagono-xs.on-map.selected {
  background: #f4c020;
}
.hexagono-xs.disabled{
  background: #292929;
}
.hexagono-xs.selected:before {
  background: #f4c020;
}
.hexagono.xs.disabled:before {
  background: #292929;
}
.hexagono-xs:hover {
  background: #f4c020;
  cursor: pointer;
}
.hexagono-xs.disabled:hover{
  background: #292929;
  cursor: grab;
}

.hexagono-xs:before {
  position: absolute;
  content: '';
}
.hexagono-xs:before {
  top: 2px; /* border width */
  left: 2px; /* border width */
  height: calc(100% - 4px); /* 100% - (2 * border width) */
  width: calc(100% - 4px); /* 100% - (2 * border width) */
}

.hexagono-xs,
.hexagono-xs:before {
  -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hexagono-xs-content {
  position: absolute;
  z-index: 1000;
  width: 90%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  font-weight: bold;
  line-height: 25px;
  font-size: 0.95rem;
}

.hexagono-xs-icon {
  color: #f4c020;
}

.hexagono-xs-icon.selected {
  color: #000;
}

.hexagono-xs-icon.disabled {
  color: #555
}

/* Mostrando los tooltips*/
div:hover .tooltip {
  display: block;
}

/* Tooltip CSS 2.1 */
.tooltip:before {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #777777;
  bottom: -6px;
  content: '';
  height: 0;
  left: 50%;
  margin-left: -6px;
  position: absolute;
  width: 0;
}

.tooltip {
  background-color: #777777;
  color: #ffffff;
  display: none;
  left: 30%;
  padding: 10px 20px;
  position: absolute;
  top: -50px;
}
</style>
