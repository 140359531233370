import mapElementDisableMixin from '@/mixins/map/mapElementDisableMixin'

export default {
  mixins: [mapElementDisableMixin],
  methods: {
    click (level, network, elementId, elementType, digitalTwin, hostingCapacityMap, multiselect) {
      const isElementDisabled = this.disabledElement(network)
      if (isElementDisabled) return null

      let isCPElementDisabled = false
      if (
        elementType === 'connectionPoint' &&
        this.$store.state.measurements.currentTab?.title === 'Measurements' &&
        this.$store.state.measurements.metersCodeMapTypes.length
      ) {
        const element = this.$store.state.measurements.inventory.find(
          (el) => el.CGP === elementId
        )
        if (element) {
          isCPElementDisabled = this.isItemDisabled(element.TYPE)
        }
      }
      if (isCPElementDisabled) return null

      this.clickElements(
        network,
        elementId,
        elementType,
        digitalTwin,
        hostingCapacityMap,
        multiselect
      )
      this.$emit('click', level, network, elementId, elementType)
    }
  }
}
