<template>
  <DtPanelTabsComponent
    id="kpi-tabs"
    :tabs="tabs"
  />
</template>

<script>
import { mdiTableLarge, mdiFormatListBulleted, mdiAlert } from '@mdi/js'
import DtPanelTabsComponent from '@/components/DigitalTwin/DtPanelTabsComponent.vue'
import { mapState } from 'vuex'

export default {
  name: 'DtPanelKpiTabs',
  components: {
    DtPanelTabsComponent
  },
  props: {
    panelHeight: {
      type: String,
      default: null
    },
    simResults: {
      type: Array,
      default: null
    },
    simHeaders: {
      type: Array,
      default: null
    },
    pvList: {
      type: Array,
      default: null
    },
    evList: {
      type: Array,
      default: null
    },
    hpList: {
      type: Array,
      default: null
    },
    loadScaleList: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState(['violations']),
    // Must be computed because the props will change the value after the component is loaded.
    // Could it be in the DTPanel, but I prefer to make it here
    tabs () {
      const tabs = [
        {
          label: 'Violations',
          icon: mdiAlert,
          isIcon: true,
          component: 'DTPanelKpisPQViolations',
          show: !!this.violations.data.length
        },
        {
          label: 'KPIs',
          icon: mdiTableLarge,
          isIcon: true,
          component: 'DtPanelKpisTable',
          show: !!this.panelHeight, // Before the only thing hiding was the component. I think that hide de tab is better
          props: {
            simResults: this.simResults,
            simHeaders: this.simHeaders,
            tableHeight: this.panelHeight
          }
        },
        {
          label: this.$t('dt.new_connections'),
          icon: mdiFormatListBulleted,
          isIcon: true,
          component: 'DtPanelKpiNewConnections',
          show: !!this.panelHeight, // Before the only thing hiding was the component. I think that hide de tab is better
          props: {
            pvList: this.pvList,
            evList: this.evList,
            hpList: this.hpList,
            loadScaleList: this.loadScaleList,
            tableHeight: this.panelHeight
          }
        }
      ]

      return tabs.filter(tab => tab.show)
    }
  }
}
</script>
