<template>
  <div>
    <v-row class="dates-selector">

      <v-checkbox
        v-if="type=='flexContainer'"
        v-model="yesterday"
        hide-details
        label="Yesterday"
        class="yellow-check"
        @click="clickYesterday()"
      />
      <v-checkbox
        v-if="type=='flexContainer'"
        v-model="today"
        hide-details
        label="Today"
        class="yellow-check"
        @click="clickToday()"
      />
      <v-checkbox
        v-if="type=='flexContainer'"
        v-model="expectedTomorrow"
        hide-details
        label="Expected for tomorrow"
        class="yellow-check"
        @click="clickTomorrow()"
      />
      <v-checkbox
        v-if="type=='flexContainer'"
        v-model="ahead"
        hide-details
        label="24 hs ahead"
        @click="clickAhead()"
      />
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'TIAMonitoringDatesSelector',

  data: () => ({
    expectedTomorrow: false,
    today: true,
    yesterday: false,
    lastWeek: false,
    lastMonth: false,
    lastYear: false,
    ahead: false
  }),
  props: {
    type: {
      type: String,
      default: ''
    }

  },
  mounted () {

  },
  methods: {
    clickTomorrow () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.periodSelected', value: 'tomorrow' })
      this.setValues('expectedTomorrow')
    },
    clickToday () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.periodSelected', value: 'today' })
      this.setValues('today')
    },
    clickYesterday () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.periodSelected', value: 'yesterday' })
      this.setValues('yesterday')
    },
    clickLastWeek () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.periodSelected', value: 'last-week' })
      this.setValues('lastWeek')
    },
    clickLastMonth () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.periodSelected', value: 'last-month' })
      this.setValues('lastMonth')
    },
    clickLastYear () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.periodSelected', value: 'last-year' })
      this.setValues('lastYear')
    },
    clickAhead () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.periodSelected', value: 'ahead' })
      this.setValues('ahead')
    },
    setValues (trueKey) {
      this.today = false
      this.expectedTomorrow = false
      this.yesterday = false
      this.lastWeek = false
      this.lastMonth = false
      this.lastYear = false
      this.ahead = false

      this[trueKey] = true
    }

  }
}
</script>

<style>
.dates-selector{
  margin-top:20px;
}
.yellow-check-ahead{
  color: blue!important;
}
</style>
