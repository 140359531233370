<template>
  <VDialog
    v-model="showForm"
    max-width="450"
    overlay-opacity="0.6"
  >
    <slot></slot>
  </VDialog>
</template>

<script>
export default {
  name: 'CMDialog',
  props: {
    showForm: {
      default: true,
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog__content {
  z-index: 501 !important;
}
.info {
  margin-bottom: 1rem;
  .col-12 {
    padding: 1px 15px !important;
    text-align: left;
  }
}
.redPlexi {
  color:#b60c0c;
}
</style>
