<template>
  <div>
    <v-expansion-panels
      v-model="panel"
      dark
      accordion
      multiple
    >
      <v-expansion-panel :key="0">
        <v-expansion-panel-header :hide-actions="false">
          {{ $t('grid_panel.general_information') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row style="margin-top:0px">
            <v-col
              cols="12"
              sm="5"
            >
              ID:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.code }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('description') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.description }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('type') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.fuseType }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('voltage') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.voltage }}
            </v-col>
          </v-row>
          <hr
            v-if="network"
            class="hr-separator-dark"
          >
          <v-row v-if="network">
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('grid.network') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ network }}
            </v-col>
          </v-row>
          <hr class="hr-separator-dark">
          <v-row style="margin-bottom:0px">
            <v-col
              cols="12"
              sm="5"
            >
              {{ $t('installation_date') }}:
            </v-col>
            <v-col
              cols="12"
              sm="7"
            >
              {{ info.installation }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>

export default {
  name: 'FuseDetails',
  props: {
    info: {
      type: Object,
      default: null
    },
    network: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      panel: [0]
    }
  }
}
</script>
<style scoped>
.v-expansion-panel-content{
    font-size:14px
}
.col-12{
    padding:1px 15px !important;
    text-align:left;
}
.hr-separator-dark{
  margin: 12px 0;
  border-top: 0.5px solid #444;
  border-bottom: none;
  border-left: none;
  border-right:none;
}
</style>
