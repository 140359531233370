<template>
  <div class="test">
    <h4>Test component for montecarlo simulation execution</h4>
  </div>
</template>

<script>
/* eslint-disable comma-dangle */
import DigitalTwinCreator from '@/assets/DigitalTwin/DigitalTwinCreator'

export default {
  name: 'MontecarloTest',
  components: {},
  props: {},
  async mounted () {
    // Initalizing Digital Twin Creator
    const dtc = new DigitalTwinCreator(
      this.$HEADER_HTTP,
      this.$API_HOST,
      this.$API_PORT,
      () => {},
      console.error,
      console.warn
    )

    const simParams = {
      StartTime: 1546300800000,
      StepCount: 24,
      SimMode: 1,
    }

    const qualityParams = {
      EV_PenetrationStart: 10.0,
      EV_PenetrationEnd: 100.0,
      EV_PenetrationStep: 10.0,
      PV_Penetration: 70.0,
      PV_PowerStart: 1000.0,
      PV_PowerStep: 200.0,
      PV_PowerFactor: 1.0,
      Vmax: 1.07,
      Vmin: 0.93,
      NegSeqMax: 0.9,
      ZeroSeqMax: 0.9,
      OverCurrentRatio: 1.1,
      OverLoadingRatio: 1.2,
    }

    const config = {
      rcp: 7,
      c: 50,
      kmd: 20,
      stdkmd: 0.005,
      pf: 0.9,
    }

    // const config = {
    // };

    // Initialize Montecarlo in the backend
    await dtc.createNewMontecarlo('TestEV15', [65025], simParams, qualityParams, config)
    // Initialize Montecarlo in the frontend
    const dt = await dtc.openMontecarlo('TestEV15')
    // // const dt = await dtc.openMontecarlo('NewMontecarlo3');

    // const info = await dtc.getMontecarloInfo('testPostman5');
    // console.log(info);
  },
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
