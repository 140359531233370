import { plexiRequest } from '.'
import { get } from '@/assets/queries'

export async function getBufferResults (host, station) {
  const response = await get(`${host}/hosting-capacity/results?station=${station}`, undefined, undefined, true)
  const binaryResponse = await response.arrayBuffer()
  return new Float32Array(binaryResponse)
}

export async function getDBResults (host, station) {
  const response = await get(`${host}/hosting-capacity/db?station=${station}`, undefined, undefined, true)
  const binaryResponse = await response.arrayBuffer()
  return new Uint8Array(binaryResponse)
}

export const manageHC = async () =>
  plexiRequest({ url: 'hosting-capacity/manage' })

export const getHC = async (station) =>
  station && plexiRequest({ url: `hosting-capacity/manage?station=${station}` })

export const getHCForAllStations = async (stationIds) => {
  const maxStationsPerRequest = 50
  let stationsUrl, stations
  const allRequests = []
  while (stationIds.length > 0) {
    stations = stationIds.splice(0, maxStationsPerRequest)
    stationsUrl = stations.join('&station=')

    allRequests.push(plexiRequest({ url: `hosting-capacity/manage?station=${stationsUrl}` })
      .then(data => data).catch(e => []))
  }
  // const stationsUrl = stationIds.join('&station=')
  return (stationIds && stationsUrl) && Promise.all(allRequests)
    .then(dataArray => {
      let data = []
      dataArray = dataArray.filter((d) => d.response === undefined)
      dataArray.forEach((d) => { data = data.concat(d) })
      const { stationsHCInfo } = data
      if (!stationsHCInfo) return data
      return []
    })
}

/** Reservation */
export const addReservation = async (body) =>
  body &&
  plexiRequest({
    url: 'hosting-capacity/reserve',
    method: 'post',
    data: body
  })

export const getReservation = async (type, id) =>
  type && id &&
  plexiRequest({ url: `hosting-capacity/reserve?${type}=${id}` })

export const removeReservation = async (id) =>
  id &&
  plexiRequest({
    url: 'hosting-capacity/reserve',
    method: 'delete',
    data: id
  })
