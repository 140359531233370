import { getAreaGridByZone, getGridByElementId, getGridByElementName } from '@/services/areaSelector'
import { mapState } from 'vuex'
// import axios from 'axios'

export default {
  computed: {
    ...mapState({
      areaGrid: (state) => state.areaGrid
    })
  },

  methods: {
    async getAreaGrid (drawMap) {
      this.$store.dispatch('setElement', { path: 'projectNetworks', value: [] })
      if (this.areaGrid === null) {
        const response = await getAreaGridByZone(this.$sessionStorage.zoneId)
        if (response) {
          // store json grid
          this.$store.dispatch('setElement', {
            path: 'areaGrid',
            value: response
          })
          const keys = ['Xmax', 'Xmin', 'Ymax', 'Ymin']
          const formatKey = (object, key) => parseFloat(object[key]).toFixed(2)
          keys.forEach((key) => {
            window.sessionStorage[key] = formatKey(response, `@@${key}`)
          })
        }
      }

      if (drawMap) {
        drawMap(this.areaGrid)
      }
      return this.areaGrid
    },

    async getElementGrid (drawMap) {
      const gridElement = JSON.parse(this.$sessionStorage.gridElement)
      let response
      if (gridElement.id !== undefined) {
        response = await getGridByElementId(gridElement)
      } else if (gridElement.name !== undefined) {
        response = await getGridByElementName(gridElement)
      }

      if (response) {
        this.$store.dispatch('setElement', {
          path: 'areaGrid',
          value: response
        })
        const keys = ['Xmax', 'Xmin', 'Ymax', 'Ymin']
        const formatKey = (object, key) => parseFloat(object[key]).toFixed(2)
        keys.forEach((key) => {
          window.sessionStorage[key] = formatKey(response, `@@${key}`)
        })
        if (drawMap) {
          drawMap(response)
        }
        return response
      }
    }
  }
}
