import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isContextMenu'])
  },

  methods: {
    async resetContextMenu () {
      if (this.isContextMenu) {
        await new Promise((resolve) => {
          setTimeout(() => {
            this.$store.dispatch('setElement', {
              path: 'contextMenu.isVisible',
              value: !this.isContextMenu
            })
            resolve()
          }, 10)
        })
      }
    }
  }
}
