<template>
  <v-data-table
    v-if="simResults"
    ref="table"
    dense
    :items="simResults"
    :headers="simHeaders"
    class="kpi-table"
    fixed-header
    group-by="KPI"
    show-group-by
    disable-pagination
    hide-default-footer
    :height="tableHeight"
  />
</template>

<script>
export default {
  name: 'DtPanelKpisTable',
  props: {
    simResults: {
      type: Array,
      default: () => []
    },
    simHeaders: {
      type: Array,
      default: () => []
    },
    tableHeight: {
      type: String,
      default: '274px'
    }
  },

  mounted () {
    // -- Code to obly to collapse grouped elements in the kpis table
    const { table } = this.$refs
    if (table) {
      const keys = Object.keys(table.$vnode.componentInstance.openCache)
      keys.shift() // remove first element so that first group stays open
      keys.forEach((x) => {
        table.$vnode.componentInstance.openCache[x] = false
      })
    }
  }
}

</script>

<style scoped>
.kpi-table {
  font-family: 'Faktum Light', sans-serif;
}
</style>
