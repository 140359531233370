const d3 = require('d3');

export default {
  methods: {
    barChart(bind, data) {
      const margin = {
        top: 10, right: 30, bottom: 60, left: 30,
      };
      const width = 290 - margin.left - margin.right;
      const height = 160 - margin.top - margin.bottom;
      // append the svg object to the body of the page
      const svg = d3.select(bind)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);
      const xScale = d3.scaleBand()
        .range([0, width])
        .domain(data.map((d) => d.label))
        .padding(0.1);
      const yScale = d3.scaleLinear()
        .range([height, 0])
        .domain([0, d3.max(data, (d) => d.valueHist)]);
      svg.append('g')
        .attr('class', 'blanco')
        .call(d3.axisLeft(yScale).tickFormat((d) => d).ticks(8))
        .append('text')
        .attr('y', 6)
        .attr('dy', '0.71em')
        .attr('text-anchor', 'end');
      const bars = svg.selectAll('.bar')
        .data(data)
        .enter().append('rect')
        .attr('id', (d) => `${bind.substring(1)}_${d.label}`)
        .attr('fill', '#c39409')
        .attr('x', (d) => xScale(d.label))
        .attr('y', (d) => yScale(d.valueHist))
        .attr('width', xScale.bandwidth())
        .attr('height', (d) => height - yScale(d.valueHist));
        // add hover event
      bars.on('mouseover', function barMouseover() {
        d3.select(this)
          .attr('stroke', '#fff')
          .attr('stroke-width', 2);
      });
      bars.on('mouseout', function barMouseout() {
        d3.select(this)
          .attr('stroke', 'none');
      });
      // bars.on('click', function barClick() {
      //   const params = this.id.split('_');
      //   console.log(params);
      //   window.location.replace(`/grid?filters=${params}`);
      // });

      svg.append('g')
        .attr('transform', `translate(0,${height})`)
        .attr('class', 'blanco')
        .call(d3.axisBottom(xScale))
        .selectAll('text')
        .attr('transform', 'translate(-10,10)rotate(-45)');
    },
  },
};
