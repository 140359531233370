<template>
  <div>
    <v-menu
      :value="isVisible"
      :position-x="position[0]"
      :position-y="position[1]"
      z-index="500"
      absolute
      offset-y
    >
      <v-list
        flat
        nav
      >
        <v-list-item-group>
          <v-list-item
            v-if="stationMarker.is_flex==0 ||stationMarker.is_flex==1"
            class="v-list-item--hover"
            dense
            @click="activateDeactivateFlex(stationMarker.uuid, 2)"
          >
            <v-list-item-title>Activate Flex</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="stationMarker.is_flex==2"
            class="v-list-item--hover"
            dense
            @click="activateDeactivateFlex(stationMarker.uuid, 1)"
          >
            <v-list-item-title>Deactivate Flex</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>
<script>

import { mapState } from 'vuex'
export default {
  name: 'TIAContextMenu',
  props: {
    position: {
      type: Array,
      default: () => []
    },
    stationMarker: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      isVisible: (state) => state.TIA.TIAMap.contextMenu
    })
  },

  methods: {
    async activateDeactivateFlex (uuid, value) {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.contextMenu', value: false })
      // const isFlex = await changeStationFlex(uuid, value)
    }
  }
}
</script>
<style scoped>
.v-list-item--hover:hover {
  color: white;
  background-color: #f4c020;
}
</style>
