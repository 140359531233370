<template>
  <DtPanelTabsComponent
    :tabs="tabs"
  />
</template>

<script>

export default {
  name: 'DtPanelKpiNewConnections', // Before was DtPanelModifications
  components: {
    DtPanelTabsComponent: () => import('@/components/DigitalTwin/DtPanelTabsComponent.vue')
  },
  props: {
    pvList: {
      type: Array,
      default: () => []
    },
    evList: {
      type: Array,
      default: () => []
    },
    hpList: {
      type: Array,
      default: () => []
    },
    loadScaleList: {
      type: Number,
      default: null
    },
    tableHeight: {
      type: String,
      default: '274px'
    }
  },
  data () {
    return {
      evCustomNames: {
        name: 'Name',
        number: 'Number of EV chargers',
        cgp: 'Connection Point',
        rcp: 'EV charger power (kW)',
        c: 'EV energy capacity (kWh)',
        ph: 'Phase',
        kmd: 'EV mean daily km traveled',
        stdkmd: 'Daily km traveled STD (%)',
        pf: 'EV charger power factor',
        start_min: 'EV possible charging t0',
        start_max: 'EV possible charging t1'
      },
      evKeys: [
        'name',
        'number',
        'cgp',
        'rcp',
        'c',
        'ph',
        'kmd',
        'stdkmd',
        'pf',
        'start_min',
        'start_max'
      ],
      pvCustomNames: {
        name: 'Name',
        number: 'Number of PV systems',
        cgp: 'Connection Point',
        ph: 'Phase',
        pp: 'Peak power of each system (kW)'
      },
      pvKeys: [
        'name',
        'number',
        'cgp',
        'ph',
        'pp'
      ],
      hpCustomNames: {
        name: 'Name',
        number: 'Number of Heat Pumps',
        cgp: 'Connection Point',
        ph: 'Phase',
        pf: 'Power factor',
        pnom: 'Pnom. of each Heat Pump (kW)',
        cop: 'Heating coeff. of performance'
      },
      hpKeys: [
        'name',
        'number',
        'cgp',
        'ph',
        'pf',
        'pnom',
        'cop'
      ]
    }
  },
  computed: {
    // Must be computed because the props will change the value after the component is loaded.
    // Could it be in the DTPanel, but I prefer to make it here
    tabs () {
      return [
        {
          icon: 'EV',
          label: 'Electric Vehicles',
          component: 'DtPanelKpisNewConnectionsData',
          show: true,
          props: {
            name: 'Electric Vehicles',
            list: this.evList,
            customNames: this.evCustomNames,
            keys: this.evKeys,
            tableHeight: this.tableHeight
          }
        },
        {
          icon: 'PV',
          label: 'PV Systems',
          component: 'DtPanelKpisNewConnectionsData',
          show: true,
          props: {
            name: 'PV Systems',
            list: this.pvList,
            customNames: this.pvCustomNames,
            keys: this.pvKeys,
            tableHeight: this.tableHeight
          }
        },
        {
          icon: 'HP',
          label: 'Heat Pumps',
          component: 'DtPanelKpisNewConnectionsData',
          show: true,
          props: {
            name: 'Heat Pumps',
            list: this.hpList,
            customNames: this.hpCustomNames,
            keys: this.hpKeys,
            tableHeight: this.tableHeight
          }
        },
        {
          icon: 'LF',
          label: 'Load Factor',
          component: 'DtPanelKpisNewLF',
          show: true,
          props: {
            name: 'Load Factor',
            loadScaleList: this.loadScaleList
          }
        }
      ]
    }
  }
}
</script>
