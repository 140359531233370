<template>
  <v-card elevation="10">
    <v-form
      ref="EditOverloadLimitsForm"
      v-model="valid"
      lazy-validation
    >
      <v-card-title class="text-h5">
        {{ $t('plot.subscription_limit') }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <label for="">{{ $t('plot.consumption_limit') }}</label>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="values.consumptionLimitP"
                :label="'P+ (kW)'"
                type="number"
                dense
                @input="updateValue('consumptionLimitP', $event)"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="values.consumptionLimitQ"
                :label="'Q+ (kVar)'"
                type="number"
                dense
                @input="updateValue('consumptionLimitQ', $event)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label for="">{{ $t('plot.injection_limit') }}</label>
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="values.injectionLimitP"
                :label="'P- (kW)'"
                type="number"
                dense
                @input="updateValue('injectionLimitP', $event)"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :value="values.injectionLimitQ"
                :label="'Q- (kVar)'"
                type="number"
                dense
                @input="updateValue('injectionLimitQ', $event)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#f4c020"
          text
          @click="closeDialog"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          id="edit"
          :disabled="!valid"
          text
          @click="editLimit"
        >
          {{ $t('edit') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'DTEditOverloadAnalysisForm',
  props: {
    idStation: {
      type: Number,
      default: null
    },
    consumptionLimitP: {
      type: Number,
      default: null
    },
    consumptionLimitQ: {
      type: Number,
      default: null
    },
    injectionLimitP: {
      type: Number,
      default: null
    },
    injectionLimitQ: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      values: {
        idStation: this.idStation,
        consumptionLimitP: this.consumptionLimitP,
        consumptionLimitQ: this.consumptionLimitQ,
        injectionLimitP: this.injectionLimitP,
        injectionLimitQ: this.injectionLimitQ
      },
      valid: true
    }
  },

  computed: {
    ...mapState({
      overloadAnalysis: (state) => state.overloadAnalysis
    })
  },

  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },
    updateValue (key, event) {
      this.values[key] = event
    },
    editLimit () {
      const storeValues = {
        idStation: this.values.idStation,
        consumptionLimitP: parseFloat(this.values.consumptionLimitP),
        consumptionLimitQ: parseFloat(this.values.consumptionLimitQ),
        injectionLimitP: parseFloat(this.values.injectionLimitP),
        injectionLimitQ: parseFloat(this.values.injectionLimitQ)
      }

      const station = this.overloadAnalysis.find(station => station.idStation === this.values.idStation)

      if (station) {
        Object.assign(station, storeValues)
        this.$store.dispatch('setElement', { path: 'overloadAnalysis', value: this.overloadAnalysis })
      }

      this.$emit('closeDialog')
    }
  }
}
</script>
