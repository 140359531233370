<template>
  <div>
    <div class="plotStation">
      <Plotly
        :data="getDataPlot"
        :layout="getLayoutPlot"
        :display-mode-bar="false"
        :displaylogo="false"
      />
      <div class="button-download">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">       
            <button v-bind="attrs" v-on="on" @click="openDownload"><v-icon class="button-download-icon">{{ downloadIcon }}</v-icon></button>              
          </template>
        <span>Download</span>
        </v-tooltip>
      </div>
    </div>

    <v-dialog
      v-model="downloadDialog"
      max-width="400"
      overlay-opacity="0.5"
    >
      <TIAMonitoringExportPlotDataForm
        :data="data"
        @closeDownload="closeDownload"
      />
    </v-dialog>
  </div>
</template>

<script>
import { Plotly } from 'vue-plotly'
import { mdiDownload } from '@mdi/js'
import TIAMonitoringExportPlotDataForm from '@/components/TIA/TIAMonitoring/TIAMonitoringExportPlotDataForm.vue'

export default {
  name: 'TIAMonitoringPlotStation',

  components: {
    Plotly,
    TIAMonitoringExportPlotDataForm
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }

  },
  data: () => ({
    downloadIcon: mdiDownload,
    downloadDialog: false,
    heightPlot: null,
    nonFlexValue: [],
    flexValue: [],
    outLimitsValue: [],
    DOEValue: [],
    powerLimit:[],
    horizontalPlane: null,
    consolidatedPhase: [],
    data: null,
    flexMargin:[]
  }),

  computed: {
    getDataPlot () {
      // const proxyData = new Proxy(this.data, {
      //   // Handler del this.data
      //   get (target, prop) {
      //     return target[prop]
      //   }
      // })

      // Variables for Plot
      let datos = []
      let measurement
      let timePast =[]
      let timeFuture =[]      
      let consumedTotalPower =[]
      let consumedNonFlexPower=[]
      let predictedTotalPower=[]  
      let predictedNonFlex=[]  
      let forecastTotalPower=[]     
      let forecastNonFlex=[]   
      let timeDay = []
      let doePast = []
      let doeFuture = []      

      //Limit
      timeDay=this.getDayTime(this.data) 
      measurement = {
        x: timeDay,
        y: this.powerLimit,
        type: 'lines',
        mode: 'lines',
        name: "Power Limit",
        line: {
          color: 'rgba(255,80,90, 0.8)',
          dash: 'solid',
          width: 3
        },
        visible: true
      }        
      datos.push(measurement)

      //PAST
        // create the series fot the past
        this.data.past.forEach((measur) => {
          timePast.push(new Date(measur.time))          
          consumedTotalPower.push(measur.consumed_total_active_power_avg_kw)
          consumedNonFlexPower.push(measur.consumed_non_flex_active_power_avg_kw)
          predictedTotalPower.push(measur.predicted_total_active_power_avg_kw)
          predictedNonFlex.push(measur.predicted_non_flex_active_power_avg_kw)
        })
        // DOE Past
        doePast = this.calculateDOEPast(this.powerLimit, predictedNonFlex, predictedTotalPower)
        //create the measurement from series
        // ADD DOE
        measurement = {
          x: timePast,
          y: doePast,
          type: 'bar',
          mode: 'lines',
          name: 'Past Flex Capacity',
          line: {
            shape: 'hvh'
          },
          visible: true,
          marker: {
            color: 'rgba(158,202,225, 0)',
            opacity: 1,
            line: {
              color: 'rgba(158,202,225,0.5)',
              width: 0.9
            }
          }
        }
        datos.push(measurement)

        //Total Power Consumed
        measurement = {
          x: timePast,
          y: consumedTotalPower,
          type: 'lines',
          mode: 'lines',
          name: "Consumed Total Power",
          line: {
            color: 'rgba(171, 235, 198 , 0.2)',
            dash: 'solid',
            width: 3
          },
          visible: true
        }        
        datos.push(measurement)

        //Non flex Power Consumed
        measurement = {
          x: timePast,
          y: consumedNonFlexPower,
          type: 'lines',
          mode: 'lines',
          name: "Consumed Non flex Power",
          line: {
            color: 'rgba(158,180,240, 0.4)',
            dash: 'solid',
            width: 3
          },
          visible: true
        }        
        datos.push(measurement)


      //FUTURE
        // create the series fot the past
        this.data.future.forEach((measur) => {
          timeFuture.push(new Date(measur.time))
          forecastTotalPower.push(measur.predicted_total_active_power_avg_kw)
          forecastNonFlex.push(measur.predicted_non_flex_active_power_avg_kw)
        })
        //create the measurement from series
        // DOE Past
        doeFuture = this.calculateDOEFuture(this.powerLimit, forecastNonFlex, forecastTotalPower)
        //create the measurement from series
        // ADD DOE
        measurement = {
          x: timeFuture,
          y: doeFuture,
          type: 'bar',
          mode: 'lines',
          name: 'Future Flex Capacity',
          line: {
            shape: 'hvh'
          },
          visible: true,
          marker: {
            color: 'rgba(158,202,225, 0)',
            opacity: 1,
            line: {
              color: 'rgb(158,202,225)',
              width: 0.9
            }
          }
        }
        datos.push(measurement)

        //Predicted Total Power
        measurement = {
          x: timeFuture,
          y: forecastTotalPower,
          type: 'lines',
          mode: 'lines',
          name: "Predicted Total Power",
          line: {
            color: 'rgba(171, 235, 198 , 1)',
            dash: 'solid',
            width: 3
          },
          visible: true
        }        
        datos.push(measurement)
        //create the measurement from series
        //Predicted Non Flex Power
        measurement = {
          x: timeFuture,
          y: forecastNonFlex,
          type: 'lines',
          mode: 'lines',
          name: "Predicted Non Flex Power",
          line: {
            color: 'rgba(158,180,240, 1)',
            dash: 'solid',
            width: 3
          },
          visible: true
        }        
        datos.push(measurement)

        //Predicted Total Power
        measurement = {
          x: timePast,
          y: predictedTotalPower,
          type: 'lines',
          mode: 'lines',
          name: "Past Predicted Total Power",
          line: {
            color: 'rgba(171, 235, 198 , 1)',
            dash: 'solid',
            width: 3
          },
          visible: 'legendonly'
        }        
        datos.push(measurement)
       
        //Predicted Non Flex
        measurement = {
          x: timePast,
          y: predictedNonFlex,
          type: 'lines',
          mode: 'lines',
          name: "Past Predicted Non Flex Power",
          line: {
            color: 'rgba(158,180,240, 1)',
            dash: 'solid',
            width: 3
          },
          visible: 'legendonly'
        }        
     
        datos.push(measurement)
       
        // FLEX MARGIN
        //FLEX MARGIN
        measurement = {
          x: timeDay,
          y: this.flexMargin,
          type: 'bar',
          mode: 'lines',
          name: 'Flex Margin',
          line: {
            shape: 'hvh'
          },
          visible: 'legendonly',
          marker: {
            color: 'rgba(158,202,225, 0)',
            opacity: 1,
            line: {
              color: 'rgba(225,225,225,0.1)',
              width: 0.9
            }
          }
        }        
        // datos.push(measurement)

      this.getDataCSV(datos)
      return datos
    },

    getLayoutPlot () {
      const layout = {
        title: '',
        legend: {
          orientation: 'h',
          entrywidth: 200,
          valign: 'top',
          yanchor: 'bottom',
          y: 1.02,
          xanchor: 'right',
          x: 1,
          bgcolor: '#272727',
          font: {
            size: '12',
            family: 'Faktum Light'
          }

        },
        barmode: 'bar',
        height: this.heightPlot,
        margin: {
          l: 60,
          r: 10,
          b: 100,
          t: 0,
          pad: 5
        },
        xaxis: {
          title: 'Time (h)',
          linecolor: '#fff',
          nticks: 10,
          gridcolor: '#595959',
          showspikes: true,
          spikethickness: 2,
          spikedash: 'dot',
          spikecolor: '#fff',
          spikemode: 'across',
          tickangle: 0,
          tickfont: {
            size: 12
          }
        },
        yaxis: {
          title: '(kW)',
          linecolor: '#fff',
          gridcolor: '#595959'
        },
        paper_bgcolor: '#272727',
        plot_bgcolor: '#272727',
        font: {
          color: '#fff',
          family: 'Faktum Light'
        },
        hovermode: 'x',
        modebar: {
          activecolor: '#f4c020',
          color: '#fff',
          remove: ['zoomin2d', 'zoomout2d', 'resetscale2d']
        }
      }

      return layout
    }

  },

  mounted () {
    this.heightPlot = window.innerHeight * 0.37
  },
  methods: {

     getDayTime(data) {
      const result = []
      const maxPower = this.$store.state.TIA.TIAMap.maxPowerStation      
      data["past"].forEach((measur) => {
        result.push(new Date(measur.time))
        this.powerLimit.push(maxPower)
      })
      data["future"].forEach((measur) => {
        result.push(new Date(measur.time))
        this.powerLimit.push(maxPower)
      })
      return result
    },
    minusArrays (array1, array2) {
      if (array1.length !== array2.length) {
        throw new Error('Arrays must have the same lenght.')
      }
      const resultado = []
      for (let i = 0; i < array1.length; i++) {
        const resta = array1[i] - array2[i]
        resultado.push(resta)
      }

      return resultado
    },
    calculateFlexAndOutLimits (nonFlex, flex, maxPwr) {
      const flexValues = []
      const DOEValues = []
      const outLimitsValues = []

      for (let i = 0; i < nonFlex.length; i++) {
        const totalPower = nonFlex[i] + flex[i]

        if (totalPower <= maxPwr[i]) {
          // If the sum of nonFlex and flex is less than or equal to maxPwr, the value is equal to the value of flex.
          flexValues.push(flex[i])
          DOEValues.push(maxPwr[i] - totalPower)
          outLimitsValues.push(0)
        } else {
          // If the sum of nonFlex and flex is greater than maxPwr, we calculate the value of flexValues and outLimitsValues
          flexValues.push(maxPwr[i] - nonFlex[i])
          DOEValues.push(0)
          outLimitsValues.push(totalPower - maxPwr[i])
        }
      }
      this.outLimitsValue = outLimitsValues
      this.DOEValue = DOEValues
      return flexValues
    },
    calculateDOEPast(powerLimit, nonFlexPower, totalPower){  
      let DOE = []   
      let minSize = Math.min(powerLimit.length, nonFlexPower.length)

      for (let i = 0; i < minSize; i++) {
        if (totalPower[i]>powerLimit[i]){
          DOE.push(powerLimit[i] - nonFlexPower[i])                
          this.flexMargin.push(0)
        }else{
          DOE.push(0)
          this.flexMargin.push(powerLimit[i] - nonFlexPower[i])
          
        }
        
      }
      return DOE
    },
    calculateDOEFuture(powerLimit, nonFlexPower, totalPower){  
      let DOE = [] 
      for (let i = 0; i < nonFlexPower.length; i++) {
        if (totalPower[i]>powerLimit[i]){
          DOE.push(powerLimit[i] - nonFlexPower[i])                
          this.flexMargin.push(0)
        }else{
          this.flexMargin.push(powerLimit[i] - nonFlexPower[i])
          DOE.push(0)
        }   
      }
      return DOE
    },
    openDownload() {
      this.downloadDialog = true
    },
    closeDownload() {
      this.downloadDialog = false
    },
    getDataCSV(allData) {
      const extractedData = allData.map(obj => ({
        name: obj.name,
        x: obj.x,
        y: obj.y
      }))
    this.data = extractedData
    },

  }
}
</script>

<style scoped>
.plotStation{
  margin-top: 20px!important;
}
.button-download {
  position: absolute;
  top: 115px;
  right: auto;
}
.button-download-icon{
  color:white;
  margin-left:47px;
}
</style>